import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  INVITE_SIGN_USER,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
} from 'constants/ActionTypes';
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
} from '../../firebase/firebase';
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut as fbSignOut,
} from 'firebase/auth';
import {
  errorNotification,
  hideAuthLoader,
  infoNotification,
  showAuthLoader,
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userProfileReset,
  userSignOutSuccess,
  userSignUpSuccess,
  userTwitterSignInSuccess,
} from '../actions';

const clearLocalStorage = () => {
  localStorage.removeItem('activeOrg');
  localStorage.removeItem('updatedAccessAt');
  localStorage.removeItem('user_id');
  localStorage.removeItem('selectedDivs');
  localStorage.removeItem('metrics_filter');
  localStorage.removeItem('position_history_state');
  localStorage.removeItem('gpsLastPosition');
  localStorage.removeItem('locale');
  localStorage.removeItem('timeZone');
};

const createUserWithEmailPasswordRequest = async (email, password) =>
  await createUserWithEmailAndPassword(auth, email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await signInWithEmailAndPassword(auth, email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signOutRequest = async () =>
  await fbSignOut(auth)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGoogleRequest = async () =>
  await signInWithPopup(auth, googleAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithFacebookRequest = async () =>
  await signInWithPopup(auth, facebookAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGithubRequest = async () =>
  await signInWithPopup(auth, githubAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithTwitterRequest = async () =>
  await signInWithPopup(auth, twitterAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const checkEmailExists = async email =>
  fetchSignInMethodsForEmail(auth, email)
    .then(methods => methods.length > 0)
    .catch(() => false);

function* createUserWithEmailPassword({ payload }) {
  const { email, password, avoidReload } = payload;
  try {
    yield put(showAuthLoader());
    const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
    if (signUpUser.message) {
      yield put(infoNotification(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));
      if (!avoidReload) {
        // force reload to cleanup
        document.location.href = '/';
      }
    }
  } catch (error) {
    yield put(errorNotification(error.toString()));
  }
  yield put(hideAuthLoader());
}

function* signInUserWithGoogle() {
  try {
    yield put(showAuthLoader());
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(infoNotification(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      // force reload to cleanup
      document.location.href = '/';
    }
  } catch (error) {
    yield put(errorNotification(error.toString()));
  }
  yield put(hideAuthLoader());
}

function* signInUserWithFacebook() {
  try {
    yield put(showAuthLoader());
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(infoNotification(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(errorNotification(error.toString()));
  }
}

function* signInUserWithGithub() {
  try {
    yield put(showAuthLoader());
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(infoNotification(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(errorNotification(error.toString()));
  }
}

function* signInUserWithTwitter() {
  try {
    yield put(showAuthLoader());
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(errorNotification('Your request has been canceled.'));
      } else {
        yield put(infoNotification(signUpUser.message));
      }
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(errorNotification(error.toString()));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    yield put(showAuthLoader());
    const response = yield call(signInUserWithEmailPasswordRequest, email, password);
    if (response.message) {
      yield put(errorNotification(response.message));
    } else {
      localStorage.setItem('user_id', response.user.uid);
      // force reload to cleanup
      document.location.href = '/-';
    }
  } catch (error) {
    yield put(errorNotification(error.toString()));
  }
  yield put(hideAuthLoader());
}

function* signOut() {
  try {
    const response = yield call(signOutRequest);
    if (response === undefined) {
      clearLocalStorage();
      yield put(userSignOutSuccess(response));
      // force reload to cleanup
      document.location.href = '/';
    } else {
      yield put(infoNotification(response.message));
    }
  } catch (error) {
    yield put(errorNotification(error.toString()));
  }
}

function* inviteSign({ payload }) {
  try {
    const emailExists = yield call(checkEmailExists, payload.email);

    yield put(userProfileReset());
    clearLocalStorage();

    yield put(userSignOutSuccess());
    if (emailExists) {
      yield call(signInUserWithEmailPassword, { payload });
    } else {
      yield call(createUserWithEmailPassword, { payload });
    }
  } catch (error) {
    yield put(errorNotification(error.toString()));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* inviteSignUser() {
  yield takeEvery(INVITE_SIGN_USER, inviteSign);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(signOutUser),
    fork(inviteSignUser),
  ]);
}
