import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

/* NOTE: When we pass "onlyMinutes" like a prop , we going to manage
  the time like a reminder
*/

const DisplayDuration = ({ minutes }) => {
  const intl = useIntl();
  const duration = moment.duration(minutes, 'minutes');
  const hoursInteger = Math.floor(duration.asHours());
  const mins = duration.minutes();
  const labelTime = {
    hour: intl.formatMessage({ id: 'general.hour' }),
    hours: intl.formatMessage({ id: 'general.hours' }),
    minute: intl.formatMessage({ id: 'general.minute' }),
    minutes: intl.formatMessage({ id: 'general.minutes' }),
  };

  if (hoursInteger === 0) {
    return `${mins} ${mins !== 1 ? labelTime.minutes : labelTime.minute}`;
  } else if (mins === 0) {
    return `${hoursInteger} ${hoursInteger !== 1 ? labelTime.hours : labelTime.hour}`;
  } else {
    return `${hoursInteger} ${
      hoursInteger !== 1 ? labelTime.hours : labelTime.hour
    } ${intl.formatMessage({ id: 'general.and' })} ${mins} ${
      mins !== 1 ? labelTime.minutes : labelTime.minute
    }`;
  }
};

DisplayDuration.RealTime = ({ time, refreshEach = 5000 }) => {
  const calculateTimeLeft = () => Math.max(0, Math.floor((time - Date.now()) / (1000 * 60)));
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const interval = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      if (newTimeLeft !== 0) {
        setTimeLeft(newTimeLeft);
      }
    }, refreshEach);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <DisplayDuration minutes={timeLeft} />;
};

// TODO: Currently we are not use this component but we can use it in the future
DisplayDuration.ExpireTime = ({ endTime, duration, turnOn }) => {
  const limit = endTime + duration;
  const canStart = Date.now() > endTime;
  const calculateTimeLeft = () => Math.max(0, Math.floor((limit - Date.now()) / 1000));
  const [timeLeft, setTimeLeft] = useState(canStart ? calculateTimeLeft : duration);

  useEffect(() => {
    if (turnOn) {
      const interval = setInterval(() => {
        const newTimeLeft = calculateTimeLeft();
        if (newTimeLeft !== 0 && canStart) {
          setTimeLeft(newTimeLeft);
        } else {
          clearInterval(interval);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [turnOn]);

  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = timeLeft % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
    seconds,
  ).padStart(2, '0')}`;
};

DisplayDuration.propTypes = {
  minutes: PropTypes.number.isRequired,
};

export default DisplayDuration;
