import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { RESET_PASSWORD } from 'constants/ActionTypes';
import { auth } from 'firebase/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

import {
  errorNotification,
  successNotification,
  userResetPasswordSuccess,
  userResetPasswordError,
} from '../actions';

const resetPasswordRequest = async email =>
  sendPasswordResetEmail(auth, email).catch(error => error);

function* resetPassword({ payload }) {
  try {
    const response = yield call(resetPasswordRequest, payload);
    if (response?.message) {
      yield put(userResetPasswordError());
      yield put(errorNotification(response.message));
    } else {
      yield put(successNotification('Password reset email sent!', 'Password reset'));
      yield put(userResetPasswordSuccess());
    }
  } catch (error) {
    yield put(errorNotification(error.toString()));
    yield put(userResetPasswordError());
  }
}

export function* userResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export default function* rootSaga() {
  yield all([fork(userResetPassword)]);
}
