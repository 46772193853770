import PropTypes from 'prop-types';
import { COMPONENT_TYPES } from '../../../eventTypes/constants';

export const eventTypesPropTypes = PropTypes.shape({
  description: PropTypes.string,
  divId: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  createdAt: PropTypes.object.isRequired,
  updatedAt: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  color: PropTypes.shape({
    a: PropTypes.number.isRequired,
    b: PropTypes.number.isRequired,
    g: PropTypes.number.isRequired,
    r: PropTypes.number.isRequired,
  }).isRequired,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      componentProps: PropTypes.shape({
        autocomplete: PropTypes.bool,
        checked: PropTypes.bool,
        format: PropTypes.string,
        isDecimal: PropTypes.bool,
        max: PropTypes.number, // max number value
        maxCount: PropTypes.number,
        maxLength: PropTypes.number, // max length of characters
        maxSize: PropTypes.number,
        min: PropTypes.number, // min number value
        minLength: PropTypes.number, // min length of characters
        pattern: PropTypes.string,
        placeholder: PropTypes.string,
      }).isRequired,
      commonProps: PropTypes.shape({ required: PropTypes.bool.isRequired }).isRequired,
      label: PropTypes.shape({
        description: PropTypes.string,
        name: PropTypes.string.isRequired,
      }),
      type: PropTypes.oneOf(Object.values(COMPONENT_TYPES)),
      textValue: PropTypes.string,
      numberValue: PropTypes.number,
      booleanValue: PropTypes.bool,
      tagValue: PropTypes.arrayOf(PropTypes.string),
      videoValue: PropTypes.arrayOf(
        PropTypes.shape({
          size: PropTypes.number.isRequired,
          tags: PropTypes.arrayOf(PropTypes.string).isRequired,
          url: PropTypes.string.isRequired,
        }),
      ),
      photoValue: PropTypes.arrayOf(
        PropTypes.shape({
          size: PropTypes.number.isRequired,
          tags: PropTypes.arrayOf(PropTypes.string).isRequired,
          url: PropTypes.string.isRequired,
        }),
      ),
      fileValue: PropTypes.arrayOf(
        PropTypes.shape({
          format: PropTypes.string.isRequired,
          size: PropTypes.number.isRequired,
          tags: PropTypes.arrayOf(PropTypes.string).isRequired,
          url: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
});

export const eventTypeSummaryPropTypes = PropTypes.shape({
  description: PropTypes.string,
  divId: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  color: PropTypes.shape({
    a: PropTypes.number.isRequired,
    b: PropTypes.number.isRequired,
    g: PropTypes.number.isRequired,
    r: PropTypes.number.isRequired,
  }).isRequired,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      componentProps: PropTypes.shape({
        autocomplete: PropTypes.bool,
        checked: PropTypes.bool,
        format: PropTypes.string,
        isDecimal: PropTypes.bool,
        max: PropTypes.number, // max number value
        maxCount: PropTypes.number,
        maxLength: PropTypes.number, // max length of characters
        maxSize: PropTypes.number,
        min: PropTypes.number, // min number value
        minLength: PropTypes.number, // min length of characters
        pattern: PropTypes.string,
        placeholder: PropTypes.string,
      }).isRequired,
      commonProps: PropTypes.shape({ required: PropTypes.bool.isRequired }).isRequired,
      label: PropTypes.shape({
        description: PropTypes.string,
        name: PropTypes.string.isRequired,
      }),
      type: PropTypes.oneOf(Object.values(COMPONENT_TYPES)),
      textValue: PropTypes.string,
      numberValue: PropTypes.number,
      booleanValue: PropTypes.bool,
      tagValue: PropTypes.arrayOf(PropTypes.string),
      videoValue: PropTypes.arrayOf(
        PropTypes.shape({
          size: PropTypes.number.isRequired,
          tags: PropTypes.arrayOf(PropTypes.string).isRequired,
          url: PropTypes.string.isRequired,
        }),
      ),
      photoValue: PropTypes.arrayOf(
        PropTypes.shape({
          size: PropTypes.number.isRequired,
          tags: PropTypes.arrayOf(PropTypes.string).isRequired,
          url: PropTypes.string.isRequired,
        }),
      ),
      fileValue: PropTypes.arrayOf(
        PropTypes.shape({
          format: PropTypes.string.isRequired,
          size: PropTypes.number.isRequired,
          tags: PropTypes.arrayOf(PropTypes.string).isRequired,
          url: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
});
