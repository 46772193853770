import React, { useEffect, useState } from 'react';
import styles from './styles.module.less';
import DisplayDuration from 'components/DisplayDuration';
import { InfoCircleOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';
import { EXECUTION_STATUS } from 'constants/commons';

export default function TimeExpiredWarning({ endTime, expiredTime, hasDelay, status }) {
  /**
   * Comment this block of code because it is not used for a while
   * and it is not necessary for the current
   * When services return stattus we can remove this block of code
   */

  // const [active, setActive] = useState(false);

  // const show = active && hasDelay;
  // const expiredTimeMs = expiredTime * 60000;
  // const limit = endTime + expiredTimeMs;

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     const currentTime = Date.now();
  //     if (currentTime >= endTime && currentTime <= limit) {
  //       setActive(true);
  //     } else {
  //       setActive(false);
  //       clearInterval(timer);
  //     }
  //   }, 5000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  // if (!show) return null;

  if (status !== EXECUTION_STATUS.delayed) return null;
  return (
    <div className="gx-mt-2 gx-mt-lg-0 gx-mb-2 gx-w-100">
      <div className="gx-flex-row gx-w-100">
        <div>
          <InfoCircleOutlined className={styles.expireTimeWarning} />
        </div>
        <div>
          <span className={styles.expireTimeWarningMessage}>
            <IntlMessages
              id="work.order.info.time.warning.expired.message"
              values={{ time: <DisplayDuration minutes={expiredTime} /> }}
            />
          </span>
        </div>
      </div>
    </div>
  );
}
