import React, { useState } from 'react';
import { Modal, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { db } from 'firebase/firebase';
import { collection, query, orderBy } from 'firebase/firestore';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';
import { useFirestoreQuery, ALLOWED_ROLES } from 'packages/utils';

import BoxContainer from 'components/BoxContainer';
import Title from 'components/BoxContainer/components/Title';
import FilterContainer from 'components/FilterContainer';
import User from '../../components/User';
import {
  getRouteCreateUser,
  getRouteToUserPermissions,
  getRouteToUsersInvite,
} from '../../constants';
import { userRemoveFetch, userSaveFetch } from '../../redux/actions';

import styles from './styles.module.less';
import useRemoteConfig from '../../../utils/hooks/useRemoteConfig';

const UsersList = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { value: userNotificationRemoteControl } = useRemoteConfig('notifications_view');

  const { id: orgId, data: orgData } = useSelector(
    ({ organizations }) => organizations.organization,
  );
  const [orgName, userId] = useSelector(({ user }) => [
    user.profile.data?.organizations?.[orgId]?.name,
    user.profile.data?.id,
  ]);

  const [deleteUserId, setDeleteUserId] = useState(null);

  const orgUsersRef = query(
    collection(db, 'organizations', orgId, 'users'),
    orderBy('displayName'),
  );

  const { data: users = [], loading } = useFirestoreQuery(orgUsersRef, [orgId]);

  const handleInviteUsers = () => history.push(getRouteToUsersInvite());

  const onChangeState = id => {
    const user = users.find(u => u.id === id);
    dispatch(userSaveFetch(orgId, id, { ...user, disabled: !user.disabled }));
  };

  const onCloseDeleteModal = () => setDeleteUserId(null);

  const onDeleteConfirm = () => {
    if (users.some(u => u.id === deleteUserId)) {
      dispatch(userRemoveFetch(orgId, deleteUserId));
    }
    onCloseDeleteModal();
  };

  const handleCreateUser = () => history.push(getRouteCreateUser());
  const userFound = users.find(u => u.id === deleteUserId);
  const fullName = userFound ? `${userFound?.firstName} || ${userFound?.lastName}` : '';

  return (
    <BoxContainer>
      <BoxContainer content fixed shadow>
        <FilterContainer
          title={
            <Title.Header
              value={<IntlMessages id="users.list.usersOf" values={{ organization: orgName }} />}
            />
          }
          actionButtons={[
            {
              label: intl.formatMessage({ id: 'users.list.inviteUsers' }),
              action: handleInviteUsers,
              type: 'primary',
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.INVITATIONS.CREATE,
            },
            {
              label: intl.formatMessage({ id: 'users.list.createUser' }),
              action: handleCreateUser,
              type: 'primary',
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.INVITATIONS.CREATE,
              hidden: !orgData?.domain,
            },
          ]}
          description={
            <div>
              <IntlMessages
                id="users.list.totalMembers"
                values={{ total: <span className={styles.membersAmount}>{users.length}</span> }}
              />
            </div>
          }
        />
      </BoxContainer>
      <BoxContainer content>
        <Modal
          cancelText={intl.formatMessage({ id: 'button.cancel' })}
          icon={<ExclamationCircleOutlined />}
          okText={intl.formatMessage({ id: 'form.confirm' })}
          onCancel={onCloseDeleteModal}
          onOk={onDeleteConfirm}
          title={intl.formatMessage({ id: 'users.list.remove.title' })}
          open={!!deleteUserId}
        >
          {intl.formatMessage(
            { id: 'users.list.remove.confirm' },
            {
              user: <b>{fullName}</b>,
              organization: <b>{orgName}</b>,
            },
          )}
        </Modal>
        {loading && <Spin size="large" />}
        {!loading && (
          <div className="gx-pt-4">
            {users?.map(user => (
              <User
                access={user.access}
                disabled={!!user.disabled}
                email={user.email}
                id={user.id}
                key={user.id}
                name={`${user?.firstName} ${user?.lastName}`}
                onChangeState={user.id !== userId ? onChangeState : null}
                onDelete={user.id !== userId ? setDeleteUserId : null}
                permissionsURL={getRouteToUserPermissions(user.id)}
                remoteConfigVal={userNotificationRemoteControl}
              />
            ))}
          </div>
        )}
      </BoxContainer>
    </BoxContainer>
  );
};

export default UsersList;
