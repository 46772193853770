import React, { useState } from 'react';
import { Button, Input, Modal, Radio, Space } from 'antd';
import PropTypes from 'prop-types';
import { ACTION_SCHEDULE_OPTIONS } from 'packages/schedules/constants';
import IntlMessages from 'util/IntlMessages';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import styles from './styles.module.less';

const ConfirmUpdateSchedule = ({ onChange, isVisible, onClose, onSave }) => {
  const intl = useIntl();
  const isLoading = useSelector(({ schedules }) => schedules.schedule.save.loading);
  const [confirmExecutionChanges, setConfirmExecutionChanges] = useState('');
  const [executionOverrideSelected, setExecutionOverrideSelected] = useState(
    ACTION_SCHEDULE_OPTIONS.next,
  );

  const CONTENT = {
    important: intl.formatMessage({ id: 'schedule.confirm.important' }),
    title: intl.formatMessage({ id: 'schedule.confirm.title' }),
    description: intl.formatMessage({ id: 'schedule.confirm.description' }),
    subtitle: intl.formatMessage({ id: 'schedule.confirm.subtitle' }),
    futureExecutionOption: intl.formatMessage({ id: 'schedule.confirm.future.option' }),
    replaceExecutionOption: intl.formatMessage({ id: 'schedule.confirm.replace.option' }),
    replaceExecutionDescription: intl.formatMessage({
      id: 'schedule.confirm.replace.description',
    }),
  };
  const { locale = 'es' } = useSelector(({ settings }) => settings.locale);
  const textToConfirm = locale === 'es' ? 'CONFIRMAR' : 'CONFIRM';
  const disabledSaveButton =
    confirmExecutionChanges !== textToConfirm &&
    executionOverrideSelected === ACTION_SCHEDULE_OPTIONS.now;

  const handleExecutionOptionChange = e => {
    onChange(e);
    setExecutionOverrideSelected(e.target.value);
  };

  return (
    <Modal
      className={styles.executionWarning}
      open={isVisible}
      closable={false}
      footer={null}
      width={480}
    >
      <div>
        <div className="gx-text-center">
          <InfoCircleTwoTone twoToneColor="#FFB556" style={{ fontSize: '27px' }} />
        </div>
        <div className="gx-mt-3">
          <div className="gx-text-center gx-guarnic-headline-1">
            <span className="gx-text-truncate gx-d-block">{CONTENT.important}</span>
            <span className="gx-text-truncate">{CONTENT.title}</span>
          </div>
          <div className="gx-text-center">
            <p>{CONTENT.description}</p>
          </div>
        </div>
        <div className="gx-mt-3">
          <div className="gx-guarnic-headline-2 gx-guarnic-pb-1">
            <span>{CONTENT.subtitle}</span>
          </div>
          <Radio.Group
            onChange={handleExecutionOptionChange}
            defaultValue={ACTION_SCHEDULE_OPTIONS.next}
          >
            <Space direction="vertical">
              <Radio value={ACTION_SCHEDULE_OPTIONS.next}>
                <span className="gx-text-truncate">{CONTENT.futureExecutionOption}</span>
              </Radio>
              <Radio value={ACTION_SCHEDULE_OPTIONS.now}>
                <span className="gx-text-truncate gx-d-block">
                  {CONTENT.replaceExecutionOption}
                </span>
                <span className="gx-fs-md">({CONTENT.replaceExecutionDescription})</span>
              </Radio>
            </Space>
          </Radio.Group>
        </div>
        {executionOverrideSelected === ACTION_SCHEDULE_OPTIONS.now && (
          <Input
            onChange={e => setConfirmExecutionChanges(e?.target?.value.toUpperCase())}
            className="gx-w-100 gx-mt-3"
            placeholder={textToConfirm}
            value={confirmExecutionChanges}
          />
        )}

        <div className="gx-mt-4 gx-w-100 gx-d-flex">
          <Button className="gx-mb-0 gx-w-100" onClick={onClose} disabled={isLoading}>
            <IntlMessages id="button.cancel" />
          </Button>
          <Button
            disabled={disabledSaveButton || isLoading}
            type="primary"
            className="gx-mb-0 gx-w-100"
            onClick={onSave}
          >
            <IntlMessages id="button.save" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmUpdateSchedule.propTypes = {
  onChange: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ConfirmUpdateSchedule;
