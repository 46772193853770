import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tooltip } from 'antd';
import IntlMessages from 'util/IntlMessages';

import styles from './index.module.less';

const MultiFilter = props => {
  const { className, options, placeholder, values } = props;
  const valuesOptions = options.filter(({ value }) => values.includes(value));

  const renderValuesTexts = () => (
    <>
      <span className={styles.textOptions}>
        {valuesOptions
          .slice(0, 2)
          .map(o => o.text)
          .join(' | ')}
      </span>
      {valuesOptions.length > 2 && (
        <span className={styles.textOptionsCount}>+ {valuesOptions.length - 2}</span>
      )}
    </>
  );

  return (
    <>
      <button
        aria-controls="expandable"
        aria-expanded="false"
        aria-haspopup="listbox"
        aria-label={placeholder}
        className={classnames(className, styles.button)}
        data-toggle="true"
        role="combobox"
        type="button"
      >
        <span className={styles.text}>
          {valuesOptions.length > 0 ? renderValuesTexts() : placeholder}
        </span>
        <span className={styles.actions}>
          {valuesOptions.length > 0 && (
            <span className={styles.icon}>
              <Tooltip title={<IntlMessages id="components.multiFilter.removeSelected" />}>
                <i className="icon icon-close" />
              </Tooltip>
            </span>
          )}
          <i className={classnames(styles.icon, 'icon icon-charvlet-down')} />
        </span>
      </button>
    </>
  );
};

MultiFilter.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,

  className: PropTypes.string,
};

MultiFilter.defaultProps = {
  className: '',
};

export default MultiFilter;
