import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Button, Checkbox, Divider, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import IntlMessages from 'util/IntlMessages';
import { showAuthLoader, userSignIn } from 'appRedux/actions/Auth';

import styles from './styles.module.less';

import SocialSignIn from '../../components/SocialSignIn';
import Card from '../../components/Card';

const SignIn = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { authUser, loader } = useSelector(({ auth }) => auth);
  const isUserIn = !!authUser;
  const disableInputs = loader || isUserIn;

  const handleFinish = values => {
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
  };

  return (
    <Card
      footer={
        <>
          <IntlMessages id="auth.signIn.dontHaveAccount" />{' '}
          <Link to="/signup">
            <IntlMessages id="app.userAuth.signUp" />
          </Link>
        </>
      }
      classname={styles.texts}
    >
      <h1 className={classnames('m-bottom-7', styles.title)}>
        <IntlMessages id="auth.signIn.title" />
      </h1>
      <SocialSignIn disabled={disableInputs} />
      <Divider>
        <IntlMessages id="auth.socialSignIn.or" />
      </Divider>
      <Form
        initialValues={{
          email: '',
          password: '',
          publicDevice: false,
        }}
        layout="vertical"
        requiredMark={false}
        name="basic"
        onFinish={handleFinish}
        className="gx-signin-form gx-form-row0"
      >
        <Form.Item
          label={intl.formatMessage({ id: 'appModule.email' })}
          name="email"
          rules={[
            {
              type: 'email',
              message: intl.formatMessage({ id: 'form.format.msg' }),
            },
            { required: true, message: intl.formatMessage({ id: 'form.required.msg' }) },
          ]}
        >
          <Input autoComplete="username" disabled={disableInputs} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'appModule.password' })}
          name="password"
          rules={[{ required: true, message: intl.formatMessage({ id: 'form.required.msg' }) }]}
        >
          <Input.Password autoComplete="current-password" disabled={disableInputs} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            className={classnames('gx-mb-0', styles.submitButton, isUserIn && 'gx-btn-cyan')}
            htmlType="submit"
            loading={disableInputs}
            disabled={disableInputs}
          >
            <IntlMessages id={isUserIn ? 'app.userAuth.signInDone' : 'app.userAuth.signIn'} />
          </Button>
        </Form.Item>
        <div className={styles.subpanel}>
          <Form.Item name="publicDevice" valuePropName="checked">
            <Checkbox disabled={disableInputs}>
              <IntlMessages id="auth.signIn.publicDevice" />
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Link className="gx-login-form-forgot" to="/forgot_password">
              <IntlMessages id="app.userAuth.forgotPassword" />
            </Link>
          </Form.Item>
        </div>
      </Form>
    </Card>
  );
};

export default SignIn;
