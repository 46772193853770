export const ACCESS_TYPES = {
  // Org access only
  OWNER: 'o',
  // Div access only
  EDITOR: 'e',
  // Both
  ADMIN: 'a',
  READER: 'r',
  USER: 'u',
};

export const ACCESS_TYPES_INTL = {
  [ACCESS_TYPES.OWNER]: {
    desc: 'accessType.owner.description',
    name: 'accessType.owner',
  },
  [ACCESS_TYPES.ADMIN]: {
    desc: 'accessType.admin.description',
    name: 'accessType.admin',
  },
  [ACCESS_TYPES.EDITOR]: {
    desc: 'accessType.editor.description',
    name: 'accessType.editor',
  },
  [ACCESS_TYPES.READER]: {
    desc: 'accessType.reader.description',
    name: 'accessType.reader',
  },
  [ACCESS_TYPES.USER]: {
    desc: 'accessType.user.description',
    name: 'accessType.user',
  },
};

// To the dev: if you add a new role here
// you should update "getUserRoles" and "ALLOWED_ROLES"
export const USER_ROLES = {
  OWNER: 'owner',
  ORG_ADMIN: 'orgAdmin',
  ORG_READER: 'orgReader',
  // ----------------------
  // The following are combinations of the "u" or "r" org access, with div access
  DIV_ADMIN: 'divAdmin',
  DIV_READER: 'divReader',
  DIV_EDITOR: 'divEditor',
  DIV_USER: 'divUser',
};

const ALL_USER_ROLES = Object.values(USER_ROLES);
// User that doesnt have any of the above accesses
const OUTSIDER = 'outsider';

// Possible actions to use with firestore
const ACTIONS = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  GET: 'GET',
  LIST: 'LIST',
};

// Owners and Admins only have/need the ORG access
// Users only have/need the division access (check?)
// But Readers have 2 access levels, the one at the DIV and the one at the ORG
// Case 1: to read a X doc you need to be ORG_ADMIN or DIV_ADMIN at div Y
// - User has: org access: Reader; div Y access: Admin
// - has enought div access, but not org access. Result: he should be able to read the X doc.
// Case 2: to read a X doc you need to be ORG_READER or DIV_ADMIN at div Y
// - User has: org access: Reader; div Y access: User
// - has enought org access, but not div access. Result: he should be able to read the X doc.
//
// Both access levels should be taken in consideration,
// thats why ORG_READERS return 2 "userRoles"
const getUserRoles = (orgAccess, divAccess) => {
  const divUserRoles = {
    [ACCESS_TYPES.ADMIN]: USER_ROLES.DIV_ADMIN,
    [ACCESS_TYPES.EDITOR]: USER_ROLES.DIV_EDITOR,
    [ACCESS_TYPES.READER]: USER_ROLES.DIV_READER,
    [ACCESS_TYPES.USER]: USER_ROLES.DIV_USER,
  };

  switch (orgAccess) {
    case ACCESS_TYPES.OWNER:
      return [USER_ROLES.OWNER];
    case ACCESS_TYPES.ADMIN:
      return [USER_ROLES.ORG_ADMIN];
    case ACCESS_TYPES.READER:
      return [divUserRoles[divAccess], USER_ROLES.ORG_READER];
    case ACCESS_TYPES.USER:
      return [divUserRoles[divAccess]] || [OUTSIDER];
    default:
      return [OUTSIDER];
  }
};

// Returns an arry of only those divisions that meets any of the required "allowedRoles"
export const getDivsWithAccess = (allowedRoles, orgAccess, userDivs) => {
  const allowedDivs = Object.values(userDivs || {}).filter(({ access }) => {
    const userRoles = getUserRoles(orgAccess, access);
    return userRoles.some(role => allowedRoles.includes(role));
  });

  return allowedDivs;
};

// If ANY of those types meets any of the required "allowedRoles"
// then the user has access
export const hasAnyAllowedRole = (allowedRoles, orgAccess, userDivs) => {
  const allUserRoles = [];
  Object.values(userDivs || {}).forEach(({ access }) => {
    const userRoles = getUserRoles(orgAccess, access);
    allUserRoles.push(...userRoles);
  });
  allUserRoles.push(...getUserRoles(orgAccess));

  return allowedRoles ? allUserRoles.some(role => allowedRoles.includes(role)) : true;
};

// Allowed firebase actions by entity and user access
// Firebase collections/entities organized with their corresponding nested entities
// To the dev: this is an attempt to mirror how the accesses permissions where organizated and declared at "Services" project.
// You should check the "generic.test.ts" files to keep these Actions updated. In case of a new ACCESS_TYPE being added you
// should map all the corresponding actions access he has for each entity.
// If new entities are added to the "Services" project this should be updated
export const ALLOWED_ROLES = {
  ORGANIZATIONS: {
    [ACTIONS.CREATE]: [...ALL_USER_ROLES, OUTSIDER],
    [ACTIONS.UPDATE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
    [ACTIONS.DELETE]: [],
    [ACTIONS.GET]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_READER],
    [ACTIONS.LIST]: [],
    DIVISIONS: {
      [ACTIONS.CREATE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
      [ACTIONS.UPDATE]: [
        USER_ROLES.OWNER,
        USER_ROLES.ORG_ADMIN,
        USER_ROLES.DIV_ADMIN,
        USER_ROLES.DIV_EDITOR,
      ],
      [ACTIONS.DELETE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
      [ACTIONS.GET]: [
        USER_ROLES.OWNER,
        USER_ROLES.ORG_ADMIN,
        USER_ROLES.ORG_READER,
        USER_ROLES.DIV_ADMIN,
        USER_ROLES.DIV_EDITOR,
        USER_ROLES.DIV_READER,
      ],
      [ACTIONS.LIST]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_READER],
      EVENT_TYPES: {
        [ACTIONS.CREATE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.UPDATE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.DELETE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
        [ACTIONS.GET]: ALL_USER_ROLES,
        [ACTIONS.LIST]: ALL_USER_ROLES,
      },
      EVENTS: {
        [ACTIONS.CREATE]: ALL_USER_ROLES,
        [ACTIONS.UPDATE]: ALL_USER_ROLES,
        [ACTIONS.DELETE]: [],
        [ACTIONS.GET]: ALL_USER_ROLES,
        [ACTIONS.LIST]: ALL_USER_ROLES,
      },
      EXECUTIONS: {
        [ACTIONS.CREATE]: [],
        [ACTIONS.UPDATE]: [USER_ROLES.DIV_USER],
        [ACTIONS.DELETE]: [],
        [ACTIONS.GET]: ALL_USER_ROLES,
        [ACTIONS.LIST]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.ORG_READER,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_READER,
          USER_ROLES.DIV_EDITOR,
        ],
      },
      GROUPS: {
        [ACTIONS.CREATE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.UPDATE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.DELETE]: [],
        [ACTIONS.GET]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.ORG_READER,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_READER,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.LIST]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.ORG_READER,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_READER,
          USER_ROLES.DIV_EDITOR,
        ],
      },
      LOCATIONS: {
        [ACTIONS.CREATE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.UPDATE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.DELETE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
        [ACTIONS.GET]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.ORG_READER,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_READER,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.LIST]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.ORG_READER,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_READER,
          USER_ROLES.DIV_EDITOR,
        ],
      },
      SCHEDULES: {
        [ACTIONS.CREATE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.UPDATE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.DELETE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.ORG_READER,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_READER,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.GET]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.LIST]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.ORG_READER,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_READER,
          USER_ROLES.DIV_EDITOR,
        ],
      },
      TRIGGERS: {
        [ACTIONS.CREATE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.UPDATE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.DELETE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.GET]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.LIST]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.ORG_READER,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_READER,
          USER_ROLES.DIV_EDITOR,
        ],
      },
      USER_POSITION_HISTORY: {
        [ACTIONS.GET]: ALL_USER_ROLES,
        [ACTIONS.LIST]: ALL_USER_ROLES,
      },
      WORK_TEMPLATES: {
        [ACTIONS.CREATE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.UPDATE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.DELETE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.ORG_READER,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_READER,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.GET]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.LIST]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.ORG_READER,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_READER,
          USER_ROLES.DIV_EDITOR,
        ],
      },
      WORK_ORDER: {
        [ACTIONS.CREATE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.UPDATE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.DELETE]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.ORG_READER,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_READER,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.GET]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_EDITOR,
        ],
        [ACTIONS.LIST]: [
          USER_ROLES.OWNER,
          USER_ROLES.ORG_ADMIN,
          USER_ROLES.ORG_READER,
          USER_ROLES.DIV_ADMIN,
          USER_ROLES.DIV_READER,
          USER_ROLES.DIV_EDITOR,
        ],
      },
    },
    DASHBOARDS: {
      [ACTIONS.CREATE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
      [ACTIONS.UPDATE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
      [ACTIONS.DELETE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
      [ACTIONS.GET]: ALL_USER_ROLES,
      [ACTIONS.LIST]: ALL_USER_ROLES,
      CHARTS: {
        [ACTIONS.CREATE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
        [ACTIONS.UPDATE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
        [ACTIONS.DELETE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
        [ACTIONS.GET]: ALL_USER_ROLES,
        [ACTIONS.LIST]: ALL_USER_ROLES,
      },
    },
    EVENTS_SUMMARY: {
      [ACTIONS.CREATE]: [],
      [ACTIONS.UPDATE]: [],
      [ACTIONS.DELETE]: [],
      [ACTIONS.GET]: ALL_USER_ROLES,
      [ACTIONS.LIST]: ALL_USER_ROLES,
    },
    EVENT_TYPES: {
      [ACTIONS.CREATE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
      [ACTIONS.UPDATE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
      [ACTIONS.DELETE]: [],
      [ACTIONS.GET]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_READER],
      [ACTIONS.LIST]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_READER],
    },
    INVITATIONS: {
      [ACTIONS.CREATE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
      [ACTIONS.UPDATE]: [],
      [ACTIONS.DELETE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
      [ACTIONS.GET]: [],
      [ACTIONS.LIST]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
    },
    LOCATIONS: {
      [ACTIONS.CREATE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN, USER_ROLES.DIV_ADMIN],
      [ACTIONS.UPDATE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
      [ACTIONS.DELETE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
      [ACTIONS.GET]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_READER],
      [ACTIONS.LIST]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_READER],
    },
    USERS: {
      [ACTIONS.CREATE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
      [ACTIONS.UPDATE]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN],
      [ACTIONS.DELETE]: [],
      [ACTIONS.GET]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_READER],
      [ACTIONS.LIST]: [USER_ROLES.OWNER, USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_READER],
    },
    USER_POSITION_HISTORY: {
      [ACTIONS.GET]: ALL_USER_ROLES,
      [ACTIONS.LIST]: ALL_USER_ROLES,
    },
  },
  USERS: {
    [ACTIONS.CREATE]: [],
    [ACTIONS.UPDATE]: [],
    [ACTIONS.DELETE]: [],
    [ACTIONS.GET]: [],
    [ACTIONS.LIST]: [],
  },
  USERS_ACCESS: {
    [ACTIONS.CREATE]: [],
    [ACTIONS.UPDATE]: [],
    [ACTIONS.DELETE]: [],
    [ACTIONS.GET]: [],
    [ACTIONS.LIST]: [],
  },
};
