import { ALLOWED_ROLES } from 'packages/utils';

import { PATHS } from './constants';
import SchedulesList from './screens/SchedulesList';
import Schedule from './screens/Schedule';

export default [
  {
    component: SchedulesList,
    path: PATHS.BASE_URL,
    exact: true,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.SCHEDULES.LIST,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: Schedule,
    path: PATHS.EDIT_SCHEDULE,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.SCHEDULES.UPDATE,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: Schedule,
    path: PATHS.CREATE_SCHEDULE,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.SCHEDULES.CREATE,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
];
