import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'antd';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { startCase } from 'lodash';

import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';
import CustomInput from 'components/CustomInput';

import styles from '../../styles.module.less';

const StepOne = ({ onNext, initialData }) => {
  const intl = useIntl();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { isDirty, isValid, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: initialData,
  });

  const customSubmit = ({ firstName, lastName }) =>
    onNext(
      isDirty
        ? {
            firstName: startCase(firstName.trim().toLowerCase()),
            lastName: startCase(lastName.trim().toLowerCase()),
          }
        : null,
    );

  const formSubmit = handleSubmit(customSubmit);

  const onEnter = f => e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault();
      f();
    }
  };

  return (
    <>
      <h2 className={classnames('m-bottom-9', styles.titleSmall)}>
        <IntlMessages id="onboarding.stepOne.title" />
      </h2>
      <form onSubmit={formSubmit} className={styles.formContainer}>
        <CustomInput
          autoFocus
          onKeyDown={onEnter(() => setFocus('lastName'))}
          label={intl.formatMessage({ id: 'onboarding.stepOne.name' })}
          textClassName={styles.whiteText}
          errors={errors}
          register={register('firstName', {
            required: <IntlMessages id="form.required.msg" />,
            minLength: {
              value: 3,
              message: intl.formatMessage({ id: 'form.minCharLength' }, { length: 3 }),
            },
            pattern: {
              value: /^([A-Za-zÀ-ÿ][a-zA-Z]+[ ]*)+$/g,
              message: intl.formatMessage({ id: 'form.noSpecialCharacters' }),
            },
          })}
        />
        <CustomInput
          onKeyDown={onEnter(formSubmit)}
          className="m-top-5"
          label={intl.formatMessage({ id: 'onboarding.stepOne.lastname' })}
          textClassName={styles.whiteText}
          errors={errors}
          register={register('lastName', {
            required: <IntlMessages id="form.required.msg" />,
            minLength: {
              value: 3,
              message: intl.formatMessage({ id: 'form.minCharLength' }, { length: 3 }),
            },
            pattern: {
              value: /^([A-Za-zÀ-ÿ][a-zA-Z]+[ ]*)+$/g,
              message: intl.formatMessage({ id: 'form.noSpecialCharacters' }),
            },
          })}
        />
        <div className={classnames('m-top-5', styles.buttonsPanel)}>
          <Button type="text" className={styles.text} onClick={() => history.push('../')}>
            <ArrowLeftOutlined className="m-right-1" />
            <IntlMessages id="onboarding.wizard.previous" />
          </Button>
          <Button
            type={isValid ? 'primary' : 'default'}
            shape="circle"
            htmlType="submit"
            disabled={!isValid}
          >
            <ArrowRightOutlined />
          </Button>
        </div>
      </form>
    </>
  );
};

export default StepOne;
