import React from 'react';

import { COMPONENT_TYPES } from '../../../eventTypes/constants';

import Videos from './components/Videos';
import Files from './components/Files';
import Number from './components/Number';
import Photos from './components/Photos';
import Tags from './components/Tags';
import Text from './components/Text';
import Toggle from './components/Toggle';
import Select from './components/Select';
import Date from './components/Date';

const componentValue = (component, idx, labelClassname, fullSize = false) => {
  const values = {
    [COMPONENT_TYPES.FILE]: () => (
      <Files
        key={`${component.label.name}-${idx}`}
        component={component}
        labelClassname={labelClassname}
      />
    ),
    [COMPONENT_TYPES.NUMBER]: () => (
      <Number
        key={`${component.label.name}-${idx}`}
        component={component}
        labelClassname={labelClassname}
      />
    ),
    [COMPONENT_TYPES.PHOTO]: () => (
      <Photos
        key={`${component.label.name}-${idx}`}
        component={component}
        labelClassname={labelClassname}
      />
    ),
    [COMPONENT_TYPES.TAG]: () => (
      <Tags
        key={`${component.label.name}-${idx}`}
        component={component}
        labelClassname={labelClassname}
        fullSize={fullSize}
      />
    ),
    [COMPONENT_TYPES.TEXT]: () => (
      <Text
        key={`${component.label.name}-${idx}`}
        component={component}
        labelClassname={labelClassname}
        fullSize={fullSize}
      />
    ),
    [COMPONENT_TYPES.TOGGLE]: () => (
      <Toggle
        key={`${component.label.name}-${idx}`}
        component={component}
        labelClassname={labelClassname}
      />
    ),
    [COMPONENT_TYPES.VIDEO]: () => (
      <Videos
        key={`${component.label.name}-${idx}`}
        component={component}
        labelClassname={labelClassname}
      />
    ),
    [COMPONENT_TYPES.SELECT]: () => (
      <Select
        key={`${component.label.name}-${idx}`}
        component={component}
        labelClassname={labelClassname}
      />
    ),
    [COMPONENT_TYPES.DATE]: () => (
      <Date
        key={`${component.label.name}-${idx}`}
        component={component}
        labelClassname={labelClassname}
      />
    ),

    default: () => <span key={idx}>component does not exists</span>,
  };

  const componentBuilded = values[`${component?.type}`];

  return componentBuilded ? componentBuilded() : values.default();
};

export { Videos, Files, Number, Photos, Tags, Text, Toggle, componentValue };
