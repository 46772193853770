import React from 'react';
import { Avatar } from 'antd';
import IntlMessages from 'util/IntlMessages';

import styles from './styles.module.less';

const StepIndicator = ({ step, isInvitedUser }) => {
  return (
    <div className={`m-bottom-10 ${styles.container}`}>
      <div className={styles.stepContainer}>
        <Avatar className={step === 0 ? styles.activeStep : styles.step}>1</Avatar>
        <span className={`m-left-2 ${styles.text}`}>
          <IntlMessages id="onboarding.stepIndicator.aboutYou" />
        </span>
      </div>
      {!isInvitedUser && (
        <>
          <div className={`m-left-2 m-right-2 ${styles.divider}`} />
          <div className={styles.stepContainer}>
            <Avatar className={step === 1 ? styles.activeStep : styles.step}>2</Avatar>
            <span className={`m-left-2 ${styles.text}`}>
              <IntlMessages id="onboarding.stepIndicator.yourOrganization" />
            </span>
          </div>
          <div className={`m-left-2 m-right-2 ${styles.divider}`} />
          <div className={styles.stepContainer}>
            <Avatar className={step === 2 ? styles.activeStep : styles.step}>3</Avatar>
            <span className={`m-left-2 ${styles.text}`}>
              <IntlMessages id="onboarding.stepIndicator.yourDivision" />
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default StepIndicator;
