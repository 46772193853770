import {
  USER_ACCESS_FETCH,
  USER_ACCESS_FETCH_ERROR,
  USER_ACCESS_FETCH_SUCCESS,
  USER_ACCESS_RESET,
} from 'constants/ActionTypes';

const getInitialState = () => ({
  data: null,
  error: null,
  loaded: false,
  loading: false,
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case USER_ACCESS_FETCH: {
      return {
        ...state,
        data: null,
        error: null,
        loaded: false,
        loading: true,
      };
    }
    case USER_ACCESS_FETCH_ERROR: {
      return {
        ...state,
        data: null,
        error: action.payload,
        loaded: true,
        loading: false,
      };
    }
    case USER_ACCESS_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loaded: true,
        loading: false,
      };
    }

    case USER_ACCESS_RESET: {
      return getInitialState();
    }

    default:
      return state;
  }
};
