export const ACTIONS = {
  UTI_FRP_FIRST_BATCH: 'UTI_FRP_FIRST_BATCH',
  UTI_FRP_FIRST_BATCH_ERROR: 'UTI_FRP_FIRST_BATCH_ERROR',
  UTI_FRP_NEXT_BATCH: 'UTI_FRP_NEXT_BATCH',
  UTI_FRP_NEXT_BATCH_ERROR: 'UTI_FRP_NEXT_BATCH_ERROR',
  UTI_FRP_BATCH_SUCCESS: 'UTI_FRP_BATCH_SUCCESS',
  UTI_FRP_RESET: 'UTI_FRP_RESET',
};

export const realtimePaginationFirstBatch = () => ({
  type: ACTIONS.UTI_FRP_FIRST_BATCH,
});

export const realtimePaginationFirstBatchError = error => ({
  payload: error,
  type: ACTIONS.UTI_FRP_FIRST_BATCH_ERROR,
});

export const realtimePaginationNextBatch = () => ({
  type: ACTIONS.UTI_FRP_NEXT_BATCH,
});

export const realtimePaginationNextBatchError = error => ({
  payload: error,
  type: ACTIONS.UTI_FRP_NEXT_BATCH_ERROR,
});

export const realtimePaginationBatchSuccess = (lastDocument, data, next) => ({
  payload: { lastDocument, data, next },
  type: ACTIONS.UTI_FRP_BATCH_SUCCESS,
});

export const realtimePaginationReset = () => ({
  type: ACTIONS.UTI_FRP_RESET,
});
