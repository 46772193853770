import React, { useCallback, useEffect, useState } from 'react';
import styles from '../styles.module.less';
import Logo from '../../Logo';
import { Button, Spin } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { useHistory } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';

const UnSubscribed = ({ token }) => {
  const history = useHistory();
  const functions = getFunctions();
  const [loading, setLoading] = useState(false);
  const [unSubscribeSucessful, setUnSubscribeSucessful] = useState(false);

  const SUBSCRIBE_FUNC_NAME = `functions-subscribe-subscribe?token=${token}`;
  const UNSUBSCRIBE_FUNC_NAME = `functions-unsubscribe-unsubscribe?token=${token}`;

  const subscribing = useCallback(() => httpsCallable(functions, SUBSCRIBE_FUNC_NAME), []);
  const unsubscribing = useCallback(() => httpsCallable(functions, UNSUBSCRIBE_FUNC_NAME), []);

  const onSubscribing = () => {
    if (!token) {
      history.push('/subscriptions/notifications/subscribedError');
      return;
    }
    setLoading(true);

    subscribing()()
      .then(() => {
        history.push('/subscriptions/notifications/subscribed');
      })
      .catch(() => {
        history.push('/subscriptions/notifications/subscribedError');
      });
  };

  useEffect(() => {
    if (!token) {
      history.push('/subscriptions/notifications/unsubscribedError');
      return;
    }

    setLoading(true);

    unsubscribing()()
      .then(() => {
        setUnSubscribeSucessful(true);
        setLoading(false);
      })
      .catch(() => {
        history.push('/subscriptions/notifications/unsubscribedError');
      });
  }, []);

  if (loading) return <Spin />;

  return (
    unSubscribeSucessful && (
      <div className="gx-flex-row gx-justify-content-center gx-pt-4">
        <div className="gx-flex-column gx-justify-content-center">
          <div className="gx-flex-column gx-align-items-center gx-pb-4">
            <Logo />
            <div className={styles.bodyContainer}>
              <p className={styles.bodyTitle}>
                <IntlMessages id="subscriptions.notification.unsubscribed.title" />
              </p>
              <p className={styles.subTitle}>
                <IntlMessages id="subscriptions.notification.unsubscribed.subTitle" />
              </p>
            </div>
          </div>
          <div className={styles.buttonC}>
            <Button onClick={() => history.push('/signin')} block type="primary">
              <IntlMessages id="subscriptions.notification.enter" />
            </Button>
            <Button onClick={onSubscribing} block danger>
              <IntlMessages id="subscriptions.notification.subscribed.cancel" />
            </Button>
          </div>
        </div>
      </div>
    )
  );
};

export default UnSubscribed;
