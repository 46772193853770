import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Button, Table, Switch, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { getStateList } from 'packages/divisions';
import { ACCESS_TYPES, ACCESS_TYPES_INTL } from 'packages/utils';
import IntlMessages from 'util/IntlMessages';

import styles from './styles.module.less';

import { userSaveFetch } from '../../../../redux/actions';

const DivisionsPermissions = ({ user }) => {
  const { orgId, userId } = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [permissions, setPermissions] = useState({});
  const [modified, setModified] = useState(false);
  const divisionsListState = useSelector(getStateList);

  useEffect(() => {
    // turn divisions { id: { access, id, name, desc } }
    // into access { id: access }
    const newPerms = Object.entries(user.divisions || {}).reduce(
      (perms, [id, { access }]) => ({ ...perms, [id]: access }),
      {},
    );

    if (Object.keys(newPerms).length === 0) {
      const allPermissions = divisionsListState.data?.reduce(
        (record, { id: divId }) => ({
          ...record,
          [divId]: user.access,
        }),
        {},
      );
      setPermissions(allPermissions);
    } else {
      setPermissions(newPerms);
    }
    setModified(false);
  }, [divisionsListState.data, user]);

  const onSave = () => {
    // turn access { id: access }
    // into divisions { id: { access, id, name, desc } }
    const divisions = Object.entries(permissions).reduce(
      (divs, [id, access]) => ({
        ...divs,
        [id]: {
          access,
          ...divisionsListState.data?.find(d => d.id === id),
        },
      }),
      {},
    );

    dispatch(userSaveFetch(orgId, userId, { ...user, divisions }));
  };

  const setAccess = (divId, access) => {
    const { [divId]: old, ...newPerms } = permissions;
    if (access) {
      newPerms[divId] = access;
    }

    setPermissions(newPerms);
    setModified(true);
  };

  const availableDivisionAccess = [
    ACCESS_TYPES.ADMIN,
    ACCESS_TYPES.EDITOR,
    ACCESS_TYPES.READER,
    ACCESS_TYPES.USER,
  ];

  const accessList = availableDivisionAccess.map(ac => ({
    key: ac,
    name: intl.formatMessage({ id: ACCESS_TYPES_INTL[ac].name }),
  }));

  const columns = [
    {
      dataIndex: 'name',
      fixed: 'left',
      title: intl.formatMessage({ id: 'users.permissions.table.associatedDivisions' }),
    },
    ...accessList.map((access, index) => ({
      colSpan: index === 0 ? accessList.length : 0,
      dataIndex: access.key,
      title: intl.formatMessage({ id: 'users.permissions.table.permissions' }),
    })),
  ];
  // Show a "diable/enable" division button for "Users" access
  if (user.access === ACCESS_TYPES.USER) {
    columns.push({
      dataIndex: 'access',
      title: intl.formatMessage({ id: 'users.permissions.table.access' }),
    });
  }

  const dataSource =
    divisionsListState.data?.map(div => {
      const rowData = {
        key: div.id,
        name: div.name,
        ...accessList.reduce(
          (obj, ac) => ({
            ...obj,
            [ac.key]: (
              <Radio
                checked={permissions[div.id] === ac.key}
                value={ac.key}
                onClick={() => setAccess(div.id, ac.key)}
              >
                {ac.name}
              </Radio>
            ),
          }),
          {},
        ),
      };
      // Show a "diable/enable" division button for "Users" access
      if (user.access === ACCESS_TYPES.USER) {
        rowData.access = (
          <Switch
            checked={!!permissions[div.id]}
            onChange={on => setAccess(div.id, on ? accessList[0].key : null)}
          />
        );
      }
      return rowData;
    }) || [];

  return (
    <>
      <Table columns={columns} dataSource={dataSource} bordered pagination={false} />
      {modified && (
        <div className={classnames('m-top-6', styles.buttonsPanel)}>
          <Button type="primary" onClick={onSave}>
            <IntlMessages id="form.save" />
          </Button>
        </div>
      )}
    </>
  );
};

DivisionsPermissions.propTypes = {
  user: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    divisions: PropTypes.objectOf(
      PropTypes.shape({
        access: PropTypes.oneOf(Object.values(ACCESS_TYPES)).isRequired,
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        descriptionn: PropTypes.string,
        avatarURL: PropTypes.string,
      }),
    ).isRequired,
    access: PropTypes.oneOf(Object.values(ACCESS_TYPES)).isRequired,

    photoURL: PropTypes.string,
  }).isRequired,
};

export default DivisionsPermissions;
