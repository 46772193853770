import React from 'react';
import { Route, Switch } from 'react-router-dom';

import styles from './styles.module.less';
import Welcome from './screens/Welcome';
import Wizard from './screens/Wizard';

const Onboarding = ({ match }) => {
  return (
    <div className={styles.mainContainer}>
      <Switch>
        <Route exact path={`${match.path}`} component={Welcome} />
        <Route exact path={`${match.path}/wizard`} component={Wizard} />
      </Switch>
    </div>
  );
};

export default Onboarding;
