import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { USER_ROLES, hasAnyAllowedRole } from 'packages/utils';

import styles from './styles.module.less';

const MenuGroup = ({ name, selected, allowedRoles, children }) => {
  const [showOptions, setShowOptions] = useState(false);
  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);

  const hasAccess = hasAnyAllowedRole(allowedRoles, userOrgAccess, userOrgDivisions);

  const handleClick = () => setShowOptions(true);
  const handleClose = () => setShowOptions(false);

  return hasAccess ? (
    <button
      type="button"
      className={classnames(styles.button, selected && styles.selected)}
      onClick={handleClick}
      onMouseLeave={handleClose}
    >
      <div className={styles.name}>
        <i className="material-icons">insert_chart</i>
        <span>{name}</span>
      </div>
      {(selected || showOptions) && <div className="gx-flex-column">{children}</div>}
    </button>
  ) : (
    <> </>
  );
};

MenuGroup.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  allowedRoles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(USER_ROLES))),
};

MenuGroup.defaultProps = {
  selected: false,
  allowedRoles: null,
};

export default MenuGroup;
