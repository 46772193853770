import { useEffect, useRef } from 'react';

// This hook makes it easy to see which prop changes are causing a component to re-render.
// If a function is particularly expensive to run and you know it renders the same results
// given the same props you can use the React.memo higher order component
// If you're still seeing re-renders that seem unnecessary you can drop in the
// useWhyDidUpdate hook and check your console to see which props changed between renders
// and view their previous/current values.

const useWhyDidUpdate = (name, props) => {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const prevRef = useRef();

  useEffect(() => {
    if (prevRef.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...prevRef.current, ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach(key => {
        // If previous is different from current
        if (prevRef.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: prevRef.current[key],
            to: props[key],
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        // eslint-disable-next-line no-console
        console.log(`${name} [did-update]`);
        // eslint-disable-next-line no-console
        console.table(changesObj);
      }
    }

    // Finally update prevRef with current props for next hook call
    prevRef.current = props;
  });
};

export default useWhyDidUpdate;
