import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import { triggerPropType } from 'packages/utils/proptypes/trigger';
import { EventType } from 'components/Events';
import IntlMessages from 'util/IntlMessages';
import useLimitList from 'packages/utils/hooks/useLimitList';
import { generateKey } from 'packages/utils';
import styles from './styles.module.less';

const EventTypeList = React.memo(({ triggers = [], withBackground, onEdit, onRemove, layout }) => {
  const {
    data: triggersFiltered,
    seeAll,
    totalToBeOpeneable,
    onSeeToggle,
    showButton,
  } = useLimitList({ data: triggers, limitForMobile: 1, limitForDesktop: 2 });

  const buttonLabel = seeAll ? (
    <>
      <IntlMessages id="button.seeLess" />
    </>
  ) : (
    <>
      {totalToBeOpeneable > 0 && (
        <>
          <IntlMessages id="button.seeMore" />
          <span className="gx-pl-1">{`(${totalToBeOpeneable})`}</span>
        </>
      )}
    </>
  );

  return (
    <Row gutter={[8, 8]} className="gx-w-100">
      {triggersFiltered?.length > 0 ? (
        triggersFiltered?.map(trigger => (
          <Col key={generateKey(trigger?.id)} {...layout}>
            <EventType
              data={trigger}
              withBackground={withBackground}
              onEdit={onEdit ? () => onEdit(trigger) : undefined}
              onRemove={onRemove ? () => onRemove(trigger.id) : undefined}
            />
          </Col>
        ))
      ) : (
        <div className={styles.emptyState}>
          <IntlMessages id="schedule.eventTypes.emptyState" />
        </div>
      )}

      {showButton && (
        <div className="gx-flex-row gx-justify-content-center gx-w-100">
          <Button className="gx-mb-0" onClick={onSeeToggle} type="link">
            {buttonLabel}
          </Button>
        </div>
      )}
    </Row>
  );
});
EventTypeList.defaultProps = {
  triggers: [],
  withBackground: false,
  onEdit: null,
  onRemove: null,
  layout: {
    span: 24,
  },
};
EventTypeList.propTypes = {
  triggers: PropTypes.arrayOf(triggerPropType),
  withBackground: PropTypes.bool,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  layout: PropTypes.shape({
    xxl: PropTypes.number,
    xl: PropTypes.number,
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    xs: PropTypes.number,
  }),
};

export { EventTypeList };
