import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input } from 'antd';

import styles from './styles.module.less';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const IconDropdown = ({ onSearch, onSelectIcon, data, value, className }) => {
  const [iconSelected, setIconSelected] = useState('');
  const targetElementRef = useRef(null);
  const mainLoad = useRef(true);
  const intl = useIntl();

  const handleIconSelected = useCallback(
    icon => {
      onSelectIcon(icon);
      setIconSelected(icon);
    },
    [onSelectIcon],
  );

  useEffect(() => {
    if (value) {
      handleIconSelected(value);
    }
  }, [value]);

  useEffect(() => {
    if (mainLoad.current && targetElementRef.current) {
      targetElementRef.current.scrollIntoView({
        behavior: 'instant',
        block: 'start',
        inline: 'nearest',
      });

      targetElementRef.current = null;
      mainLoad.current = false;
    }
  }, [iconSelected]);

  return (
    <div className={styles.iconDropContainer}>
      <div>
        <Input.Search
          placeholder={intl.formatMessage({ id: 'component.iconDropdown.placeHolder' })}
          allowClear
          onSearch={onSearch}
        />
      </div>
      <div className={classNames(styles.rootContainer, className)}>
        <div className={styles.container}>
          {data.map(iconName => (
            <div
              onClick={() => handleIconSelected(iconName)}
              key={iconName}
              className={(classNames(styles.optionContainer), iconName)}
              ref={iconName === iconSelected ? targetElementRef : null}
            >
              <span
                className={classNames(
                  'material-icons-outlined',
                  styles.optionItem,
                  iconName === iconSelected && styles.optionSelected,
                )}
              >
                {iconName}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

IconDropdown.defaultProps = {
  data: [],
  value: '',
  className: '',
};

IconDropdown.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onSelectIcon: PropTypes.func.isRequired,
  data: PropTypes.array,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default IconDropdown;
