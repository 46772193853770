import React from 'react';
import { Layout } from 'antd';

import { footerText } from 'util/config';
import App from 'routes/index';

import Sidebar from '../Sidebar/index';
import Topbar from '../Topbar/index';

const { Footer } = Layout;

const MainApp = () => (
  <Layout className="gx-app-layout">
    <Sidebar />
    <Layout>
      <Topbar />
      <App />
      <Footer>
        <div className="gx-layout-footer-content">{footerText}</div>
      </Footer>
    </Layout>
  </Layout>
);

export default React.memo(MainApp);
