import { ALLOWED_ROLES } from 'packages/utils';

import { union } from 'lodash';
import UserPositionHistory from './userPositionHistory/screens/UserPositionHistory';
import { PATHS } from './constants';

// TODO: Check who must access to this view, we are going to give permissions to all roles for a while.
export default [
  {
    component: UserPositionHistory,
    path: PATHS.USER_POSITIONS_HISTORY,
    exact: true,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.USER_POSITION_HISTORY.LIST,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.USER_POSITION_HISTORY.LIST,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
];
