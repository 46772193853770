export const encoder = window.btoa;

export const getRandomChar = str => str[Math.floor(Math.random() * str.length)] || '';

export const encode = str => {
  const newStr = encoder(str);
  return `V1${getRandomChar(newStr)}${getRandomChar(newStr)}${getRandomChar(newStr)}${newStr}`;
};

export default target => encode(encode(encode(JSON.stringify(target))));
