import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import PropTypes from 'prop-types';

import { getDivsWithAccess, ALLOWED_ROLES, useFirestoreQueryBatched } from 'packages/utils';
import { db } from 'firebase/firebase';
import { collection } from 'firebase/firestore';
import IntlMessages from 'util/IntlMessages';

const { Option } = Select;
const ALL_SCHS = 'all';

const SchedulesSelector = ({ selectedSchedules, setSelected, setLoading }) => {
  const orgId = useSelector(state => state.organizations.organization.id);
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids || []);

  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);

  const allowedDivs = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.SCHEDULES.LIST,
    userOrgAccess,
    userOrgDivisions,
  );
  const allowedDivsIds = allowedDivs.map(d => d.id);
  const allowedSelectedDivs = selectedDivsIds.filter(divId => allowedDivsIds.includes(divId));

  const queriesList = allowedSelectedDivs.map(divId =>
    collection(db, 'organizations', orgId, 'divisions', divId, 'schedules'),
  );
  const { data: schedules, loading: loadingSchedules } = useFirestoreQueryBatched(queriesList, [
    orgId,
    selectedDivsIds,
  ]);

  const options = useMemo(() => {
    const schedulesById = schedules.reduce((prevUpdate, sch) => {
      const update = { ...prevUpdate };
      update[sch.id] = sch;
      return update;
    }, {});

    return Object.values(schedulesById).map(sch => (
      <Option key={sch.id} value={sch.id}>
        {sch.name}
      </Option>
    ));
  }, [schedules]);

  const onChange = values => {
    const lastValue = [...values].pop();

    // User clicked "all" option
    const userSelectedAll = lastValue === ALL_SCHS;
    // User selected all the schedules
    // At the first time he clicks the array contains the "all" option ["all", schId]
    const userSelectedAllIndividualy = values.length === schedules.length && values[0] !== ALL_SCHS;
    // User clicked "clear" button
    const userClearedSelection = !values.length;

    const schedulesById = schedules.reduce((prevUpdate, sch) => {
      const update = { ...prevUpdate };
      update[sch.id] = sch;
      return update;
    }, {});

    if (userSelectedAll || userSelectedAllIndividualy || userClearedSelection) {
      setSelected({ byId: schedulesById, selectedAll: true });
      return;
    }

    // Else, user selected some schedules.
    const selectedById = {};
    values.forEach(schId => {
      // Skip the "all" option
      if (schId !== ALL_SCHS) {
        selectedById[schId] = schedulesById[schId];
      }
    });
    setSelected({ byId: selectedById, selectedAll: false });
  };

  // When selected division changes, clean selected schedules
  useEffect(() => {
    setSelected({ byId: {}, selectedAll: true });
  }, [selectedDivsIds, setLoading, setSelected]);

  // On mount effect
  useEffect(() => {
    const hasntSetSchedulesData = !Object.keys(selectedSchedules.byId).length;
    if (!loadingSchedules && hasntSetSchedulesData) {
      // When mounted the selected array will be empty, so select all by default
      const schedulesById = schedules.reduce((prevUpdate, sch) => {
        const update = { ...prevUpdate };
        update[sch.id] = sch;
        return update;
      }, {});
      setSelected({ byId: schedulesById, selectedAll: true });
    }
    // TODO double check these dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSchedules, orgId, setSelected]);

  useEffect(() => {
    setLoading(loadingSchedules); // This load causes the first time the loading to appear
  }, [loadingSchedules, setLoading]);

  return (
    <Select
      allowClear
      className="gx-m-0"
      dropdownMatchSelectWidth={false}
      maxTagCount={2}
      mode="multiple"
      onChange={onChange}
      optionFilterProp="children"
      showSearch
      value={selectedSchedules.selectedAll ? [ALL_SCHS] : Object.keys(selectedSchedules.byId)}
      loading={loadingSchedules}
    >
      <Option key={ALL_SCHS} value={ALL_SCHS}>
        <IntlMessages id="schedulesSelector.allSchedules" />
      </Option>
      {options}
    </Select>
  );
};

SchedulesSelector.propTypes = {
  selectedSchedules: PropTypes.shape({
    selectedAll: PropTypes.bool.isRequired,
    byId: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  setSelected: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default SchedulesSelector;
