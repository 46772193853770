import React from 'react';
import PropTypes from 'prop-types';

import IntlMessages from 'util/IntlMessages';

import Title from 'components/BoxContainer/components/Title';
import { InfoCardList } from 'components/InfoCard';
import MemberAvatar from 'components/MemberList/MemberAvatar';
import styles from './styles.module.less';

const SelectedUsers = ({ users, onRemoveUser }) => (
  <div className={styles.mainContainer}>
    <span className="gx-guarnic-pb-1">
      <Title.LabelForm value={<IntlMessages id="schedule.form.members.modal.users.title" />} />
    </span>
    {users.length === 0 ? (
      <span>
        <IntlMessages id="schedule.form.members.modal.users.noUsers" />
      </span>
    ) : (
      users.map(user => (
        <InfoCardList
          key={user.id}
          avatar={<MemberAvatar user={user} />}
          title={`${user?.firstName} ${user?.lastName}`}
          actionButtons={[
            {
              iconButton: 'delete',
              action: () => onRemoveUser(user.id),
              shape: 'circle',
            },
          ]}
        />
      ))
    )}
  </div>
);

SelectedUsers.defaultProps = {
  onRemoveUser: null,
};

SelectedUsers.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      photoURL: PropTypes.string,
      displayName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onRemoveUser: PropTypes.func,
};

export default SelectedUsers;
