import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

import { useFirestoreQuery } from 'packages/utils';
import { db } from 'firebase/firebase';
import { collection, orderBy, query, where } from 'firebase/firestore';

import TimelineEvent from '../../../../../events/components/TimelineEvent';

const ModalContent = ({ orgId, divId, execId }) => {
  const { data: events = [], loading: eventsLoading } = useFirestoreQuery(
    query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'events'),
      where('execution.id', '==', execId),
      orderBy('createdAt', 'desc'),
    ),
    [orgId, divId, execId],
  );

  return (
    <div>
      {eventsLoading && <Spin />}
      {events.map(evt => (
        <TimelineEvent event={evt} key={evt.id} />
      ))}
    </div>
  );
};

ModalContent.propTypes = {
  orgId: PropTypes.string.isRequired,
  divId: PropTypes.string.isRequired,
  execId: PropTypes.string.isRequired,
};

export default ModalContent;
