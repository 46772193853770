import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Column } from '@ant-design/charts';

const ColumnChart = ({ data, schedulesById }) => {
  const cleanData = [];

  data.forEach(timeGroup => {
    timeGroup.bySchedule.buckets.forEach(schedule => {
      if (schedulesById[schedule.key]) {
        cleanData.push({
          date: moment.utc(timeGroup.key).format('DD-MM-yyyy'),
          progress: parseInt(schedule.scheduleFinishedPercentage.value?.toFixed() || '0', 10),
          schedule: schedulesById[schedule.key].name,
        });
      }
    });
  });

  const config = {
    data: cleanData,
    xField: 'date',
    yField: 'progress',
    seriesField: 'schedule',
    isGroup: true,
    columnStyle: {
      radius: [10, 10, 0, 0],
    },
  };

  return <Column {...config} />;
};

ColumnChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      bySchedule: PropTypes.shape({
        buckets: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string.isRequired,
            triggersNested: PropTypes.shape({
              byTrigger: PropTypes.shape({
                buckets: PropTypes.arrayOf(
                  PropTypes.shape({
                    key: PropTypes.string.isRequired,
                    finishedTriggerExecutions: PropTypes.shape({
                      value: PropTypes.number.isRequired,
                    }).isRequired,
                    expectedTriggerExecutions: PropTypes.shape({
                      value: PropTypes.number.isRequired,
                    }).isRequired,
                    triggerFinishedPercentage: PropTypes.shape({
                      value: PropTypes.number.isRequired,
                    }).isRequired,
                  }),
                ).isRequired,
              }).isRequired,
              finishedExecutions: PropTypes.shape({
                value: PropTypes.number.isRequired,
              }).isRequired,
              expectedExecutions: PropTypes.shape({
                value: PropTypes.number.isRequired,
              }).isRequired,
            }).isRequired,
            scheduleFinishedPercentage: PropTypes.shape({
              value: PropTypes.number,
            }).isRequired,
          }),
        ).isRequired,
      }).isRequired,
      key: PropTypes.number.isRequired,
      keyAsString: PropTypes.string.isRequired,
    }),
  ).isRequired,
  schedulesById: PropTypes.objectOf(PropTypes.shape({})).isRequired,
};

export default ColumnChart;
