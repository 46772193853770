export const ACTIONS = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
};

export const ORGANIZATIONS = `organizations`;
export const DIVISIONS = 'divisions';
export const SCHEDULE_TEMPLATES = 'schedule_templates';
export const SCHEDULES = 'schedules';
export const EXECUTIONS = 'executions';
export const TRIGGERS = 'triggers';
export const SCHEDULE_OPTIONS = 'options';

// Divisions
export const getRouteToDivision = orgId => `${ORGANIZATIONS}/${orgId}/${DIVISIONS}`;

// Schedule Templates
export const getRouteToScheduleTemplates = (orgId, divId) =>
  `${ORGANIZATIONS}/${orgId}/${DIVISIONS}/${divId}/${SCHEDULE_TEMPLATES}`;

// Schedule
export const getRouteToSchedule = (orgId, divId) =>
  `${ORGANIZATIONS}/${orgId}/${DIVISIONS}/${divId}/${SCHEDULES}`;

// schedule/options
export const getRouteToScheduleOptions = (orgId, divId, scheduleId) =>
  `${ORGANIZATIONS}/${orgId}/${DIVISIONS}/${divId}/${SCHEDULES}/${scheduleId}/${SCHEDULE_OPTIONS}`;
