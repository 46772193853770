import React from 'react';
import PropTypes from 'prop-types';
import { compareExecutions } from '../../packages/utils/functions/executions';
import { Badge } from 'antd';
import { triggerPropType } from '../../packages/utils/proptypes/trigger';

const ExecutionStatus = ({ execution }) => {
  const { active, pending } = compareExecutions([execution]);

  return active.length > 0 ? (
    <Badge size="small" status="success" />
  ) : pending.length > 0 ? (
    <Badge size="small" status="processing" />
  ) : (
    <Badge size="small" status="error" />
  );
};

ExecutionStatus.propTypes = {
  execution: PropTypes.shape({
    createdAt: PropTypes.object.isRequired,
    division: PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    endAt: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    organization: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    schedule: PropTypes.shape({
      createdAt: PropTypes.object.isRequired,
      divId: PropTypes.string.isRequired,
      duration: PropTypes.number.isRequired,
      id: PropTypes.string.isRequired,

      members: PropTypes.shape({
        groups: PropTypes.arrayOf(
          PropTypes.shape({
            divId: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            avatarURL: PropTypes.string,
            users: PropTypes.arrayOf(
              PropTypes.shape({
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string.isRequired,
                photoURL: PropTypes.string,
                displayName: PropTypes.string.isRequired,
                id: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
              }),
            ),
          }),
        ).isRequired,
        users: PropTypes.arrayOf(
          PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            photoURL: PropTypes.string,
            displayName: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
          }),
        ),
      }).isRequired,
      name: PropTypes.string.isRequired,
      reminders: PropTypes.arrayOf(PropTypes.number).isRequired,
      triggers: PropTypes.arrayOf(triggerPropType),
      jobs: PropTypes.shape({
        start: PropTypes.shape({
          cron: PropTypes.string.isRequired,
          execution: PropTypes.shape({
            group: PropTypes.string.isRequired,
            state: PropTypes.string.isRequired,
            uuid: PropTypes.string.isRequired,
            status: PropTypes.string,
            lastExecution: PropTypes.string,
            nextExecution: PropTypes.string,
            description: PropTypes.string,
          }),
        }).isRequired,
        end: PropTypes.shape({
          cron: PropTypes.string.isRequired,
          execution: PropTypes.shape({
            group: PropTypes.string.isRequired,
            state: PropTypes.string.isRequired,
            uuid: PropTypes.string.isRequired,
            status: PropTypes.string,
            lastExecution: PropTypes.string,
            nextExecution: PropTypes.string,
            description: PropTypes.string,
          }),
        }).isRequired,
        reminders: PropTypes.arrayOf(
          PropTypes.shape({
            cron: PropTypes.string.isRequired,
            execution: PropTypes.shape({
              group: PropTypes.string.isRequired,
              state: PropTypes.string.isRequired,
              uuid: PropTypes.string.isRequired,
              status: PropTypes.string,
              lastExecution: PropTypes.string,
              nextExecution: PropTypes.string,
              description: PropTypes.string,
            }),
          }),
        ),
      }),
    }),
    startAt: PropTypes.object.isRequired,
    triggersIds: PropTypes.arrayOf(PropTypes.string),
    updatedAt: PropTypes.object.isRequired,
  }).isRequired,
};

export default ExecutionStatus;
