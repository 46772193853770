import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import styles from './styles.module.less';
import 'leaflet/dist/leaflet.css';
import BaseLayers from './BaseLayers';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Spin } from 'antd';

const Map = props => {
  const loading = props?.loading || false;
  return (
    <MapContainer
      {...props}
      zoom={props?.zoom || 13}
      center={props?.center || [51.505, -0.09]}
      className={classNames(styles.mapContainer, props?.className)}
      ref={props?.mapRef}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <BaseLayers onActiveLayer={props?.onLayer} layer={props?.activeLayer} />

      {loading && <Spin />}
      {props.children}
    </MapContainer>
  );
};
Map.defaultProps = {
  zoom: 8,
  center: [51.505, -0.09],
  className: '',
  loading: false,
  activeLayer: '',
  onLayer: null,
};

Map.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  zoom: PropTypes.number,
  center: PropTypes.array,
  className: PropTypes.string,
  loading: PropTypes.bool,
  activeLayer: PropTypes.string,
  onLayer: PropTypes.func,
};

export default Map;
