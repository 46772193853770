import PropTypes from 'prop-types';
import { eventTypesPropTypes } from '../eventTypes';

export const eventPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  createdAt: PropTypes.object.isRequired,
  updatedAt: PropTypes.object.isRequired,
  createdAtDevice: PropTypes.object.isRequired,
  triggerId: PropTypes.string,
  eventType: eventTypesPropTypes.isRequired,
  execution: PropTypes.shape({
    createdAt: PropTypes.object.isRequired,
    division: PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    endAt: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    organization: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    schedule: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    startAt: PropTypes.object.isRequired,
    triggersIds: PropTypes.arrayOf(PropTypes.string),
    updatedAt: PropTypes.object.isRequired,
  }),
  division: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  user: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    photoURL: PropTypes.string,
  }),
  trigger: PropTypes.shape({
    avoidConsecutiveTriggering: PropTypes.bool.isRequired,
    cooldown: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    eventType: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.object,
        distance: PropTypes.shape({
          meters: PropTypes.number,
          outside: PropTypes.bool,
        }),
        divId: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        position: PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
          lon: PropTypes.number,
        }),
        type: PropTypes.string,
        uid: PropTypes.string,
        updatedAt: PropTypes.object,
      }),
    ),
    name: PropTypes.string.isRequired,
  }),
});
