import { all } from 'redux-saga/effects';
import { rootSaga as divisionsSagas } from 'packages/divisions';
import { rootSaga as eventTypesSagas } from 'packages/eventTypes';
import { rootSaga as eventsSagas } from 'packages/events';
import { rootSaga as groupsSagas } from 'packages/groups';
import { rootSaga as locationsSagas } from 'packages/locations';
import { rootSaga as schedulesSagas } from 'packages/schedules';
import { rootSaga as usersSagas } from 'packages/users';
import authSagas from './Auth';
import notesSagas from './Notes';

import notificationSagas from './notifications';
import organizationSagas from './organizations';
import resetPasswordSaga from './resetPassword';
import userSagas from './user';

export default function* rootSaga() {
  yield all([
    authSagas(),
    notesSagas(),

    notificationSagas(),
    organizationSagas(),
    resetPasswordSaga(),
    userSagas(),

    divisionsSagas(),
    eventTypesSagas(),
    eventsSagas(),
    groupsSagas(),
    locationsSagas(),
    schedulesSagas(),
    usersSagas(),
  ]);
}
