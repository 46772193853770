import {
  ERROR_NOTIFICATION,
  INFO_NOTIFICATION,
  SUCCESS_NOTIFICATION,
  WARNING_NOTIFICATION,
} from 'constants/ActionTypes';

export const errorNotification = (
  message = '',
  title = '',
  timeOut = 6000,
  callback = () => {},
  priority = false,
) => ({
  type: ERROR_NOTIFICATION,
  payload: { message, title, timeOut, callback, priority },
});

export const infoNotification = (
  message = '',
  title = '',
  timeOut = 3500,
  callback = () => {},
  priority = false,
) => ({
  type: INFO_NOTIFICATION,
  payload: { message, title, timeOut, callback, priority },
});

export const successNotification = (
  message = '',
  title = '',
  timeOut = 3000,
  callback = () => {},
  priority = false,
) => ({
  type: SUCCESS_NOTIFICATION,
  payload: { message, title, timeOut, callback, priority },
});

export const warningNotification = (
  message = '',
  title = '',
  timeOut = 4000,
  callback = () => {},
  priority = false,
) => ({
  type: WARNING_NOTIFICATION,
  payload: { message, title, timeOut, callback, priority },
});
