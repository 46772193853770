import React from 'react';
import { Empty } from 'antd';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { db } from 'firebase/firebase';
import { collection, query, Timestamp, where } from 'firebase/firestore';
import { useFirestoreRealtimeBatched } from 'packages/utils';

import IntlMessages from 'util/IntlMessages';
import Title from 'components/BoxContainer/components/Title';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import { SCHEDULE_TYPES } from 'constants/commons';
import ExecutionItem from '../../../../components/ExecutionItem';
import { getActiveAndNextExecutions } from '../../../utils/functions/executions';

const ActiveSchedulesDashboard = () => {
  const intl = useIntl();

  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids);
  const orgId = useSelector(state => state.organizations.organization.id);

  // Each query organized by its divId
  // { [divId]: query(...) }
  const queryByDivId = {};
  selectedDivsIds.forEach(divId => {
    queryByDivId[divId] = query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'executions'),
      where('schedule.type', '==', SCHEDULE_TYPES.schedule),
      where('endAt', '>', Timestamp.fromDate(moment().subtract(24, 'hours').toDate())),
    );
  });

  const { data, loading, error } = useFirestoreRealtimeBatched(Object.values(queryByDivId), [
    selectedDivsIds,
  ]);

  const { activeExecutions } = getActiveAndNextExecutions(data);
  // I have to re-sort it because they are multiple queries merged, not sorted between eachother
  const sortedData = activeExecutions.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

  return (
    <BoxContainer>
      <BoxContainer content fixed shadow>
        <FilterContainer title={<Title value={<IntlMessages id="dashboards.actives.title" />} />} />
      </BoxContainer>
      <BoxContainer content loading={loading} error={error}>
        {!loading && sortedData.map(exec => <ExecutionItem key={exec.id} execution={exec} />)}
        {!loading && sortedData.length === 0 && (
          <Empty description={intl.formatMessage({ id: 'execution.empty.state' })} />
        )}
      </BoxContainer>
    </BoxContainer>
  );
};

export default ActiveSchedulesDashboard;
