import { useEffect, useRef } from 'react';

import { useOnScreen } from 'packages/utils';

const InfiniteScroll = ({ condition, callback }) => {
  const listBottomRef = useRef();
  const isVisible = useOnScreen(listBottomRef);

  useEffect(() => {
    if (isVisible && condition && callback) {
      callback();
    }
  }, [isVisible, condition]);

  return <div ref={listBottomRef} />;
};

export default InfiniteScroll;
