import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import classNames from 'classnames';

import Poi from 'components/Location/Poi';
import IntlMessages from 'util/IntlMessages';
import { useIsMobile } from 'packages/utils';
import { FORMS } from 'packages/workOrders/constants';
import { triggerPropType } from 'packages/utils/proptypes/trigger';
import EventName from 'components/TriggersList/component/EventName';
import { locationPropTypes } from 'packages/utils/proptypes/locations';

import styles from './styles.module.less';

const Task = ({ trigger, locations = [], hasOrder }) => {
  const { isMobile } = useIsMobile();
  const [form] = Form.useForm();
  const locationOptional = trigger?.locationOptional;
  const locationRequired = trigger?.locationRequired;
  const hiddeSelect = trigger?.locations?.length === 0 && locationOptional === false;
  const locationOptions = locations;

  const auxByRules = {
    disabled: !locationOptional,
    defaultValue: trigger?.locations?.[0]?.id,
  };

  useEffect(() => {
    if (trigger) {
      const loc = trigger.locations?.[0]?.id;
      form.setFieldsValue({ [trigger.id]: loc });
    }
  }, [form, trigger]);
  return (
    <Form name={`${FORMS.TASK_FORM}_${trigger.id}`} form={form}>
      <div className="gx-d-flex gx-w-100">
        {hasOrder && (
          <div className="gx-flex-column gx-justify-content-center gx-mr-3 gx-font-weight-semi-bold gx-fs-xl gx-mb-1">
            {trigger?.order}
          </div>
        )}
        <div className={classNames('gx-w-100', styles.mainTask)}>
          <div className="gx-w-50 gx-pb-2 gx-pb-xl-0 gx-pb-lg-0">
            <EventName
              eventName={trigger?.name}
              eventColor={trigger.eventType?.color}
              eventIcon={trigger.eventType?.icon}
            />
          </div>
          {!hiddeSelect && (
            <Form.Item
              rules={[{ required: locationRequired, message: <IntlMessages id="form.required" /> }]}
              className="gx-w-100 gx-mb-0"
              name={trigger.id}
            >
              <div className="gx-mw-100 gx-w-100">
                <Select
                  {...auxByRules}
                  placeholder={<IntlMessages id="work.order.tasks.location.placeholder" />}
                  className="gx-w-100"
                  labelInValue
                  optionFilterProp="title"
                  optionLabelProp="title"
                  showSearch
                  onChange={item => {
                    form.setFieldsValue({ [trigger.id]: item?.value || undefined });
                  }}
                  allowClear
                >
                  {locationOptions.map(({ id, name, type }) => (
                    <Select.Option value={id} key={id} title={name}>
                      {isMobile ? (
                        <div>
                          <div>{name}</div>
                          <div>{type}</div>
                        </div>
                      ) : (
                        <Poi name={name} type={type} />
                      )}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
          )}
        </div>
      </div>
    </Form>
  );
};

Task.propTypes = {
  trigger: triggerPropType,
  locations: locationPropTypes,
  hasOrder: PropTypes.bool.isRequired,
};

Task.defaultProps = {
  trigger: null,
  locations: [],
};

export default Task;
