import React from 'react';
import classname from 'classnames';
import { Tooltip } from 'antd';

import IntlMessages from 'util/IntlMessages';
import EventTypeIcon from 'components/EventTypeIcon';

import styles from './styles.module.less';
import { triggerPropType } from '../../../../../utils/proptypes/trigger';

const TriggerProgressItem = ({ trigger }) => {
  const getStatus = percentageCompleted => {
    if (percentageCompleted < 100) {
      return (
        <div className={classname(styles.status, styles.inProgress)}>
          <div className={styles.progressBar} style={{ width: `${percentageCompleted}%` }} />
          <span>
            <IntlMessages id="dashboards.schedules.triggerProgress.inProgress" />
          </span>
        </div>
      );
    }
    return (
      <div className={classname(styles.status, styles.done)}>
        <span>
          <IntlMessages id="dashboards.schedules.triggerProgress.done" />
        </span>
      </div>
    );
  };

  const eventType = trigger?.eventType || null;

  return (
    <div className={styles.progressItem}>
      <div
        className={classname(
          'gx-flex-row gx-flex-1 gx-align-items-center gx-flex-nowrap',
          styles.overflowHide,
        )}
      >
        {eventType && (
          <>
            <Tooltip title={eventType.name}>
              <div>
                {/* To the dev: idk why but without wraping it in a div, the tooltip wont work */}
                <EventTypeIcon color={eventType.color} icon={eventType.icon} className="gx-mr-3" />
              </div>
            </Tooltip>
            <span className="gx-text-truncate">{trigger.name}</span>
          </>
        )}
      </div>
      {getStatus((trigger.finishedExecutions / trigger.expectedExecutions) * 100)}
      <div className={styles.executionsAmount}>
        {trigger.finishedExecutions}/{trigger.expectedExecutions}
      </div>
    </div>
  );
};

TriggerProgressItem.propTypes = {
  trigger: triggerPropType.isRequired,
};

export default TriggerProgressItem;
