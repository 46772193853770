/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Form, Button, Input } from 'antd';
import PropTypes from 'prop-types';
import { PlusOutlined, MenuOutlined, DeleteTwoTone } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import IntlMessages from 'util/IntlMessages';
import { useDispatch } from 'react-redux';
import { errorNotification } from 'appRedux/actions';
import styles from './styles.module.less';

// eslint-disable-next-line react/prop-types
const Options = ({ formOptions = [], optionName, extraRules, onRemove }) => {
  const intl = useIntl();

  const fillRules = element => {
    const mainRules = [
      {
        required: element.required,
        message: intl.formatMessage({
          id: `eventTypes.component.componentProps.options.${element.name}.required`,
        }),
      },
    ];

    return [...mainRules, ...extraRules];
  };

  return (
    <div className="gx-w-100">
      <div className="gx-flex gx-flex-row gx-w-100">
        <div className={styles.inputForm}>
          {formOptions?.map(item => (
            <Form.Item noStyle key={item.name} name={[optionName]} rules={fillRules(item)}>
              <Input
                placeholder={intl.formatMessage({
                  id: `eventTypes.component.componentProps.options.${item.name}`,
                })}
              />
            </Form.Item>
          ))}
        </div>
        <div>
          <DeleteTwoTone
            className="ant-btn ant-btn-link"
            onClick={() => onRemove(optionName)}
            twoToneColor="#ff766c"
          />
        </div>
      </div>
    </div>
  );
};

/** Params
 * fieldkey is composed by: field and prop
 */
const SelectOptions = ({
  fieldKey,
  name,
  label,
  formOptions = [],
  extraRules,
  isRequired,
  limitOptions,
  form,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [field, prop] = fieldKey;

  const handleAdd = async add => {
    const optionsByComponent = form.getFieldValue(['components', field, 'options']);
    const totalOptions = optionsByComponent?.length + 1;
    if (totalOptions > limitOptions) {
      return dispatch(
        errorNotification(
          intl.formatMessage(
            { id: 'eventTypes.component.componentProps.options.length.error' },
            { value: limitOptions },
          ),
        ),
      );
    }

    return add();
  };
  return (
    <Form.Item
      className={styles.selectContainer}
      labelCol={{ span: 24 }}
      key={fieldKey}
      label={intl.formatMessage({ id: label })}
      name={name}
      required
      rules={[
        isRequired
          ? {
              required: true,
              message: intl.formatMessage({
                id: 'eventTypes.component.componentProps.options.requiredError',
              }),
            }
          : {},
      ]}
    >
      <Form.List name={[field, 'componentProps', prop]}>
        {(fields, { add, remove, move }) => (
          <>
            <SelectOptionsSortable
              formOptions={formOptions}
              extraRules={extraRules}
              onRemove={remove}
              items={fields}
              onMove={move}
            />
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => handleAdd(add, fields)}
                block
                icon={<PlusOutlined />}
              >
                <IntlMessages id="button.add" />
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  );
};

const DragHandle = SortableHandle(() => (
  <div>
    <MenuOutlined />
  </div>
));
const SortableItem = SortableElement(({ value, sortIndex }) => (
  <li
    style={{ paddingLeft: '0px', listStyleType: 'none', paddingBottom: '8px', paddingTop: '8px' }}
    tabIndex={sortIndex}
  >
    <div className="gx-w-100 gx-flex-row">
      <div className={styles.dragIcon}>
        <DragHandle />
      </div>
      <div className={styles.formOptions}>{value}</div>
    </div>
  </li>
));

const SortableList = SortableContainer(({ items }) => (
  <ul style={{ padding: '0px' }}>
    {items.map((value, index) => (
      <SortableItem key={index} index={index} value={value} sortIndex={index} />
    ))}
  </ul>
));

export const SelectOptionsSortable = React.memo(
  ({ onMove, formOptions, extraRules, onRemove, items }) => {
    const [records, setRecords] = useState([]);

    // onMove method will help to order by index when we are using drag and drop
    const handleSortEnd = ({ oldIndex, newIndex }) => {
      onMove(oldIndex, newIndex);
    };

    useEffect(() => {
      if (items?.length !== records?.length) {
        setRecords(items);
      }
    }, [items, records]);

    const newItems = records.map(item => (
      <Options
        formOptions={formOptions}
        optionName={item.name}
        onRemove={onRemove}
        extraRules={extraRules}
      />
    ));
    return (
      <SortableList
        onSortStart={(_, e) => {
          e.preventDefault();
        }}
        onSortEnd={(data, e) => {
          e.preventDefault();
          handleSortEnd(data);
        }}
        items={newItems}
        useDragHandle
      />
    );
  },
);

SelectOptionsSortable.defaultProps = {
  extraRules: null,
};

SelectOptionsSortable.propTypes = {
  onMove: PropTypes.func.isRequired,
  formOptions: PropTypes.array.isRequired,
  extraRules: PropTypes.array,
  onRemove: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};

SelectOptions.defaultProps = {
  extraRules: [],
  isRequired: false,
  limitOptions: 1,
};
SelectOptions.propTypes = {
  fieldKey: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  formOptions: PropTypes.array.isRequired,
  extraRules: PropTypes.array,
  isRequired: PropTypes.bool,
  limitOptions: PropTypes.number,
  form: PropTypes.any.isRequired,
};

export default SelectOptions;
