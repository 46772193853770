import { useState, useEffect } from 'react';

const useStopWatch = limitTime => {
  const [time, setTime] = useState(null);

  const initTime = () => {
    setTime(0);
  };
  useEffect(() => {
    let intervalo;
    if (time !== null) {
      intervalo = setInterval(() => {
        setTime(prevtime => (prevtime === limitTime ? 0 : prevtime + 1));
      }, 1000);
    }

    return () => clearInterval(intervalo);
  }, [limitTime, time]);

  const resetTime = () => {
    setTime(0);
  };

  return { time, resetTime, initTime };
};

export default useStopWatch;
