import { transform, camelCase, isArray, isObject } from 'lodash';

export const camelize = obj =>
  transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);

    acc[camelKey] = isObject(value) ? camelize(value) : value;
  });

export const dateTypes = ['day', 'week', 'month', 'quarter', 'year'];
