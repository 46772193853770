import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useFirestoreQuery } from '../../packages/utils/hooks';
import { db } from 'firebase/firebase';
import { collection } from 'firebase/firestore';
import Poi from '../../packages/schedules/components/Poi';

const DropDownLoc = ({ orgId, divId, location, onChange }) => {
  const orgLocationsRef = orgId && collection(db, 'organizations', orgId, 'locations');
  const divLocationsRef =
    orgId && divId && collection(db, 'organizations', orgId, 'divisions', divId, 'locations');

  const { data: orgLocations = [] } = useFirestoreQuery(orgLocationsRef, [orgId]);
  const { data: divLocations = [] } = useFirestoreQuery(divLocationsRef, [orgId, divId]);

  const justIdsSelected = location.locationList.map(item => item.id);
  const locations = divLocations.concat(orgLocations);
  const selectedLocations = locations.filter(loc => justIdsSelected.includes(loc.id));
  const isTypeSelected = type => !!selectedLocations.find(loc => loc.type === type);

  const removeLocation = id => {
    const locationsUpdated = location.locationList.filter(locId => locId.id !== id);
    onChange({
      id: '',
      locationList: locationsUpdated,
    });
  };

  const handleLocation = loc => {
    const newLocationData = locations.filter(
      item => item.id === loc.value && !justIdsSelected.includes(item.id),
    );
    onChange({
      id: loc?.value,
      locationList: [...location.locationList, ...newLocationData],
    });
  };
  const poiList = location.locationList;

  return (
    <>
      <Select
        labelInValue
        onChange={e => {
          handleLocation(e);
        }}
        showSearch
        optionFilterProp="title"
        optionLabelProp="title"
        className="gx-mb-3"
      >
        {locations.map(({ id, name, type }) => (
          <Select.Option value={id} key={id} title={name} disabled={isTypeSelected(type)}>
            <Poi name={name} selected={!isTypeSelected(type)} type={type} />
          </Select.Option>
        ))}
      </Select>
      {poiList.map(loc => (
        <Poi
          key={loc.id}
          name={loc.name}
          type={loc.type}
          fullSize
          onRemove={() => removeLocation(loc.id)}
          distance={loc?.distance}
        />
      ))}
    </>
  );
};

DropDownLoc.defaultProps = {
  divId: '',
  location: {
    id: '',
    locationList: [],
  },
};

DropDownLoc.propTypes = {
  orgId: PropTypes.string.isRequired,
  divId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  location: PropTypes.shape({
    id: PropTypes.string,
    locationList: PropTypes.array,
  }),
};

export const DropDownLocations = React.memo(DropDownLoc);
