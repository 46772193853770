import React from 'react';
import PropTypes from 'prop-types';
import { Affix, Col } from 'antd';
import styles from './styles.module.less';
import { ChildrenSchema } from 'packages/utils';
import classNames from 'classnames';

const FloatingContent = ({ children, position, className, size = 'auto' }) => {
  const positions = {
    top: {
      top: 50,
      height: size || 'auto',
      maxHeight: '65%',
      boxShadow: '0px 30px 20px 0px rgba(0, 0, 0, 0.05)',
    },
    bottom: {
      bottom: 50,
      height: size || 'auto',
      maxHeight: '65%',
    },
  };

  const affixProps = {
    top: {
      offsetBottom: 0,
    },
    bottom: {
      offsetTop: 0,
    },
  };

  const positionConfig = positions[position];
  const affixPropsConfig = affixProps[position];

  return (
    <Col style={{ position: 'fixed', zIndex: 10, padding: '4px' }}>
      <Affix {...affixPropsConfig}>
        <div
          style={{
            ...positionConfig,
          }}
          className={classNames(styles.content, className)}
        >
          {children}
        </div>
      </Affix>
    </Col>
  );
};

FloatingContent.defaultProps = {
  position: 'top',
  size: 'auto',
};

FloatingContent.propTypes = {
  children: ChildrenSchema,
  position: PropTypes.string,
  size: PropTypes.string,
};

export default FloatingContent;
