import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, notification, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { auth, db } from 'firebase/firebase';
import Card from 'containers/App/Auth/components/Card';
import IntlMessages from 'util/IntlMessages';
import { showAuthLoader, userInviteSign } from 'appRedux/actions';
import { ACCESS_TYPES, getDocData, simpleDecode } from 'packages/utils';
import { fetchSignInMethodsForEmail } from 'firebase/auth';

import AcceptInvitationForm from '../../components/AcceptInvitationForm';
import AcceptedInvitation from '../../components/AcceptWithExistingUser';

import styles from './styles.module.less';

const AcceptInvitation = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { token } = useParams();

  const [errorMessage, setErrorMessage] = useState('');
  const [userExists, setUserExists] = useState(false);
  const [invitation, setInvitation] = useState(null);
  const [isUserIn, setUserIn] = useState();
  const [loading, setLoading] = useState(true);
  const { authUser, loader } = useSelector(({ auth: userData }) => userData);

  const lastAuthRef = useRef(authUser);
  const userFormRef = useRef();

  const verifyToken = () => {
    const decodedToken = simpleDecode(token);
    if (!decodedToken?.id || !decodedToken?.orgId) {
      setErrorMessage(intl.formatMessage({ id: 'users.invitation.accept.badToken' }));
      return false;
    }
    return true;
  };

  useEffect(() => {
    const checkEmailExists = async email =>
      fetchSignInMethodsForEmail(auth, email)
        .then(methods => methods.length > 0)
        .catch(() => false);

    const fetchData = async () => {
      const decodedToken = simpleDecode(token);
      if (!decodedToken?.id || !decodedToken?.orgId) {
        setErrorMessage(intl.formatMessage({ id: 'users.invitation.accept.badToken' }));
      } else {
        try {
          // Too small to use a ephemeral to use a reducer
          const document = getDocData(
            await getDoc(
              doc(db, 'organizations', decodedToken.orgId, 'invitations', decodedToken.id),
            ),
          );

          if (!document?.email || document.expiresAt.toMillis() <= Date.now()) {
            setErrorMessage(intl.formatMessage({ id: 'users.invitation.accept.badToken' }));
          } else {
            const emailUsed = await checkEmailExists(document.email);
            if (emailUsed) {
              setUserExists(emailUsed);
            }
            setInvitation(document);
            setLoading(false);
          }
        } catch (e) {
          setErrorMessage(intl.formatMessage({ id: 'users.invitation.accept.badToken' }));
        }
      }
    };
    fetchData();
    // Only run effect on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createUser = useCallback(
    async (orgId, inviteId) => {
      try {
        await setDoc(
          doc(db, 'organizations', orgId, 'invitations', inviteId),
          {
            acceptorId: authUser,
            uid: authUser,
          },
          { merge: true },
        );

        auth.onIdTokenChanged(() => {
          auth.currentUser.getIdTokenResult().then(async ({ claims }) => {
            const orgs = claims.org || {};
            if (orgs[orgId]) {
              const onBoardingIsCompleted = !!orgs[orgId] && orgs[orgId] !== ACCESS_TYPES.OWNER;

              await setDoc(
                doc(db, 'users', claims?.user_id),
                {
                  firstName: userFormRef.current?.firstName,
                  lastName: userFormRef.current?.lastName,
                  displayName: `${userFormRef.current?.firstName} ${userFormRef.current?.lastName}`,
                  updatedAt: serverTimestamp(),
                  onboardingCompleted: onBoardingIsCompleted,
                },
                {
                  merge: true,
                },
              );
              document.location.href = '/';
            } else {
              setTimeout(() => auth.currentUser.getIdToken(true), 1100);
            }
          });
        });
      } catch (error) {
        notification.error({ message: error.message });
      }
    },
    [authUser],
  );

  const addUserToOrg = useCallback(
    (orgId, inviteId) => {
      setDoc(
        doc(db, 'organizations', orgId, 'invitations', inviteId),
        {
          acceptorId: authUser,
          uid: authUser,
        },
        { merge: true },
      ).then(() => {
        auth.onIdTokenChanged(() => {
          auth.currentUser.getIdTokenResult().then(({ claims }) => {
            // we should wait until the whole remissions are set
            if (claims.org?.[orgId]) {
              // requires full browser reload
              document.location.href = '/';
            } else {
              // That magic number 1100: just a bit more than a second according to max request for FB
              setTimeout(() => auth.currentUser.getIdToken(true), 1100);
            }
          });
        });
      });
    },
    [authUser],
  );

  useEffect(() => {
    if (authUser && authUser !== lastAuthRef.current) {
      // the user is been sign in or up
      setUserIn(true);

      const decodedToken = simpleDecode(token);
      createUser(decodedToken.orgId, decodedToken.id);
    } else {
      lastAuthRef.current = authUser;
    }
  }, [authUser, createUser, token]);

  const onSign = async ({ email, password, firstName, lastName }) => {
    const validToken = verifyToken();
    if (validToken) {
      userFormRef.current = {
        email,
        password,
        firstName,
        lastName,
      };
      // sign in or sign up
      dispatch(showAuthLoader());
      dispatch(userInviteSign(email, password, true));
    }
  };

  return (
    <Card footer={<IntlMessages id="users.invitation.accept.footer" />}>
      <h1 className={styles.title}>
        <IntlMessages id="users.invitation.accept.header" />
      </h1>
      {!!errorMessage && <Alert description={errorMessage} message="" type="error" />}
      {loading && !errorMessage && (
        <Spin>
          <Alert
            description={<IntlMessages id="users.invitation.accept.footer" />}
            message={<IntlMessages id="users.invitation.accept.loading" />}
            type="info"
          />
        </Spin>
      )}
      {!loading && !errorMessage && userExists && (
        <AcceptedInvitation
          inviteId={invitation.id}
          email={invitation.email}
          organization={invitation.organization}
          handleAddUser={addUserToOrg}
        />
      )}
      {!loading && !errorMessage && !userExists && (
        <AcceptInvitationForm
          email={invitation.email}
          isUserIn={isUserIn}
          loading={loader}
          onSign={onSign}
          organizationName={invitation.organization.name}
        />
      )}
    </Card>
  );
};

export default AcceptInvitation;
