export const ACTIONS = {
  UTI_FRBP_FIRST_FETCH: 'UTI_FRBP_FIRST_FETCH',
  UTI_FRBP_FIRST_FETCH_ERROR: 'UTI_FRBP_FIRST_FETCH_ERROR',

  UTI_FRBP_NEXT_FETCH: 'UTI_FRBP_NEXT_FETCH',
  UTI_FRBP_NEXT_FETCH_ERROR: 'UTI_FRBP_NEXT_FETCH_ERROR',

  UTI_FRBP_FETCH_BATCH_SUCCESS: 'UTI_FRBP_FETCH_BATCH_SUCCESS',
  UTI_FRBP_RESET: 'UTI_FRBP_RESET',
};

export const realtimePaginationFirstFetch = batchesAmount => ({
  payload: { batchesAmount },
  type: ACTIONS.UTI_FRBP_FIRST_FETCH,
});

export const realtimePaginationFirstFetchError = error => ({
  payload: error,
  type: ACTIONS.UTI_FRBP_FIRST_FETCH_ERROR,
});

export const realtimePaginationNextFetch = batchesIds => ({
  payload: { batchesIds },
  type: ACTIONS.UTI_FRBP_NEXT_FETCH,
});

export const realtimePaginationNextFetchError = error => ({
  payload: error,
  type: ACTIONS.UTI_FRBP_NEXT_FETCH_ERROR,
});

export const realtimePaginationFetchBatchSuccess = (batchId, lastDocument, data, next) => ({
  payload: { batchId, lastDocument, data, next },
  type: ACTIONS.UTI_FRBP_FETCH_BATCH_SUCCESS,
});

export const realtimePaginationReset = () => ({
  type: ACTIONS.UTI_FRBP_RESET,
});
