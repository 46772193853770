export const SCHEDULE_SAVE_FETCH = 'SCHEDULE_SAVE_FETCH';
export const SCHEDULE_SAVE_FETCH_ERROR = 'SCHEDULE_SAVE_FETCH_ERROR';
export const SCHEDULE_SAVE_FETCH_SUCCESS = 'SCHEDULE_SAVE_FETCH_SUCCESS';

export const SCHEDULE_DELETE = 'SCHEDULE_DELETE';
export const SCHEDULE_DELETE_ERROR = 'SCHEDULE_DELETE_ERROR';
export const SCHEDULE_DELETE_SUCCESS = 'SCHEDULE_DELETE_SUCCESS';

export const scheduleSaveFetch = (organizationId, divId, data) => ({
  type: SCHEDULE_SAVE_FETCH,
  payload: { organizationId, divId, data },
});

export const scheduleSaveFetchSuccess = schedule => ({
  type: SCHEDULE_SAVE_FETCH_SUCCESS,
  payload: schedule,
});

export const scheduleSaveFetchError = error => ({
  type: SCHEDULE_SAVE_FETCH_ERROR,
  payload: error,
});

export const scheduleDelete = (organizationId, divisionId, scheduleId) => ({
  type: SCHEDULE_DELETE,
  payload: { organizationId, divisionId, scheduleId },
});

export const scheduleDeleteSuccess = () => ({
  type: SCHEDULE_DELETE_SUCCESS,
});

export const scheduleDeleteError = error => ({
  type: SCHEDULE_DELETE_ERROR,
  payload: error,
});
