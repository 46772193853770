import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';

import { db, Timestamp } from 'firebase/firebase';
import { collection, query, orderBy, where } from 'firebase/firestore';
import { useFirestoreRealtimeBatchedPagination } from 'packages/utils';

import IntlMessages from 'util/IntlMessages';
import InfiniteScroll from 'components/InfiniteScroll';

import { delay } from 'lodash';
import Title from 'components/BoxContainer/components/Title';
import FilterContainer from 'components/FilterContainer';
import BoxContainer from 'components/BoxContainer';
import TimelineEvent from '../../components/TimelineEvent';
import TimelineFilters from '../../components/TimelineFilters';

import styles from './index.module.less';

// @todo ths is the first approach for pagination with subscribers
// soon it will have search, filters ... more fancy stuff
// so you don't need to review this file jet
// https://app.shortcut.com/guarnic/story/1202/timeline-pagination-filters
const Timeline = () => {
  const orgId = useSelector(state => state.organizations.organization.id);
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids);
  const [clean, setClean] = useState(false);

  const [filters, onFiltersChange] = useState({
    dates: [],
    eventTypes: [],
    user: null,
  });

  // Each query organized by its divId
  // { [divId]: query(...) }
  let queryByDivId = {};
  selectedDivsIds.forEach(divId => {
    queryByDivId[divId] = query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'events'),
      orderBy('createdAtDevice', 'desc'),
    );
  });

  if (filters.user) {
    Object.keys(queryByDivId).forEach(divId => {
      queryByDivId[divId] = query(queryByDivId[divId], where('uid', '==', filters.user));
    });
  }

  if (filters.dates.length > 0) {
    Object.keys(queryByDivId).forEach(divId => {
      queryByDivId[divId] = query(
        queryByDivId[divId],
        where('createdAtDevice', '>', Timestamp.fromMillis(filters.dates[0].valueOf())),
        where('createdAtDevice', '<', Timestamp.fromMillis(filters.dates[1].valueOf())),
      );
    });
  }

  if (filters.eventTypes.length > 0) {
    const eventTypesByDiv = filters.eventTypes?.reduce((prevUpdate, eventType) => {
      const update = { ...prevUpdate };
      update[eventType.divId] = update[eventType.divId] || [];
      update[eventType.divId].push(eventType.id);
      return update;
    }, {});

    Object.keys(queryByDivId).forEach(divId => {
      const divEventTypes = eventTypesByDiv[divId];
      if (divEventTypes) {
        queryByDivId[divId] = query(
          queryByDivId[divId],
          where('eventType.id', 'in', divEventTypes),
        );
      } else {
        // remove the div query if doesnt have a filtered event type
        const { [divId]: removed, ...rest } = queryByDivId;
        queryByDivId = rest;
      }
    });
  }

  // Dev: This query requires an index
  const { data, loading, next, gotNewData } = useFirestoreRealtimeBatchedPagination(
    Object.values(queryByDivId),
    [selectedDivsIds, filters],
  );

  // I have to re-sort it because they are multiple queries merged, not sorted between eachother
  const sortedData = data.sort((a, b) => (a.createdAtDevice < b.createdAtDevice ? 1 : -1));
  const onClear = () => {
    setClean(true);
    delay(() => {
      setClean(prev => !prev);
    }, 5000);
  };

  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer
          actionButtons={[
            {
              label: <IntlMessages id="button.clear" />,
              action: onClear,
              danger: true,
            },
          ]}
          showHide
          title={<Title value={<IntlMessages id="sidebar.dataDisplay.timeLine" />} />}
          content={<TimelineFilters toBeClean={clean} onFiltersChange={onFiltersChange} />}
        />
      </BoxContainer>

      <BoxContainer content>
        <div className={styles.timelineList}>
          {sortedData.map(evt => (
            <TimelineEvent event={evt} key={evt.id} />
          ))}
          <InfiniteScroll condition={!loading && gotNewData} callback={next} />
          {loading && <Spin />}
        </div>
      </BoxContainer>
    </BoxContainer>
  );
};

export default Timeline;
