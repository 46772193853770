import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from 'constants/ActionTypes';

const INIT_STATE = {
  loader: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_PASSWORD: {
      return { loader: true };
    }
    case RESET_PASSWORD_SUCCESS: {
      return { loader: false };
    }
    case RESET_PASSWORD_ERROR: {
      return { loader: false };
    }
    default:
      return state;
  }
};
