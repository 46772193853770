import { collection, getDocs } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import { getCollectionData } from 'packages/utils';
import { DIVISIONS, EXECUTIONS, ORGANIZATIONS, TRIGGERS } from '../../constants';

const getTriggersByExecution = async (orgId, divId, execId) =>
  getDocs(
    collection(db, ORGANIZATIONS, orgId, DIVISIONS, divId, EXECUTIONS, execId, TRIGGERS),
  ).then(snapshot => getCollectionData(snapshot));

export { getTriggersByExecution };
