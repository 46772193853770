export const DIV_LIST_FETCH = 'DIV_LIST_FETCH';
export const DIV_LIST_FETCH_ERROR = 'DIV_LIST_FETCH_ERROR';
export const DIV_LIST_FETCH_SUCCESS = 'DIV_LIST_FETCH_SUCCESS';
export const DIV_LIST_UPDATE_DIV = 'DIV_LIST_UPDATE_DIV';

export const divisionsListFetch = organizationId => ({
  type: DIV_LIST_FETCH,
  payload: { organizationId },
});

export const divisionsListFetchSuccess = userId => ({
  type: DIV_LIST_FETCH_SUCCESS,
  payload: userId,
});

export const divisionsListFetchError = error => ({
  type: DIV_LIST_FETCH_ERROR,
  payload: error,
});

export const divisionListUpdateDivision = division => ({
  type: DIV_LIST_UPDATE_DIV,
  payload: division,
});
