import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { ChildrenSchema } from 'packages/utils';
import classNames from 'classnames';
import styles from './styles.module.less';

const SwitchAction = ({ label, className, checked = false, onChange, loading }) => (
  <div className={classNames('gx-pt-2 gx-pb-2', styles.elementActionC, className)}>
    <div className="gx-flex-row gx-w-100">
      <div className={styles.elementLabel}>
        <p>{label}</p>
      </div>
      <div>
        <Switch
          disabled={loading}
          loading={loading}
          defaultChecked={checked}
          checked={checked}
          onChange={onChange}
        />
      </div>
    </div>
  </div>
);

SwitchAction.defaultProps = {
  className: '',
  checked: false,
  loading: false,
};

SwitchAction.propTypes = {
  label: PropTypes.oneOfType([ChildrenSchema, PropTypes.string]).isRequired,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default SwitchAction;
