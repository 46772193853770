import * as actions from '../actions/list';

const getInitialState = () => ({
  data: null,
  error: null,
  id: null,
  loaded: false,
  loading: false,
  fetchingList: false, // NOTE: this state will be created to control the async divisions on their updated
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case actions.DIV_LIST_FETCH: {
      return {
        ...state,
        data: null,
        error: null,
        id: action.payload.organizationId,
        loaded: false,
        loading: true,
      };
    }
    case actions.DIV_LIST_FETCH_ERROR: {
      return {
        ...state,
        data: null,
        error: action.payload,
        loaded: true,
        loading: false,
      };
    }
    case actions.DIV_LIST_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loaded: true,
        loading: false,
      };
    }

    case actions.DIV_LIST_UPDATE_DIV: {
      const divs = [...(state.data || [])];
      const divIndx = divs.findIndex(div => div.id === action.payload.id);
      const updateDiv = divIndx !== -1;
      const divData = action.payload;

      if (updateDiv) {
        divs[divIndx] = divData;
      } else {
        divs.push(divData);
      }

      return {
        ...state,
        data: divs,
        loaded: true,
        loading: false,
      };
    }

    default:
      return state;
  }
};
