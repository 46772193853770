import React from 'react';
import { Form, Select } from 'antd';
import PropTypes from 'prop-types';

import IntlMessages from 'util/IntlMessages';
import { useIntl } from 'react-intl';

const { Option } = Select;
const SelectInput = ({ fieldKey, extraProps, name, label, form, defaultValue, data, required }) => {
  const intl = useIntl();
  const isEditing = form.getFieldValue(['components', ...name]) !== undefined;
  const defaultProps = isEditing ? {} : { initialValue: defaultValue };
  const formName = fieldKey[1];

  const rulesAux = required && {
    rules: [
      {
        required: true,
        message: intl.formatMessage({
          id: `eventTypes.component.componentProps.${formName}.requiredError`,
        }),
      },
    ],
  };
  return (
    <Form.Item
      key={fieldKey}
      label={<IntlMessages id={label} />}
      name={name}
      {...defaultProps}
      {...extraProps}
      {...rulesAux}
    >
      <Select style={{ minWidth: '200px' }}>
        {data.map(item => (
          <Option key={item.value}>
            <IntlMessages id={item.label} />
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

SelectInput.defaultProps = {
  defaultValue: '',
  required: false,
};
SelectInput.propTypes = {
  extraProps: PropTypes.shape({}).isRequired,
  fieldKey: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  defaultValue: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  required: PropTypes.bool,
};

export default SelectInput;
