export const REDUCER_INDEX = 'groups';
export const BASE_URL = 'groups';

export const PATHS = {
  BASE_URL,
  CREATE_GROUP: `${BASE_URL}/group`,
  EDIT_GROUP: `${BASE_URL}/group/:divId/:groupId`,
};

export const getRouteToEditGroup = (divId, groupId) =>
  PATHS.EDIT_GROUP.replace(':divId', divId).replace(':groupId', groupId);
