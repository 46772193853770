import React from 'react';
import moment from 'moment-timezone';
import { TimePicker } from 'antd';

const MS_TO_MIN = 1000 * 60;
const DEFAULT_MIN_STEP = 15;

const DurationPicker = props => {
  const onChange = time =>
    props.onChange(Math.round((time.valueOf() - moment().startOf('day').valueOf()) / MS_TO_MIN));

  return (
    <TimePicker
      {...props}
      allowClear={false}
      format="HH:mm"
      // inputReadOnly avoids virtual keyboard on touch devices
      inputReadOnly
      minuteStep={props.minuteStep ?? DEFAULT_MIN_STEP}
      render
      showNow={false}
      showSecond={false}
      suffixIcon={null}
      onChange={onChange}
      onSelect={onChange}
      value={moment()
        .startOf('day')
        .add(+props.value || 0, 'minutes')}
    />
  );
};

DurationPicker.propTypes = TimePicker.propTypes;

export default DurationPicker;
