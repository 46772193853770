import { ACTIONS } from './actions';

export const getInitialState = () => ({
  data: {},
  error: null,
  lastDocument: null,
  loaded: false,
  loading: false,
  next: null,
  gotNewData: true,
});

export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ACTIONS.UTI_FRP_FIRST_BATCH: {
      return {
        ...getInitialState(),
        loading: true,
      };
    }
    case ACTIONS.UTI_FRP_FIRST_BATCH_ERROR: {
      return {
        ...getInitialState(),
        error: action.payload,
        loaded: true,
      };
    }
    case ACTIONS.UTI_FRP_NEXT_BATCH: {
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    }
    case ACTIONS.UTI_FRP_NEXT_BATCH_ERROR: {
      return {
        ...getInitialState(),
        error: action.payload,
        loaded: true,
      };
    }
    case ACTIONS.UTI_FRP_BATCH_SUCCESS: {
      const prevDocIds = Object.keys(state.data);
      const newDocIds = Object.keys(action.payload.data);
      const isSnapUpdate = newDocIds.some(id => prevDocIds.includes(id));
      const hasNewDocs = !newDocIds.every(id => prevDocIds.includes(id));

      return {
        ...getInitialState(),
        data: { ...state.data, ...action.payload.data },
        gotNewData: isSnapUpdate ? state.gotNewData : hasNewDocs,
        lastDocument: isSnapUpdate ? state.lastDocument : action.payload.lastDocument,
        next: action.payload.next || state.next,
        loaded: true,
      };
    }
    case ACTIONS.UTI_FRP_RESET: {
      return getInitialState();
    }
    default:
      throw new Error('useFirestoreRealtimePagination: Invalid action');
  }
};
