import React from 'react';
import { Container } from 'packages/ui';
import styles from './styles.module.less';
import UnSubscribed from '../../components/Notifications/UnSubscribed';
import Subscribed from '../../components/Notifications/Subscribed';
import { useParams, useLocation } from 'react-router-dom';
import Error from '../../components/Notifications/Error';

const Notifications = () => {
  const params = useParams();

  const location = useLocation();

  const token = new URLSearchParams(location.search).get('token');
  const type = params?.type;

  const executeComponent = {
    subscribed: <Subscribed />,
    unsubscribed: <UnSubscribed token={token} />,
    subscribedError: <Error type="subscribed" />,
    unsubscribedError: <Error type="unsubscribed" />,
  };

  return (
    <div className="gx-flex-row gx-w-100  gx-justify-content-center gx-pt-4">
      <div>
        <Container withBackground className={styles.customContainer}>
          {executeComponent[type]}
        </Container>
      </div>
    </div>
  );
};

export default Notifications;
