/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, message, Row, Select, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { cloneDeep } from 'lodash';
import classnames from 'classnames';

import { auth, db, serverTimestamp } from 'firebase/firebase';
import { collection, doc, setDoc } from 'firebase/firestore';
import { useFirestoreQuery } from 'packages/utils';
import { COMPONENT_TYPES } from 'packages/eventTypes/constants';
import { SiteContent } from 'packages/ui';

import styles from './styles.module.less';

const mockComponent = initialProps => props => ({
  defaultValue: '',
  description: 'Component description',
  name: 'test_component',
  order: 0,
  type: undefined,
  value: '',
  ...initialProps,
  ...props,

  availableProps: {
    autocomplete: 'off',
    disabled: false,
    required: false,
    ...(initialProps?.availableProps || {}),
    ...(props?.availableProps || {}),
  },
  label: {
    description: 'Tooltip or help for the label',
    text: 'Component Label',
    ...(initialProps?.label || {}),
    ...(props?.label || {}),
  },
  props: {
    ...(initialProps?.props || {}),
    ...(props?.props || {}),
  },
});

const mockEventType = initialProps => props => ({
  components: [...(initialProps?.components || []), ...(props?.components || [])],
  description: 'EventType description',
  id: 'EventTypeID',
  name: 'My EventType',
  ...initialProps,
  ...props,
});

const renderComponent = component => {
  const key = `${component.label.name}-${component.order}`;

  const rules = [
    component.componentProps.maxLength > 0 && {
      max: component.componentProps.maxLength,
      message: 'Max len not meet!',
    },
    component.componentProps.minLength > 0 && {
      min: component.componentProps.minLength,
      message: 'Min len not meet!',
    },
    component.commonProps.required && { required: true, message: 'Required field!' },
  ].filter(Boolean);

  const formItemProps = {
    initialValue: component.defaultValue || '',
    label: component.label.name || '',
    name: key,
    required: !!component.commonProps.required,
    rules,
    tooltip: component.label?.description || '',
  };

  const formInputProps = {
    'data-name': component.label.name,
    'data-order': component.order,
    id: key,
    max: component.componentProps.max > 0 ? component.componentProps.max : undefined,
    min: component.componentProps.min > 0 ? component.componentProps.min : undefined,
  };

  let input = '';
  switch (component.type) {
    case COMPONENT_TYPES.TEXT: {
      if (component.multiLine) {
        input = <Input.TextArea {...formInputProps} />;
      } else {
        input = <Input {...formInputProps} type="text" />;
      }
      break;
    }
    case COMPONENT_TYPES.NUMBER: {
      input = <Input {...formInputProps} type="number" />;
      break;
    }
    /*     case COMPONENT_TYPES.CHECK: {
      formItemProps.label = undefined;
      input = (
        <Checkbox {...formInputProps} checked={!!props.value}>
          {formItemProps.name}
        </Checkbox>
      );
      break;
    } */
    default: {
      input = <Input {...formInputProps} type="text" />;
    }
  }

  return (
    <li key={key}>
      <Form.Item {...formItemProps}>{input}</Form.Item>
      {formItemProps.required && <em className={styles.required}>Requerido</em>}
    </li>
  );
};

const ListElement = SortableElement(({ label, type }) => (
  <div className="gx-contact-item gx-dragndrop-item">
    <span className="gx-draggable-icon gx-pt-2">
      <i className="icon icon-expand" style={{ fontSize: 15 }} />
    </span>
    <div className="gx-col gx-dragndrop-name gx-text-truncate gx-px-2">{label.name}</div>
    <div className="gx-col gx-dragndrop-name gx-text-truncate gx-px-2">{type}</div>
  </div>
));

const List = SortableContainer(({ components }) => (
  <Row>
    <Col span={24}>
      {components.map((comp, index) => (
        <ListElement key={`${comp.label.name}-${comp.order}`} index={index} {...comp} />
      ))}
    </Col>
  </Row>
));

const TempEventForm = () => {
  const [divId, setDivId] = useState(null);
  const [eventType, setEventType] = useState(null);

  const { orgId, org } = useSelector(({ organizations }) => ({
    orgId: organizations?.organization?.id,
    org: organizations?.organization,
  }));
  const userSummary = useSelector(({ user }) => ({
    createdAt: user.createdAt,
    displayName: user.displayName,
    email: user.email,
    firstName: user.firstName,
    id: user.id,
    lastName: user.lastName,
    photoURL: user.photoURL,
    updatedAt: user.updatedAt,
  }));
  const userDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
  const components = (eventType?.components || []).sort((a, b) => a.order - b.order);

  const {
    data: divTypes = [],
    error: divTypesError,
    loading: divTypesLoading,
  } = useFirestoreQuery(
    divId && collection(db, 'organizations', orgId, 'divisions', divId, 'event_types'),
    [orgId, divId],
  );

  const types = divTypes.sort((a, b) =>
    a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1,
  );

  const onSelectEventType = id => setEventType(cloneDeep(types.find(t => t.id === id)));
  const onSelectDiv = id => setDivId(id);

  const onChangeValues = ({ target }) => {
    const { name, order } = target.dataset;
    const component = components.find(c => c.order === +order && c.label.name === name);
    if (component) {
      component[`${component.type}Value`] =
        component.type === COMPONENT_TYPES.TOGGLE ? !!target.checked : target.value;
    }

    setEventType({
      ...eventType,
      components: [...components],
    });
  };

  const onSaveEvent = async () => {
    const ref = divId && collection(db, 'organizations', orgId, 'divisions', divId, 'events');
    const newDocRef = doc(ref);
    try {
      // This has to match the TEvent interface from backend
      await setDoc(newDocRef, {
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        createdAtDevice: Date.now(),
        eventType,
        uid: auth.currentUser.uid,
        organizationId: orgId,
        id: newDocRef.id,
        organization: { ...org, id: orgId },
        division: {
          ...userDivisions[divId],
          // im adding it "hardcoded" because these are not at the object im using
          // TODO remove these with the story ch1479
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        },
        user: userSummary,
      });
      message.success('Success !');
      setEventType(undefined);
    } catch (e) {
      message.error(`${e.name} || ${e.code} `);
    }
  };

  return (
    <SiteContent title="Temporal Create Form" wrapped={false}>
      <Card className="gx-card">
        <div>
          <h4>This is a temporary form for triggering events with simple values</h4>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>Division:</label>
          <Select
            allowClear
            className="gx-w-100"
            onChange={onSelectDiv}
            placeholder="Please select a division"
          >
            {Object.values(userDivisions).map(currentDiv => (
              <Select.Option key={currentDiv.id} value={currentDiv.id}>
                {currentDiv.name}
              </Select.Option>
            ))}
          </Select>
          <br />
          {divTypesError && <span>ERROR: {divTypesError}</span>}
          <br />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>Event Type:</label>
          <Select
            allowClear
            className="gx-w-100"
            onChange={onSelectEventType}
            placeholder="Please select one event type"
            loading={divTypesLoading}
          >
            {types.map(type => (
              <Select.Option key={type.id} value={type.id}>
                <div
                  className={classnames('gx-timeline-badge', styles.icon)}
                  style={{
                    backgroundColor: type.color
                      ? `rgba(${type.color.r}, ${type.color.g}, ${type.color.b}, ${type.color.a})`
                      : '',
                  }}
                >
                  {!!type.icon && <i className="material-icons">{type.icon}</i>}
                </div>
                {type.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        {!!eventType && (
          <div
            style={{
              borderLeft: eventType.color
                ? `2px solid rgba(${eventType.color.r}, ${eventType.color.g}, ${eventType.color.b}, ${eventType.color.a})`
                : '',
              paddingLeft: '10px',
            }}
          >
            <Divider />
            <h2>
              <div
                className={classnames('gx-timeline-badge', styles.icon)}
                style={{
                  backgroundColor: eventType.color
                    ? `rgba(${eventType.color.r}, ${eventType.color.g}, ${eventType.color.b}, ${eventType.color.a})`
                    : '',
                }}
              >
                {!!eventType.icon && <i className="material-icons">{eventType.icon}</i>}
              </div>
              {eventType?.name} <Tag># {eventType?.id}</Tag>
              <br />
              <small>{eventType?.description}</small>
            </h2>
            <Form autoComplete="off" layout="vertical" onChange={onChangeValues}>
              {components.length > 0 && <ol>{components.map(renderComponent)}</ol>}
              <Form.Item>
                <Button className="gx-mb-0" htmlType="submit" onClick={onSaveEvent} type="primary">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </Card>
      <Card className="gx-card" title="Event Type">
        <Input.TextArea id="eventType" style={{ height: 300 }} value={JSON.stringify(eventType)} />
      </Card>
    </SiteContent>
  );
};

export default TempEventForm;
