import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Button, Checkbox, Divider, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import IntlMessages from 'util/IntlMessages';
import { showAuthLoader, userSignUp } from 'appRedux/actions/Auth';

import styles from './styles.module.less';

import SocialSignIn from '../../components/SocialSignIn';
import Card from '../../components/Card';

const SignUp = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { authUser, loader } = useSelector(({ auth }) => auth);
  const isUserIn = !!authUser;
  const disableInputs = loader || isUserIn;

  const handleFinish = values => {
    dispatch(showAuthLoader());
    dispatch(userSignUp(values));
  };

  return (
    <Card
      footer={
        <>
          <IntlMessages id="auth.signUp.alreadyHaveAccount" />{' '}
          <Link to="/signin">
            <IntlMessages id="app.userAuth.signIn" />
          </Link>
        </>
      }
    >
      <h1 className={classnames('m-bottom-7', styles.title)}>
        <IntlMessages id="auth.signUp.title" />
      </h1>
      <SocialSignIn disabled={disableInputs} />
      <Divider>
        <IntlMessages id="auth.socialSignIn.or" />
      </Divider>
      <Form
        initialValues={{
          acceptedTerms: null,
          email: '',
          password: '',
        }}
        layout="vertical"
        requiredMark={false}
        name="basic"
        onFinish={handleFinish}
        className="gx-signin-form gx-form-row0"
      >
        <Form.Item
          label={intl.formatMessage({ id: 'appModule.email' })}
          name="email"
          rules={[
            {
              type: 'email',
              message: intl.formatMessage({ id: 'form.format.msg' }),
            },
            { required: true, message: intl.formatMessage({ id: 'form.required.msg' }) },
          ]}
        >
          <Input autoComplete="username" disabled={disableInputs} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'appModule.password' })}
          name="password"
          rules={[{ required: true, message: intl.formatMessage({ id: 'form.required.msg' }) }]}
        >
          <Input.Password autoComplete="current-password" disabled={disableInputs} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            className={classnames('gx-mb-0', styles.submitButton, isUserIn && 'gx-btn-cyan')}
            htmlType="submit"
            loading={disableInputs}
            disabled={disableInputs}
          >
            <IntlMessages id={isUserIn ? 'app.userAuth.signUpDone' : 'app.userAuth.signUp'} />
          </Button>
        </Form.Item>
        <div className={styles.subpanel}>
          <Form.Item
            name="acceptedTerms"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error(intl.formatMessage({ id: 'form.required.msg' }))),
              },
            ]}
          >
            <Checkbox disabled={disableInputs}>
              <IntlMessages id="auth.signUp.iAccept" />{' '}
              <Link className="gx-login-form-forgot" to="/terms_and_conditions">
                <IntlMessages id="auth.signUp.termsAndConditions" />
              </Link>
            </Checkbox>
          </Form.Item>
        </div>
      </Form>
    </Card>
  );
};

export default SignUp;
