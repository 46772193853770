export const getCompletitionData = triggers => {
  const data = triggers.reduce(
    (accumulator, trigger) => ({
      expectedExecutions: accumulator.expectedExecutions + (trigger?.expectedExecutions || 0),
      finishedExecutions:
        accumulator.finishedExecutions +
        (Math.max(0, Math.min(trigger?.expectedExecutions, trigger?.finishedExecutions || 0)) || 0),
    }),
    {
      expectedExecutions: 0,
      finishedExecutions: 0,
    },
  );

  const finishedPercentage = Math.min(
    (data.finishedExecutions / data.expectedExecutions) * 100 || 0,
    100,
  );

  return { ...data, finishedPercentage };
};

export const addOrderToTriggers = triggers => {
  const triggersParsed = triggers.reduce((acc, item, index) => {
    acc.push({
      ...item,
      order: index + 1,
    });
    return acc;
  }, []);
  return triggersParsed;
};
export const removeOrderFromTriggers = triggers => {
  const triggersWithoutOrder = triggers.map(trigger => {
    const { order, ...triggerWithoutOrder } = trigger;
    return triggerWithoutOrder;
  });
  return triggersWithoutOrder;
};
