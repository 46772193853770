import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import IntlMessages from 'util/IntlMessages';
import { userResetPassword } from 'appRedux/actions';

import styles from './styles.module.less';
import Card from '../../components/Card';

const ForgotPassword = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { loader } = useSelector(({ resetPassword }) => resetPassword);

  const handleFinish = ({ email }) => {
    dispatch(userResetPassword(email));
  };

  return (
    <Card
      footer={
        <>
          <IntlMessages id="auth.forgotPassword.youKnowPassword" />{' '}
          <Link to="/signin">
            <IntlMessages id="app.userAuth.signIn" />
          </Link>
        </>
      }
    >
      <h1 className={classnames('m-bottom-5', styles.title)}>
        <IntlMessages id="auth.forgotPassword.title" />
      </h1>
      <p className="m-bottom-6">
        <IntlMessages id="auth.forgotPassword.description" />
      </p>
      <Form
        layout="vertical"
        requiredMark={false}
        name="basic"
        onFinish={handleFinish}
        className="gx-signin-form gx-form-row0"
      >
        <Form.Item
          rules={[
            {
              type: 'email',
              message: intl.formatMessage({ id: 'form.format.msg' }),
            },
            { required: true, message: intl.formatMessage({ id: 'form.required.msg' }) },
          ]}
          name="email"
          label={intl.formatMessage({ id: 'appModule.email' })}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            className={classnames('gx-mb-0', styles.submitButton)}
            htmlType="submit"
            loading={loader}
            disabled={loader}
          >
            <IntlMessages id="app.userAuth.send" />
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ForgotPassword;
