import * as actions from '../actions/user';

const getInitialState = () => ({
  data: null,
  error: null,
  id: null,
  loaded: false,
  loading: false,
  organizationId: null,
  save: {
    data: null,
    error: null,
    id: null,
    organizationId: null,
    loaded: false,
    loading: false,
  },
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case actions.USR_FETCH: {
      return {
        ...state,
        data: null,
        error: null,
        id: action.payload.userId,
        loaded: false,
        loading: true,
        organizationId: action.payload.organizationId,
      };
    }
    case actions.USR_FETCH_ERROR: {
      return {
        ...state,
        data: null,
        error: action.payload,
        loaded: true,
        loading: false,
      };
    }
    case actions.USR_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loaded: true,
        loading: false,
      };
    }

    case actions.USR_SAVE_FETCH: {
      return {
        ...state,
        save: {
          data: null,
          error: null,
          id: action.payload.userId,
          loaded: false,
          loading: true,
          organizationId: action.payload.organizationId,
        },
      };
    }
    case actions.USR_SAVE_FETCH_ERROR: {
      return {
        ...state,
        save: {
          data: null,
          error: action.payload,
          loaded: true,
          loading: false,
        },
      };
    }
    case actions.USR_SAVE_FETCH_SUCCESS: {
      return {
        ...state,
        save: {
          data: action.payload,
          loaded: true,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};
