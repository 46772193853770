import { ALLOWED_ROLES } from 'packages/utils';

import { PATHS } from './constants';
import UsersList from './screens/UsersList';
import UserPermissions from './screens/UserPermissions';
import InviteUsers from './screens/InviteUsers';
import AcceptInvitation from './screens/AcceptInvitation';
import CreateUser from './screens/CreateUser';
import UserNotifications from './screens/Notifications';

export default [
  {
    component: UsersList,
    path: PATHS.BASE_URL,
    exact: true,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.USERS.LIST,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: UserPermissions,
    exact: true,
    path: PATHS.USER_PERMISSIONS,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.USERS.UPDATE,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: InviteUsers,
    path: PATHS.USERS_INVITE,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.INVITATIONS.CREATE,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: AcceptInvitation,
    path: PATHS.ACCEPT_INVITATION,
    onOrgChangeRedirectTo: '',
  },
  {
    component: CreateUser,
    path: PATHS.CREATE_USER,
    onOrgChangeRedirectTo: '',
  },
  {
    component: UserNotifications,
    path: PATHS.USER_NOTIFICATIONS,
    onOrgChangeRedirectTo: '',
  },
];
