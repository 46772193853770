import React from 'react';

import PropTypes from 'prop-types';
import { Drawer, Tabs } from 'antd';

import IntlMessages from 'util/IntlMessages';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';

import styles from './styles.module.less';
import GeneralInformation from './GeneralInformation';

const TaskDetails = ({ onDrawer, visible, data }) => {
  const { general } = data;

  return (
    <Drawer
      onClose={onDrawer}
      className={styles.mainContainer}
      open={visible}
      closable={false}
      footer={null}
    >
      <BoxContainer content>
        <FilterContainer
          title={<Title.Header value={general?.schedule?.name} />}
          goBack={onDrawer}
        />
      </BoxContainer>
      <BoxContainer content>
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: <IntlMessages id="work.order.details.general" />,
              key: '1',
              children: <GeneralInformation data={general} />,
            },
          ]}
        />
      </BoxContainer>
    </Drawer>
  );
};

TaskDetails.propTypes = {
  onDrawer: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
};

export default React.memo(TaskDetails);
