import { useEffect, useCallback, useRef, useReducer } from 'react';

import { query, startAfter, limit, getDocs } from 'firebase/firestore';

import getDocData from '../../firebase/getDocData';

import {
  queryPaginationFirstFetch,
  queryPaginationFirstFetchError,
  queryPaginationNextFetch,
  queryPaginationNextFetchError,
  queryPaginationFetchBatchSuccess,
  queryPaginationReset,
} from './actions';
import { getInitialState, reducer } from './reducer';

const DEFAULT_PAGE_SIZE = 10;

// This hook helps to fetch data in your Firestore database in multiple queries without having to worry
// about state management and pagination. Instead of calling Firestore's getDocs(query(...), () => {}) in a loop
// you simply pass a query list to useFirestoreQueryBatchedPagination() and you get back everything you need,
// including loaded, loading, data, and error.
// use it like so:
// const { data, error, loading, loaded, next } = useFirestoreQueryBatchedPagination(queryRef[], dependencies, pageSize);
// where next is a callback action that you can trigger when you need the next page

// ### YOU MIGHT NEED AN INDEX TO USE THIS ON PRODUCTION ! ###
const useFirestoreQueryBatchedPagination = (
  queryRefList,
  dependencies,
  pageSize = DEFAULT_PAGE_SIZE,
) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const stateRef = useRef();
  const lastQueryByBatchRef = useRef({});
  stateRef.current = state;
  const loading = Object.values(stateRef.current.loadingByBatch).some(i => i);
  const loaded = Object.values(stateRef.current.loadedByBatch).every(i => i);

  const onNextFetchError = useCallback(
    error => dispatch(queryPaginationNextFetchError(error)),
    [dispatch],
  );
  const onNextFetchBatchSuccess = useCallback(
    (batchId, lastDocument, data) =>
      dispatch(queryPaginationFetchBatchSuccess(batchId, lastDocument, data)),
    [dispatch],
  );

  const getNextFetch = useCallback(async () => {
    if (!loading) {
      const lastDocs = stateRef.current.lastDocumentByBatch;
      const lastDocsBatchIds = Object.keys(lastDocs).filter(i => !!lastDocs[i]);

      dispatch(queryPaginationNextFetch(lastDocsBatchIds));
      await lastDocsBatchIds.map(async batchId => {
        const currentLastDoc = stateRef.current.lastDocumentByBatch[batchId];
        const currentLastQuery = lastQueryByBatchRef.current[batchId];
        try {
          const newQuery = query(currentLastQuery, startAfter(currentLastDoc), limit(pageSize));
          lastQueryByBatchRef.current[batchId] = newQuery;

          const data = await getDocs(newQuery);
          const lastDoc = data.docs[data.size - 1];
          const docs = {};
          data.docs.forEach(i => {
            const doc = getDocData(i);
            docs[doc.id] = doc;
          });
          onNextFetchBatchSuccess(batchId, lastDoc, docs);
        } catch (error) {
          onNextFetchError(error);
        }
      });
    }
  }, [loading, pageSize, onNextFetchBatchSuccess, onNextFetchError]);

  const onFirstFetchError = useCallback(
    error => dispatch(queryPaginationFirstFetchError(error)),
    [dispatch],
  );
  const onFirstFetchBatchSuccess = useCallback(
    (batchId, lastDocument, data) =>
      dispatch(queryPaginationFetchBatchSuccess(batchId, lastDocument, data, getNextFetch)),
    [dispatch, getNextFetch],
  );

  const getFirstFetch = useCallback(async () => {
    dispatch(queryPaginationFirstFetch(queryRefList.length));
    lastQueryByBatchRef.current = {};

    await Promise.all(
      queryRefList.map(async (currentQry, idx) => {
        try {
          const newQuery = query(currentQry, limit(pageSize));
          lastQueryByBatchRef.current[idx] = newQuery;
          const data = await getDocs(newQuery);
          const lastDoc = data.docs[data.size - 1];
          const docs = {};
          data.docs.forEach(i => {
            const doc = getDocData(i);
            docs[doc.id] = doc;
          });
          onFirstFetchBatchSuccess(idx, lastDoc, docs);
        } catch (error) {
          onFirstFetchError(error);
        }
      }),
    );
  }, [dispatch, onFirstFetchError, onFirstFetchBatchSuccess, pageSize, queryRefList]);

  useEffect(() => {
    // If the dependencies changes, then reset
    dispatch(queryPaginationReset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  useEffect(() => {
    if (!queryRefList.length) {
      return;
    }
    if (!loaded && !loading) {
      getFirstFetch();
    }
  }, [dispatch, getFirstFetch, loaded, loading, queryRefList]);

  const data = [];
  const currentState = stateRef.current;
  Object.values(currentState.dataByBatch).map(batch =>
    Object.values(batch).map(item => data.push(item)),
  );
  const shortState = {
    data,
    error: currentState.error,
    gotNewData: Object.values(currentState.gotNewDataByBatch).some(i => i),
    loaded: Object.values(currentState.loadedByBatch).every(i => i),
    loading: Object.values(currentState.loadingByBatch).some(i => i),
    next: currentState.next,
  };

  return shortState;
};

export default useFirestoreQueryBatchedPagination;
