import React from 'react';
import { Col, Divider, Empty, List, Row, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import styles from './styles.module.less';
import ExecutionItem from '../../../../components/ExecutionItem';
import { generateKey } from '../../../utils/functions';
import { triggerPropType } from '../../../utils/proptypes/trigger';

const ExecutionList = ({ executions, loading, nextExecutions }) => {
  const SkeletonCard = () =>
    [1, 2, 3].map(item => (
      <React.Fragment key={generateKey(item)}>
        <Skeleton loading>
          <List.Item.Meta />
        </Skeleton>
        <Divider />
      </React.Fragment>
    ));

  const emptyStateId = nextExecutions ? 'home.noSchedulePlans.title' : 'home.noActivePlans.title';
  const hasExecutions = executions.length > 0;

  const ExecutionData = () => {
    if (loading) return <SkeletonCard />;

    if (hasExecutions && !loading) {
      return executions.map(exec => (
        <React.Fragment key={exec.id}>
          <ExecutionItem key={exec.id} execution={exec} />
        </React.Fragment>
      ));
    }

    return (
      <Row className={styles.emptyContainer}>
        <Col className={styles.emptyComponent} span={24}>
          <Empty description={<IntlMessages id={emptyStateId} />} />
        </Col>
      </Row>
    );
  };
  return (
    <Row>
      <Col span={24}>
        <ExecutionData />
      </Col>
    </Row>
  );
};

ExecutionList.defaultProps = {
  nextExecutions: false,
};

ExecutionList.propTypes = {
  executions: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.object.isRequired,
      division: PropTypes.shape({
        description: PropTypes.string,
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }).isRequired,
      endAt: PropTypes.object.isRequired,
      id: PropTypes.string.isRequired,
      organization: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
      schedule: PropTypes.shape({
        createdAt: PropTypes.object.isRequired,
        divId: PropTypes.string.isRequired,
        duration: PropTypes.number.isRequired,
        id: PropTypes.string.isRequired,

        members: PropTypes.shape({
          groups: PropTypes.arrayOf(
            PropTypes.shape({
              divId: PropTypes.string.isRequired,
              id: PropTypes.string.isRequired,
              name: PropTypes.string.isRequired,
              avatarURL: PropTypes.string,
              users: PropTypes.arrayOf(
                PropTypes.shape({
                  firstName: PropTypes.string.isRequired,
                  lastName: PropTypes.string.isRequired,
                  photoURL: PropTypes.string,
                  displayName: PropTypes.string.isRequired,
                  id: PropTypes.string.isRequired,
                  email: PropTypes.string.isRequired,
                }),
              ),
            }),
          ).isRequired,
          users: PropTypes.arrayOf(
            PropTypes.shape({
              firstName: PropTypes.string.isRequired,
              lastName: PropTypes.string.isRequired,
              photoURL: PropTypes.string,
              displayName: PropTypes.string.isRequired,
              id: PropTypes.string.isRequired,
              email: PropTypes.string.isRequired,
            }),
          ),
        }).isRequired,
        name: PropTypes.string.isRequired,
        reminders: PropTypes.arrayOf(PropTypes.number).isRequired,
        triggers: PropTypes.arrayOf(triggerPropType),
        jobs: PropTypes.shape({
          start: PropTypes.shape({
            cron: PropTypes.string.isRequired,
            execution: PropTypes.shape({
              group: PropTypes.string.isRequired,
              state: PropTypes.string.isRequired,
              uuid: PropTypes.string.isRequired,
              status: PropTypes.string,
              lastExecution: PropTypes.string,
              nextExecution: PropTypes.string,
              description: PropTypes.string,
            }),
          }).isRequired,
          end: PropTypes.shape({
            cron: PropTypes.string.isRequired,
            execution: PropTypes.shape({
              group: PropTypes.string.isRequired,
              state: PropTypes.string.isRequired,
              uuid: PropTypes.string.isRequired,
              status: PropTypes.string,
              lastExecution: PropTypes.string,
              nextExecution: PropTypes.string,
              description: PropTypes.string,
            }),
          }).isRequired,
          reminders: PropTypes.arrayOf(
            PropTypes.shape({
              cron: PropTypes.string.isRequired,
              execution: PropTypes.shape({
                group: PropTypes.string.isRequired,
                state: PropTypes.string.isRequired,
                uuid: PropTypes.string.isRequired,
                status: PropTypes.string,
                lastExecution: PropTypes.string,
                nextExecution: PropTypes.string,
                description: PropTypes.string,
              }),
            }),
          ),
        }),
      }),
      startAt: PropTypes.object.isRequired,
      triggersIds: PropTypes.arrayOf(PropTypes.string),
      updatedAt: PropTypes.object.isRequired,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  nextExecutions: PropTypes.bool,
};

export default ExecutionList;
