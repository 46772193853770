import React from 'react';
import { Col, DatePicker, Input, Radio, Row } from 'antd';
import Title from 'components/BoxContainer/components/Title';
import { KeyValueWidget } from 'components/KeyValueWidget';
import { SCHEDULE_STATUS_DEFAULT } from 'packages/schedules/constants';
import { useIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
import moment from 'moment';
import DropDownUsers from 'containers/DropDownUsers';

const { RangePicker } = DatePicker;
const ScheduleFilter = React.memo(({ handleFilters, filters }) => {
  const intl = useIntl();

  return (
    <Row justify="start" gutter={[16, 8]}>
      <Col xs={24} sm={6} md={6} xl={6} xxl={6}>
        <KeyValueWidget
          label={<Title.Filter value={<IntlMessages id="general.name" />} />}
          value={
            <Input
              value={filters?.name || ''}
              placeholder={intl.formatMessage({ id: 'schedules.filter.name' })}
              onInput={e =>
                handleFilters(prev => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
            />
          }
        />
      </Col>
      <Col xs={24} sm={6} md={6} xl={6} xxl={6}>
        <KeyValueWidget
          label={<Title.Filter value={<IntlMessages id="general.range.dates" />} />}
          value={
            <RangePicker
              value={filters.dates}
              className="gx-w-100"
              ranges={{
                Today: [moment().startOf('day'), moment().endOf('day')],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
              }}
              format="YYYY-MM-DD"
              onChange={e => {
                handleFilters(prev => ({
                  ...prev,
                  dates: e ? [e[0], e[1]] : [],
                }));
              }}
              allowClear
            />
          }
        />
      </Col>
      <Col xs={24} sm={6} md={6} xl={6} xxl={6}>
        <KeyValueWidget
          label={<Title.Filter value={<IntlMessages id="schedules.filter.author" />} />}
          value={
            <DropDownUsers
              value={filters.user}
              onChange={val => handleFilters(prev => ({ ...prev, user: val }))}
            />
          }
        />
      </Col>
      <Col xs={24} sm={6} md={6} xl={6} xxl={6}>
        <KeyValueWidget
          label={
            <Title.Filter
              value={
                <>
                  <IntlMessages id="general.active" />
                  {' / '}
                  <IntlMessages id="general.completed" />
                </>
              }
            />
          }
          value={
            <Radio.Group
              value={filters.active}
              onChange={e =>
                handleFilters(prev => ({
                  ...prev,
                  active: e.target.value,
                }))
              }
              defaultValue={SCHEDULE_STATUS_DEFAULT}
              className="gx-w-100"
            >
              <Radio.Button value="0">
                <IntlMessages id="general.active" />
              </Radio.Button>
              <Radio.Button value="1">
                <IntlMessages id="general.completed" />
              </Radio.Button>
            </Radio.Group>
          }
        />
      </Col>
    </Row>
  );
});

ScheduleFilter.propTypes = {
  handleFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    name: PropTypes.string,
    dates: PropTypes.array,
    active: PropTypes.string,
    user: PropTypes.string,
  }).isRequired,
};

export default ScheduleFilter;
