import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, InputNumber, Radio, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';

import IntlMessages from 'util/IntlMessages';
import * as actions from 'packages/locations/redux/actions';
import { db } from 'firebase/firebase';
import { doc } from 'firebase/firestore';
import { useFirestoreQuery, getDivsWithAccess, ALLOWED_ROLES, getFullUrl } from 'packages/utils';
import DivisionName from 'components/DivisionName';

import { parserLocation } from 'packages/utils/functions/locations';
import Title from 'components/BoxContainer/components/Title';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import { LOC_TYPES, PATHS } from '../../constants';

import styles from './styles.module.less';
import Map from './components/Map';
import { get, set } from '../../../utils/storage';

const { Item } = Form;
const { Option } = Select;

const GpsLocation = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const intl = useIntl();
  const formRef = useRef();
  const [distance, setDistance] = useState({ outside: false, meters: 0 });
  const [position, setPosition] = useState(null);
  const [newDivId, setNewDivId] = useState(null);
  const { orgId, locId, divId: paramDivId } = useParams();
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
  const fromOrganization = history.location.state?.fromOrganization || false;

  const isEditing = !!locId;
  const isCreatingAtDiv = !fromOrganization && !isEditing;
  // const isEditingAtDiv = isEditing && !!paramDivId;
  const divId = paramDivId || newDivId;
  const lastPositionViewed = get('gpsLastPosition');

  const allowedDivisions = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.CREATE,
    userOrgAccess,
    userOrgDivisions,
  );

  // This Refs are to cover all 4 cases:
  // - Creating a location at: Organization & Division
  // - Editing a location at: Organization & Division
  const orgRef = doc(db, 'organizations', orgId);
  const divRef = divId && doc(orgRef, 'divisions', divId);
  const locRef = isEditing && doc(divRef || orgRef, 'locations', locId);
  const { data: locationData } = useFirestoreQuery(locRef, [orgId, divId, locId]);

  const validatePosition = () => ({
    validator() {
      if (position) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(
          intl.formatMessage({
            id: 'locations.gps.form.position.requiredError',
          }),
        ),
      );
    },
  });

  const handleGoBack = () => history.push(getFullUrl(PATHS.BASE_URL, url));
  const onFinish = values => {
    const { name, distance: dis } = values;
    const newDistance = {
      ...dis,
      outside: dis?.outside || false,
    };
    const { lat, lon } = parserLocation(position);
    const location = {
      distance: newDistance,
      name,
      type: LOC_TYPES.GPS,
      position: {
        lat,
        lon,
      },
    };

    if (divId) {
      location.divId = divId;
    }

    dispatch(actions.locationSaveFetch(orgId, divId, locId, location));
    set('gpsLastPosition', location.position);
    handleGoBack();
  };

  const handleSubmit = () => {
    formRef.current.submit();
  };

  const handlePosition = pos => {
    const positionParsed = parserLocation(pos);
    setPosition(positionParsed);
  };

  useEffect(() => {
    if (locationData) {
      form.setFieldsValue({
        name: locationData.name,
        distance: locationData.distance,
      });
      setDistance(locationData.distance);
      handlePosition(locationData.position);
    }
  }, [form, locationData]);

  useEffect(() => {
    // Search last location added
    if (!position) setPosition(lastPositionViewed);
  }, [lastPositionViewed, position]);

  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer
          goBack={() => history.push(getFullUrl(PATHS.BASE_URL, url))}
          title={
            <Title
              value={
                <IntlMessages id={isEditing ? 'locations.gps.edit' : 'locations.gps.create'} />
              }
            />
          }
          actionButtons={[
            {
              label: <IntlMessages id="form.save" />,
              action: handleSubmit,
              type: 'primary',
              htmlType: 'submit',
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer content loading={isEditing && !locationData}>
        <Form name="gpsLocation" ref={formRef} form={form} layout="vertical" onFinish={onFinish}>
          {isCreatingAtDiv ? (
            <Item
              label={intl.formatMessage({ id: 'form.division' })}
              name="divId"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'form.division.requiredError' }),
                },
              ]}
            >
              <Select
                dropdownMatchSelectWidth={false}
                onChange={id => setNewDivId(id)}
                optionFilterProp="children"
                placeholder={<IntlMessages id="form.division.placeholder" />}
                showSearch
              >
                {allowedDivisions.map(div => (
                  <Option key={div.id} value={div.id}>
                    {div.name}
                  </Option>
                ))}
              </Select>
            </Item>
          ) : (
            <DivisionName divId={divId} />
          )}
          {/* {isEditingAtDiv && <DivisionName divId={divId} />} */}
          <Item
            label={intl.formatMessage({ id: 'locations.gps.form.name' })}
            name="name"
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'locations.gps.form.name.requiredError',
                }),
              },
              {
                max: 180,
                message: intl.formatMessage({ id: 'form.maxLength.msg' }, { amount: 180 }),
              },
            ]}
          >
            <Input
              placeholder={intl.formatMessage({ id: 'locations.gps.form.name.placeholder' })}
            />
          </Item>

          <Item
            className="gx-mb-0"
            label={intl.formatMessage({ id: 'locations.gps.form.distance' })}
            required
          >
            <Item
              name={['distance', 'meters']}
              noStyle
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'locations.gps.form.distance.meters.requiredError',
                  }),
                },
                {
                  type: 'number',
                },
              ]}
            >
              <InputNumber
                max={9000000}
                min={1}
                onChange={meters => setDistance(prevDis => ({ ...prevDis, meters }))}
                placeholder={intl.formatMessage({ id: 'locations.gps.form.distance.meters' })}
              />
            </Item>
          </Item>
          <Item
            className="gx-mt-3"
            label={intl.formatMessage({ id: 'locations.gps.form.distance.side' })}
            name={['distance', 'outside']}
            tooltip={intl.formatMessage({ id: 'locations.gps.form.distance.side.tooltip' })}
          >
            <Radio.Group
              defaultValue={false}
              name="radius"
              onChange={({ target }) =>
                setDistance(prevDis => ({ ...prevDis, outside: target.value }))
              }
            >
              <Radio value>
                <IntlMessages id="side.outside" />
              </Radio>
              <Radio value={false}>
                <IntlMessages id="side.inside" />
              </Radio>
            </Radio.Group>
          </Item>
          <Item
            label={intl.formatMessage({ id: 'locations.gps.form.position' })}
            name="position"
            rules={[validatePosition]}
          >
            <Map
              containerElement={<div className={styles.mapContainer} />}
              isOutside={distance.outside}
              loadingElement={<div className={styles.mapElement} />}
              mapElement={<div className={styles.mapElement} />}
              meters={distance.meters}
              position={position}
              setPosition={handlePosition}
            />
          </Item>
        </Form>
      </BoxContainer>
    </BoxContainer>
  );
};

export default GpsLocation;
