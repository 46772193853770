import React, { useEffect, useRef, useState } from 'react';
import IntlMessages from 'util/IntlMessages';
import {
  Col,
  Typography,
  Grid,
  Form,
  Input,
  Button,
  Row,
  notification,
  Card,
  Select,
  Spin,
} from 'antd';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { collection, doc, setDoc } from 'firebase/firestore';
import { getFullUrl, ALLOWED_ROLES } from 'packages/utils';
import { useRouteMatch, useHistory } from 'react-router-dom';
import ComponentBlocker from 'components/ComponentBlocker';
import styles from './styles.module.less';
import { db } from '../../../../firebase/firebase';
import { ContentContainer } from '../../../ui';
import { getRouteToUsersHome } from '../../constants';

const { useBreakpoint } = Grid;
const { Item } = Form;
const CreateUser = () => {
  const [fetching, setFetching] = useState(false);
  const screen = useBreakpoint();
  const { url } = useRouteMatch();
  const createUserRef = useRef();
  const history = useHistory();
  const { id: orgId, data } = useSelector(({ organizations }) => organizations.organization);
  const { divisions: orgDivisions = {} } = useSelector(
    ({ user }) => user.profile.data?.organizations?.[orgId] || {},
  );
  const { locale: userLanguage } = useSelector(({ user }) => user.profile.data);

  const orgDomain = data?.domain;
  const intl = useIntl();
  const isMobile = screen.xs;
  const handleSave = async values => {
    try {
      setFetching(true);

      const { firstName, lastName, email, divisions, password } = values;
      const emailCompleted = `${email}@${orgDomain}`;

      const collectionRef = collection(db, 'organizations', orgId, 'fakeUsers');
      const newDocRef = doc(collectionRef);
      const docId = newDocRef.id;
      const body = {
        firstName,
        lastName,
        email: emailCompleted,
        divisions,
        access: 'u',
        id: docId,
        locale: userLanguage,
        password,
      };

      await setDoc(newDocRef, body);
      setFetching(false);
      notification.success({
        message: intl.formatMessage({ id: 'general.save.successful.message' }),
        placement: 'topRight',
      });
      history.push(getFullUrl(getRouteToUsersHome(), url));
    } catch (error) {
      notification.error({
        message: intl.formatMessage({ id: 'general.save.error.message' }),
        placement: 'topRight',
      });
      setFetching(false);
    }
  };

  useEffect(() => {
    // go back when we do not have the domain
    if (!orgDomain) {
      history.push(getFullUrl(getRouteToUsersHome(), url));
    }
  }, [history, orgDomain, url]);

  if (fetching) return <Spin size="large" />;

  return (
    <ContentContainer
      breadcrumb={[
        {
          name: <IntlMessages id="user.form.breadcrumb.user.title" />,
          path: getFullUrl(getRouteToUsersHome(), url),
        },
        { name: <IntlMessages id="user.form.breadcrumb.createUser.title" /> },
      ]}
      title={<IntlMessages id="user.form.breadcrumb.createUser.title" />}
    >
      <Card>
        <Typography.Text>
          <IntlMessages id="users.list.createUser" />
        </Typography.Text>
        <Form
          name="createUser"
          ref={createUserRef}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          layout={isMobile ? 'vertical' : 'inline'}
          className="gx-mt-4"
        >
          <Row gutter={8} className={styles.formContent}>
            <Col xs={24} xl={12} xxl={12}>
              <Item
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="user.form.firstName.requiredError" />,
                    min: 2,
                    max: 144,
                  },
                ]}
                name="firstName"
                label={<IntlMessages id="user.form.firstName" />}
                style={{ width: '100%' }}
              >
                <Input className={styles.inputsForm} />
              </Item>
            </Col>
            <Col xs={24} xl={12} xxl={12}>
              <Item
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="user.form.lastName.requiredError" />,
                    max: 144,
                  },
                ]}
                name="lastName"
                label={<IntlMessages id="user.form.lastName" />}
                style={{ width: '100%' }}
              >
                <Input className={styles.inputsForm} />
              </Item>
            </Col>
            <Col xs={24} xl={12} xxl={12}>
              <Item
                labelAlign="left"
                rules={[
                  { required: true, message: <IntlMessages id="user.form.email.requiredError" /> },
                  {
                    validator: (_, value) => {
                      if (/^[^@]+(?=@)/.test(value)) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                    message: <IntlMessages id="user.form.email.validator.error" />,
                  },
                ]}
                name="email"
                label={<IntlMessages id="user.form.email" />}
                style={{ width: '100%' }}
              >
                <Input className={styles.inputsForm} addonAfter={`@${orgDomain}`} />
              </Item>
            </Col>
            <Col xs={24} xl={12} xxl={12}>
              <Item
                labelAlign="left"
                name="divisions"
                label={<IntlMessages id="user.form.divisions" />}
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="user.form.divisions.requiredError" />,
                  },
                ]}
              >
                <Select className={styles.inputsForm} mode="multiple" optionFilterProp="children">
                  {Object.values(orgDivisions).map(div => (
                    <Select.Option value={div.id} key={div.id}>
                      {div.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xs={24} xl={12} xxl={12}>
              <Item
                label={<IntlMessages id="user.form.password" />}
                labelAlign="left"
                name="password"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="user.form.password.requiredError" />,
                  },
                  {
                    validator: () => {
                      const { password } = createUserRef.current.getFieldsValue();
                      if (password && password.length >= 6) return Promise.resolve();

                      return Promise.reject();
                    },
                    message: <IntlMessages id="user.form.minCharacters.requiredError" />,
                  },
                ]}
              >
                <Input.Password className={styles.inputsForm} />
              </Item>
            </Col>
            <Col xs={24} xl={12} xxl={12}>
              <Item
                label={<IntlMessages id="user.form.repeatPassword" />}
                labelAlign="left"
                name="repeatPassword"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="user.form.confirmPassword.requiredError" />,
                  },
                  {
                    validator: () => {
                      const { password, repeatPassword } = createUserRef.current.getFieldsValue();
                      if (password && repeatPassword) {
                        if (password === repeatPassword) return Promise.resolve();
                      }
                      return Promise.reject();
                    },
                    message: <IntlMessages id="user.form.bothPassword.requiredError" />,
                  },
                  {
                    validator: () => {
                      const { repeatPassword } = createUserRef.current.getFieldsValue();
                      if (repeatPassword && repeatPassword.length >= 6) return Promise.resolve();

                      return Promise.reject();
                    },
                    message: <IntlMessages id="user.form.minCharacters.requiredError" />,
                  },
                ]}
              >
                <Input.Password className={styles.inputsForm} />
              </Item>
            </Col>
            <Col className="gx-mt-4" xs={24} xl={{ span: 6, push: 18 }} xxl={{ span: 6, push: 18 }}>
              <Item labelAlign="left" style={{ width: '100%' }}>
                <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.USERS.CREATE}>
                  <Button
                    className={styles.createButton}
                    disabled={fetching}
                    type="primary"
                    block
                    onClick={async () => {
                      createUserRef.current.validateFields().then(fields => {
                        handleSave(fields);
                      });
                    }}
                  >
                    <IntlMessages id="user.form.button.createUser" />
                  </Button>
                </ComponentBlocker>
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </ContentContainer>
  );
};
export default CreateUser;
