import { ACTIONS, getRouteToSchedule, getRouteToScheduleOptions } from '../constants';
import { createOperation } from '../utils/createOperation';

const createSchedule = async ({ data, orgId, divId }) =>
  createOperation(getRouteToSchedule(orgId, divId), null, data, ACTIONS.CREATE);

const createScheduleOptions = async ({ data, orgId, divId, scheduleId }) =>
  createOperation(getRouteToScheduleOptions(orgId, divId, scheduleId), null, data, ACTIONS.CREATE);

export { createSchedule, createScheduleOptions };
