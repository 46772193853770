export const REDUCER_INDEX = 'triggers';

export const BASE_URL = 'triggers';

export const PATHS = {
  BASE_URL,
  CREATE_TRIGGER: `${BASE_URL}/create`,
  EDIT_TRIGGER: `${BASE_URL}/trigger/:divId/:triggerId`,
};

export const getRouteToEditTrigger = (divId, triggerId) =>
  PATHS.EDIT_TRIGGER.replace(':divId', divId).replace(':triggerId', triggerId);

export const getRouteToCreateTrigger = () => PATHS.CREATE_TRIGGER;
