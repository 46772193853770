import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import classnames from 'classnames';
import IntlMessages from 'util/IntlMessages';

import styles from './index.module.less';

const TriggerProgress = ({ trigger }) => {
  const progress = (trigger?.finishedExecutions / trigger?.expectedExecutions) * 100;
  // When a trigger is a Trigger Collection trigger it does not have a scheduled execution.
  const hasExpectedExecution = !!trigger?.finishedExecutions && !!trigger?.expectedExecutions;
  return (
    <div>
      <h4 className={classnames('gx-m-0', styles.title)}>
        <IntlMessages id="timeline.event.eventType" values={{ name: trigger.name }} />
      </h4>
      {hasExpectedExecution && (
        <div className="gx-flex-row gx-align-items-center">
          <Progress percent={progress} showInfo={false} size="small" className={styles.progress} />
          <span className={styles.completition}>
            {trigger.finishedExecutions} of {trigger.expectedExecutions}
          </span>
        </div>
      )}
    </div>
  );
};

TriggerProgress.propTypes = {
  trigger: PropTypes.shape({
    expectedExecutions: PropTypes.number.isRequired,
    finishedExecutions: PropTypes.number,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default React.memo(TriggerProgress);
