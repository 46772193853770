import React, { useState } from 'react';
import { Button } from 'antd';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';

import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';
import { errorNotification } from 'appRedux/actions';
import GooglePlaceAutocomplete from 'components/GooglePlaceAutocomplete';
import CustomInput from 'components/CustomInput';

import styles from './styles.module.less';

const StepTwo = ({ onNext, onPrevious, initialData }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [address, setAddress] = useState(null);

  const sizeSteps = ['1-10', '11-50', '51-200', '201-500', '501-1000', '+1000'];

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { dirtyFields, isValid, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      size: sizeSteps.indexOf(initialData.size),
      name: initialData.name,
      address: initialData.address?.address || '',
      phone: initialData.phone,
    },
  });

  const onEnter = f => e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault();
      f();
    }
  };

  const customSubmit = values => {
    const isDirty = Object.keys(dirtyFields).length !== 0;
    const hasAddressChanged = initialData.address !== values.address;
    if (isDirty && !address && hasAddressChanged) {
      dispatch(errorNotification(intl.formatMessage({ id: 'form.selectAddress' })));
    } else {
      const fullValues = {
        phone: values.phone,
        address: { ...address, address: values.address },
        size: sizeSteps[values.size],
        name: startCase(values.name.trim().toLowerCase()),
      };
      onNext(isDirty ? fullValues : null);
    }
  };

  const formSubmit = handleSubmit(customSubmit);

  return (
    <>
      <h2 className={styles.titleSmall}>
        <IntlMessages id="onboarding.stepTwo.title" />
      </h2>
      <h3 className={classnames('m-bottom-9', styles.infoText)}>
        <IntlMessages id="onboarding.stepTwo.info" />
      </h3>
      <form onSubmit={formSubmit} className={styles.formContainer}>
        <CustomInput
          autoFocus
          onKeyDown={onEnter(() => setFocus('address'))}
          label={intl.formatMessage({ id: 'onboarding.stepTwo.organizationName' })}
          textClassName={styles.whiteText}
          errors={errors}
          register={register('name', {
            required: intl.formatMessage({ id: 'form.required.msg' }),
            maxLength: {
              value: 255,
              message: intl.formatMessage(
                {
                  id: 'form.maxLength.msg',
                },
                { amount: 255 },
              ),
            },
            minLength: {
              value: 3,
              message: intl.formatMessage(
                {
                  id: 'form.minLength.msg',
                },
                { amount: 3 },
              ),
            },
          })}
        />
        <GooglePlaceAutocomplete
          className="m-top-5"
          errors={errors}
          label={intl.formatMessage({ id: 'onboarding.stepTwo.address' })}
          textClassName={styles.whiteText}
          onChange={setAddress}
          onSelect={() => setFocus('phone')}
          register={register('address', {
            required: intl.formatMessage({ id: 'form.required.msg' }),
          })}
        />
        <CustomInput
          onKeyDown={onEnter(() => setFocus('size'))}
          className="m-top-5"
          label={intl.formatMessage({ id: 'onboarding.stepTwo.organizationPhone' })}
          textClassName={styles.whiteText}
          errors={errors}
          register={register('phone', {
            pattern: {
              value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
              message: intl.formatMessage({
                id: 'onboarding.stepTwo.organizationPhone.pattern.error',
              }),
            },
            maxLength: {
              value: 50,
              message: intl.formatMessage(
                {
                  id: 'form.maxLength.msg',
                },
                { amount: 50 },
              ),
            },
          })}
        />
        <div className="m-top-5">
          <label className={classnames('m-bottom-2', styles.whiteText)} htmlFor="size">
            <IntlMessages id="onboarding.stepTwo.organizationSize" />
          </label>
          <input
            onKeyDown={onEnter(formSubmit)}
            className="gx-w-100"
            type="range"
            list="tickmarks"
            defaultValue={0}
            {...register('size')}
            max={sizeSteps.length - 1}
          />
          <datalist id="tickmarks" className={styles.slider}>
            {sizeSteps.map((v, i) => (
              <option key={v} value={i} label={v} />
            ))}
          </datalist>
        </div>
        <div className={classnames('m-top-5', styles.buttonsPanel)}>
          <Button type="text" className={styles.text} onClick={onPrevious}>
            <ArrowLeftOutlined className="m-right-1" />
            <IntlMessages id="onboarding.wizard.previous" />
          </Button>
          <Button
            type={isValid ? 'primary' : 'default'}
            shape="circle"
            htmlType="submit"
            disabled={!isValid}
          >
            <ArrowRightOutlined />
          </Button>
        </div>
      </form>
    </>
  );
};

StepTwo.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.shape({
      address: PropTypes.string,
    }),
    phone: PropTypes.string,
    size: PropTypes.string,
  }).isRequired,
};

export default StepTwo;
