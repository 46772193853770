import React, { useRef } from 'react';
import { KeyValueWidget } from 'components/KeyValueWidget';
import styles from './styles.module.less';
import EventTypeIcon from 'components/EventTypeIcon';
import { Button } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { triggerPropType } from 'packages/utils/proptypes/trigger';
import { useIsMobile } from 'packages/utils';
import { Location } from 'components/Location';
import Proptypes from 'prop-types';
import classNames from 'classnames';
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons';

const EventType = ({ data, withBackground, onEdit, onRemove }) => {
  const { isMobile } = useIsMobile();
  const {
    name: eventName,
    locations = [],
    eventType = {},
    cooldown = 0,
    avoidConsecutiveTriggering = false,
    expectedExecutions = 0,
    description = '',
  } = data;
  const eventTypeContainerRef = useRef();

  const isFullyCard = eventTypeContainerRef.current?.offsetWidth > 600;
  const auxEventTypeClass = isFullyCard ? 'gx-flex-nowrap' : 'gx-flex';

  return (
    <div className={classNames(styles.eventTypeMain, withBackground && styles.eventTypeBackground)}>
      <KeyValueWidget
        label={
          <div className="gx-flex-row gx-w-100 gx-justify-content-between">
            <div className="gx-flex-row gx-flex-nowrap">
              {eventType?.icon && (
                <div className="gx-mr-2 gx-align-self-center">
                  <EventTypeIcon
                    icon={eventType?.icon}
                    color={eventType.color}
                    className={styles.eventIconCustom}
                  />
                </div>
              )}
              <div className="gx-align-self-center gx-guarnic-headline-2">{eventName}</div>
            </div>
            <div className="gx-flex-row">
              {onEdit && (
                <div className="gx-ml-2">
                  <Button
                    size="small"
                    className={styles.editIconButton}
                    shape="circle"
                    icon={<EditTwoTone />}
                    onClick={onEdit}
                  />
                </div>
              )}
              {onRemove && (
                <div className="gx-ml-2">
                  <Button
                    size="small"
                    shape="circle"
                    icon={<DeleteTwoTone twoToneColor="#E13E3B" />}
                    onClick={onRemove}
                    danger
                  />
                </div>
              )}
            </div>
          </div>
        }
      />

      <div className="gx-mb-2 gx-mt-2">
        <div>
          <KeyValueWidget label={<IntlMessages id="schedule.location.label" />} />
          <div className={classNames(styles.locationsContainer, 'gx-pt-2')}>
            {locations.length > 0 ? (
              locations?.map(location => (
                <div key={location?.id}>
                  <Location location={location} />
                </div>
              ))
            ) : (
              <>-</>
            )}
          </div>
        </div>
      </div>

      <div className="gx-mb-2">
        <div
          ref={eventTypeContainerRef}
          className={classNames(styles.eventTypeTime, auxEventTypeClass)}
        >
          <div className={styles.cooldown}>
            <KeyValueWidget
              label={<IntlMessages id="schedule.trigger.cooldown.label" />}
              value={cooldown}
              oneLine={isMobile}
            />
          </div>
          <div className={styles.avoidConsecutiveTriggering}>
            <KeyValueWidget
              label={<IntlMessages id="schedule.trigger.avoidConsecutiveTriggering.label" />}
              value={
                avoidConsecutiveTriggering ? (
                  <IntlMessages id="general.yes" />
                ) : (
                  <IntlMessages id="general.no" />
                )
              }
              oneLine={isMobile}
            />
          </div>
          <div className={styles.expectedExecutions}>
            <KeyValueWidget
              label={<IntlMessages id="schedule.trigger.executions.label" />}
              value={expectedExecutions}
              oneLine={isMobile}
            />
          </div>
          {isFullyCard && (
            <div className={classNames(styles.description, isFullyCard && 'gx-pl-5')}>
              <KeyValueWidget
                label={<IntlMessages id="schedules.list.description.label" />}
                value={description || '-'}
              />
            </div>
          )}
        </div>
        {!isFullyCard && (
          <div className="gx-mb-2">
            <KeyValueWidget
              label={<IntlMessages id="schedules.list.description.label" />}
              value={description || '-'}
            />
          </div>
        )}
      </div>
    </div>
  );
};

EventType.defaultProps = {
  withBackground: false,
  onEdit: null,
  onRemove: null,
};
EventType.propTypes = {
  data: triggerPropType.isRequired,
  withBackground: Proptypes.bool,
  onEdit: Proptypes.func,
  onRemove: Proptypes.func,
};

export { EventType };
