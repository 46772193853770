import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ORGANIZATION_FETCH, ORGANIZATION_SAVE_FETCH } from 'constants/ActionTypes';
import { auth, db, serverTimestamp } from 'firebase/firebase';
import { doc, getDoc, setDoc, collection } from 'firebase/firestore';
import { storage } from 'packages/utils';

import {
  errorNotification,
  organizationFetchError,
  organizationFetchSuccess,
  organizationSaveFetchError,
  organizationSaveFetchSuccess,
} from '../actions';

const organizationFetchRequest = async organizationId =>
  getDoc(doc(db, 'organizations', organizationId)).then(snapshot => snapshot.data());

function* organizationFetch({ payload }) {
  try {
    const response = yield call(organizationFetchRequest, payload);
    yield put(organizationFetchSuccess(response));
    // set default active org for next reload
    localStorage.setItem('activeOrg', payload);
  } catch (error) {
    // remove the active org to avoid infinite loop
    // in case user have lost access
    localStorage.removeItem('activeOrg');
    yield put(organizationFetchError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* fetchOrganization() {
  yield takeEvery(ORGANIZATION_FETCH, organizationFetch);
}

const organizationSaveFetchRequest = async (organizationId, data) => {
  const { createdAt, ...rest } = data;

  const organization = {
    // TODO: temp set of "language"
    language: storage.get('locale').locale || 'en',
    ...rest,
    updatedAt: serverTimestamp(),
  };

  if (organizationId) {
    await setDoc(doc(db, 'organizations', organizationId), organization, { merge: true });
    return Promise.resolve(organization);
  }

  const newDocRef = doc(collection(db, 'organizations'));
  const newDocData = {
    ...organization,
    id: newDocRef.id,
    createdAt: serverTimestamp(),
    uid: auth.currentUser.uid,
  };
  await setDoc(newDocRef, newDocData);
  return Promise.resolve(newDocData);
};

function* organizationSaveFetch({ payload: { organizationId, data } }) {
  try {
    const response = yield call(organizationSaveFetchRequest, organizationId, data);
    yield put(organizationSaveFetchSuccess(response));
  } catch (error) {
    yield put(organizationSaveFetchError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* fetchOrganizationSave() {
  yield takeEvery(ORGANIZATION_SAVE_FETCH, organizationSaveFetch);
}

export default function* rootSaga() {
  yield all([fork(fetchOrganization), fork(fetchOrganizationSave)]);
}
