import React, { useCallback, useRef } from 'react';
import { GoogleMap, withGoogleMap, Circle } from 'react-google-maps';
import GooglePlaceAutocomplete from 'components/GooglePlaceAutocomplete';
import { useForm } from 'react-hook-form';
import {
  DEFAULT_ZOOM_IN,
  DEFAULT_ZOOM_OUT,
  INSIDE_COLOR,
  OUTSIDE_COLOR,
  SATELLITE,
} from './constants';
import styles from '../../styles.module.less';

const Map = withGoogleMap(props => {
  const {
    register,
    formState: { errors },
  } = useForm();
  const mapRef = useRef(null);
  const hasPosition = Boolean(props?.position);
  const positionParsed = {
    lat: props?.position?.lat || 0,
    lng: props?.position?.lon || props?.position?.lng || 0,
  };

  // NOTE: here set posiiton to apply and redirecto to marker
  const setZoom = location => {
    const bounds = new window.google.maps.LatLngBounds(location);
    mapRef.current.fitBounds(bounds);
  };
  const handleSelectPlace = useCallback(
    ({ lat, lng }) => {
      const location = { lat, lng };
      props.setPosition(location);
      setZoom(location);
    },
    [props],
  );

  const getMapOptions = maps => ({
    clickable: false,
    fillColor: props.isOutside ? OUTSIDE_COLOR : INSIDE_COLOR,
    fullscreenControl: false,
    mapTypeControl: true,
    streetViewControl: false,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE, maps.MapTypeId.HYBRID],
    },
  });
  return (
    <>
      <div className={styles.gpsFormPos}>
        <GooglePlaceAutocomplete
          className={styles.gpsFormMap}
          errors={errors}
          onSelect={e => handleSelectPlace({ lat: e?.latitude, lng: e?.longitude })}
          register={register('address')}
          inputClassName={styles.inputClassName}
        />
      </div>
      <GoogleMap
        mapTypeId={SATELLITE}
        ref={mapRef}
        zoom={hasPosition ? DEFAULT_ZOOM_IN : DEFAULT_ZOOM_OUT}
        center={positionParsed || { lat: 0, lng: 0 }}
        defaultCenter={positionParsed || { lat: 0, lng: 0 }}
        defaultZoom={hasPosition ? DEFAULT_ZOOM_IN : DEFAULT_ZOOM_OUT}
        radius={props.meters}
        options={getMapOptions}
        onClick={e => props.setPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() })}
      >
        {!!props.position && (
          <Circle
            center={positionParsed}
            options={{
              clickable: false,
              fillColor: props.isOutside ? OUTSIDE_COLOR : INSIDE_COLOR,
            }}
            radius={props.meters}
          />
        )}
      </GoogleMap>
    </>
  );
});

export default Map;
