import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ChildrenSchema } from 'packages/utils';
import { Spin } from 'antd';
import styles from './styles.module.less';

const Container = ({ children, withBackground, className, loading }) => (
  <div className={classNames(styles.main)}>
    {loading ? (
      <div
        className={classNames(styles.children, withBackground && styles.withBackground, className)}
      >
        <Spin size="large" />
      </div>
    ) : (
      <div
        className={classNames(styles.children, withBackground && styles.withBackground, className)}
      >
        {children}
      </div>
    )}
  </div>
);

Container.defaultProps = {
  withBackground: false,
  className: '',
  loading: false,
};

Container.propTypes = {
  children: ChildrenSchema.isRequired,
  withBackground: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

export default Container;
