import React from 'react';
import { Row, Col } from 'antd';
import { KeyValueWidget } from 'components/KeyValueWidget';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
import DisplayDuration from 'components/DisplayDuration';
import { useSelector } from 'react-redux';
import { jobsPropType } from 'packages/utils/proptypes/schedule';
import { getDurationFromStartDate } from 'packages/utils/functions/schedule';
import { Recurrence } from './Recurrence';
import { getUserTimeZoneDate } from 'packages/utils';

const ScheduleInfo = ({
  id,
  startDate,
  endDate,
  duration,
  divisionName,
  status,
  jobs,
  schedule,
}) => {
  const { locale = 'es' } = useSelector(({ settings }) => settings.locale);
  const profile = useSelector(({ user }) => user.profile);
  const { repetition, startAt, timeZone: scheduleTimeZone } = schedule;

  const dateWithTimeZone = getUserTimeZoneDate(
    startAt.toMillis(),
    scheduleTimeZone,
    profile.data?.timeZone,
  ).jsDate;

  return (
    <Row key={id} gutter={[8, 8]}>
      <Col xs={12} xl={4} xxl={4}>
        <KeyValueWidget
          label={<IntlMessages id="schedules.list.division.label" />}
          value={divisionName}
        />
      </Col>
      <Col xs={12} xl={4} xxl={4}>
        <KeyValueWidget
          label={<IntlMessages id="schedules.list.start.label" />}
          value={
            <IntlMessages id={`schedules.list.date.${locale}.value`} values={{ date: startDate }} />
          }
        />
      </Col>
      <Col xs={12} xl={4} xxl={4}>
        <KeyValueWidget
          label={<IntlMessages id="schedules.list.end.label" />}
          value={
            <IntlMessages id={`schedules.list.date.${locale}.value`} values={{ date: endDate }} />
          }
        />
      </Col>
      <Col xs={12} xl={4} xxl={4}>
        <KeyValueWidget
          label={<IntlMessages id={`schedules.list.time.${locale}.label`} />}
          value={
            <IntlMessages
              id={`schedules.list.time.${locale}.value`}
              values={{
                startDate: dateWithTimeZone,
                endDate: getDurationFromStartDate(dateWithTimeZone, duration),
              }}
            />
          }
        />
      </Col>
      <Col xs={12} xl={4} xxl={4}>
        <KeyValueWidget
          label={<IntlMessages id="schedules.list.duration.label" />}
          value={<DisplayDuration minutes={duration} />}
        />
      </Col>
      {jobs?.start?.execution?.nextExecution && (
        <Col xs={12} xl={4} xxl={4}>
          <KeyValueWidget
            label={<IntlMessages id="schedules.list.nextAt" />}
            value={
              <IntlMessages
                id={`schedules.list.date.${locale}.value`}
                values={{ date: new Date(jobs.start.execution.nextExecution) }}
              />
            }
          />
        </Col>
      )}
      <Col xs={12} xl={4} xxl={4}>
        <KeyValueWidget label={<IntlMessages id="schedules.list.status.label" />} value={status} />
      </Col>
      <Col xs={24} xl={12} xxl={12}>
        <KeyValueWidget
          label={<IntlMessages id="schedules.list.reucurrence.label" />}
          value={<Recurrence repetition={repetition} startAt={startAt} />}
        />
      </Col>
    </Row>
  );
};

ScheduleInfo.defaultProps = {
  startDate: null,
  endDate: null,
  duration: null,
  divisionName: null,
  status: false,
  jobs: null,
};

ScheduleInfo.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  divisionName: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  jobs: jobsPropType,
};

export { ScheduleInfo };
