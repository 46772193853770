import { useEffect, useReducer } from 'react';

import { onSnapshot } from 'firebase/firestore';

import getCollectionData from '../firebase/getCollectionData';
import getDocData from '../firebase/getDocData';

// This hook helps to subscribe to data in your Firestore database without having to worry
// about state management and pagination. Instead of calling Firestore's query.onSnapshot()
// you simply pass a query to useFirestoreQuery() and you get back everything you need,
// including loaded, loading, data, and error.
// Your component will re-render when data changes and your subscription will be
// automatically removed when the component unmounts.
// you can use it like so:
// const {
//   data as listOfDocumentsOrDocument,
//   error,
//   loaded,
//   loading,
// } = useFirestoreQuery(firestore().collection('organizations') OR .doc(orgId))

const ACTIONS = {
  ERROR: 'ERROR',
  FETCH: 'FETCH',
  RESET: 'RESET',
  SUCCESS: 'SUCCESS',
};

const getInitialState = () => ({
  data: undefined,
  error: null,
  loaded: false,
  loading: false,
});

// Reducer for hook state and actions
const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ERROR:
      return { ...state, error: action.payload, loaded: true, loading: false };
    case ACTIONS.FETCH:
      return { ...state, error: null, loaded: false, loading: true };
    case ACTIONS.RESET:
      return getInitialState();
    case ACTIONS.SUCCESS:
      return { ...state, data: action.payload, error: null, loaded: true, loading: false };
    default:
      throw new Error('useFirestoreQuery: Invalid action');
  }
};

const useFirestoreQuery = (query, dependencies = []) => {
  // Our initial state
  // Start with an "empty" status if query is falsy, as that means hook consumer is
  // waiting on required data before creating the query object.
  // Example: useFirestoreQuery(uid && firestore.collection("users"))
  const initialState = {
    ...getInitialState(),
    loading: !!query,
  };

  // Setup our state and actions
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // Return early if query is falsy and reset to "INITIAL" status in case
    // we're coming from "SUCCESS" or "ERROR" status due to query change.
    if (!query) {
      dispatch({ type: ACTIONS.RESET });
      return undefined;
    }

    dispatch({ type: ACTIONS.FETCH });

    // Subscribe to query with onSnapshot
    // Will unsubscribe on cleanup since this returns an unsubscribe function
    return onSnapshot(
      query,
      snapshot =>
        dispatch({
          type: ACTIONS.SUCCESS,
          payload: snapshot.docs ? getCollectionData(snapshot) : getDocData(snapshot),
        }),
      error =>
        dispatch({
          type: ACTIONS.ERROR,
          payload: error,
        }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies); // Only run effect if queryCached changes

  useEffect(() => {
    // If the dependencies changes, then reset
    dispatch({ type: ACTIONS.RESET });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return state;
};

export default useFirestoreQuery;
