import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classname from 'classnames';
import { Avatar, Button } from 'antd';
import PropTypes from 'prop-types';

import { ApartmentOutlined, DoubleRightOutlined, UserOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';

import styles from './styles.module.less';
import TriggerProgressItem from '../TriggerProgressItem';
import { eventTypeSummaryPropTypes } from '../../../../../utils/proptypes/eventTypes';

const getScheduleData = schedule => {
  const expectedExecutions = schedule.triggersNested.expectedExecutions.value;
  const finishedExecutions = schedule.triggersNested.finishedExecutions.value;
  const finishedPercentage = schedule.scheduleFinishedPercentage.value || 0;
  const triggers = schedule.triggersNested.byTrigger.buckets;

  return { expectedExecutions, finishedExecutions, finishedPercentage, triggers };
};

const ProgressItem = ({ aggregation, schedule }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const orgId = useSelector(state => state?.organizations?.organization?.id);
  const division = useSelector(
    ({ user }) => user?.profile?.data?.organizations[orgId]?.divisions[schedule?.divId] || {},
  );

  // If there is no schedule info skip the render
  if (!schedule) {
    return <></>;
  }

  const triggersById = schedule.triggers.reduce((prevValue, trigger) => {
    const copy = { ...prevValue };
    copy[trigger.id] = trigger;
    return copy;
  }, {});

  const scheduleData = getScheduleData(aggregation);

  const getStatus = percentageCompleted => {
    if (percentageCompleted !== undefined && percentageCompleted < 100) {
      return (
        <div className={classname(styles.status, styles.incomplete)}>
          <div className={styles.progressBar} style={{ width: `${percentageCompleted}%` }} />
          <span>{percentageCompleted.toFixed()}%</span>
        </div>
      );
    }
    return <div className={classname(styles.status, styles.complete)} />;
  };

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const getGroupsAvatars = () =>
    schedule.members.groups.map(grp => (
      <div className="gx-flex-row gx-align-items-center gx-mr-3 gx-mb-2" key={grp.id}>
        <Avatar src={grp.avatarURL} icon={<ApartmentOutlined />} className={styles.groupAvatar} />
        <span className="gx-ml-1">{grp.name}</span>
      </div>
    ));

  const getUsersAvatars = () =>
    schedule.members.users.map(usr => (
      <div className="gx-flex-row gx-align-items-center gx-mr-3 gx-mb-2" key={usr.id}>
        <Avatar src={usr.photoURL} icon={<UserOutlined />} className={styles.userAvatar} />
        <span className="gx-ml-1">{`${usr?.firstName} ${usr?.lastName}`}</span>
      </div>
    ));

  return (
    <div className={classname('gx-mb-4', styles.progressItem)}>
      <div className={styles.row}>
        <div className="gx-flex-column gx-flex-1 gx-pb-3">
          <div className={classname('gx-px-3 gx-pt-3', styles.infoRow)}>
            <div>
              <h6 className={styles.label}>
                <IntlMessages id="general.schedule" />
              </h6>
              <h2 className={styles.name}>{schedule.name}</h2>
              <h6 className={styles.label}>
                <IntlMessages id="general.division" />
              </h6>
              <span>{division.name}</span>
            </div>
            <div className={styles.membersItemDesk}>
              <h6 className={styles.label}>
                <IntlMessages id="dashboards.schedules.progressItem.members.label" />
              </h6>
              <Avatar.Group
                maxCount={5}
                size="small"
                maxStyle={{
                  color: '#f56a00',
                  backgroundColor: '#fde3cf',
                }}
                className={styles.membersList}
              >
                {getGroupsAvatars()}
                {getUsersAvatars()}
              </Avatar.Group>
            </div>
            <div className="gx-flex-column">
              <h6 className={styles.label}>
                <IntlMessages id="dashboards.schedules.progressItem.executions.label" />
              </h6>
              <span>
                {scheduleData.finishedExecutions}/{scheduleData.expectedExecutions}
              </span>
            </div>
          </div>
          <div className={classname('gx-px-3', styles.panel, isExpanded && styles.expanded)}>
            <div className={classname('gx-mt-3', styles.membersItemMobile)}>
              <h6 className={styles.label}>
                <IntlMessages id="dashboards.schedules.progressItem.members.label" />
              </h6>
              <Avatar.Group
                maxCount={2}
                size="small"
                maxStyle={{
                  color: '#f56a00',
                  backgroundColor: '#fde3cf',
                }}
                className={styles.membersList}
              >
                {getGroupsAvatars()}
                {getUsersAvatars()}
              </Avatar.Group>
            </div>
            <h6 className={classname('gx-mt-3', styles.label)}>
              <IntlMessages id="dashboards.schedules.progressItem.triggers.label" />
            </h6>
            <div className={classname('gx-pr-3', isExpanded && styles.triggerScroll)}>
              {scheduleData.triggers.map(t => (
                <TriggerProgressItem aggregation={t} trigger={triggersById[t.key]} key={t.key} />
              ))}
            </div>
          </div>
        </div>

        <Button
          type="text"
          className={classname(
            'gx-flex-column gx-align-items-center gx-p-3 gx-mb-0 gx-justify-content-between',
            styles.expandButton,
          )}
          onClick={handleExpandToggle}
        >
          <DoubleRightOutlined rotate={isExpanded ? -90 : 90} />
          <DoubleRightOutlined rotate={isExpanded ? -90 : 90} className="gx-ml-0" />
        </Button>
      </div>
      {getStatus(scheduleData.finishedPercentage)}
    </div>
  );
};

ProgressItem.propTypes = {
  aggregation: PropTypes.shape({
    key: PropTypes.string.isRequired,
    triggersNested: PropTypes.shape({
      byTrigger: PropTypes.shape({
        buckets: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string.isRequired,
            finishedTriggerExecutions: PropTypes.shape({
              value: PropTypes.number.isRequired,
            }).isRequired,
            expectedTriggerExecutions: PropTypes.shape({
              value: PropTypes.number.isRequired,
            }).isRequired,
            triggerFinishedPercentage: PropTypes.shape({
              value: PropTypes.number.isRequired,
            }).isRequired,
          }),
        ).isRequired,
      }).isRequired,
      finishedExecutions: PropTypes.shape({
        value: PropTypes.number.isRequired,
      }).isRequired,
      expectedExecutions: PropTypes.shape({
        value: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    scheduleFinishedPercentage: PropTypes.shape({
      value: PropTypes.number,
    }).isRequired,
  }).isRequired,
  schedule: PropTypes.shape({
    id: PropTypes.string.isRequired,
    members: PropTypes.shape({
      groups: PropTypes.arrayOf(
        PropTypes.shape({
          /* TODO */
        }),
      ),
      users: PropTypes.arrayOf(
        PropTypes.shape({
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired,
          photoURL: PropTypes.string,
          displayName: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
          email: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    name: PropTypes.string.isRequired,
    triggers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        cooldown: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        expectedExecutions: PropTypes.number.isRequired,
        avoidConsecutiveTriggering: PropTypes.bool.isRequired,
        locations: PropTypes.arrayOf(
          PropTypes.shape({
            /* TODO */
          }),
        ),
        eventType: eventTypeSummaryPropTypes.isRequired,
        id: PropTypes.string.isRequired,
      }),
    ),
    divId: PropTypes.string.isRequired,
  }),
};

ProgressItem.defaultProps = {
  schedule: null,
};

export default ProgressItem;
