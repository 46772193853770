const BASE_URL = 'config';

export const PATHS = {
  BASE_URL,
  ORGANIZATION: `${BASE_URL}/organization`,
  DIVISIONS: `${BASE_URL}/divisions`,
  DIVISION_FORM: `${BASE_URL}/division/:divId?`,
};

export const getRouteToDivisionForm = divId => PATHS.EDIT_GROUP.replace(':divId', divId);
