import React, { useState } from 'react';
import IntlMessages from 'util/IntlMessages';
import { useSelector } from 'react-redux';
import { db } from 'firebase/firebase';
import { collection, deleteDoc, doc, getDocs, orderBy, query, where } from 'firebase/firestore';
import {
  ALLOWED_ROLES,
  getDivsWithAccess,
  getCollectionData,
  useFirestoreRealtimeBatchedPagination,
} from 'packages/utils';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useIntl } from 'react-intl';
import InfiniteScroll from 'components/InfiniteScroll';
import Title from 'components/BoxContainer/components/Title';
import FilterContainer from 'components/FilterContainer';
import BoxContainer from 'components/BoxContainer';
import styles from './styles.module.less';
import TriggersList from '../../../../components/TriggersList';
import { getRouteToCreateTrigger, getRouteToEditTrigger } from '../../constants';

const Triggers = () => {
  const intl = useIntl();
  const history = useHistory();
  const orgId = useSelector(state => state.organizations.organization.id);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids);

  const [isLoading, setIsLoading] = useState(false);
  const allowedDivs = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.LIST,
    userOrgAccess,
    userOrgDivisions,
  );

  const allowedDivsIds = allowedDivs.map(d => d.id);
  const allowedSelectedDivs = selectedDivsIds.filter(divId => allowedDivsIds.includes(divId));

  const queriesList = allowedSelectedDivs.map(divId =>
    query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'triggers'),
      orderBy('createdAt'),
    ),
  );

  const {
    data: triggersData,
    loading: triggersLoading,
    gotNewData,
    next,
  } = useFirestoreRealtimeBatchedPagination(queriesList, [selectedDivsIds, isLoading]);

  const handleEdit = ({ divId, id }) => {
    history.push(getRouteToEditTrigger(divId, id));
  };

  const handleDelete = async ({ divId, id }) => {
    /** Steps before Delete
     * First: Find all schedules actives
     * Second: Filter by triggerId
     * Check if there are some one
     * If it exists, we will not delete anything if it does not, we will.
     */

    setIsLoading(true);
    // First
    const queryRef = query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'schedules'),
      where('schedule.endAt', '>', moment().subtract(1, 'day').toDate()),
      orderBy('schedule.endAt', 'desc'),
    );
    const docs = await getDocs(queryRef);
    const data = getCollectionData(docs);
    const todayDate = moment();
    const activeSchedules = [];
    data.forEach(element => {
      const executionDate = moment(element?.schedule?.startAt.toDate());
      const endDate = moment(element?.schedule?.endAt.toDate());

      if (executionDate.isBefore(todayDate) && endDate.isAfter(todayDate)) {
        activeSchedules.push(element);
      }
    });
    // Second
    const activeTriggerIds = activeSchedules.reduce((acc, val) => {
      let triggerIds = val?.triggers.map(item => item?.triggerId);
      triggerIds = [...acc, ...triggerIds];
      return [...new Set(triggerIds)];
    }, []);
    const hasSomeOne = activeTriggerIds.includes(id);

    if (hasSomeOne) {
      setIsLoading(false);
      return notification.warning({
        message: intl.formatMessage({ id: 'triggers.view.activeTrigger.message' }),
        placement: 'topRight',
      });
    }

    return deleteDoc(doc(db, 'organizations', orgId, 'divisions', divId, 'triggers', id))
      .then(() => {
        notification.success({
          message: intl.formatMessage({ id: 'general.save.successful.message' }),
          placement: 'topRight',
        });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <BoxContainer>
      <BoxContainer content fixed shadow>
        <FilterContainer
          title={<Title value={<IntlMessages id="sidebar.menu.triggers" />} />}
          actionButtons={[
            {
              label: <IntlMessages id="general.create" />,
              action: () => history.push(getRouteToCreateTrigger()),
              type: 'primary',
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.TRIGGERS.CREATE,
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer content loading={triggersLoading || isLoading}>
        <TriggersList
          className={styles.triggerListBody}
          data={triggersData}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
        <InfiniteScroll condition={!triggersLoading && gotNewData} callback={next} />
      </BoxContainer>
    </BoxContainer>
  );
};

export default Triggers;
