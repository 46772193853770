import React, { useState, memo } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';
import { rgbToHex } from '../../packages/utils/functions';

const SimpleColorPicker = memo(({ onChange, color, hexColor, keyProp, disabled, className }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const colorFormated =
    typeof color === 'string' ? color : `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

  const handleOpenPicker = () => {
    if (disabled) return;
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClosePicker = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = newColor => {
    const outputColor = hexColor ? rgbToHex(newColor.rgb) : newColor.rgb;

    onChange(outputColor);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: '34px',
        height: '34px',
        borderRadius: '40px',
        background: colorFormated,
        position: 'absolute',
      },
      swatch: {
        padding: '5px',
        borderRadius: '40px',
        display: 'inline-block',
        cursor: 'pointer',
        position: 'relative',
        cursor: disabled ? 'not-allowed' : 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
        right: '50%',
        top: '30%',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
      container: {
        borderRadius: '10px',
      },
      iconPickerContainer: {
        width: '20px',
        height: '20px',
        background: '#CDCDCD',
        borderRadius: '40px',
        padding: '2px',
        position: 'absolute',
        top: '25px',
        left: '20px',
      },
      iconPicker: {
        fontSize: '16px',
      },
    },
  });

  return (
    <div key={keyProp} style={styles.container} className={className}>
      <div style={styles.swatch} onClick={() => handleOpenPicker()}>
        <div style={styles.color} />
        <div style={styles.iconPickerContainer}>
          <span style={styles.iconPicker} className="material-icons-outlined">
            edit
          </span>
        </div>
      </div>
      {displayColorPicker && !disabled ? (
        <div className="simpleColorPopover" style={styles.popover}>
          <div style={styles.cover} onClick={handleClosePicker} />
          <SketchPicker color={color} onChangeComplete={e => handleChange(e)} />
        </div>
      ) : null}
    </div>
  );
});

SimpleColorPicker.defaultProps = {
  color: {
    r: '241',
    g: '112',
    b: '19',
    a: '1',
  },
  hexColor: false,
  disabled: false,
};

SimpleColorPicker.propTypes = {
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      a: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      b: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      g: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      r: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ]),
  onChange: PropTypes.func.isRequired,
  hexColor: PropTypes.bool,
  keyProp: PropTypes.any,
  disabled: PropTypes.bool,
};

export default SimpleColorPicker;
