import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'antd';
import { ChildrenSchema, generateKey, useIsMobile } from 'packages/utils';
import styles from './styles.module.less';
import ComponentBlocker from 'components/ComponentBlocker';
import { EditTwoTone, DeleteTwoTone, CopyTwoTone } from '@ant-design/icons';
import { buttonCommonClass } from './constants';

const InfoCard = React.memo(({ children, className, title, subTitle, actionButtons }) => {
  const { isMobile } = useIsMobile();

  const TOP_ICONS_BUTTONS = {
    edit: props => <EditTwoTone {...props} />,
    delete: props => <DeleteTwoTone {...props} twoToneColor="#E13E3B" />,
    clone: props => <CopyTwoTone {...props} />,
  };
  const AUX_CLASS_BY_BUTTON = {
    edit: styles.editIconButton,
    delete: styles.deleteIconButton,
    clone: styles.cloneIconButton,
  };

  // NOTE: only will render when is mobile.
  const mobileButtons = () => {
    if (!isMobile) return [];
    return actionButtons?.map(bt => {
      if (bt?.hidden) return null;

      return (
        <React.Fragment>
          {bt?.allowedRole && bt.allowedRole.length > 0 ? (
            <ComponentBlocker divId={bt?.divId} allowedRoles={bt.allowedRole}>
              {TOP_ICONS_BUTTONS[bt.iconButton]({
                onClick: bt?.action,
              })}
            </ComponentBlocker>
          ) : (
            TOP_ICONS_BUTTONS[bt.iconButton]({
              onClick: bt?.action,
            })
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <div className={classNames(className, 'gx-w-100', styles.cardContainer)}>
      <Card
        title={
          title || subTitle ? (
            <div className="gx-w-100">
              {title && <div>{title}</div>}
              {subTitle && <div>{subTitle}</div>}
            </div>
          ) : null
        }
        extra={
          actionButtons?.length > 0 ? (
            <div>
              {!isMobile &&
                actionButtons?.length > 0 &&
                actionButtons?.map((bt, idx) => {
                  if (bt?.hidden) return null;
                  return (
                    <React.Fragment key={generateKey(idx)}>
                      {bt?.allowedRole && bt?.allowedRole.length > 0 ? (
                        <ComponentBlocker divId={bt?.divId} allowedRoles={bt.allowedRole}>
                          <Button
                            {...buttonCommonClass({ props: bt })}
                            icon={TOP_ICONS_BUTTONS[bt?.iconButton]()}
                            className={bt?.iconButton && AUX_CLASS_BY_BUTTON[bt?.iconButton]}
                          >
                            {bt?.label}
                          </Button>
                        </ComponentBlocker>
                      ) : (
                        <Button
                          {...buttonCommonClass({ props: bt })}
                          icon={TOP_ICONS_BUTTONS[bt?.iconButton]()}
                          className={bt?.iconButton && AUX_CLASS_BY_BUTTON[bt?.iconButton]}
                        >
                          {bt?.label}
                        </Button>
                      )}
                    </React.Fragment>
                  );
                })}
            </div>
          ) : null
        }
        className={styles.card}
        headStyle={{ padding: '0 12px' }}
        bodyStyle={{ padding: '12px' }}
        actions={mobileButtons()}
      >
        {children}
      </Card>
    </div>
  );
});

// SubCompoents
InfoCard.Title = ({ value }) => <div className="gx-guarnic-label">{value}</div>;
InfoCard.SubTitle = ({ value }) => <div className="gx-guarnic-headline-1">{value}</div>;

InfoCard.defaultProps = {
  classNames: null,
  title: null,
  actionButtons: [],
};
InfoCard.propTypes = {
  children: ChildrenSchema.isRequired,
  classNames: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      iconButton: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, ChildrenSchema]),
      type: PropTypes.string,
      danger: PropTypes.bool,
      action: PropTypes.func,
      disabled: PropTypes.bool,
      loading: PropTypes.bool,
      hidden: PropTypes.bool,
      allowedRole: PropTypes.any,
      htmlType: PropTypes.string,
      shape: PropTypes.string,
      className: PropTypes.string,
      divId: PropTypes.string,
    }),
  ),
};

export { InfoCard };
