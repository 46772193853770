export const USR_LIST_FETCH = 'USR_LIST_FETCH';
export const USR_LIST_FETCH_ERROR = 'USR_LIST_FETCH_ERROR';
export const USR_LIST_FETCH_SUCCESS = 'USR_LIST_FETCH_SUCCESS';

export const usersListFetch = organizationId => ({
  type: USR_LIST_FETCH,
  payload: { organizationId },
});

export const usersListFetchSuccess = userId => ({
  type: USR_LIST_FETCH_SUCCESS,
  payload: userId,
});

export const usersListFetchError = error => ({
  type: USR_LIST_FETCH_ERROR,
  payload: error,
});
