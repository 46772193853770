import { initializeApp } from 'firebase/app';
import {
  getAuth,
  connectAuthEmulator,
  GoogleAuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
} from 'firebase/auth';
import { initializeFirestore, Timestamp, serverTimestamp } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getRemoteConfig } from 'firebase/remote-config';

const firebaseConfig = require('./config.json').result.sdkConfig;

export const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();
const githubAuthProvider = new GithubAuthProvider();
const twitterAuthProvider = new TwitterAuthProvider();

const storage = getStorage();
const database = getDatabase();
const functions = getFunctions();

let settings = { ignoreUndefinedProperties: true };

// REMOTE CONFIG
const remoteConfig = getRemoteConfig(firebaseApp);
remoteConfig.settings.minimumFetchIntervalMillis = 3600;
remoteConfig.defaultConfig = {
  notifications_view: false,
};

if (process.env.NODE_ENV !== 'production') {
  connectAuthEmulator(auth, `http://${window.location.hostname}:9099`);
  connectStorageEmulator(storage, window.location.hostname, 9199);
  connectFunctionsEmulator(functions, window.location.hostname, 5001);
  settings = {
    ...settings,
    host: `${window.location.hostname}:8080`,
    ssl: false,
  };
}
const db = initializeFirestore(firebaseApp, settings);

export {
  storage,
  database,
  functions,
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  // end of wieldy's
  db,
  serverTimestamp,
  Timestamp,
  remoteConfig,
};
