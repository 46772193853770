import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import classNames from 'classnames';

import { storage } from 'firebase/firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import { PaperClipOutlined } from '@ant-design/icons';

import IntlMessages from 'util/IntlMessages';
import { COMPONENT_TYPES } from '../../../../../eventTypes/constants';

import styles from './index.module.less';

const Files = ({ component, labelClassname }) => {
  const getFile = async fileUrl => getDownloadURL(ref(storage, fileUrl));
  const getFileName = path => {
    const parts = path.split('/');
    const lastIdx = parts.length - 1;
    return parts[lastIdx];
  };

  const [isLoading, setLoading] = useState(true);
  const [urls, setUrls] = useState([]);
  const [error, setError] = useState(false);

  const loadFiles = async () => {
    try {
      const promises = [];
      let loadedUrls = [];
      component.fileValue?.forEach(file => {
        promises.push(getFile(file.url));
      });

      loadedUrls = await Promise.all(promises);

      setUrls(loadedUrls);
      setLoading(false);
    } catch (_) {
      setLoading(false);
      setError(true);
    }
  };

  const warningMessage = error ? (
    <IntlMessages id="eventTypes.component.notFound" />
  ) : (
    urls.length === 0 && <IntlMessages id="eventTypes.component.notLoaded" />
  );

  useEffect(() => {
    loadFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="gx-mt-3">
      <h4 className={classNames(styles.title, labelClassname)}>{component.label.name}</h4>
      {warningMessage}
      {!error && isLoading && urls.length === 0 && <Spin />}
      {!error &&
        urls.length > 0 &&
        !isLoading &&
        urls.map((fileName, i) => (
          <div className="gx-flex-row gx-align-items-center" key={fileName}>
            <PaperClipOutlined className="gx-mr-1" />
            <a href={fileName} className={styles.link}>
              {getFileName(component.fileValue[i].url)}
            </a>
          </div>
        ))}
    </div>
  );
};

Files.propTypes = {
  component: PropTypes.shape({
    componentProps: PropTypes.shape({
      autocomplete: PropTypes.bool,
      checked: PropTypes.bool,
      format: PropTypes.string,
      isDecimal: PropTypes.bool,
      max: PropTypes.number, // max number value
      maxCount: PropTypes.number,
      maxLength: PropTypes.number, // max length of characters
      maxSize: PropTypes.number,
      min: PropTypes.number, // min number value
      minLength: PropTypes.number, // min length of characters
      pattern: PropTypes.string,
      placeholder: PropTypes.string,
    }).isRequired,
    commonProps: PropTypes.shape({ required: PropTypes.bool.isRequired }).isRequired,
    label: PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
    type: COMPONENT_TYPES.FILE,
    fileValue: PropTypes.arrayOf(
      PropTypes.shape({
        format: PropTypes.string.isRequired,
        size: PropTypes.number.isRequired,
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
        url: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,

  labelClassname: PropTypes.string,
};

Files.defaultProps = {
  labelClassname: '',
};

export default React.memo(Files);
