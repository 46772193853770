import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { Empty, Spin } from 'antd';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import IntlMessages from 'util/IntlMessages';
import { KeyValueWidget } from 'components/KeyValueWidget';
import Title from 'components/BoxContainer/components/Title';

import { CustomDot } from 'components/charts/CustomDot';
import { getScaleByHours } from 'components/charts/constants';
import { UserLegend } from 'components/charts/UserLegend';
import { CHARTS } from 'packages/reports/constants';
import styles from './styles.module.less';

export const SpeedByUser = React.memo(
  ({ data, className, loading, hasUsers, rangeDate, onSetPos, posByIndex }) => {
    const from = moment(moment(rangeDate[0]).format('YYYY-MM-DD HH:mm:ss'));
    const to = moment(moment(rangeDate[1]).format('YYYY-MM-DD HH:mm:ss'));

    const handleMouseDown = e => {
      onSetPos(prev => ({
        ...prev,
        chart: CHARTS.speed_by_user,
        cx: e.cx,
        data: {
          location: [e?.payload?.location?.lat, e?.payload?.location?.lon],
          eventId: e.payload.id,
        },
      }));
    };
    const renderScatter = (scatterData, index) => (
      <Scatter
        key={index}
        dataKey="speed"
        name={scatterData.speed}
        data={scatterData.geoData}
        fill={scatterData.color}
        shape={props => {
          // eslint-disable-next-line react/prop-types
          const { cx, cy } = props;
          const isSelected = cx === posByIndex.cx;
          if (cx && cy) {
            return <CustomDot cx={cx} cy={cy} active={isSelected} fill={scatterData.color} />;
          }
          return null;
        }}
        onMouseDown={handleMouseDown}
      />
    );

    const renderChart = () => (
      <ResponsiveContainer className="gx-p-2" width="100%" height={200}>
        <ScatterChart data={data.geoData} margin={{ top: 5, right: 0, left: 0, bottom: 0 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis type="number" dataKey="speed" name="speed" allowDuplicatedCategory={false} />
          <XAxis
            dataKey="timestamp"
            domain={[from.valueOf(), to.valueOf()]}
            name="timestamp"
            tickFormatter={unixTime => moment(unixTime).format('HH:mm:ss')}
            type="number"
            ticks={getScaleByHours(from.valueOf(), to.valueOf())}
            allowDuplicatedCategory={false}
          />
          <Legend content={<UserLegend />} />
          {data.map(renderScatter)}
          <Tooltip content={<CustomTooltip />} />
        </ScatterChart>
      </ResponsiveContainer>
    );

    const renderEmptyState = () => {
      const message = hasUsers ? (
        <IntlMessages id="general.users.without.records.unselected" />
      ) : (
        <IntlMessages id="general.users.without.records" />
      );

      return <Empty description={message} />;
    };

    return (
      <div className={className}>
        <Title.LabelForm value={<IntlMessages id="chart.user.speed.title" />} />
        <Spin spinning={loading}>{data.length > 0 ? renderChart() : renderEmptyState()}</Spin>
      </div>
    );
  },
);

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const axiTime = payload.find(item => item.name === 'timestamp');
    const axiSpeed = payload.find(item => item.name === 'speed');

    const speedParsed = `${axiSpeed?.value || 0} km/h`;
    const time = moment(axiTime?.value || 0).format('HH:mm:ss');

    const user = payload[0 ?? 1]?.payload;
    const name = `${user?.firstName} ${user?.lastName}`;

    return (
      <div className={styles.customTooltipContainer}>
        <KeyValueWidget oneLine label={<IntlMessages id="general.name" />} value={name} />
        <KeyValueWidget oneLine label={<IntlMessages id="common.speed" />} value={speedParsed} />
        <KeyValueWidget oneLine label={<IntlMessages id="common.time" />} value={time} />
      </div>
    );
  }

  return null;
};

CustomTooltip.defaultProps = {
  active: false,
  payload: null,
};
CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.any,
};

SpeedByUser.defaultProps = {
  data: [],
  className: null,
  posByIndex: null,
};

SpeedByUser.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      geoData: PropTypes.any,
      speed: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  rangeDate: PropTypes.array.isRequired,
  onSetPos: PropTypes.func.isRequired,
  posByIndex: PropTypes.object,
  hasUsers: PropTypes.bool.isRequired,
};
