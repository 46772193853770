import {
  ORGANIZATION_FETCH,
  ORGANIZATION_FETCH_ERROR,
  ORGANIZATION_FETCH_SUCCESS,
  ORGANIZATION_SAVE_FETCH,
  ORGANIZATION_SAVE_FETCH_ERROR,
  ORGANIZATION_SAVE_FETCH_SUCCESS,
  ORGANIZATION_SELECT,
} from 'constants/ActionTypes';

export const organizationSelect = organizationId => ({
  type: ORGANIZATION_SELECT,
  payload: organizationId,
});

export const organizationFetch = organizationId => ({
  type: ORGANIZATION_FETCH,
  payload: organizationId,
});

export const organizationFetchSuccess = organizationId => ({
  type: ORGANIZATION_FETCH_SUCCESS,
  payload: organizationId,
});

export const organizationFetchError = error => ({
  type: ORGANIZATION_FETCH_ERROR,
  payload: error,
});

export const organizationSaveFetch = (organizationId, data) => ({
  type: ORGANIZATION_SAVE_FETCH,
  payload: { organizationId, data },
});

export const organizationSaveFetchSuccess = organization => ({
  type: ORGANIZATION_SAVE_FETCH_SUCCESS,
  payload: organization,
});

export const organizationSaveFetchError = error => ({
  type: ORGANIZATION_SAVE_FETCH_ERROR,
  payload: error,
});
