import moment from 'moment';
import React from 'react';
import IntlMessages from 'util/IntlMessages';
import { useIntl } from 'react-intl';
import styles from './styles.module.less';

const Recurrence = React.memo(({ repetition, startAt }) => {
  const intl = useIntl();
  const { day, hour, minute, month, weekDay, year } = repetition;
  const getStartAt = moment(startAt.toMillis());

  const TRASLATED_DAYS = {
    1: intl.formatMessage({ id: 'schedule.repetition.day.sunday' }),
    2: intl.formatMessage({ id: 'schedule.repetition.day.monday' }),
    3: intl.formatMessage({ id: 'schedule.repetition.day.tuesday' }),
    4: intl.formatMessage({ id: 'schedule.repetition.day.wednesday' }),
    5: intl.formatMessage({ id: 'schedule.repetition.day.thursday' }),
    6: intl.formatMessage({ id: 'schedule.repetition.day.friday' }),
    7: intl.formatMessage({ id: 'schedule.repetition.day.saturday' }),
  };

  const currentRepetition = {
    day: day?.length > 0,
    hour: hour?.length > 0,
    minute: minute?.length > 0,
    month: month?.length > 0,
    weekDay: weekDay?.length > 0,
    year: year?.length > 0,
  };

  let selectedOption = 'none';
  if (repetition) {
    if (!currentRepetition.weekDay && !currentRepetition.day) {
      selectedOption = 'daily';
    } else if (!currentRepetition.day) {
      selectedOption = 'weekDay';
    } else if (!currentRepetition.month) {
      selectedOption = 'monthly';
    } else if (!currentRepetition.year) {
      selectedOption = 'yearly';
    }
  }

  const RECURRENCE_TYPES = {
    none: <IntlMessages id="schedule.repetition.none" />,
    daily: <IntlMessages id="schedule.repetition.dailyAt" values={{ date: getStartAt }} />,
    weekDay: (
      <div>
        <IntlMessages id="schedule.repetition.weeklyAt" values={{ date: getStartAt }} />
        {weekDay.map((day, i) => (
          <span key={day} className={styles.displayDaysByTag}>
            {TRASLATED_DAYS[day]}
            {i === weekDay.length - 1 ? '' : ','}
          </span>
        ))}
      </div>
    ),
    monthly: <IntlMessages id="schedule.repetition.monthlyAt" values={{ date: getStartAt }} />,
    yearly: <IntlMessages id="schedule.repetition.yearlyAt" values={{ date: getStartAt }} />,
  };

  return RECURRENCE_TYPES[selectedOption];
});

export { Recurrence };
