import { useCallback, useEffect, useState } from 'react';

import { functions } from 'firebase/firebase';
import { httpsCallable } from 'firebase/functions';

const useCloudFunction = ({ params, dependencies, functionName, parseData }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const getRequestHttps = useCallback(() => httpsCallable(functions, functionName), [functionName]);

  const fetchData = useCallback(() => {
    setLoading(true);
    getRequestHttps()(params)
      .then(response => {
        if (response?.data) {
          if (parseData) {
            const dataParsed = parseData(response.data);
            setData(dataParsed);
            setLoading(false);
            return;
          }

          setData(response.data);
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        setError(err);
      });
  }, [getRequestHttps, params, parseData]);

  useEffect(() => {
    setError(null);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);

  return {
    data,
    loading,
    error,
  };
};

export default useCloudFunction;
