import { addOrderToTriggers, removeOrderFromTriggers } from 'packages/utils/functions/triggers';

export const REDUCER_INDEX = 'schedules';

export const BASE_URL = 'schedules';

export const PATHS = {
  BASE_URL,
  CREATE_SCHEDULE: `${BASE_URL}/schedule`,
  EDIT_SCHEDULE: `${BASE_URL}/schedule/:divId/:schId`,
};

export const getRouteToEditSchedule = (divId, schId) =>
  PATHS.EDIT_SCHEDULE.replace(':divId', divId).replace(':schId', schId);

export const MEMBER_TYPES = {
  GROUP: 'group',
  USER: 'user',
};

export const POI = {
  GPS: 'GPS',
  QR: 'QR',
  NFC: 'NFC',
};

export const TIME_KEYS = {
  MINUTE: 'min',
  HOUR: 'h',
  DAY: 'd',
  WEEK: 'w',
  MONTH: 'm',
  YEAR: 'y',
};

export const TIME_SCALES = {
  [TIME_KEYS.MINUTE]: 'timeScales.minute',
  [TIME_KEYS.HOUR]: 'timeScales.hour',
  [TIME_KEYS.DAY]: 'timeScales.day',
  [TIME_KEYS.WEEK]: 'timeScales.week',
  [TIME_KEYS.MONTH]: 'timeScales.month',
  [TIME_KEYS.YEAR]: 'timeScales.year',
};

export const DAYS_KEYS = {
  SUNDAY: '0',
  MONDAY: '1',
  TUESDAY: '2',
  WEDNESDAY: '3',
  THURSDAY: '4',
  FRIDAY: '5',
  SATURDAY: '6',
};

export const DAYS = {
  [DAYS_KEYS.SUNDAY]: 'days.sunday',
  [DAYS_KEYS.MONDAY]: 'days.monday',
  [DAYS_KEYS.TUESDAY]: 'days.tuesday',
  [DAYS_KEYS.WEDNESDAY]: 'days.wednesday',
  [DAYS_KEYS.THURSDAY]: 'days.thursday',
  [DAYS_KEYS.FRIDAY]: 'days.friday',
  [DAYS_KEYS.SATURDAY]: 'days.saturday',
};

export const FORMS = {
  REPETITION: 'repetition',
  MEMBERS: 'members',
  SCHEDULE: 'schedule',
  TRIGGERS: 'triggers',
  GPS: 'gps',
};

export const FORM_ITEM_LAYOUT = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 24,
  },
};

export const SCHEDULE_STATUS_DEFAULT = '0';

export const INIT_FILTERS = {
  name: '',
  active: SCHEDULE_STATUS_DEFAULT,
  dates: [],
  user: '',
};

export const PAGE_SIZE = 50;

export const ACTION_SCHEDULE_OPTIONS = {
  now: 'NOW',
  next: 'NEXT',
};

export const triggersFactory = (triggers, orderByIndex) => {
  let triggersParsed = triggers;

  // remove unused fields
  triggersParsed = triggersParsed.map(trigger => {
    const { newOrExistingTrigger, ...rest } = trigger;
    return rest;
  });

  // If the user wants to order the triggers by the index (according to sortable component)
  if (orderByIndex) {
    triggersParsed = addOrderToTriggers(triggersParsed);
  } else {
    triggersParsed = removeOrderFromTriggers(triggersParsed);
  }

  return triggersParsed;
};
