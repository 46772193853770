import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.less';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ChildrenSchema } from 'packages/utils';
import classNames from 'classnames';

const Title = ({ title, onBack, className }) => {
  return (
    <div className={classNames('gx-flex-row', className)}>
      {onBack && <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />}
      {title && (
        <div className={styles.titleC}>
          <div className={styles.title}>{title}</div>
        </div>
      )}
    </div>
  );
};

Title.defaultProps = {
  title: null,
  onBack: null,
  className: null,
};

Title.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, ChildrenSchema]),
  onBack: PropTypes.func,
  className: PropTypes.string,
};

export default Title;
