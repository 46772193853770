export const DIV_FETCH = 'DIV_FETCH';
export const DIV_FETCH_ERROR = 'DIV_FETCH_ERROR';
export const DIV_FETCH_SUCCESS = 'DIV_FETCH_SUCCESS';
export const DIV_SAVE_FETCH = 'DIV_SAVE_FETCH';
export const DIV_RESET = 'DIV_RESET';
export const DIV_SAVE_FETCH_ERROR = 'DIV_SAVE_FETCH_ERROR';
export const DIV_SAVE_FETCH_SUCCESS = 'DIV_SAVE_FETCH_SUCCESS';
export const DIV_DELETE = 'DIV_DELETE';
export const DIV_DELETE_SUCCESS = 'DIV_DELETE_SUCCESS';
export const DIV_DELETE_ERROR = 'DIV_DELETE_ERROR';

export const divisionFetch = (organizationId, divisionId) => ({
  type: DIV_FETCH,
  payload: { organizationId, divisionId },
});

export const divisionFetchSuccess = divisionId => ({
  type: DIV_FETCH_SUCCESS,
  payload: divisionId,
});

export const divisionFetchError = error => ({
  type: DIV_FETCH_ERROR,
  payload: error,
});

export const divisionReset = () => ({
  type: DIV_RESET,
  payload: null,
});

export const divisionSaveFetch = (organizationId, divisionId, data) => ({
  type: DIV_SAVE_FETCH,
  payload: { organizationId, divisionId, data },
});

export const divisionSaveFetchSuccess = division => ({
  type: DIV_SAVE_FETCH_SUCCESS,
  payload: division,
});

export const divisionSaveFetchError = error => ({
  type: DIV_SAVE_FETCH_ERROR,
  payload: error,
});

export const divisionDelete = (organizationId, divisionId) => ({
  type: DIV_DELETE,
  payload: { organizationId, divisionId },
});

export const divisionDeleteSuccess = () => ({
  type: DIV_DELETE_SUCCESS,
});

export const divisionDeleteError = error => ({
  type: DIV_DELETE_ERROR,
  payload: error,
});
