import * as actions from '../actions/division';

const getInitialState = () => ({
  data: null,
  error: null,
  id: null,
  loaded: false,
  loading: false,
  organizationId: null,
  save: {
    data: null,
    error: null,
    id: null,
    organizationId: null,
    loaded: false,
    loading: false,
  },
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case actions.DIV_FETCH: {
      return {
        ...state,
        data: null,
        error: null,
        id: action.payload.divisionId,
        loaded: false,
        loading: true,
        organizationId: action.payload.organizationId,
      };
    }
    case actions.DIV_FETCH_ERROR: {
      return {
        ...state,
        data: null,
        error: action.payload,
        loaded: true,
        loading: false,
      };
    }
    case actions.DIV_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loaded: true,
        loading: false,
      };
    }

    case actions.DIV_RESET: {
      return getInitialState();
    }

    case actions.DIV_SAVE_FETCH: {
      return {
        ...state,
        save: {
          data: null,
          submitedData: action.payload.data,
          error: null,
          id: action.payload.divisionId,
          loaded: false,
          loading: true,
          organizationId: action.payload.organizationId,
        },
      };
    }
    case actions.DIV_SAVE_FETCH_ERROR: {
      return {
        ...state,
        save: {
          data: null,
          error: action.payload,
          loaded: true,
          loading: false,
        },
      };
    }
    case actions.DIV_SAVE_FETCH_SUCCESS: {
      return {
        ...state,
        data: { ...state.data, ...state.save.submitedData },
        id: state.id || action.payload.id,
        save: {
          data: action.payload,
          id: action.payload.id,
          loaded: true,
          loading: false,
        },
      };
    }
    case actions.DIV_DELETE: {
      return {
        ...state,
        save: {
          data: null,
          id: action.payload.divisionId,
          loaded: false,
          loading: true,
          organizationId: action.payload.organizationId,
          divisionId: action.payload.divisionId,
        },
      };
    }
    case actions.DIV_DELETE_ERROR: {
      return {
        ...state,
        save: {
          data: null,
          error: action.payload,
          loaded: true,
          loading: false,
        },
      };
    }
    case actions.DIV_DELETE_SUCCESS: {
      return {
        ...state,
        save: {
          data: action.payload,
          loaded: true,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};
