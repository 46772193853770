import * as actions from '../actions';

const getInitialState = () => ({
  data: null,
  error: null,
  id: null,
  loaded: false,
  loading: false,
  organizationId: null,
  save: {
    data: null,
    error: null,
    id: null,
    organizationId: null,
    loaded: false,
    loading: false,
  },
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case actions.EVENT_TYPE_SAVE_FETCH: {
      return {
        ...state,
        save: {
          data: null,
          error: null,
          id: action.payload.eventTypeId,
          loaded: false,
          loading: true,
          organizationId: action.payload.organizationId,
        },
      };
    }
    case actions.EVENT_TYPE_SAVE_FETCH_ERROR: {
      return {
        ...state,
        save: {
          data: null,
          error: action.payload,
          loaded: true,
          loading: false,
        },
      };
    }
    case actions.EVENT_TYPE_SAVE_FETCH_SUCCESS: {
      return {
        ...state,
        save: {
          data: action.payload,
          loaded: true,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};
