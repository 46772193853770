import {
  USER_PROFILE_FETCH,
  USER_PROFILE_FETCH_ERROR,
  USER_PROFILE_FETCH_SUCCESS,
  USER_PROFILE_RESET,
  USER_PROFILE_SAVE_FETCH,
  USER_PROFILE_SAVE_FETCH_ERROR,
  USER_PROFILE_SAVE_FETCH_SUCCESS,
  USER_PROFILE_VALUES_RESET,
  USER_PROFILE_VALUES_SET,
} from 'constants/ActionTypes';

export const userProfileFetch = () => ({
  type: USER_PROFILE_FETCH,
  payload: null,
});

export const userProfileFetchSuccess = profile => ({
  type: USER_PROFILE_FETCH_SUCCESS,
  payload: profile,
});

export const userProfileFetchError = error => ({
  type: USER_PROFILE_FETCH_ERROR,
  payload: error,
});

export const userProfileReset = () => ({
  type: USER_PROFILE_RESET,
  payload: null,
});

export const userProfileSaveFetch = user => ({
  type: USER_PROFILE_SAVE_FETCH,
  payload: user,
});

export const userProfileSaveFetchSuccess = profile => ({
  type: USER_PROFILE_SAVE_FETCH_SUCCESS,
  payload: profile,
});

export const userProfileSaveFetchError = error => ({
  type: USER_PROFILE_SAVE_FETCH_ERROR,
  payload: error,
});

export const userProfileValuesReset = () => ({
  payload: null,
  type: USER_PROFILE_VALUES_RESET,
});

export const userProfileValuesSet = values => ({
  payload: values,
  type: USER_PROFILE_VALUES_SET,
});
