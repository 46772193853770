import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import rootSaga from '../sagas/index';
import createRootReducer from '../reducers';

// history will be used from react-router-dom
// eslint-disable-next-line import/no-extraneous-dependencies
const { createBrowserHistory } = require('history');

export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk, sagaMiddleware, routeMiddleware];

if (process.env.NODE_ENV === 'development') {
  // Adds the logger
  // eslint-disable-next-line global-require
  const { createLogger } = require('redux-logger');
  middlewares.push(
    createLogger({
      collapsed: true,
      duration: true,
    }),
  );
}

export default function configureStore(preloadedState) {
  const composer =
    (process.env.NODE_ENV === 'development' && window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composer(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middlewares,
      ),
    ),
  );

  sagaMiddleware.run(rootSaga);
  return store;
}
