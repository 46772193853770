import React from 'react';

import { useIntl } from 'react-intl';
import { Col, DatePicker, Form, Input, InputNumber, Row, Select, Switch } from 'antd';

import { KeyValueWidget } from 'components/KeyValueWidget';
import Title from 'components/BoxContainer/components/Title';

import FormTag from './FormTag';
import styles from './styles.module.less';
import { dynamicFormFactory } from './constants';
import SectionHeader from 'components/SectionHeader';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
/**
 * A dynamic form component that renders form fields based on the provided formData.
 * @param {Object[]} formData - An array of objects representing EVENT_TYPE DOCUMENT.
 * @param {string} formName - The name of the form.
 * @param {string} formTitle - The title of the form.
 * @param {Object} props - Additional props to be passed to the Form component.
 * @returns {JSX.Element} - The rendered dynamic form.
 */
const DynamicForm = ({ formData = {}, formName, formTitle, ...props }) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const data = dynamicFormFactory(formData);

  const renderFormItem = item => {
    switch (item.type) {
      case 'text':
        return (
          <Input
            autoComplete={item?.autoComplete}
            placeholder={item?.placeholder}
            className="gx-w-100"
          />
        );
      case 'number':
        return <InputNumber type="number" placeholder={item?.placeholder} className="gx-w-100" />;
      case 'toggle':
        return <Switch checked={false} />;
      case 'select':
        return (
          <Select placeholder={item?.placeholder}>
            {item.options.map((option, idx) => (
              <Select.Option key={idx} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        );
      case 'tag':
        return <FormTag />;
      case 'date':
        return (
          <DatePicker
            className="gx-w-100"
            showTime={item.includesTime ? { format: 'HH:mm' } : false}
            format={item.includesTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}
            showSecond={false}
          />
        );
      default:
        return null;
    }
  };

  const getAuxProps = item => {
    switch (item.type) {
      case 'toggle':
        return {
          valuePropName: 'checked',
          initialValue: item?.checked ?? false,
        };
      default:
        return {};
    }
  };

  const buildRules = item => {
    const output = [];
    if (item.required) {
      output.push({
        required: true,
        message: intl.formatMessage({ id: 'form.required' }),
      });
    }

    if (item.validator) {
      output.push({
        validator: item.validator,
      });
    }

    return output;
  };

  const sortedData = data.sort((a, b) => a.order - b.order);

  return (
    <Form name={formName} form={form} {...props}>
      <div className="gx-guarnic-pb-2">
        <span className="gx-guarnic-headline-2">{formTitle}</span>
      </div>
      <Row gutter={16}>
        {sortedData.map(item => (
          <React.Fragment key={item?.id || Math.random().toString(36).substring(7)}>
            <Col span={12}>
              <Title.LabelForm required={item?.required} value={item.label} />
              <Form.Item
                rules={buildRules(item)}
                {...getAuxProps(item)}
                name={[item.id, item.label]}
              >
                {renderFormItem(item)}
              </Form.Item>
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </Form>
  );
};

DynamicForm.Display = ({ forms = [] }) => {
  const renderFormItem = item => {
    if (
      (!item.value && item.type !== 'toggle') ||
      (typeof item.value === 'object' && item?.value?.length === 0)
    ) {
      return '-';
    }

    switch (item.type) {
      case 'text':
        return <span>{item.value}</span>;
      case 'number':
        return <span>{item.value}</span>;
      case 'toggle':
        return item.value ? <IntlMessages id="general.yes" /> : <IntlMessages id="general.no" />;
      case 'select':
        return <span>{item.value.join(', ')}</span>;
      case 'tag':
        return <span>{item.value.join(', ')}</span>;
      case 'date':
        return (
          <IntlMessages
            id="common.date.time.value"
            values={{
              date: item.value,
            }}
          />
        );
      default:
        return null;
    }
  };

  const getComponents = form => {
    const data = dynamicFormFactory(form);
    let sortedData = data?.sort((a, b) => a?.order - b?.order);
    return sortedData.map(item => (
      <KeyValueWidget
        key={item.id}
        label={<Title.LabelForm value={item.label} />}
        value={renderFormItem(item)}
      />
    ));
  };

  return (
    <>
      {forms.map(form => (
        <React.Fragment key={form.id}>
          <SectionHeader title={form.name}>
            <div className={styles.formDisplayComponents}>{getComponents(form)}</div>
          </SectionHeader>
        </React.Fragment>
      ))}
    </>
  );
};

DynamicForm.propTypes = {
  formData: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.oneOf(['text', 'number', 'toggle', 'select', 'tag', 'date']),
    autoComplete: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    includesTime: PropTypes.bool,
    required: PropTypes.bool,
    validator: PropTypes.func,
    checked: PropTypes.bool,
    label: PropTypes.string,
    order: PropTypes.number,
  }),
  formName: PropTypes.string,
  formTitle: PropTypes.string,
};

DynamicForm.Display.propTypes = {
  forms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      type: PropTypes.oneOf(['text', 'number', 'toggle', 'select', 'tag', 'date']),
      includesTime: PropTypes.bool,
      order: PropTypes.number,
    }),
  ),
};

export default DynamicForm;
