import { useState, useEffect, useRef } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import { getDocData } from 'packages/utils';
import { EXECUTION_STATUS } from 'constants/commons';

/**
 * Hook to get triggers by executions in real-time, this will be auxiliar while backend is not ready
 */

export const useTriggersByExecutionsRt = executions => {
  const [triggers, setTriggers] = useState({});
  const activeSubscriptions = useRef({});

  const cleanupActiveSubscriptions = () => {
    Object.values(activeSubscriptions.current).forEach(unsubscribe => unsubscribe());
    activeSubscriptions.current = {};
  };

  useEffect(() => {
    const unsubscribeFunctions = executions.map(execution => {
      // We don't need to listen to triggers if the execution is not active
      if (
        [
          EXECUTION_STATUS.expired,
          EXECUTION_STATUS.completed,
          EXECUTION_STATUS.incomplete,
        ].includes(execution?.status)
      )
        return null;

      const divId = execution?.division?.id;
      const orgId = execution?.organization?.id;
      const executionId = execution?.id;

      const triggersRef = collection(
        db,
        'organizations',
        orgId,
        'divisions',
        divId,
        'executions',
        executionId,
        'triggers',
      );

      const unsubscribe = onSnapshot(triggersRef, snapshot => {
        const triggersData = snapshot.docs.map(doc => getDocData(doc));
        setTriggers(prev => ({
          ...prev,
          [executionId]: triggersData,
        }));
      });

      activeSubscriptions.current[executionId] = unsubscribe;
      return unsubscribe;
    });

    // Cleanup subscriptions on unmount
    return () => {
      unsubscribeFunctions.forEach(unsubscribe => unsubscribe && unsubscribe());
    };
  }, [executions]);

  return {
    data: triggers,
    cleanupActiveSubscriptions,
  };
};
