import moment from 'moment';

export const getScheduleStatus = (startDate, endDate) => {
  const currentTimestamp = moment();
  const start = moment(startDate);
  const end = moment(endDate);

  return (
    currentTimestamp.isBetween(start, end) ||
    currentTimestamp.isSame(start) ||
    currentTimestamp.isSame(end)
  );
};
