import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import banner from 'assets/images/banner-name-white.png';

import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, TAB_SIZE } from '../../constants/ThemeSetting';
import styles from './styles.module.less';

const SidebarLogo = () => {
  const { width } = useSelector(({ settings }) => settings);
  let navStyle = useSelector(({ settings }) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <div className={classnames('gx-layout-sider-header', styles.header)}>
      <Link to="/" className="gx-justify-content-center">
        <img src={banner} alt="Guarnic" />
      </Link>
    </div>
  );
};

export default SidebarLogo;
