import * as actions from '../actions/list';

const getInitialState = () => ({
  data: null,
  error: null,
  id: null,
  loaded: false,
  loading: false,
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case actions.USR_LIST_FETCH: {
      return {
        ...state,
        data: null,
        error: null,
        id: action.payload.organizationId,
        loaded: false,
        loading: true,
      };
    }
    case actions.USR_LIST_FETCH_ERROR: {
      return {
        ...state,
        data: null,
        error: action.payload,
        loaded: true,
        loading: false,
      };
    }
    case actions.USR_LIST_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loaded: true,
        loading: false,
      };
    }

    default:
      return state;
  }
};
