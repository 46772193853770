import { AimOutlined, DeleteOutlined, QrcodeOutlined } from '@ant-design/icons';
import { Avatar, Button } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import IntlMessages from 'util/IntlMessages';

import styles from './styles.module.less';
import { POI } from '../../constants';

const Poi = ({ type, name, fullSize, onRemove, onEdit, selected, distance }) => {
  const icons = {
    GPS: <AimOutlined />,
    QR: <QrcodeOutlined />,
    NFC: <span>{POI.NFC}</span>,
  };

  return (
    <div className={classnames('gx-mb-3', styles.column, fullSize && styles.bordered)}>
      <div className={styles.rowCenter}>
        <Avatar
          className={classnames('gx-mr-2', (selected || fullSize) && styles.selected)}
          size={fullSize ? 40 : 24}
          icon={icons[type]}
        />
        <div className={styles.info}>
          <span className={styles.boldText}>{name}</span>
          <span className={styles.type}>{type}</span>
        </div>
        {onRemove && (
          <Button
            className={classnames(styles.rowCenter, 'gx-mb-0')}
            onClick={onRemove}
            type="link"
          >
            <DeleteOutlined className={styles.buttonIcon} />
          </Button>
        )}
      </div>
      {fullSize && type === POI.GPS && (
        <>
          <span className="gx-mt-3">
            <IntlMessages
              id="schedule.form.triggers.modal.location.GPS.description"
              values={{
                side: (
                  <span className={styles.boldText}>
                    <IntlMessages id={distance.outside ? 'side.outside' : 'side.inside'} />
                  </span>
                ),
                meters: msg => <span className={styles.boldText}>{msg}</span>,
                amount: <span className={styles.boldText}>{distance.meters}</span>,
              }}
            />
          </span>

          {onEdit && (
            <div className={styles.editContainer}>
              <Button
                type="link"
                className={classnames(styles.rowCenter, 'gx-mb-0')}
                onClick={onEdit}
              >
                <IntlMessages id="button.edit" />
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

Poi.defaultProps = {
  distance: null,
  fullSize: false,
  onEdit: null,
  onRemove: null,
  selected: false,
};

Poi.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(POI)).isRequired,

  distance: PropTypes.shape({
    meters: PropTypes.number,
    outside: PropTypes.bool,
  }),
  fullSize: PropTypes.bool,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  selected: PropTypes.bool,
};

export default Poi;
