import { combineReducers } from 'redux';

import { REDUCER_INDEX } from '../../constants';

import division from './division';
import list from './list';
import selector from './selector';
import assigned from './assigned';

export const reducer = combineReducers({
  division,
  list,
  selector,
  assigned,
});

export const registerReducer = state => ({
  ...state,
  [REDUCER_INDEX]: reducer,
});

export const getState = state => state[REDUCER_INDEX];
export const getStateDivision = state => state[REDUCER_INDEX].division;
export const getStateList = state => state[REDUCER_INDEX].list;
export const getStateSelector = state => state[REDUCER_INDEX].selector;
export const getStateAssigned = state => state[REDUCER_INDEX].assigned;
