import PropTypes from 'prop-types';
import { triggerPropType } from '../trigger';

export const jobsPropType = PropTypes.shape({
  start: PropTypes.shape({
    cron: PropTypes.string.isRequired,
    execution: PropTypes.shape({
      group: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
      status: PropTypes.string,
      lastExecution: PropTypes.string,
      nextExecution: PropTypes.string,
      description: PropTypes.string,
    }),
  }).isRequired,
  end: PropTypes.shape({
    cron: PropTypes.string.isRequired,
    execution: PropTypes.shape({
      group: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
      status: PropTypes.string,
      lastExecution: PropTypes.string,
      nextExecution: PropTypes.string,
      description: PropTypes.string,
    }),
  }).isRequired,
  reminders: PropTypes.arrayOf(
    PropTypes.shape({
      cron: PropTypes.string.isRequired,
      execution: PropTypes.shape({
        group: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        uuid: PropTypes.string.isRequired,
        status: PropTypes.string,
        lastExecution: PropTypes.string,
        nextExecution: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
  ),
});

export const scheduleProptype = PropTypes.shape({
  createdAt: PropTypes.object.isRequired,
  divId: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  members: PropTypes.shape({
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        divId: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        avatarURL: PropTypes.string,
        users: PropTypes.arrayOf(
          PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            photoURL: PropTypes.string,
            displayName: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
          }),
        ),
      }),
    ).isRequired,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        photoURL: PropTypes.string,
        displayName: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  name: PropTypes.string.isRequired,
  reminders: PropTypes.arrayOf(PropTypes.number).isRequired,
  triggers: PropTypes.arrayOf(triggerPropType),
  jobs: jobsPropType,
});
