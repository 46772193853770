import React, { useState } from 'react';
import { Modal } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import classname from 'classnames';
import { startCase } from 'lodash';
import { Button } from 'antd';

import { ArrowRightOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';
import { errorNotification } from 'appRedux/actions';
import GooglePlaceAutocomplete from 'components/GooglePlaceAutocomplete';
import CustomInput from 'components/CustomInput';
import { organizationSaveFetch } from 'appRedux/actions';

import styles from './styles.module.less';

const NewOrganizationModal = ({ closeModal, isOpen }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [address, setAddress] = useState(null);

  const sizeSteps = ['1-10', '11-50', '51-200', '201-500', '501-1000', '+1000'];

  const handleOk = () => {
    closeModal();
  };

  const handleCancel = () => {
    closeModal();
  };

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { dirtyFields, isValid, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      size: sizeSteps[0],
      name: '',
      address: '',
      phone: null,
    },
  });

  const onEnter = f => e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault();
      f();
    }
  };

  const customSubmit = values => {
    const isDirty = Object.keys(dirtyFields).length !== 0;
    if (isDirty && !address) {
      dispatch(errorNotification(intl.formatMessage({ id: 'form.selectAddress' })));
    } else {
      const fullValues = {
        phone: values.phone,
        address: { ...address, address: values.address },
        size: sizeSteps[values.size],
        name: startCase(values.name.trim().toLowerCase()),
      };

      if (isDirty) {
        dispatch(organizationSaveFetch(null, fullValues));
        closeModal();
      }
    }
  };

  const formSubmit = handleSubmit(customSubmit);

  return (
    <Modal
      title={intl.formatMessage({ id: 'sidebar.newOrganization' })}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <form onSubmit={formSubmit}>
        <CustomInput
          autoFocus
          onKeyDown={onEnter(() => setFocus('address'))}
          label={intl.formatMessage({ id: 'onboarding.stepTwo.organizationName' })}
          errors={errors}
          register={register('name', {
            required: intl.formatMessage({ id: 'form.required.msg' }),
            maxLength: {
              value: 255,
              message: intl.formatMessage(
                {
                  id: 'form.maxLength.msg',
                },
                { amount: 255 },
              ),
            },
            minLength: {
              value: 3,
              message: intl.formatMessage(
                {
                  id: 'form.minLength.msg',
                },
                { amount: 3 },
              ),
            },
          })}
        />
        <GooglePlaceAutocomplete
          className="m-top-5"
          errors={errors}
          label={intl.formatMessage({ id: 'onboarding.stepTwo.address' })}
          onChange={setAddress}
          onSelect={() => setFocus('phone')}
          register={register('address', {
            required: intl.formatMessage({ id: 'form.required.msg' }),
          })}
        />
        <CustomInput
          onKeyDown={onEnter(() => setFocus('size'))}
          className="m-top-5"
          label={intl.formatMessage({ id: 'onboarding.stepTwo.organizationPhone' })}
          errors={errors}
          register={register('phone', {
            pattern: {
              value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
              message: intl.formatMessage({
                id: 'onboarding.stepTwo.organizationPhone.pattern.error',
              }),
            },
            maxLength: {
              value: 50,
              message: intl.formatMessage(
                {
                  id: 'form.maxLength.msg',
                },
                { amount: 50 },
              ),
            },
          })}
        />
        <div className="m-top-5">
          <label className="m-bottom-2" htmlFor="size">
            <IntlMessages id="onboarding.stepTwo.organizationSize" />
          </label>
          <input
            onKeyDown={onEnter(formSubmit)}
            className="gx-w-100"
            type="range"
            list="tickmarks"
            defaultValue={0}
            {...register('size')}
            max={sizeSteps.length - 1}
          />
          <datalist id="tickmarks" className={styles.slider}>
            {sizeSteps.map((v, i) => (
              <option key={v} value={i} label={v} />
            ))}
          </datalist>
        </div>
        <div className={classname('m-top-5', styles.buttonsPanel)}>
          <Button
            type={isValid ? 'primary' : 'default'}
            shape="circle"
            htmlType="submit"
            disabled={!isValid}
            className="gx-mb-0"
          >
            <ArrowRightOutlined />
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default NewOrganizationModal;
