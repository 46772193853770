import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Radio, Select, Space, Tag } from 'antd';
import { useIntl } from 'react-intl';

// format
// https://formatjs.io/docs/intl-messageformat/

// other way
// intl.formatDate(initialDate, { weekday: 'long' })

/*
type TRepetition = {
  day: number[];
  hour: number[];
  minute: number[];
  month: number[];
  weekDay: number[];
  year: number[];
};
*/

import styles from './styles.module.less';

const RepetitionSelection = React.memo(props => {
  const {
    disabled = false,
    initialDate,
    onChange: onValueChange,
    setIsRecurrent,
    value,
    ...inputProps
  } = props;
  const intl = useIntl();
  const initialDateMillis = initialDate.valueOf();

  const defaultRepetition = {
    day: [initialDate.date()],
    hour: [initialDate.hours()],
    minute: [initialDate.minutes()],
    month: [initialDate.month() + 1],
    weekDay: value?.weekDay?.length > 0 ? value?.weekDay : [initialDate.isoWeekday()],
    year: [initialDate.year()],
  };

  const [weekDays, setWeekDays] = useState(defaultRepetition.weekDay);

  const currentRepetition = {
    day: value?.day?.length > 0,
    hour: value?.hour?.length > 0,
    minute: value?.minute?.length > 0,
    month: value?.month?.length > 0,
    weekDay: value?.weekDay?.length > 0,
    year: value?.year?.length > 0,
  };
  const TRASLATED_DAYS = {
    Su: intl.formatMessage({ id: 'schedule.repetition.day.sunday' }),
    Mo: intl.formatMessage({ id: 'schedule.repetition.day.monday' }),
    Tu: intl.formatMessage({ id: 'schedule.repetition.day.tuesday' }),
    We: intl.formatMessage({ id: 'schedule.repetition.day.wednesday' }),
    Th: intl.formatMessage({ id: 'schedule.repetition.day.thursday' }),
    Fr: intl.formatMessage({ id: 'schedule.repetition.day.friday' }),
    Sa: intl.formatMessage({ id: 'schedule.repetition.day.saturday' }),
  };

  let selectedOption = 'none';
  if (value) {
    if (!currentRepetition.weekDay && !currentRepetition.day) {
      selectedOption = 'daily';
    } else if (!currentRepetition.day) {
      selectedOption = 'weekly';
    } else if (!currentRepetition.month) {
      selectedOption = 'monthly';
    } else if (!currentRepetition.year) {
      selectedOption = 'yearly';
    }
  }

  const options = [
    {
      label: intl.formatMessage({ id: 'schedule.repetition.none' }),
      name: 'none',
      value: {
        ...defaultRepetition,
        weekDay: [],
      },
    },
    {
      label: intl.formatMessage({ id: 'schedule.repetition.dailyAt' }, { date: initialDate }),
      name: 'daily',
      value: {
        ...defaultRepetition,
        day: [],
        month: [],
        year: [],
        weekDay: [],
      },
    },
    {
      label: intl.formatMessage({ id: 'schedule.repetition.weeklyAt' }, { date: initialDate }),
      name: 'weekly',
      value: {
        ...defaultRepetition,
        day: [],
        month: [],
        year: [],
      },
    },
    {
      label: intl.formatMessage({ id: 'schedule.repetition.monthlyAt' }, { date: initialDate }),
      name: 'monthly',
      value: {
        ...defaultRepetition,
        month: [],
        year: [],
        weekDay: [],
      },
    },
    {
      name: 'yearly',
      label: intl.formatMessage({ id: 'schedule.repetition.yearlyAt' }, { date: initialDate }),
      value: {
        ...defaultRepetition,
        year: [],
        weekDay: [],
      },
    },
  ];

  const onChange = ({ target: { value: name } }) => {
    const optionValue = options.find(opt => opt.name === name).value;
    setIsRecurrent(name !== 'none');
    onValueChange({
      ...optionValue,
      weekDay: name === 'weekly' ? weekDays : optionValue.weekDay,
    });
  };

  const onChangeWeekDay = (isoDate, checked) => {
    const days = weekDays.filter(day => day !== isoDate);
    if (checked) {
      days.push(isoDate);
      days.sort((a, b) => a - b);
    }
    setWeekDays(days.length >= 7 ? [] : days);
    onValueChange({
      ...options.find(opt => opt.name === 'weekly').value,
      weekDay: days.length >= 7 ? [] : days,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange({ target: { value: selectedOption } }), [initialDateMillis]);

  const renderOption = opt => (
    <Radio disabled={disabled} key={opt.name} value={opt.name}>
      <span className="gx-guarnic-body">{opt.label}</span>
    </Radio>
  );

  const renderDaysOptions = opt => (
    <Radio disabled={disabled} className={styles.daysRadio} key={opt.name} value={opt.name}>
      {opt.label}
      {moment.weekdaysMin().map((day, index) => (
        <Tag.CheckableTag
          className={styles.dayTag}
          key={day}
          checked={weekDays.includes(index + 1)}
          onClick={e => e.preventDefault()}
          onChange={checked => {
            if (disabled) return;
            onChangeWeekDay(index + 1, checked);
            setIsRecurrent(true);
          }}
        >
          {TRASLATED_DAYS[day]}
        </Tag.CheckableTag>
      ))}
    </Radio>
  );

  return (
    <div>
      <Radio.Group disabled={disabled} {...inputProps} onChange={onChange} value={selectedOption}>
        <Space direction="vertical">
          {options.map(opt => (opt.name === 'weekly' ? renderDaysOptions(opt) : renderOption(opt)))}
        </Space>
      </Radio.Group>
    </div>
  );
});

RepetitionSelection.propTypes = {
  ...Select.propTypes,

  initialDate: PropTypes.instanceOf(moment).isRequired,
};

RepetitionSelection.defaultProps = {
  ...Select.defaultProps,
};

export default RepetitionSelection;
