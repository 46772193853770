import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select } from 'antd';
import { useIntl } from 'react-intl';

import IntlMessages from 'util/IntlMessages';

import { COMPONENT_TYPES, TYPES_INTL } from '../../constants';

const { Item } = Form;
const { Option } = Select;

const ComponentInformation = ({ id, fieldKey, onTypeSelect, extraProps }) => {
  const intl = useIntl();

  return (
    <div>
      <Item
        {...extraProps}
        label={intl.formatMessage({
          id: 'eventTypes.eventType.component.form.componentType.label',
        })}
        name={[id, 'type']}
        fieldKey={[fieldKey, 'type']}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'eventTypes.eventType.component.form.componentType.requiredError',
            }),
          },
        ]}
      >
        <Select
          placeholder={intl.formatMessage({
            id: 'eventTypes.eventType.component.form.componentType.placeholder',
          })}
          onChange={onTypeSelect}
        >
          {Object.values(COMPONENT_TYPES).map(type => (
            <Option value={type} key={type}>
              <IntlMessages id={TYPES_INTL[type]} />
            </Option>
          ))}
        </Select>
      </Item>
      <Item
        {...extraProps}
        label={<IntlMessages id="eventTypes.eventType.component.form.label.label" />}
        name={[id, 'label', 'name']}
        fieldKey={[fieldKey, 'label', 'name']}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'eventTypes.eventType.component.form.label.requiredError',
            }),
          },
        ]}
      >
        <Input
          maxLength={120}
          placeholder={intl.formatMessage({
            id: 'eventTypes.eventType.component.form.label.placeholder',
          })}
        />
      </Item>
      <Item
        {...extraProps}
        label={<IntlMessages id="eventTypes.eventType.component.form.description.label" />}
        name={[id, 'label', 'description']}
        fieldKey={[fieldKey, 'label', 'description']}
      >
        <Input
          maxLength={360}
          placeholder={intl.formatMessage({
            id: 'eventTypes.eventType.component.form.description.placeholder',
          })}
        />
      </Item>
    </div>
  );
};

ComponentInformation.propTypes = {
  extraProps: PropTypes.shape({
    isListField: PropTypes.bool,
  }).isRequired,
  fieldKey: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  onTypeSelect: PropTypes.func.isRequired,
};

export default ComponentInformation;
