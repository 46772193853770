import React from 'react';
import PropTypes from 'prop-types';
import { useFirestoreQuery } from '../../packages/utils/hooks';
import { collection } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import { Select } from 'antd';
import classNames from 'classnames';
import { CaretDownOutlined } from '@ant-design/icons';
import styles from './styles.module.less';

const DropDownEvents = ({ orgId, divId, onChange, value, disabled }) => {
  const divEventTypesRef =
    divId && collection(db, 'organizations', orgId, 'divisions', divId, 'event_types');
  const { data: divETs } = useFirestoreQuery(divEventTypesRef, [orgId, divId]);

  const options = divETs?.map(type => ({
    label: (
      <>
        <span
          style={{
            backgroundColor: type.color
              ? `rgba(${type.color.r}, ${type.color.g}, ${type.color.b}, ${type.color.a})`
              : '',
          }}
          className={classNames('gx-timeline-badge', styles.icon)}
        >
          {!!type.icon && <i className="material-icons">{type.icon}</i>}
        </span>
        {type.name}
      </>
    ),
    value: type.id,
  }));

  const valueProp = value
    ? {
        value: value,
      }
    : {};
  return (
    <Select
      disabled={!divId || disabled}
      options={options}
      onChange={e => onChange(e)}
      suffixIcon={<CaretDownOutlined />}
      {...valueProp}
    />
  );
};

DropDownEvents.defaultProps = {
  disabled: false,
};

DropDownEvents.propTypes = {
  orgId: PropTypes.string.isRequired,
  divId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

export const DropDownEventsMemo = React.memo(DropDownEvents);
