import { ACTIONS } from './actions';

export const getInitialState = () => ({
  dataByBatch: {},
  loadedByBatch: { 0: false },
  loadingByBatch: { 0: false },
  error: null,
  next: null,
});

export const reducer = (state = getInitialState(), { type, payload }) => {
  switch (type) {
    case ACTIONS.UTI_FQB_FETCH: {
      const updatedLoaded = { ...new Array(payload.batchesAmount).fill(false) };
      const updatedLoading = { ...new Array(payload.batchesAmount).fill(true) };

      return {
        ...getInitialState(),
        loadedByBatch: updatedLoaded,
        loadingByBatch: updatedLoading,
      };
    }
    case ACTIONS.UTI_FQB_FETCH_ERROR: {
      return {
        ...getInitialState(),
        error: payload,
        loadedByBatch: { 0: true },
      };
    }
    case ACTIONS.UTI_FQB_FETCH_BATCH_SUCCESS: {
      const { batchId } = payload;

      const updatedData = {
        ...state.dataByBatch,
        [batchId]: { ...state.dataByBatch[batchId], ...payload.data },
      };
      const updatedLoading = {
        ...state.loadingByBatch,
        [batchId]: false,
      };
      const updatedLoaded = {
        ...state.loadedByBatch,
        [batchId]: true,
      };

      return {
        ...getInitialState(),
        dataByBatch: updatedData,
        loadedByBatch: updatedLoaded,
        loadingByBatch: updatedLoading,
      };
    }
    case ACTIONS.UTI_FQB_RESET: {
      return getInitialState();
    }
    default:
      throw new Error('useFirestoreQueryBatched: Invalid action');
  }
};
