import React, { useEffect } from 'react';
import { Form, Modal, InputNumber, Radio } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import IntlMessages from 'util/IntlMessages';

import { FORMS } from '../../constants';

const { Item } = Form;

const GpsForm = ({ isVisible, onCancel, initialData, onSubmit }) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields().then(values => {
      onSubmit(values);
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [form, initialData]);

  return (
    <Modal
      title={intl.formatMessage({ id: 'schedule.form.triggers.modal.gpsModal.title' })}
      open={isVisible}
      onOk={handleOk}
      onCancel={onCancel}
      closable={false}
      okText={intl.formatMessage({ id: 'form.confirm' })}
      cancelText={intl.formatMessage({ id: 'button.cancel' })}
    >
      <Form form={form} name={FORMS.GPS} layout="vertical" initialValues={initialData}>
        <Item name="meters">
          <InputNumber min={1} />
        </Item>
        <Item name="outside">
          <Radio.Group>
            <Radio value>
              <IntlMessages id="side.outside" />
            </Radio>
            <Radio value={false}>
              <IntlMessages id="side.inside" />
            </Radio>
          </Radio.Group>
        </Item>
      </Form>
    </Modal>
  );
};

GpsForm.defaultProps = {
  initialData: {},
};

GpsForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  initialData: PropTypes.shape({
    meters: PropTypes.number,
    outside: PropTypes.bool,
  }),
};

export default GpsForm;
