export const GRP_SAVE_FETCH = 'GRP_SAVE_FETCH';
export const GRP_SAVE_FETCH_ERROR = 'GRP_SAVE_FETCH_ERROR';
export const GRP_SAVE_FETCH_SUCCESS = 'GRP_SAVE_FETCH_SUCCESS';

export const groupSaveFetch = (organizationId, divisionId, groupId, data) => ({
  type: GRP_SAVE_FETCH,
  payload: { organizationId, divisionId, groupId, data },
});

export const groupSaveFetchSuccess = group => ({
  type: GRP_SAVE_FETCH_SUCCESS,
  payload: group,
});

export const groupSaveFetchError = error => ({
  type: GRP_SAVE_FETCH_ERROR,
  payload: error,
});
