import { Grid } from 'antd';

const { useBreakpoint } = Grid;
const useIsMobile = () => {
  const screens = useBreakpoint();
  const isMobile = screens.xs;

  return {
    isMobile,
  };
};

export default useIsMobile;
