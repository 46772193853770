import { combineReducers } from 'redux';

import { REDUCER_INDEX } from '../../constants';

import eventType from './eventType';

export const reducer = combineReducers({
  eventType,
});

export const registerReducer = state => ({
  ...state,
  [REDUCER_INDEX]: reducer,
});

export const getState = state => state[REDUCER_INDEX];
export const getStateEventType = state => state[REDUCER_INDEX].eventType;
