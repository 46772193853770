export const ACTIONS = {
  UTI_FQP_FIRST_BATCH: 'UTI_FQP_FIRST_BATCH',
  UTI_FQP_FIRST_BATCH_ERROR: 'UTI_FQP_FIRST_BATCH_ERROR',
  UTI_FQP_NEXT_BATCH: 'UTI_FQP_NEXT_BATCH',
  UTI_FQP_NEXT_BATCH_ERROR: 'UTI_FQP_NEXT_BATCH_ERROR',
  UTI_FQP_BATCH_SUCCESS: 'UTI_FQP_BATCH_SUCCESS',
  UTI_FQP_RESET: 'UTI_FQP_RESET',
};

export const queryPaginationFirstBatch = () => ({
  type: ACTIONS.UTI_FQP_FIRST_BATCH,
});

export const queryPaginationFirstBatchError = error => ({
  payload: error,
  type: ACTIONS.UTI_FQP_FIRST_BATCH_ERROR,
});

export const queryPaginationNextBatch = () => ({
  type: ACTIONS.UTI_FQP_NEXT_BATCH,
});

export const queryPaginationNextBatchError = error => ({
  payload: error,
  type: ACTIONS.UTI_FQP_NEXT_BATCH_ERROR,
});

export const queryPaginationBatchSuccess = (lastDocument, data, next) => ({
  payload: { lastDocument, data, next },
  type: ACTIONS.UTI_FQP_BATCH_SUCCESS,
});

export const queryPaginationReset = () => ({
  type: ACTIONS.UTI_FQP_RESET,
});
