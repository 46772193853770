import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { useEffect, useState } from 'react';
import { remoteConfig } from '../../../firebase/firebase';

const useRemoteConfig = key => {
  const [value, setValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        await fetchAndActivate(remoteConfig);
        const response = getValue(remoteConfig, key).asString();
        let parsedValue;
        // Check if it is a number
        if (!Number.isNaN(Number(response)) && response !== '') {
          parsedValue = Number(response);
        } else if (response.toLowerCase() === 'true' || response.toLowerCase() === 'false') {
          // Check if it is a boolean
          parsedValue = response.toLowerCase() === 'true';
        } else {
          try {
            // try to parse it
            parsedValue = JSON.parse(response);
          } catch (e) {
            // if it fails, just use the string
            parsedValue = response;
          }
        }

        setValue(parsedValue);
        setLoading(false);
        setLoaded(true);
      } catch (error) {
        setLoading(false);
        setLoaded(true);
      }
    };

    fetch();
  }, [key]);

  return {
    value,
    loading,
    isLoaded,
  };
};

export default useRemoteConfig;
