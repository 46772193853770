import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ALLOWED_ROLES } from 'packages/utils';
import { Modal, Space, Empty } from 'antd';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';
import ComponentBlocker from 'components/ComponentBlocker';
import PropTypes from 'prop-types';
import { getRouteToEditLocation } from '../../constants';
import { locationDelete } from '../../redux/actions';
import styles from './styles.module.less';
import LocationList from '../../../../components/LocationList';

const { confirm } = Modal;
const DivisionList = ({ data = [], loading, refresh }) => {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);

  const handleEditLocation = (type, locId, divId) =>
    history.push(getRouteToEditLocation(type, locId, divId), {
      fromOrganization: false,
    });

  const showDeleteConfirm = useCallback(
    (loc, locDivId) => {
      confirm({
        cancelText: intl.formatMessage({ id: 'button.no' }),
        content: intl.formatMessage({ id: 'locations.list.deleteModal.content' }),
        icon: <ExclamationCircleOutlined />,
        okText: intl.formatMessage({ id: 'button.yes' }),
        okType: 'danger',
        onOk() {
          dispatch(locationDelete(orgId, locDivId, loc.id));
          refresh(prev => !prev);
        },
        title: intl.formatMessage(
          { id: 'locations.list.deleteModal.title' },
          { location: loc.name },
        ),
      });
    },
    [dispatch, intl, refresh, orgId],
  );
  return (
    <>
      {data?.length > 0 && !loading ? (
        data.map(loc => (
          <div key={loc.id} className={styles.locationElement}>
            <LocationList
              key={loc.id}
              loc={loc}
              actions={[
                <ComponentBlocker
                  divId={loc.divId}
                  allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.UPDATE}
                >
                  <Space
                    className={styles.locationActionButtons}
                    onClick={() => handleEditLocation(loc.type, loc.id, loc.divId)}
                  >
                    <EditTwoTone />
                    <IntlMessages id="button.edit" />
                  </Space>
                </ComponentBlocker>,
                <ComponentBlocker
                  divId={loc.divId}
                  allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.DELETE}
                >
                  <Space
                    className={styles.locationActionButtons}
                    onClick={() => showDeleteConfirm(loc, loc.divId)}
                  >
                    <DeleteTwoTone twoToneColor="#eb2f96" />
                    <IntlMessages id="button.delete" />
                  </Space>
                </ComponentBlocker>,
              ]}
              hasGpsDescription
            />
          </div>
        ))
      ) : (
        <Empty description={<IntlMessages id="location.empty.state" />} />
      )}
    </>
  );
};

DivisionList.defaultProps = {
  data: [],
};

DivisionList.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
};
export default DivisionList;
