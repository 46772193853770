import React, { useRef, useState } from 'react';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import IntlMessages from 'util/IntlMessages';
import { useHistory } from 'react-router-dom';
import {
  getRouteToCreateWorkOrder,
  getRouteToCreateWorkTemplate,
  getRouteToEditWorkTemplate,
  PAGE_SIZE,
} from 'packages/workTemplates/constants';
import { InfoCardList } from 'components/InfoCard';
import { useSelector } from 'react-redux';
import {
  ALLOWED_ROLES,
  getDivsWithAccess,
  useFirestoreRealtimeBatchedPagination,
  useToggle,
} from 'packages/utils';
import { collection, orderBy, query } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import { Modal, Pagination } from 'antd';
import { deleteScheduleTemplate } from 'util/firebase-operations/scheduleTemplates/delete';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import Title from 'components/BoxContainer/components/Title';

const WorkTemplateList = () => {
  const intl = useIntl();
  const navigate = useHistory();
  const scheduleTemplatesRef = useRef();

  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, setRefresh] = useToggle(false);

  const orgId = useSelector(state => state.organizations.organization.id);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
  const allowedDivs = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.SCHEDULES.LIST,
    userOrgAccess,
    userOrgDivisions,
  );
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids);
  const allowedDivsIds = allowedDivs.map(d => d.id);
  const allowedSelectedDivs = selectedDivsIds.filter(divId => allowedDivsIds.includes(divId));

  const queryByDivId = {};
  allowedSelectedDivs.forEach(divId => {
    queryByDivId[divId] = query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'schedule_templates'),
      orderBy('createdAt', 'desc'),
    );
  });
  const { data, loading, next, gotNewData, error } = useFirestoreRealtimeBatchedPagination(
    Object.values(queryByDivId),
    [selectedDivsIds, refresh],
    PAGE_SIZE,
  );

  const handlePageChange = page => {
    setCurrentPage(page);
    if (gotNewData && !loading) {
      next();
    }
    scheduleTemplatesRef.current.scrollIntoView();
  };

  const onDelete = (divId, id, name) => {
    Modal.confirm({
      cancelText: intl.formatMessage({ id: 'button.no' }),
      icon: <ExclamationCircleOutlined />,
      okText: intl.formatMessage({ id: 'button.yes' }),
      okType: 'danger',
      onOk() {
        const apiCall = async () => {
          await deleteScheduleTemplate({ orgId, divId, templateId: id });
          setRefresh(prev => !prev);
        };
        apiCall();
      },
      title: intl.formatMessage({ id: 'work.template.delete.title' }, { name }),
    });
  };

  const start = (currentPage - 1) * PAGE_SIZE;
  const end = start + PAGE_SIZE;
  const scheduleTemplateRecords = data.slice(start, end);

  return (
    <BoxContainer>
      <BoxContainer content fixed shadow>
        <FilterContainer
          title={<Title.Header value={<IntlMessages id="work.template.list.title" />} />}
          actionButtons={[
            {
              label: <IntlMessages id="button.create" />,
              action: () => navigate.push(getRouteToCreateWorkTemplate()),
              type: 'primary',
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer content loading={loading} error={!!error} empty={data.length === 0}>
        {scheduleTemplateRecords.map((workTemplate, idx) => (
          <React.Fragment key={workTemplate.id}>
            <div ref={idx === 0 ? scheduleTemplatesRef : null}>
              <InfoCardList
                title={workTemplate.name}
                actionButtons={[
                  {
                    action: () =>
                      navigate.push(getRouteToCreateWorkOrder(workTemplate.divId, workTemplate.id)),
                    label: intl.formatMessage({ id: 'work.order.button.create' }),
                    type: 'primary',
                    size: 'small',
                  },
                  {
                    action: () =>
                      navigate.push(
                        getRouteToEditWorkTemplate(workTemplate.divId, workTemplate.id),
                      ),
                    iconButton: 'edit',
                    shape: 'circle',
                  },
                  {
                    action: () => onDelete(workTemplate.divId, workTemplate.id, workTemplate.name),
                    iconButton: 'delete',
                    shape: 'circle',
                  },
                ]}
              />
            </div>
          </React.Fragment>
        ))}
      </BoxContainer>
      <div className="gx-flex-row gx-justify-content-center gx-mt-4">
        <Pagination
          current={currentPage}
          onChange={handlePageChange}
          pageSize={PAGE_SIZE}
          total={data.length + 1}
        />
      </div>
    </BoxContainer>
  );
};

export default WorkTemplateList;
