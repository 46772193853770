import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Switch, TimePicker, Typography, notification } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { LOC_TYPES } from 'packages/locations';
import IntlMessages from 'util/IntlMessages';
import { useParams, useHistory } from 'react-router-dom';
import { collection, doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { db, auth } from 'firebase/firebase';
import Title from 'components/BoxContainer/components/Title';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import ModalLabel from '../../../schedules/components/ModalLabel';
import DropDownDivisions from '../../../../components/DropDownDivision';
import { ALLOWED_ROLES, getDivsWithAccess } from '../../../utils/access';

import { DropDownEventsMemo } from '../../../../components/DropDownEvents';
import { DropDownLocations } from '../../../../components/DropDownLocations';
import getDocData from '../../../utils/firebase/getDocData';
import { eventTypeSummaryPropTypes } from '../../../utils/proptypes/eventTypes';

const { Item } = Form;
const FORMAT = 'HH:mm';

const Trigger = ({ initialData }) => {
  const history = useHistory();

  const [form] = Form.useForm();
  const intl = useIntl();
  const TriggerFormRef = useRef();
  const { triggerId, divId: divisionPerParams } = useParams();

  const { orgId } = useParams();
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
  const [fetching, setFetching] = useState(false);
  const [division, setDivision] = useState(divisionPerParams);
  const [location, setLocation] = useState({
    id: '',
    locationList: [],
  });

  const [eventSelected, setEventSelected] = useState(null);
  const allowedDivisions = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.TRIGGERS.CREATE,
    userOrgAccess,
    userOrgDivisions,
  );
  const isEditing = !!triggerId;

  const handleSave = async () => {
    const triggerRef = TriggerFormRef.current;
    await triggerRef.validateFields();
    const docId =
      triggerId ||
      doc(collection(db, 'organizations', orgId, 'divisions', division, 'triggers')).id;

    const { avoidConsecutiveTriggering, cooldown, description, divId, eventType, name } =
      triggerRef.getFieldsValue();
    const cooldownLikeMinuts = cooldown.hours() * 60 + cooldown.minutes();

    try {
      let eventData;
      let eventDataToTrigger;

      if (!isEditing) {
        eventData = await getDoc(
          doc(db, 'organizations', orgId, 'divisions', divId, 'event_types', eventType),
        ).then(snapshot => snapshot.data());

        if (!eventData) {
          throw new Error(`Event Type doesn't exits`);
        }

        // remove unnecesary fields - IEventTypeSummary
        eventDataToTrigger = eventData;
        delete eventDataToTrigger.uid;
        delete eventDataToTrigger.createdAt;
        delete eventDataToTrigger.updatedAt;
      }

      const auxFields = isEditing
        ? {
            updatedAt: serverTimestamp(),
          }
        : {
            createdAt: serverTimestamp(),
            uid: auth?.currentUser?.uid,
            id: docId,
            divId,
            eventType: eventDataToTrigger,
          };

      const dataBody = {
        avoidConsecutiveTriggering,
        cooldown: cooldownLikeMinuts,
        description,
        locations: location?.locationList,
        name,
        ...auxFields,
      };

      setFetching(true);
      setDoc(doc(db, 'organizations', orgId, 'divisions', divId, 'triggers', docId), dataBody, {
        merge: true,
      })
        .then(() => {
          triggerRef.resetFields();
          notification.success({
            message: intl.formatMessage({ id: 'general.save.successful.message' }),
            placement: 'topRight',
          });
          setFetching(false);
          history.goBack();
        })
        .catch(() => {
          setFetching(false);
          notification.error({
            message: intl.formatMessage({ id: 'general.save.error.message' }),
            placement: 'topRight',
          });
        });
    } catch (error) {
      setFetching(false);
      notification.error({
        message: intl.formatMessage({ id: 'general.save.error.message' }),
        placement: 'topRight',
      });
    }
  };

  useEffect(() => {
    if (isEditing) {
      const getTrigger = async () => {
        const triggerData = getDocData(
          await getDoc(
            doc(db, 'organizations', orgId, 'divisions', divisionPerParams, 'triggers', triggerId),
          ),
        );
        if (Object.values(triggerData).length > 0) {
          const cooldown = moment()
            .startOf('day')
            .add(+triggerData?.cooldown || 0, 'minutes');

          TriggerFormRef.current.setFieldsValue({
            avoidConsecutiveTriggering: triggerData.avoidConsecutiveTriggering,
            cooldown,
            description: triggerData?.description,
            divId: triggerData?.divId,
            eventType: triggerData?.eventType?.id,
            id: triggerData?.id,
            name: triggerData?.name,
            uid: triggerData?.uid,
          });

          setLocation({
            id: '',
            locationList: triggerData?.locations || [],
          });
        }
      };
      getTrigger();
    }
  }, [divisionPerParams, isEditing, orgId, triggerId]);

  const title = isEditing ? (
    <IntlMessages id="eventTypes.eventType.edit" />
  ) : (
    <IntlMessages id="eventTypes.eventType.create" />
  );

  useEffect(() => {
    form.resetFields();
  }, [form, initialData]);

  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer
          goBack={() => history.goBack()}
          title={<Title value={title} />}
          actionButtons={[
            {
              label: <IntlMessages id="general.save" />,
              action: handleSave,
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.TRIGGERS.CREATE,
              disabled: fetching,
              type: 'primary',
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer content loading={fetching}>
        <Form
          ref={TriggerFormRef}
          form={form}
          initialValues={{
            cooldown: moment()
              .startOf('day')
              .add(+initialData?.cooldown || 0, 'minutes'),
            description: initialData?.description || '',
            name: initialData?.name || '',
            avoidConsecutiveTriggering: !!initialData?.avoidConsecutiveTriggering,
            eventType: initialData?.eventType || '',
            divId: initialData?.divId || '',
          }}
          layout="vertical"
          name="triggerForm"
          className="gx-pb-2"
        >
          <Item
            name="divId"
            label={<IntlMessages id="triggers.view.form.division" />}
            rules={[
              {
                required: true,
                message: <IntlMessages id="general.requiredDivision.error" />,
              },
            ]}
          >
            <DropDownDivisions
              options={allowedDivisions}
              onChange={val => {
                setDivision(val);
                setEventSelected(null);
                TriggerFormRef.current.resetFields(['eventType']);
              }}
              value={division}
              disabled={isEditing}
            />
          </Item>
          <Item
            label={
              <ModalLabel
                label="form.view.selectLabel"
                description="schedule.form.triggers.modal.eventType.description"
              />
            }
            name="eventType"
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'schedule.form.triggers.modal.eventType.requiredMessage',
                }),
              },
            ]}
          >
            <DropDownEventsMemo
              value={eventSelected}
              onChange={e => setEventSelected(e)}
              orgId={orgId}
              divId={division}
              disabled={isEditing}
            />
          </Item>
          <Item
            label={
              <ModalLabel
                label="triggers.view.form.name"
                description="schedule.form.triggers.modal.name.description"
              />
            }
            name="name"
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'schedule.form.triggers.modal.name.requiredMessage',
                }),
              },
              {
                min: 5,
                max: 100,
                message: intl.formatMessage({
                  id: 'triggers.view.name.min.max.message',
                }),
              },
            ]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'schedule.form.triggers.modal.name.placeholder',
              })}
            />
          </Item>
          <Item
            label={
              <ModalLabel
                label="triggers.view.form.description"
                description="schedule.form.triggers.modal.description.description"
              />
            }
            name="description"
          >
            <Input />
          </Item>
          <Typography.Title strong level={4}>
            <IntlMessages id="triggers.view.sectionTitle.conditions" />
          </Typography.Title>
          <Item name="location" label={<IntlMessages id="triggers.view.form.location" />}>
            <DropDownLocations
              location={location}
              onChange={setLocation}
              divId={division}
              orgId={orgId}
            />
          </Item>
          <Item
            label={
              <ModalLabel
                label="triggers.view.avoidConsecutiveTriggering"
                description="schedule.form.triggers.modal.avoidConsecutiveTriggering.description"
              />
            }
            name="avoidConsecutiveTriggering"
            valuePropName="checked"
          >
            <Switch />
          </Item>
          <Item
            label={
              <ModalLabel
                label="triggers.view.cooldown"
                description="schedule.form.triggers.modal.cooldown.description"
              />
            }
            name="cooldown"
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'schedule.form.triggers.modal.cooldown.requiredMessage',
                }),
              },
            ]}
          >
            <TimePicker
              className="gx-w-100"
              format={FORMAT}
              minuteStep={5}
              showNow={false}
              placeholder={intl.formatMessage({
                id: 'schedule.form.triggers.modal.cooldown.placeholder',
              })}
            />
          </Item>
        </Form>
      </BoxContainer>
    </BoxContainer>
  );
};

Trigger.defaultProps = {
  initialData: null,
};

Trigger.propTypes = {
  initialData: PropTypes.shape({
    cooldown: PropTypes.number,
    description: PropTypes.string,
    eventType: eventTypeSummaryPropTypes.isRequired,
    name: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(Object.values(LOC_TYPES)).isRequired,
        name: PropTypes.string.isRequired,
        distance: PropTypes.shape({
          meters: PropTypes.number.isRequired,
          outside: PropTypes.bool.isRequired,
        }),
      }),
    ),
    avoidConsecutiveTriggering: PropTypes.bool,
    divId: PropTypes.string,
  }),
};
export default Trigger;
