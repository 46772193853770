export const REDUCER_INDEX = 'events';
const BASE_URL = 'timeline';

export const PATHS = {
  BASE_URL,
  EVENT_DETAIL: `${BASE_URL}/event/:divId/:eventId`,
  TEMP_CREATE: `${BASE_URL}/event/create`,
};

export const getRouteToEvent = (divId, eventId = '') =>
  PATHS.EVENT_DETAIL.replace(':divId', divId).replace(':eventId', eventId);
