import React, { useEffect, useState } from 'react';
import { Avatar, Button, Form, Input, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';

import IntlMessages from 'util/IntlMessages';
import * as actions from 'packages/groups/redux/actions';
import { db, storage } from 'firebase/firebase';
import { collection, doc } from 'firebase/firestore';
import { useFirestoreQuery, getDivsWithAccess, ALLOWED_ROLES, getFullUrl } from 'packages/utils';
import { SiteContent } from 'packages/ui';
import DivisionName from 'components/DivisionName';
import { EditTwoTone, UserOutlined } from '@ant-design/icons';
import { resizeImage } from 'util/images';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

import styles from './styles.module.less';
import { PATHS } from '../../constants';

const { Item } = Form;
const { Option } = Select;

const Group = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const intl = useIntl();

  const { divId: paramDivId, groupId } = useParams();
  const isEditing = !!paramDivId && !!groupId;

  const [newDivId, setNewDivId] = useState(null);
  const divId = paramDivId || newDivId;

  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
  const allowedDivisions = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.GROUPS.CREATE,
    userOrgAccess,
    userOrgDivisions,
  );

  const divUsersRef =
    !!divId && collection(db, 'organizations', orgId, 'divisions', divId, 'users');
  const { data: divUsers = [], loading: usersLoading } = useFirestoreQuery(divUsersRef, [
    orgId,
    divId,
  ]);

  const groupRef =
    isEditing && doc(db, 'organizations', orgId, 'divisions', paramDivId, 'groups', groupId);
  const { data: groupData, loading } = useFirestoreQuery(groupRef, [orgId, paramDivId, groupId]);

  const uploadImage = image => {
    uploadBytes(
      ref(storage, `organizations/${orgId}/divisions/${paramDivId}/groups/${groupId}/profile.jpg`),
      image,
    ).then(snapshot =>
      getDownloadURL(snapshot.ref).then(imageUrl =>
        dispatch(actions.groupSaveFetch(orgId, paramDivId, groupId, { avatarURL: imageUrl })),
      ),
    );
  };

  const handleLoad = ({ currentTarget: { files } }) => {
    resizeImage(files[0], uploadImage, 'profile');
  };

  useEffect(() => {
    form.setFieldsValue({
      name: groupData?.name,
      users: groupData?.users?.map(user => user.id),
    });
  }, [form, groupData]);

  const handleGoBack = () => history.push(getFullUrl(PATHS.BASE_URL, url));
  const onFinish = values => {
    const formattedUsers = values.users.map(uId => divUsers.find(u => u.id === uId));
    dispatch(
      actions.groupSaveFetch(orgId, divId, groupId || null, {
        ...values,
        users: formattedUsers,
      }),
    );
    handleGoBack();
  };

  const validateUsers = () => ({
    validator(_, value = []) {
      const divUsersIds = divUsers.map(user => user.id);
      const usersAreAtDiv = value.every(user => divUsersIds.includes(user));
      if (usersAreAtDiv) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error(intl.formatMessage({ id: 'groups.group.form.users.divisionError' })),
      );
    },
  });

  return (
    <SiteContent
      breadcrumb={[
        {
          name: <IntlMessages id="sidebar.configuration.groups" />,
          path: getFullUrl(PATHS.BASE_URL, url),
        },
        {
          name: isEditing ? (
            <IntlMessages id="breadcrumb.edit" />
          ) : (
            <IntlMessages id="breadcrumb.create" />
          ),
        },
      ]}
      title={<IntlMessages id={`groups.group.${isEditing ? 'edit' : 'create'}`} />}
    >
      {!!isEditing && (
        <div className={styles.profilePic}>
          <Avatar
            size={80}
            className="m-bottom-4"
            src={groupData?.avatarURL}
            icon={!groupData?.avatarURL && <UserOutlined />}
          />
          <Button
            className={styles.changePicture}
            shape="circle"
            size="small"
            type="primary"
            icon={
              <span htmlFor="group-pic" className={styles.changePictureIcon}>
                <EditTwoTone twoToneColor="#ffffff" />
                <input
                  accept="image/png, image/jpeg"
                  id="group-pic"
                  onChange={handleLoad}
                  type="file"
                />
              </span>
            }
          />
        </div>
      )}
      {loading ? (
        <Spin size="large" />
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            name: '',
            users: [],
          }}
        >
          {isEditing && <DivisionName divId={paramDivId} />}
          {!isEditing && (
            <Item
              label={intl.formatMessage({ id: 'form.division' })}
              name="divId"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'form.division.requiredError' }),
                },
              ]}
            >
              <Select
                dropdownMatchSelectWidth={false}
                onChange={id => setNewDivId(id)}
                optionFilterProp="children"
                placeholder={<IntlMessages id="form.division.placeholder" />}
                showSearch
              >
                {allowedDivisions.map(div => (
                  <Option key={div.id} value={div.id}>
                    {div.name}
                  </Option>
                ))}
              </Select>
            </Item>
          )}
          <Item
            name="name"
            label={intl.formatMessage({ id: 'groups.group.form.name' })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'groups.group.form.name.requiredError' }),
              },
              {
                max: 180,
                message: intl.formatMessage({ id: 'form.maxLength.msg' }, { amount: 180 }),
              },
            ]}
          >
            <Input placeholder={intl.formatMessage({ id: 'groups.group.form.name.placeholder' })} />
          </Item>
          {divId && (
            <Item
              name="users"
              label={intl.formatMessage({ id: 'groups.group.form.members' })}
              dependencies={['divId']}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'groups.group.form.users.requiredError' }),
                },
                validateUsers,
              ]}
            >
              <Select
                mode="multiple"
                placeholder={intl.formatMessage({
                  id: 'groups.group.form.members.placeholder',
                })}
                optionFilterProp="children"
                loading={usersLoading}
              >
                {divUsers.map(user => (
                  <Option value={user.id} key={user.id}>
                    {`${user?.firstName} ${user?.lastName}` || user.email}
                  </Option>
                ))}
              </Select>
            </Item>
          )}
          <Item className="gx-mb-0">
            <Button className="gx-mb-0" type="primary" htmlType="submit">
              <IntlMessages id="form.save" />
            </Button>
          </Item>
        </Form>
      )}
    </SiteContent>
  );
};

export default Group;
