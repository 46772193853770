import { ALLOWED_ROLES } from 'packages/utils';

import { PATHS } from './constants';
import GroupsList from './screens/GroupsList';
import Group from './screens/Group';

export default [
  {
    component: GroupsList,
    path: PATHS.BASE_URL,
    exact: true,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.GROUPS.LIST,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: Group,
    path: PATHS.EDIT_GROUP,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.GROUPS.UPDATE,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: Group,
    path: PATHS.CREATE_GROUP,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.GROUPS.CREATE,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
];
