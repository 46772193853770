export const REDUCER_INDEX = 'users';
export const BASE_URL = 'users';

export const PATHS = {
  BASE_URL,
  ACCEPT_INVITATION: `${BASE_URL}/invitation/:token`,
  USER_PERMISSIONS: `${BASE_URL}/permissions/:userId`,
  USERS_INVITE: `${BASE_URL}/invite`,
  CREATE_USER: `${BASE_URL}/create`,
  USER_NOTIFICATIONS: `${BASE_URL}/notifications/:userId`,
};

export const getRouteToUsersHome = () => PATHS.BASE_URL;

export const getRouteToUsersInvite = () => PATHS.USERS_INVITE;

export const getRouteCreateUser = () => PATHS.CREATE_USER;

export const getRouteToUserPermissions = userId =>
  PATHS.USER_PERMISSIONS.replace(':userId', userId);

export const getRouteToUserNotifications = userId =>
  PATHS.USER_NOTIFICATIONS.replace(':userId', userId);
