import React, { useState } from 'react';
import classnames from 'classnames';
import { Button, Select, Input, Form, Divider } from 'antd';
import { db } from 'firebase/firebase';
import { collection, query, where } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useRouteMatch, useHistory } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import { invitationResend, invitationRevoke, invitationsSend } from 'packages/users/redux/actions';
import { useFirestoreQuery, ACCESS_TYPES, ACCESS_TYPES_INTL, getFullUrl } from 'packages/utils';

import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import { getRouteToUsersHome } from '../../constants';
import Invitation from '../../components/Invitation';

import styles from './styles.module.less';

const { TextArea } = Input;
const { Option } = Select;

const InviteUsers = () => {
  const history = useHistory();
  const intl = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const [role, setRole] = useState('');
  const { data: org, id: orgId } = useSelector(({ organizations }) => organizations.organization);

  const orgInvitesRef = query(
    collection(db, 'organizations', orgId, 'invitations'),
    where('acceptorId', '==', null),
  );
  const { data: invites = [] } = useFirestoreQuery(orgInvitesRef, [orgId]);

  const { divisions: orgDivisions = {} } = useSelector(
    ({ user }) => user.profile.data?.organizations?.[orgId] || {},
  );

  const errors = form.getFieldError('emails');

  const isValidEmail = email =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email,
    );

  const onFinish = ({ emails, divisions, access, message }) => {
    const orgDivsIds = Object.values(orgDivisions).map(div => div.id);
    const finalDivisions = access === ACCESS_TYPES.USER ? divisions : orgDivsIds;

    const invitations = emails.filter(isValidEmail).map(email => ({
      access,
      email,
      message: message || '',
      organization: {
        divisions: finalDivisions,
        id: orgId,
        name: org?.name || '',
      },
    }));

    if (invitations.length > 0) {
      dispatch(invitationsSend(orgId, invitations));
    }

    form.resetFields();
  };

  const onResendInvitation = invitationId => dispatch(invitationResend(orgId, invitationId));

  const onRevokeInvitation = invitationId => dispatch(invitationRevoke(orgId, invitationId));

  const renderRoleDesk = roleKey => (
    <div className={styles.roleComment}>
      <IntlMessages id={ACCESS_TYPES_INTL[roleKey].desc} />
    </div>
  );

  // TODO: Re Check this, the list of available user access to give a invited user
  const accessTypes = Object.values(ACCESS_TYPES)
    .filter(key => ![ACCESS_TYPES.OWNER, ACCESS_TYPES.EDITOR].includes(key))
    .map(key => ({
      key,
      name: intl.formatMessage({ id: ACCESS_TYPES_INTL[key].name }),
    }))
    .sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1));

  const getDivInputByAccess = access => {
    if (access === ACCESS_TYPES.USER) {
      return (
        <Form.Item
          name="divisions"
          label={<IntlMessages id="users.invitation.form.divisions" />}
          rules={[
            {
              required: true,
              message: <IntlMessages id="form.required.msg" />,
            },
          ]}
        >
          <Select
            mode="multiple"
            optionFilterProp="children"
            placeholder={<IntlMessages id="users.invitation.form.divisions.placeholder" />}
          >
            {Object.values(orgDivisions).map(div => (
              <Option value={div.id} key={div.id}>
                {div.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    }

    return null;
  };

  return (
    <BoxContainer>
      <BoxContainer shadow fixed content>
        <FilterContainer
          goBack={() => history.push(getFullUrl(getRouteToUsersHome(), url))}
          title={
            <Title.Header
              value={
                <IntlMessages id="users.invitation.form.title" values={{ name: org?.name || '' }} />
              }
            />
          }
        />
      </BoxContainer>
      <BoxContainer content>
        <Form form={form} layout="vertical" onFinish={onFinish} requiredMark={false}>
          <Form.Item
            name="emails"
            label={<IntlMessages id="users.invitation.form.emails" />}
            rules={[
              {
                required: true,
                message: <IntlMessages id="form.required.msg" />,
              },
            ]}
          >
            <>
              <Select
                mode="tags"
                onChange={emails => {
                  const areAllValid = emails.every(email => isValidEmail(email));

                  form.setFields([
                    {
                      name: 'emails',
                      value: emails,
                      errors: areAllValid
                        ? []
                        : [
                            intl.formatMessage({
                              id: 'users.invitation.form.emails.invalidPattern',
                            }),
                          ],
                    },
                  ]);
                }}
                placeholder={intl.formatMessage({ id: 'users.invitation.form.emails.placeholder' })}
              />
              {errors.length > 0 && <div>{errors[0]}</div>}
            </>
          </Form.Item>
          <Form.Item
            extra={!!ACCESS_TYPES_INTL[role] && renderRoleDesk(role)}
            label={<IntlMessages id="users.invitation.form.role" />}
            name="access"
            rules={[
              {
                required: true,
                message: <IntlMessages id="form.required.msg" />,
              },
            ]}
          >
            <Select
              onChange={value => setRole(value)}
              placeholder={<IntlMessages id="users.invitation.form.selectRole" />}
            >
              {accessTypes.map(({ key, name }) => (
                <Option key={key} value={key}>
                  <IntlMessages id="users.invitation.form.organizationRole" values={{ name }} />
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prevVal, currVal) => prevVal.access !== currVal.access}>
            {({ getFieldValue }) => getDivInputByAccess(getFieldValue('access'))}
          </Form.Item>

          <Form.Item
            name="message"
            rules={[
              {
                max: 2000,
                message: intl.formatMessage({ id: 'form.maxLength.msg' }, { amount: 2000 }),
              },
            ]}
          >
            <TextArea
              placeholder={intl.formatMessage({ id: 'users.invitation.form.message.placeholder' })}
              rows={3}
            />
          </Form.Item>
          <div className={classnames('m-top-6', styles.buttonsPanel)}>
            <Button type="primary" htmlType="submit">
              <IntlMessages id="users.invitation.form.sendInvite" />
            </Button>
          </div>
        </Form>
        <Divider />
        <h3>
          <IntlMessages id="users.invitation.form.invitations" />
        </h3>
        <div>
          {invites
            .sort((a, b) => b.createdAt - a.createdAt)
            .map(invitation => (
              <Invitation
                email={invitation.email}
                expiresAt={invitation.expiresAt?.toMillis()}
                id={invitation.id}
                key={invitation.id}
                onResend={onResendInvitation}
                onRevoke={onRevokeInvitation}
                sentAt={invitation.sentAt?.toMillis()}
              />
            ))}
        </div>
      </BoxContainer>
    </BoxContainer>
  );
};

export default InviteUsers;
