import React, { useState } from 'react';
import { Input, Row, Switch, Col, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';

import { db } from 'firebase/firebase';
import IntlMessages from 'util/IntlMessages';
import { errorNotification } from 'appRedux/actions';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ALLOWED_ROLES } from 'packages/utils';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import { aggOptions, aggIntl, aggregationFactory, DEFAULT_CHART_TYPE } from './constants';
import { CHART_TYPES, defaultAggsConfig } from '../../constants';
import SimpleColorPicker from '../../../../../../components/ColorPicker/SimpleColorPicker';
import { getFullUrl } from '../../../../../utils/functions';
import { PATHS } from '../../../../constants';
import LabelValue from '../../../../../../components/LabelValue';
import SubTitle from '../../../../../../components/Subtitle';
import styles from './styles.module.less';

const ChartEdit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const intl = useIntl();
  const orgId = useSelector(state => state.organizations.organization.id);
  const location = history?.location;
  const state = location?.state;
  const chart = state?.chart;
  const aggregations = chart?.aggregations;
  const { avg, count, max, min, sum } = aggregations || {};

  const [title, setTitle] = useState(chart?.title);
  const [selectedAggs, setSelectedAggs] = useState(chart?.aggregations);
  const [loading, setLoading] = useState(false);

  const aggregationsOn = Object.keys(selectedAggs).map(e => e);

  const colorInitValues = chart
    ? {
        min: min?.color,
        max: max?.color,
        avg: avg?.color,
        sum: sum?.color,
        count: count?.color,
      }
    : {};

  const chartTypesInitValues = chart
    ? {
        min: min?.displayType,
        max: max?.displayType,
        avg: avg?.displayType,
        sum: sum?.displayType,
        count: count?.displayType,
      }
    : {};
  const [color, setColor] = useState(colorInitValues);
  const [chartTypes, setChartTypes] = useState(chartTypesInitValues);

  const CHART_TYPE_OPTIONS = CHART_TYPES.map(ct => ({
    label: <IntlMessages id={`dashboards.chartType.form.${ct}.option`} />,
    value: ct,
  }));

  const handleChartType = (agregationSelected, chartTypeSelected) => {
    setChartTypes({
      ...chartTypes,
      [agregationSelected]: chartTypeSelected,
    });
  };
  const handleColor = (agregationSelected, rgbValue) => {
    setColor({
      ...color,
      [agregationSelected]: rgbValue,
    });
  };

  const onBack = () => history.push(getFullUrl(PATHS.EVENTS, url));
  const toggleOption = (isOn, opt) => {
    if (isOn) {
      setSelectedAggs(curr => ({ ...curr, [opt]: defaultAggsConfig[opt] }));

      handleChartType(opt, defaultAggsConfig[opt]?.displayType);
      handleColor(opt, defaultAggsConfig[opt]?.color);
    } else {
      setSelectedAggs(({ [opt]: remove, ...rest }) => ({ ...rest }));

      handleChartType(opt, null);
      handleColor(opt, null);
    }
  };

  const handleTitleChange = ({ target: { value } }) => {
    setTitle(value);
  };

  const handleOk = async () => {
    setLoading(true);

    // The ID of this default dashboard is "events"
    const dashboardId = 'events';
    const docRef =
      chart && doc(db, 'organizations', orgId, 'dashboards', dashboardId, 'charts', chart.id);

    // get new colors and merge into current aggregations
    const newAggregations = aggregationFactory(selectedAggs, color, chartTypes);
    const docData = {
      title,
      aggregations: newAggregations,
      updatedAt: serverTimestamp(),
    };

    try {
      await updateDoc(docRef, docData);
      onBack();
    } catch (e) {
      dispatch(errorNotification(e.message || e));
    }

    setLoading(false);
  };

  return (
    <BoxContainer>
      <BoxContainer shadow content fixed>
        <FilterContainer
          goBack={onBack}
          title={<Title value={<IntlMessages id="dashboards.chartType.form.title" />} />}
          actionButtons={[
            {
              label: intl.formatMessage({ id: 'button.cancel' }),
              action: onBack,
              type: 'secondary',
              disabled: loading,
            },
            {
              label: intl.formatMessage({ id: 'button.save' }),
              action: handleOk,
              type: 'primary',
              disabled: loading,
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.CHARTS.UPDATE,
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer content loading={loading}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <LabelValue
              vertical
              name={<SubTitle text={<IntlMessages id="dashboards.chart.form.title" />} />}
              value={<Input onChange={handleTitleChange} value={title} />}
            />
          </Col>
        </Row>
        <div className="gx-mb-5 gx-d-flex gx-flex-column" />
        <div className="gx-d-flex gx-flex-column">
          <SubTitle text={<IntlMessages id="dashboards.chart.form.aggregations" />} />
          <Row gutter={8} className="gx-ml-2">
            {aggOptions.length > 0 &&
              aggOptions?.map(opt => (
                <div className="gx-w-100" key={opt}>
                  <Row gutter={8}>
                    <Col xs={6} xl={2} xxl={2}>
                      <LabelValue
                        vertical
                        name={<IntlMessages id={aggIntl[opt]} />}
                        value={
                          <Switch
                            onChange={val => toggleOption(val, opt)}
                            checked={!!selectedAggs[opt]}
                          />
                        }
                      />
                    </Col>
                    <Col xs={14} xl={20} xxl={20}>
                      <LabelValue
                        vertical
                        name={<IntlMessages id="dashboards.chartType.form.title" />}
                        value={
                          <Select
                            defaultValue={DEFAULT_CHART_TYPE}
                            disabled={!aggregationsOn.includes(opt)}
                            value={chartTypes[opt]}
                            className="gx-w-100"
                            options={CHART_TYPE_OPTIONS}
                            onChange={val => handleChartType(opt, val)}
                          />
                        }
                      />
                    </Col>
                    <Col className={styles.colorPickerContainer} xs={4} xl={2} xxl={2}>
                      <SimpleColorPicker
                        onChange={newRgB => handleColor(opt, newRgB)}
                        color={color[opt] || defaultAggsConfig[opt].color}
                        hexColor
                        key={opt}
                        disabled={!aggregationsOn.includes(opt)}
                      />
                    </Col>
                  </Row>
                </div>
              ))}
          </Row>
        </div>
      </BoxContainer>
    </BoxContainer>
  );
};
export default ChartEdit;
