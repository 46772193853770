import React from 'react';
import classnames from 'classnames';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import asyncComponent from 'util/asyncComponent';
import { routes as dashboardRoutes } from 'packages/dashboard';
import { routes as eventRoutes } from 'packages/events';
import { routes as eventTypesRoutes } from 'packages/eventTypes';
import { routes as groupsRoutes } from 'packages/groups';
import { routes as locationsRoutes } from 'packages/locations';
import { routes as schedulesRoutes } from 'packages/schedules';
import { routes as usersRoutes } from 'packages/users';
import { routes as homeRoutes } from 'packages/home';
import { routes as triggersRoutes } from 'packages/triggers';
import { routes as userPositionHistoryRoutes } from 'packages/reports';
import { routes as workTemplateRoutes } from 'packages/workTemplates';
import { routes as workOrderRoutes } from 'packages/workOrders';

import AuthRoute from 'components/AuthRoute';

import styles from './index.module.less';

const routesList = [
  ...userPositionHistoryRoutes,
  ...workOrderRoutes,
  ...workTemplateRoutes,
  ...triggersRoutes,
  ...dashboardRoutes,
  ...eventRoutes,
  ...eventTypesRoutes,
  ...groupsRoutes,
  ...locationsRoutes,
  ...schedulesRoutes,
  ...usersRoutes,
  ...homeRoutes,
];
const App = () => {
  let { path } = useRouteMatch();

  return (
    <div className={classnames('gx-main-content-wrapper', styles.wrapper)}>
      <Switch>
        {/* Guarnic routes */}
        <Route path={`${path}/config`} component={asyncComponent(() => import('./config'))} />
        <Route path={`${path}/user`} component={asyncComponent(() => import('./user'))} />

        {routesList.map(route => (
          <AuthRoute
            exact={!!route.exact}
            key={route.path}
            path={`${path}/${route.path}`}
            allowedRoles={route.allowedRoles}
            component={route.component}
            onOrgChangeRedirectTo={route.onOrgChangeRedirectTo}
          />
        ))}

        {process.env.NODE_ENV === 'development' && (
          <Route
            component={asyncComponent(() => import('packages/ui/StoriesRoutes'))}
            path={`${path}/ui/component`}
          />
        )}
        <Redirect to="/error404" />
      </Switch>
    </div>
  );
};

export default App;
