import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { KeyValueWidget } from 'components/KeyValueWidget';
import { useIsMobile } from 'packages/utils';
import styles from './styles.module.less';

export const UserYAxis = ({ x, y, payload }) => {
  const { isMobile } = useIsMobile();

  if (!payload) return null;

  const includeLabel = isMobile
    ? {}
    : {
        label: <Avatar icon={<UserOutlined />} size={24} />,
      };
  const xValue = isMobile ? 0 : 20;

  return (
    <g transform={`translate(${x - xValue},${y})`}>
      <foreignObject x={-100} y={-10} width={300} height={50}>
        <KeyValueWidget
          oneLine
          value={<div className={styles.yAxis}>{payload.value}</div>}
          {...includeLabel}
        />
      </foreignObject>
    </g>
  );
};
