export const get = (index, def = undefined) => {
  const value = localStorage.getItem(index);
  try {
    return JSON.parse(value) || def;
  } catch (e) {
    return def;
  }
};

export const set = (index, value) => {
  localStorage.setItem(index, JSON.stringify(value));
};

export const remove = index => {
  const value = get(index);
  localStorage.removeItem(index);
  return value;
};
