import { combineReducers } from 'redux';

import { REDUCER_INDEX } from '../../constants';

import group from './group';

export const reducer = combineReducers({
  group,
});

export const registerReducer = state => ({
  ...state,
  [REDUCER_INDEX]: reducer,
});

export const getState = state => state[REDUCER_INDEX];
export const getStateGroup = state => state[REDUCER_INDEX].group;
