import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';

import { showAuthLoader, userFacebookSignIn, userGoogleSignIn } from 'appRedux/actions/Auth';
import IntlMessages from 'util/IntlMessages';

import styles from './styles.module.less';
import { ReactComponent as GoogleIcon } from './assets/logo_google.svg';
import { ReactComponent as FacebookIcon } from './assets/logo_facebook.svg';
import { ReactComponent as MicrosoftIcon } from './assets/logo_microsoft.svg';

const SocialSignIn = ({ disabled }) => {
  const dispatch = useDispatch();

  const hanldeGoogleSignIn = () => {
    dispatch(userGoogleSignIn());
  };

  const hanldeFacebookSignIn = () => {
    dispatch(userFacebookSignIn());
  };

  const hanldeMicrosoftSignIn = () => {};

  return (
    <div>
      <Button
        className={styles.socialOption}
        disabled={disabled}
        icon={<GoogleIcon />}
        onClick={hanldeGoogleSignIn}
      >
        <span className={styles.socialTitle}>
          <IntlMessages id="auth.socialSignIn.google" />
        </span>
      </Button>
      <Button
        className={styles.socialOption}
        disabled={disabled}
        icon={<FacebookIcon />}
        onClick={hanldeFacebookSignIn}
      >
        <span className={styles.socialTitle}>
          <IntlMessages id="auth.socialSignIn.facebook" />
        </span>
      </Button>
      <Button
        className={styles.socialOption}
        disabled={disabled}
        icon={<MicrosoftIcon />}
        onClick={hanldeMicrosoftSignIn}
      >
        <span className={styles.socialTitle}>
          <IntlMessages id="auth.socialSignIn.microsoft" />
        </span>
      </Button>
    </div>
  );
};

SocialSignIn.propTypes = {
  disabled: PropTypes.bool,
};

SocialSignIn.defaultProps = {
  disabled: false,
};

export default React.memo(SocialSignIn);
