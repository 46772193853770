import * as actions from '../actions/event';

export const getInitialEventState = () => ({
  data: null,
  error: null,
  id: null,
  loaded: false,
  loading: false,
  save: {
    data: null,
    error: null,
    id: null,
    loaded: false,
    loading: false,
  },
});

export default (state = getInitialEventState(), action) => {
  switch (action.type) {
    case actions.EVT_EVENT_FETCH: {
      return {
        ...state,
        data: null,
        error: null,
        id: action.payload.eventId,
        loaded: false,
        loading: true,
      };
    }
    case actions.EVT_EVENT_FETCH_ERROR: {
      return {
        ...state,
        data: null,
        error: action.payload,
        loaded: true,
        loading: false,
      };
    }
    case actions.EVT_EVENT_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: null,
        loaded: true,
        loading: false,
      };
    }
    case actions.EVT_EVENT_RESET: {
      return getInitialEventState();
    }
    case actions.EVT_EVENT_SAVE_FETCH: {
      return {
        ...state,
        save: {
          data: null,
          submitedData: action.payload.data,
          error: null,
          id: action.payload.eventId,
          loaded: false,
          loading: true,
        },
      };
    }
    case actions.EVT_EVENT_SAVE_FETCH_ERROR: {
      return {
        ...state,
        save: {
          data: null,
          error: action.payload,
          loaded: true,
          loading: false,
        },
      };
    }
    case actions.EVT_EVENT_SAVE_FETCH_SUCCESS: {
      return {
        ...state,
        data: { ...state.data, ...state.save.submitedData },
        id: state.id || action.payload.id,
        save: {
          data: action.payload,
          id: action.payload.id,
          loaded: true,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};
