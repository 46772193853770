import { Col, Input, Row } from 'antd';
import BoxContainer from 'components/BoxContainer';
import Title from 'components/BoxContainer/components/Title';
import TriggersList from 'components/TriggersList';
import { db } from 'firebase/firebase';
import { collection, doc } from 'firebase/firestore';
import { useFirestoreQuery } from 'packages/utils';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';

/**
 * NOTE: When a triggers is existing we must to add the id like triggerId and create a new id for the trigger
 * */
const ExistingTriggerToWorkTemplate = ({ divisionId, triggers, removeTrigger, addTrigger }) => {
  const [searchValue, setSearchValue] = useState('');

  const organizationId = useSelector(({ organizations }) => organizations.organization.id);
  const intl = useIntl();

  const {
    data = [],
    error: triggerError,
    loading: triggerLoading,
  } = useFirestoreQuery(
    organizationId &&
      divisionId &&
      collection(db, 'organizations', organizationId, 'divisions', divisionId, 'triggers'),
    [organizationId, divisionId],
  );

  const dataParsed = useMemo(
    () =>
      data.map(item => {
        const newDocRef = doc(collection(db, 'triggers'));
        const output = item;
        output.triggerId = item?.id;
        output.id = newDocRef.id;

        return output;
      }),
    [data],
  );
  const triggersAssociated = useMemo(() => triggers, [triggers]);

  const triggersAvailable = useMemo(
    () =>
      dataParsed.filter(item => {
        const isAssociated = triggersAssociated.find(
          trigger => trigger?.triggerId === item?.triggerId,
        );
        const matchesSearch = item.name.toLowerCase().includes(searchValue.toLowerCase());
        return !isAssociated && matchesSearch;
      }),
    [dataParsed, triggersAssociated, searchValue],
  );

  return (
    <BoxContainer content loading={triggerLoading} error={!!triggerError}>
      <Row gutter={16}>
        <Col span={24}>
          <Title.LabelForm
            value={<IntlMessages id="work.template.form.template.forms.associated.title" />}
          />
          <div className="gx-guarnic-pt-2">
            <TriggersList.Simple data={triggersAssociated} onRemove={removeTrigger} />
          </div>
        </Col>
      </Row>
      <Row className="gx-guarnic-pt-2" gutter={16}>
        <Col span={24}>
          <Input.Search
            placeholder={intl.formatMessage({
              id: 'work.template.form.forms.searchByForm.placeholder',
            })}
            onChange={e => setSearchValue(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title.LabelForm
            value={<IntlMessages id="work.template.form.template.forms.available.title" />}
          />
          <div className="gx-guarnic-pt-2">
            <TriggersList.Simple data={triggersAvailable} onAdd={addTrigger} />
          </div>
        </Col>
      </Row>
    </BoxContainer>
  );
};

ExistingTriggerToWorkTemplate.propTypes = {
  divisionId: PropTypes.string.isRequired,
  triggers: PropTypes.array.isRequired,
  removeTrigger: PropTypes.func.isRequired,
  addTrigger: PropTypes.func.isRequired,
};

export default ExistingTriggerToWorkTemplate;
