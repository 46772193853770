import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { List, Button } from 'antd';
import MemberAvatar from './MemberAvatar';
import styles from './styles.module.less';
import classNames from 'classnames';
import { DeleteOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';
import useLimitList from 'packages/utils/hooks/useLimitList';

const { Item } = List;
/**@params
 * Members Must be the join between users and groups [...users, ...groups]
 */
const MembersListDetail = React.memo(({ members, onRemove }) => {
  const [selectedUsers, setSelectedUsers] = useState(members);
  const {
    data: membersLimited,
    seeAll,
    totalToBeOpeneable,
    onSeeToggle,
    showButton,
  } = useLimitList({ data: selectedUsers, limitForMobile: 1, limitForDesktop: 2 });

  const buttonLabel = seeAll ? (
    <>
      <IntlMessages id="button.seeLess" />
    </>
  ) : (
    <>
      {totalToBeOpeneable > 0 && (
        <>
          <IntlMessages id="button.seeMore" />
          <span className="gx-pl-1">{`(${totalToBeOpeneable})`}</span>
        </>
      )}
    </>
  );

  useEffect(() => {
    setSelectedUsers(members);
  }, [members, selectedUsers]);

  return (
    <>
      <List
        locale={{
          emptyText: <IntlMessages id="member.empty.state" />,
        }}
        dataSource={membersLimited}
        renderItem={item => (
          <div className="gx-w-100 gx-guarnic-pb-1">
            {/* Checking if is a Group */}
            {item.hasOwnProperty('users') ? (
              <div className={styles.customLisGroupC}>
                <Item className={styles.customListGroup}>
                  <Item.Meta avatar={<MemberAvatar user={item} />} title={item?.name} />
                  {onRemove && (
                    <Button
                      size="small"
                      danger
                      shape="circle"
                      icon={<DeleteOutlined />}
                      className="gx-mb-0"
                      onClick={() => onRemove(item.id)}
                    />
                  )}
                </Item>
                {item.users.map((child, i) => (
                  <Item
                    key={`${child?.firstName}-${i}`}
                    className={classNames(styles.customListGroup, styles.centerAvatar)}
                  >
                    <Item.Meta
                      avatar={<MemberAvatar user={child} />}
                      title={`${child?.firstName} ${child?.lastName}`}
                      description={
                        <span className={styles.customListGroupDescription}>{item?.name}</span>
                      }
                    />
                  </Item>
                ))}
              </div>
            ) : (
              <Item className={classNames(styles.customListItem)}>
                <Item.Meta
                  avatar={<MemberAvatar user={item} />}
                  title={`${item?.firstName} ${item?.lastName}`}
                />
                {onRemove && (
                  <Button
                    size="small"
                    danger
                    shape="circle"
                    icon={<DeleteOutlined />}
                    className="gx-mb-0"
                    onClick={() => onRemove(item.id)}
                  />
                )}
              </Item>
            )}
          </div>
        )}
      />

      {showButton && (
        <div className="gx-flex-row gx-justify-content-center gx-w-100 gx-pb-2">
          <Button className="gx-mb-0" onClick={onSeeToggle} type="link">
            {buttonLabel}
          </Button>
        </div>
      )}
    </>
  );
});

MembersListDetail.defaultProps = {
  members: [],
  onRemove: null,
};

MembersListDetail.propTypes = {
  members: PropTypes.array,
  onRemove: PropTypes.func,
};

export default MembersListDetail;
