import { SWITCH_LANGUAGE, SWITCH_TIME_ZONE } from 'constants/ActionTypes';
import { storage, dates } from 'packages/utils';

import {
  LAYOUT_TYPE,
  LAYOUT_TYPE_FULL,
  NAV_STYLE,
  NAV_STYLE_FIXED,
  THEME_COLOR,
  THEME_TYPE,
  THEME_TYPE_SEMI_DARK,
} from 'constants/ThemeSetting';
import languageData from 'containers/Topbar/languageData';

const getNavigatorLanguage = () => {
  let locale;
  if (navigator.languages?.length) {
    locale = navigator.languages[0];
  } else {
    locale = navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
  }

  locale = locale.split('-', 1)[0];
  let language = languageData.find(l => l.locale === locale);
  if (!language) {
    language = languageData.find(l => l.locale === 'en');
  }
  return language;
};

const initialSettings = {
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,
  themeColor: null,

  isDirectionRTL: false,
  locale: storage.get('locale', getNavigatorLanguage()),
  timeZone: storage.get('timeZone', dates.getDefaultTimeZone()),
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case THEME_TYPE:
      return {
        ...state,
        themeType: action.themeType,
      };
    case THEME_COLOR:
      return {
        ...state,
        themeColor: action.themeColor,
      };

    case NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle,
      };
    case LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.layoutType,
      };

    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
      };

    case SWITCH_TIME_ZONE:
      return {
        ...state,
        timeZone: action.payload,
      };
    default:
      return state;
  }
};

export default settings;
