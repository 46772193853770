import React from 'react';
import styles from '../Notifications/styles.module.less';
import classNames from 'classnames';

const Logo = () => {
  //TODO: Hardcode - Here we are going to create the logo to manage public view when a user unsubscribe/subscribe
  return (
    <div className="gx-flex-row gx-pb-4 gx-pt-4">
      <img className={styles.logo} src="/splash_512.png" />
      <div className={classNames('gx-flex-column gx-justify-content-center', styles.guarnicText)}>
        GUARNIC
      </div>
    </div>
  );
};

export default Logo;
