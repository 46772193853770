/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Input, Row, Select } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { collection, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import moment from 'moment';
import PropTypes from 'prop-types';

import { db, auth } from 'firebase/firebase';
import IntlMessages from 'util/IntlMessages';
import { useFirestoreQueryBatched } from 'packages/utils';
import { errorNotification } from 'appRedux/actions';

import { defaultAggsConfig } from '../../constants';
import LabelValue from '../../../../../../components/LabelValue';
import styles from './styles.module.less';
import DropDownTriggers from '../../../../../../containers/DropDownTriggers';

const { Option } = Select;

const initState = {
  range: {
    isRelative: true,
    relativeAmount: 1,
    dateType: 'day',
    dateRange: [moment().subtract(6, 'month'), moment()],
  },
  interval: 'day', // formerly groupBy
  triggerId: undefined,
  componentId: undefined,
};

const EventsForm = ({ hideForm }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const auxEventType = useRef();

  const [queryParams, setQueryParams] = useState(initState);
  const [loading, setLoading] = useState(false);
  const [eventType, setEventType] = useState();
  const [title, setTitle] = useState('');
  const [trigger, setTrigger] = useState();

  const selectedDivs = useSelector(({ divisions }) => divisions.selector.ids || []);
  const orgId = useSelector(state => state.organizations.organization.id);
  const eventTypeIdByTriggerSelected = trigger?.eventType?.id || false;

  const closeForm = () => {
    setLoading(false);
    // Reset params
    setQueryParams(initState);
    // Close form
    hideForm();
  };

  const addChart = async () => {
    setLoading(true);

    // The ID of this default dashboard is "events"
    const dashboardId = 'events';
    const newDocRef = doc(
      collection(db, 'organizations', orgId, 'dashboards', dashboardId, 'charts'),
    );

    const { range } = queryParams;
    const newDocData = {
      id: newDocRef.id,
      layout: { x: 1000, y: 1000, h: 2, w: 5 },
      queryParams: {
        ...queryParams,
        range: { ...range, dateRange: range.dateRange.map(d => d.toDate()) },
      },
      title,
      aggregations: defaultAggsConfig,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      uid: auth.currentUser.uid,
    };
    try {
      await setDoc(newDocRef, newDocData);
    } catch (e) {
      dispatch(errorNotification(e.message || e));
    }

    closeForm();
  };

  const queriesList = selectedDivs.map(divId =>
    collection(db, 'organizations', orgId, 'divisions', divId, 'event_types'),
  );

  const { data: eventTypes, loading: eventTypesLoading } = useFirestoreQueryBatched(queriesList, [
    selectedDivs,
  ]);
  const eventTypesWithNumComponents = useMemo(() => {
    let output = eventTypes?.filter(et =>
      et?.components.some(component => component.type === 'number'),
    );
    if (eventTypeIdByTriggerSelected) {
      output = output.filter(et => et.id === eventTypeIdByTriggerSelected);
    }
    return output;
  }, [eventTypes, eventTypeIdByTriggerSelected]);

  const handleEventTypeChange = et => {
    setEventType(et);
    setQueryParams(curr => ({ ...curr, componentId: undefined }));
  };
  const handleComponentChange = componentId => {
    setQueryParams(curr => ({ ...curr, componentId }));
  };

  const handleTitleChange = ({ target: { value } }) => {
    setTitle(value);
  };

  const handleTriggerChange = trig => {
    setQueryParams(curr => ({ ...curr, triggerId: trig?.id }));
    setTrigger(trig);
  };

  const handleClear = () => {
    setQueryParams(curr => ({ ...curr, triggerId: null, componentId: null }));
    setTrigger(null);
    setEventType(null);
  };

  // Can't select dates after today
  const divisionPerEventType = eventTypes?.find(event => event.id === eventType)?.divId || null;

  // aux props
  const auxEventComponents = queryParams.componentId
    ? {
        value: queryParams.componentId,
      }
    : {};

  // Here we are evaluating to if you have a trigger selected we are going to take the eventType of that trigger if we don't  take the eventType selected.
  auxEventType.current =
    eventType || (eventTypeIdByTriggerSelected && eventTypesWithNumComponents.length > 0)
      ? {
          value: eventType || eventTypeIdByTriggerSelected || null,
        }
      : {};

  const hasEventTypeSetted = Object.keys(auxEventType.current).length && auxEventType.current.value;
  // Effects
  useEffect(() => {
    // Reset fields if organization changes
    setQueryParams(initState);
  }, [orgId]);

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <LabelValue
          required
          name={<IntlMessages id="dashboards.chart.form.title" />}
          vertical
          value={
            <Input
              onChange={handleTitleChange}
              placeholder={intl.formatMessage({ id: 'dashboards.chart.form.title.placeholder' })}
            />
          }
        />
      </Col>
      <Col xs={24} md={8} sm={8} xxl={8}>
        <LabelValue
          required
          name={<IntlMessages id="dashboards.chart.form.eventType" />}
          vertical
          value={
            <Select
              className={styles.itemFilter}
              dropdownMatchSelectWidth={false}
              onChange={handleEventTypeChange}
              optionFilterProp="children"
              placeholder={intl.formatMessage({ id: 'dashboards.chart.form.eventType' })}
              showSearch
              loading={eventTypesLoading}
              {...auxEventType.current}
            >
              {eventTypesWithNumComponents.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          }
        />
      </Col>
      <Col xs={24} md={8} sm={8} xxl={8}>
        <LabelValue
          required
          vertical
          name={<IntlMessages id="dashboards.chart.form.component" />}
          value={
            <Select
              className={styles.itemFilter}
              dropdownMatchSelectWidth={false}
              optionFilterProp="children"
              placeholder={intl.formatMessage({ id: 'dashboards.chart.form.component' })}
              onChange={handleComponentChange}
              showSearch
              {...auxEventComponents}
            >
              {/* Take only the components that are numbers */}
              {Object.keys(eventTypesWithNumComponents).length > 0 &&
                hasEventTypeSetted &&
                eventTypesWithNumComponents
                  .find(event => event?.id === auxEventType.current.value)
                  .components?.filter(({ type }) => type === 'number')
                  .map(({ label: { name }, id = '' }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
            </Select>
          }
        />
      </Col>
      <Col xs={24} md={8} sm={8} xxl={8}>
        <LabelValue
          vertical
          name={<IntlMessages id="dashboards.chart.form.trigger" />}
          value={
            <DropDownTriggers
              onChange={handleTriggerChange}
              division={divisionPerEventType}
              eventType={eventType}
              value={trigger?.id}
            />
          }
        />
      </Col>
      <Col className="gx-flex-row gx-justify-content-end gx-mt-4" span={24}>
        <Button loading={loading} onClick={() => handleClear()}>
          <IntlMessages id="button.clear" />
        </Button>
        <Button onClick={closeForm} loading={loading}>
          <IntlMessages id="button.cancel" />
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          onClick={addChart}
          loading={loading}
          disabled={!hasEventTypeSetted || !queryParams.componentId || !title}
        >
          <IntlMessages id="button.add" />
        </Button>
      </Col>
    </Row>
  );
};

EventsForm.propTypes = {
  hideForm: PropTypes.func.isRequired,
};

export default EventsForm;
