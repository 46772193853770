import React, { useEffect, useState, useRef, memo } from 'react';
import { Marker } from 'react-leaflet';

const MarkerOnMoving = props => {
  const { positions, movingState, onMovingState, markerRef, speed = 1 } = props;
  const [index, setIndex] = useState(0);
  const intervalId = useRef(null);
  const position = positions[index] || positions[0];
  let auxIdx = index;

  //Save current position
  markerRef.current = { ...markerRef.current, index };

  const destroyInterval = () => {
    if (intervalId?.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  };

  useEffect(() => {
    if (movingState === null) {
      destroyInterval();
      setIndex(0);
      return;
    }

    if (movingState === 0) {
      intervalId.current = setInterval(() => {
        if (auxIdx === positions.length - 1) {
          // When interval is done , we reset all
          destroyInterval();
          onMovingState(null);
        }
        auxIdx += 1;
        setIndex(prev => prev + 1);
      }, speed ?? 1);
    }

    return () => {
      destroyInterval();
    };
  }, [movingState, speed]);

  return <Marker {...props} position={position} />;
};

export default memo(MarkerOnMoving);
