import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.less';

export default function SectionHeader({ title, children }) {
  return (
    <div className={styles.sectionHeaderContainer}>
      <div className={styles.sectionHeaderTitle}>
        <span>{title}</span>
      </div>
      <div>{children}</div>
    </div>
  );
}

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
