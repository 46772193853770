import { LOC_TYPES } from 'packages/locations';
import PropTypes from 'prop-types';

export const locationPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(LOC_TYPES)).isRequired,

  token: PropTypes.string,
  distance: PropTypes.shape({
    outside: PropTypes.bool,
    meters: PropTypes.number,
  }),
  divId: PropTypes.string,
  position: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lon: PropTypes.number,
    lng: PropTypes.number,
  }),
});

export const locationPropTypes = PropTypes.arrayOf(locationPropType);
