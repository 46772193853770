import esES from 'antd/lib/locale/es_ES';

import saMessages from '../locales/es_ES.json';

const saLang = {
  messages: {
    ...saMessages,
  },
  antd: esES,
  locale: 'es',
};
export default saLang;
