export const SWITCH_TIME_ZONE = 'SWITCH_TIME_ZONE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

// Auth state listener
export const AUTH_TOKEN_LISTENER_REGISTER = 'AUTH_TOKEN_LISTENER_REGISTER';
export const AUTH_TOKEN_LISTENER_UNREGISTER = 'AUTH_TOKEN_LISTENER_UNREGISTER';

// User
export const USER_PROFILE_FETCH = 'USER_PROFILE_FETCH';
export const USER_PROFILE_FETCH_ERROR = 'USER_PROFILE_FETCH_ERROR';
export const USER_PROFILE_FETCH_SUCCESS = 'USER_PROFILE_FETCH_SUCCESS';
export const USER_PROFILE_RESET = 'USER_PROFILE_RESET';
export const USER_PROFILE_SAVE_FETCH = 'USER_PROFILE_SAVE_FETCH';
export const USER_PROFILE_SAVE_FETCH_ERROR = 'USER_PROFILE_SAVE_FETCH_ERROR';
export const USER_PROFILE_SAVE_FETCH_SUCCESS = 'USER_PROFILE_SAVE_FETCH_SUCCESS';
export const USER_PROFILE_VALUES_SET = 'USER_PROFILE_VALUES_SET';
export const USER_PROFILE_VALUES_RESET = 'USER_PROFILE_VALUES_RESET';

export const USER_ACCESS_FETCH = 'USER_ACCESS_FETCH';
export const USER_ACCESS_FETCH_ERROR = 'USER_ACCESS_FETCH_ERROR';
export const USER_ACCESS_FETCH_SUCCESS = 'USER_ACCESS_FETCH_SUCCESS';
export const USER_ACCESS_RESET = 'USER_ACCESS_RESET';

// Organizations
export const ORGANIZATION_FETCH = 'ORGANIZATION_FETCH';
export const ORGANIZATION_FETCH_ERROR = 'ORGANIZATION_FETCH_ERROR';
export const ORGANIZATION_FETCH_SUCCESS = 'ORGANIZATION_FETCH_SUCCESS';
export const ORGANIZATION_SAVE_FETCH = 'ORGANIZATION_SAVE_FETCH';
export const ORGANIZATION_SAVE_FETCH_ERROR = 'ORGANIZATION_SAVE_FETCH_ERROR';
export const ORGANIZATION_SAVE_FETCH_SUCCESS = 'ORGANIZATION_SAVE_FETCH_SUCCESS';
export const ORGANIZATION_SELECT = 'ORGANIZATION_SELECT';

// Notifications

export const ERROR_NOTIFICATION = 'ERROR_NOTIFICATION';
export const INFO_NOTIFICATION = 'INFO_NOTIFICATION';
export const SUCCESS_NOTIFICATION = 'SUCCESS_NOTIFICATION';
export const WARNING_NOTIFICATION = 'WARNING_NOTIFICATION';
