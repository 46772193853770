import React from 'react';
import { Button, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ComponentBlocker from 'components/ComponentBlocker';
import IntlMessages from 'util/IntlMessages';
import {
  ALLOWED_ROLES,
  useFirestoreQueryBatchedPagination,
  getDivsWithAccess,
} from 'packages/utils';
import { SiteContent } from 'packages/ui';
import { db } from 'firebase/firebase';
import { query, orderBy, collection } from 'firebase/firestore';
import InfiniteScroll from 'components/InfiniteScroll';

import styles from './styles.module.less';

import Group from '../../components/Group';
import { PATHS } from '../../constants';

const GroupsList = () => {
  const history = useHistory();

  const loadedGroups = useSelector(({ groups }) => groups.group.save.loaded);
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids);
  const orgId = useSelector(state => state.organizations.organization.id);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);

  const allowedDivs = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.GROUPS.LIST,
    userOrgAccess,
    userOrgDivisions,
  );
  const allowedDivsIds = allowedDivs.map(d => d.id);

  const allowedSelectedDivs = selectedDivsIds.filter(divId => allowedDivsIds.includes(divId));
  const queriesList = allowedSelectedDivs.map(divId =>
    query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'groups'),
      orderBy('createdAt'),
    ),
  );

  const {
    data: groups,
    loading: groupsLoading,
    next,
    gotNewData,
    error,
  } = useFirestoreQueryBatchedPagination(queriesList, [selectedDivsIds, loadedGroups]);

  const handleCreateGroup = () => history.push(PATHS.CREATE_GROUP);

  return (
    <SiteContent
      breadcrumb={[{ name: <IntlMessages id="sidebar.configuration.groups" /> }]}
      title={<IntlMessages id="groups.list.title" />}
      actions={
        <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.GROUPS.CREATE}>
          <Button type="primary" onClick={handleCreateGroup} className={styles.createButton}>
            <IntlMessages id="button.create" />
          </Button>
        </ComponentBlocker>
      }
    >
      {groups.map(group => (
        <Group key={group.id} group={group} />
      ))}
      {error && (
        <h2>
          <IntlMessages id="notification.somethingWentWrong" />
        </h2>
      )}
      <InfiniteScroll condition={!groupsLoading && gotNewData} callback={next} />
      {groupsLoading && <Spin />}
    </SiteContent>
  );
};

export default GroupsList;
