import * as actions from '../actions/assigned';

const getInitialState = () => ({
  data: null,
  error: null,
  loaded: false,
  loading: false,
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case actions.ASSIGNED_DIVS_FETCH: {
      return {
        data: {
          orgId: action.payload.orgId,
          divisions: [],
        },
        error: null,
        loaded: false,
        loading: true,
      };
    }
    case actions.ASSIGNED_DIVS_FETCH_ERROR: {
      return {
        data: null,
        error: action.payload,
        loaded: true,
        loading: false,
      };
    }
    case actions.ASSIGNED_DIVS_FETCH_SUCCESS: {
      return {
        data: {
          orgId: state.data.orgId,
          divisions: action.payload.divisions,
        },
        error: null,
        loaded: true,
        loading: false,
      };
    }

    default:
      return state;
  }
};
