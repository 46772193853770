import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { useIntl } from 'react-intl';

import { userProfileSaveFetch, organizationSaveFetch, infoNotification } from 'appRedux/actions';
import { ACCESS_TYPES } from 'packages/utils/access';
import { actions } from 'packages/divisions';
import logo from 'assets/images/logo.png';

import styles from './styles.module.less';
import StepOne from './components/StepOne';
import StepTwo from './components/StepTwo';
import StepThree from './components/StepThree';
import StepIndicator from './components/StepIndicator';

const Wizard = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);

  const { firstName = '', lastName = '' } = useSelector(({ user }) => user.profile.data);
  const orgs = useSelector(({ user }) => user.access.data?.claims?.org || {});
  const { orgId, orgData } = useSelector(({ organizations }) => ({
    orgId: organizations.organization.id,
    orgData: organizations.organization.data || {},
  }));

  const organizationLoading = useSelector(
    ({ organizations }) => organizations.organization.save.loading,
  );
  const userLoading = useSelector(({ user }) => user.profile.save.loading);

  const isInvitedUser = !!orgs[orgId] && orgs[orgId] !== ACCESS_TYPES.OWNER;

  const isLoading = organizationLoading || userLoading;

  const saveUser = newValues => {
    if (newValues) {
      dispatch(userProfileSaveFetch({ ...newValues, onboardingCompleted: isInvitedUser }));
    }
    if (!isInvitedUser) {
      goNext();
    }
  };

  const goNext = () => setCurrentStep(currentStep + 1);

  const goBack = () => setCurrentStep(currentStep - 1);

  const saveOrganization = newValues => {
    if (newValues) {
      dispatch(organizationSaveFetch(orgId, { ...newValues, description: '', taxId: '' }));
    }
    goNext();
  };

  const handleFinish = newValues => {
    dispatch(actions.divisionSaveFetch(orgId, null, newValues));
    dispatch(infoNotification(intl.formatMessage({ id: 'onboarding.stepThree.creatingDivision' })));
  };

  const steps = [
    <StepOne onNext={saveUser} initialData={{ firstName, lastName }} />,
    <StepTwo onNext={saveOrganization} onPrevious={goBack} initialData={orgData} />,
    <StepThree onFinish={handleFinish} onPrevious={goBack} />,
  ];

  return (
    <>
      <img src={logo} alt="Guarnic" className={styles.logo} />
      <div className={styles.stepContainer}>
        <StepIndicator step={currentStep} isInvitedUser={isInvitedUser} />
        {isLoading ? <Spin size="large" /> : steps[currentStep]}
      </div>
    </>
  );
};

export default Wizard;
