import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { ChildrenSchema, USER_ROLES, hasAnyAllowedRole } from 'packages/utils';

const ComponentBlocker = ({ children, allowedRoles, divId }) => {
  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);

  let hasAccess;
  if (divId) {
    const userDivs = {};
    if (userOrgDivisions?.[divId]) {
      userDivs[divId] = userOrgDivisions?.[divId];
    }
    hasAccess = allowedRoles ? hasAnyAllowedRole(allowedRoles, userOrgAccess, userDivs) : true;
  } else {
    hasAccess = hasAnyAllowedRole(allowedRoles, userOrgAccess, userOrgDivisions);
  }

  return hasAccess ? children : null;
};

ComponentBlocker.propTypes = {
  children: ChildrenSchema,
  allowedRoles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(USER_ROLES))),
  divId: PropTypes.string,
};

ComponentBlocker.defaultProps = {
  allowedRoles: null,
  divId: null,
};

export default ComponentBlocker;
