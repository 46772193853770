import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Grid, Input, Row, Select } from 'antd';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'lodash';
import { SketchPicker } from 'react-color';

import { db } from 'firebase/firebase';
import { collection, doc } from 'firebase/firestore';
import IntlMessages from 'util/IntlMessages';
import { PlusOutlined } from '@ant-design/icons';
import * as actions from 'packages/eventTypes/redux/actions';
import { useFirestoreQuery, ALLOWED_ROLES, getDivsWithAccess, getFullUrl } from 'packages/utils';
import DivisionName from 'components/DivisionName';

import EventTypeIcon from 'components/EventTypeIcon';
import Title from 'components/BoxContainer/components/Title';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import { AVAILABLE_PROPS, MAX_COMPONENTS_PER_EVENT, PATHS } from '../../constants';

import { ICON_MAP } from './constants';
import { SortableComponentMemo } from './EventComponentCollapse';
import IconDropdown from '../../../../components/IconDropdown';
import styles from './index.module.less';
import PreviewEventType from './PreviewEventType';

const { Item, List } = Form;
const { Option } = Select;
const ICONS = Object.keys(ICON_MAP);

// Just a simple HOC for the input to manage value as { r, g, b, a } object
// eslint-disable-next-line react/prop-types
const ColorPicker = ({ onChange, value, ...props }) => (
  <SketchPicker {...props} onChangeComplete={({ rgb }) => onChange(rgb)} color={value} />
);
const { useBreakpoint } = Grid;

const EventType = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { url } = useRouteMatch();
  const history = useHistory();
  const intl = useIntl();
  const formRef = useRef();
  const screen = useBreakpoint();
  const isMobile = screen.xs;

  const [listError, setListError] = useState(null);
  const [newDivId, setNewDivId] = useState(null);
  const [iconCoincidences, setIconCoincidences] = useState(ICONS);
  const [iconSelected, setIconSelected] = useState('');

  const { orgId, eventTypeId, divId: paramDivId, atOrg } = useParams();

  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org?.[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
  const languageSelected = useSelector(({ settings }) => settings.locale.locale);
  const isEditing = !!eventTypeId;
  const isCreatingAtDiv = !isEditing && !atOrg;
  const isEditingAtDiv = isEditing && !!paramDivId;
  const divId = paramDivId || newDivId;

  const allowedDivisions = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.EVENT_TYPES.CREATE,
    userOrgAccess,
    userOrgDivisions,
  );

  // This Refs are to cover all 4 cases:
  // - Creating an Event Type at: Organization & Division
  // - Editing an Event Type at: Organization & Division
  const orgRef = doc(db, 'organizations', orgId);
  const divRef = divId && doc(orgRef, 'divisions', divId);
  const eventTypeRef = isEditing && doc(divRef || orgRef, 'event_types', eventTypeId);

  const { data: eventTypeData, loading } = useFirestoreQuery(eventTypeRef, [
    orgId,
    divId,
    eventTypeId,
  ]);

  // Preview Color
  const [iconPreView, setIconPreview] = useState({
    color: { a: 100, b: 102, g: 51, r: 0 },
    icon: 'grade',
    name: '',
  });
  const handleGoBack = () => {
    history.push(getFullUrl(PATHS.BASE_URL, url));
  };

  const onFinish = values => {
    const componentsWithOrder = values.components.map((comp, i) => {
      const typeChanged = form.isFieldTouched(['components', i, 'type']);

      // Generate a unique ID using Firebase
      const { id: newId } = doc(collection(db, 'uniqueId'));
      const componentId = typeChanged || !comp?.id ? newId : comp.id;

      return {
        ...comp,
        order: i,
        id: componentId,
      };
    });
    const eventType = {
      ...values,
      icon: iconSelected,
      components: componentsWithOrder,
    };
    if (divId) {
      eventType.divId = divId;
    }
    dispatch(actions.eventTypeSaveFetch(orgId, divId, eventTypeId, eventType));
    handleGoBack();
  };

  const handleFinishFailed = ({ errorFields }) => {
    const hasListError = errorFields.some(item => item.name[0] === 'components');
    if (hasListError) {
      setListError(intl.formatMessage({ id: 'eventTypes.eventType.form.components.error' }));
    }
  };

  const handleTypeSelect = id => {
    const compsData = form.getFieldValue(['components']);
    const component = compsData[id];

    component.componentProps = pick(
      component.componentProps,
      Object.keys(AVAILABLE_PROPS[component.type]),
    );

    form.setFieldsValue({
      components: compsData,
    });
  };

  const filterIcons = (language, query) => {
    const lowerCaseQuery = query.toLowerCase();
    return ICONS.filter(icon =>
      ICON_MAP[icon][language].some(text => text.toLowerCase().includes(lowerCaseQuery)),
    );
  };

  const onSearch = value => {
    const coincidences = filterIcons(languageSelected, value);

    if (!value) {
      setIconCoincidences(ICONS);
      setIconSelected('');
    } else {
      setIconCoincidences(coincidences);
      setIconSelected(value);
    }
  };

  const onPreviewIcon = (tag, value) => {
    if (tag === 'icon') {
      setIconPreview({
        ...iconPreView,
        icon: value,
      });
      return;
    }
    if (tag === 'color') {
      setIconPreview({
        ...iconPreView,
        color: value,
      });
      return;
    }

    setIconPreview({
      ...iconPreView,
      name: value,
    });
  };

  const onSelectIcon = icon => {
    setIconSelected(icon);
    onPreviewIcon('icon', icon);
  };

  useEffect(() => {
    form.setFieldsValue({ ...eventTypeData });
    if (eventTypeData) {
      setIconPreview({
        color: eventTypeData?.color,
        icon: eventTypeData?.icon,
        name: eventTypeData?.name,
      });
    }
  }, [eventTypeData, form]);

  return (
    <BoxContainer>
      <BoxContainer content fixed shadow>
        <FilterContainer
          goBack={handleGoBack}
          title={
            <Title
              value={
                <IntlMessages
                  id={isEditing ? 'triggers.view.edit.title' : 'triggers.view.create.title'}
                />
              }
            />
          }
          actionButtons={[
            {
              label: <IntlMessages id="form.save" />,
              action: () => formRef.current.submit(),
              type: 'primary',
              disabled: loading,
              htmlType: 'submit',
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer content loading={loading}>
        <Form
          ref={formRef}
          name="componentEventTypes"
          form={form}
          initialValues={{ components: [], description: '' }}
          onFinish={onFinish}
          onFinishFailed={handleFinishFailed}
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 24 }}
          layout={isMobile ? 'vertical' : 'inline'}
        >
          <Row gutter={16}>
            {isEditingAtDiv && (
              <Col className={styles.divisionNameContainer} span={23}>
                <DivisionName divId={divId} />
              </Col>
            )}
            {isCreatingAtDiv && (
              <Col className={styles.customAlign} span={24}>
                <Item
                  label={intl.formatMessage({ id: 'form.division' })}
                  name="divId"
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'form.division.requiredError' }),
                    },
                  ]}
                  labelAlign="left"
                >
                  <Select
                    dropdownMatchSelectWidth={false}
                    onChange={setNewDivId}
                    optionFilterProp="children"
                    placeholder={<IntlMessages id="form.division.placeholder" />}
                    showSearch
                  >
                    {allowedDivisions.map(div => (
                      <Option key={div.id} value={div.id}>
                        {div.name}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
            )}
            <Col className={styles.customAlign} span={24}>
              <Row gutter={[16, 16]}>
                {!isMobile && (
                  <Col className="gx-pt-4 gx-pl-4" xs={24} xl={1} xxl={1}>
                    <EventTypeIcon
                      color={iconPreView.color}
                      icon={iconPreView.icon}
                      className={styles.iconPreView}
                    />
                  </Col>
                )}
                <Col className={!isMobile && 'gx-p-0 gx-pl-3'} xs={24} xxl={23} xl={23}>
                  <Item
                    label={<IntlMessages id="eventTypes.eventType.form.name.label" />}
                    name="name"
                    required
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'eventTypes.eventType.form.name.requiredError',
                        }),
                      },
                    ]}
                    labelAlign="left"
                  >
                    <Input onChange={e => onPreviewIcon('name', e.target.value)} />
                  </Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={8} xl={8} xxl={8}>
              <Item
                label={<IntlMessages id="eventTypes.eventType.form.description.label" />}
                name="description"
                labelAlign="left"
              >
                <Input.TextArea rows={isMobile ? 4 : 18} className={styles.descriptionForm} />
              </Item>
            </Col>
            <Col xs={24} md={8} xl={8} xxl={8}>
              <Item
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'eventTypes.eventType.form.icon.requiredError',
                    }),
                    validator: () => {
                      if (iconSelected) return Promise.resolve();
                      return Promise.reject();
                    },
                  },
                ]}
                labelAlign="left"
                name="icon"
                label={<IntlMessages id="eventTypes.eventType.form.icon.label" />}
              >
                <IconDropdown
                  data={iconCoincidences}
                  onSearch={onSearch}
                  onSelectIcon={onSelectIcon}
                  className={styles.iconForm}
                />
              </Item>
            </Col>
            <Col xs={24} md={8} xl={8} xxl={8}>
              <Item
                label={<IntlMessages id="eventTypes.eventType.form.color.label" />}
                name="color"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'eventTypes.eventType.form.color.requiredError',
                    }),
                  },
                ]}
                labelAlign="left"
              >
                <ColorPicker
                  onChange={e => onPreviewIcon('color', e)}
                  className={styles.colorPicker}
                />
              </Item>
            </Col>
            {isMobile && (
              <Col className={styles.customAlign} span={24}>
                <Item
                  label={<IntlMessages id="eventTypes.eventType.preview.label" />}
                  labelAlign="left"
                >
                  <PreviewEventType
                    color={iconPreView.color}
                    icon={iconPreView.icon}
                    eventName={iconPreView.name}
                  />
                </Item>
              </Col>
            )}
            <Col xs={24} xl={24} xxl={24}>
              <Item
                label={
                  <IntlMessages
                    id="eventTypes.eventType.form.components.label"
                    values={{ amount: MAX_COMPONENTS_PER_EVENT }}
                  />
                }
                name="components"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'eventTypes.eventType.form.components.requiredError',
                    }),
                  },
                ]}
                labelAlign="left"
              >
                <List name="components">
                  {(fields, { add, remove, move }) => (
                    <>
                      <SortableComponentMemo
                        items={fields}
                        remove={remove}
                        handleTypeSelect={handleTypeSelect}
                        form={form}
                        onMove={move}
                      />
                      <Item className={styles.addButtonContainer}>
                        <Button
                          type="primary"
                          disabled={fields.length === MAX_COMPONENTS_PER_EVENT}
                          onClick={() => add({})}
                          block
                          icon={<PlusOutlined />}
                          className="gx-mr-0"
                        >
                          <IntlMessages id="eventTypes.eventType.form.components.addComponent" />
                        </Button>
                        <Form.ErrorList errors={[listError]} />
                      </Item>
                    </>
                  )}
                </List>
              </Item>
            </Col>
          </Row>
        </Form>
      </BoxContainer>
    </BoxContainer>
  );
};
export default EventType;
