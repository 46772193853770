import React, { useCallback, useEffect, useRef, useState } from 'react';
import IntlMessages from 'util/IntlMessages';
import { ALLOWED_ROLES } from 'packages/utils';
import ComponentBlocker from 'components/ComponentBlocker';

import { Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { collection, orderBy, query } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import BoxContainer from 'components/BoxContainer';
import Title from 'components/BoxContainer/components/Title';
import FilterContainer from 'components/FilterContainer';
import styles from './styles.module.less';
import OrganizationList from '../../components/Location/OrganizationList';
import DivisionList from '../../components/Location/DivisionList';
import LocationFilter from '../../components/Location/LocationFilter';
import {
  getDivsWithAccess,
  hasAnyAllowedRole,
  useFirestoreQuery,
  useFirestoreQueryBatched,
} from '../../../utils';
import { getRouteToCreateLocation, getRouteUploadLocationFile } from '../../constants';

const LocationsList = () => {
  const history = useHistory();
  const isOrganization = useRef(true);
  const lastElementGotIt = useRef({});
  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
  const loadedLocs = useSelector(({ locations }) => locations.location.save.loaded);

  const canListOrg = hasAnyAllowedRole(ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.LIST, userOrgAccess);
  const orgLocationsRef = collection(db, 'organizations', orgId, 'locations');
  const initialFormFilter = {
    locationType: '',
    locationName: '',
  };
  const allowedDivs = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.LIST,
    userOrgAccess,
    userOrgDivisions,
  );
  const allowedDivsIds = allowedDivs.map(d => d.id);
  const allowedSelectedDivs = selectedDivsIds.filter(divId => allowedDivsIds.includes(divId));

  const [mainDataFiltered, setMainDataFiltered] = useState({
    organizationData: [],
    divisionsData: [],
  });
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [formFilter, setFormFilter] = useState(initialFormFilter);

  const { data: orgLocations = [], loading: orgLocsLoading } = useFirestoreQuery(
    canListOrg && orgLocationsRef,
    [canListOrg, orgId],
  );

  // NOTE: only is called when is division tab is selected
  const queriesList =
    !isOrganization.current &&
    allowedSelectedDivs.map(divId =>
      query(
        collection(db, 'organizations', orgId, 'divisions', divId, 'locations'),
        orderBy('createdAt'),
      ),
    );
  const {
    data: divLocs = [],
    loading: divLocsLoading,
    error,
  } = useFirestoreQueryBatched(queriesList, [selectedDivsIds, loadedLocs, refreshToggle]);

  const handleLocation = tab => {
    const atOrg = tab === 'organizationList';
    isOrganization.current = atOrg;

    setFormFilter(initialFormFilter);
  };

  const fillNextData = useCallback(
    (type, data) => {
      switch (type) {
        case true:
          return {
            organizationData: data,
          };
        case false:
          return {
            divisionsData: data,
          };
        default:
          return {
            organizationData: orgLocations,
            divisionsData: divLocs,
          };
      }
    },
    [divLocs, orgLocations],
  );

  const handleSearch = useCallback(() => {
    const output = fillNextData(
      isOrganization.current,
      isOrganization.current ? orgLocations : divLocs,
    );

    // return origin values when all is empty
    if (formFilter.locationType === '' && formFilter.locationName === '') {
      setMainDataFiltered(output);
      return;
    }

    const dataToBeFiltered = isOrganization.current ? orgLocations : divLocs;
    const dataFiltered = dataToBeFiltered.filter(item => {
      const textToFind = item.name.toLowerCase();
      const pattern = new RegExp(formFilter.locationName.toLowerCase(), 'g');

      return (
        (item.type === formFilter.locationType || formFilter.locationType === '') &&
        Boolean(textToFind.match(pattern)?.length)
      );
    });
    const dataChoosed = fillNextData(isOrganization.current, dataFiltered);

    setMainDataFiltered(prev => ({
      ...prev,
      ...dataChoosed,
    }));
  }, [divLocs, fillNextData, formFilter.locationName, formFilter.locationType, orgLocations]);

  const handleFilter = (type, value) => {
    setFormFilter(prev => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleCreateLocation = type => {
    history.push(getRouteToCreateLocation(type, isOrganization.current), {
      fromOrganization: isOrganization.current,
    });
  };

  const tabsList = [
    {
      label: <IntlMessages id="locations.list.organizationLocations" />,
      key: 'organizationList',
      children: (
        <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.LIST}>
          <BoxContainer loading={orgLocsLoading}>
            <OrganizationList
              data={mainDataFiltered.organizationData}
              loading={orgLocsLoading}
              refresh={setRefreshToggle}
            />
          </BoxContainer>
        </ComponentBlocker>
      ),
    },
    {
      label: <IntlMessages id="locations.list.divisionsLocations" />,
      key: 'divisionList',
      children: (
        <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.LIST}>
          <BoxContainer loading={divLocsLoading}>
            <DivisionList
              data={mainDataFiltered.divisionsData}
              loading={divLocsLoading}
              refresh={setRefreshToggle}
            />
          </BoxContainer>
        </ComponentBlocker>
      ),
    },
  ];
  useEffect(() => {
    if (
      (!orgLocsLoading || !divLocsLoading) &&
      (mainDataFiltered.divisionsData?.length !== divLocs?.length ||
        mainDataFiltered.organizationData?.length !== orgLocations?.length) &&
      formFilter.locationType === '' &&
      formFilter.locationName === ''
    ) {
      setMainDataFiltered({
        organizationData: orgLocations,
        divisionsData: divLocs,
      });
    }
  }, [formFilter, orgLocsLoading, mainDataFiltered, orgLocations, divLocs, divLocsLoading]);

  useEffect(() => {
    if (formFilter.locationType === '') {
      lastElementGotIt.current = '';
    }
    if (formFilter.locationType !== lastElementGotIt.current && formFilter.locationType !== '') {
      lastElementGotIt.current = formFilter.locationType;
      handleSearch();
    }
  }, [formFilter.locationType, handleSearch, lastElementGotIt]);

  return (
    <BoxContainer>
      <BoxContainer content fixed shadow>
        <FilterContainer
          showHide
          title={<Title value={<IntlMessages id="sidebar.configuration.locations" />} />}
          content={
            <LocationFilter
              locationName={formFilter.locationName}
              locationType={formFilter.locationType}
              handleSearch={handleSearch}
              handleFilter={handleFilter}
              handleClear={() => setFormFilter(initialFormFilter)}
            />
          }
          actionButtons={[
            {
              label: <IntlMessages id="locations.upload.file.label" />,
              action: () => history.push(getRouteUploadLocationFile()),
              type: 'primary',
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.CREATE,
            },
            {
              label: (
                <span className="gx-text-uppercase">
                  <PlusOutlined /> <IntlMessages id="locations.gps" />
                </span>
              ),
              action: () => handleCreateLocation('GPS'),
              type: 'primary',
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.CREATE,
            },
            {
              label: (
                <span className="gx-text-uppercase">
                  <PlusOutlined /> <IntlMessages id="locations.nfc" />
                </span>
              ),
              action: () => handleCreateLocation('NFC'),
              type: 'primary',
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.CREATE,
            },
            {
              label: (
                <span className="gx-text-uppercase">
                  <PlusOutlined /> <IntlMessages id="locations.qr" />
                </span>
              ),
              action: () => handleCreateLocation('QR'),
              type: 'primary',
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.CREATE,
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer error={error} content>
        <Tabs
          defaultValue={isOrganization.current ? 'organizationList' : 'divisionList'}
          defaultActiveKey={isOrganization.current ? 'organizationList' : 'divisionList'}
          onChange={e => handleLocation(e)}
          className={styles.locationTabsContainer}
          items={tabsList}
        />
      </BoxContainer>
    </BoxContainer>
  );
};

export default LocationsList;
