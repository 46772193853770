import { combineReducers } from 'redux';

import { REDUCER_INDEX } from '../../constants';

import list from './list';
import user from './user';

export const reducer = combineReducers({
  list,
  user,
});

export const registerReducer = state => ({
  ...state,
  [REDUCER_INDEX]: reducer,
});

export const getState = state => state[REDUCER_INDEX];
export const getStateUser = state => state[REDUCER_INDEX].user;
export const getStateList = state => state[REDUCER_INDEX].list;
