import React, { memo, useEffect, useRef } from 'react';

import { Avatar } from 'antd';
import L, { divIcon } from 'leaflet';
import { useIntl } from 'react-intl';
import { useMap } from 'react-leaflet';

import { renderToStaticMarkup } from 'react-dom/server';

import 'leaflet.markercluster';

import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import moment from 'moment';

const EventsOnMap = props => {
  const intl = useIntl();
  const map = useMap();
  const { data, eventMarkersRef } = props;
  const customIcon = (eventIcon, eventColor) =>
    new divIcon({
      html: renderToStaticMarkup(
        <Avatar
          style={{
            backgroundColor: eventColor,
          }}
          icon={<span className="material-icons-outlined">{eventIcon}</span>}
          className="gx-flex-row gx-justify-content-center gx-align-items-center"
        />,
      ),
      className: null,
    });
  const markersRef = useRef([]);
  const layersAdded = useRef(false);

  useEffect(() => {
    //NOTE: clear event markers when data is empty
    if (data.length === 0) {
      markersRef.current.forEach(ly => map.removeLayer(ly)); //remove event markers
      if (eventMarkersRef.current.clusterRef !== null) {
        map.removeLayer(eventMarkersRef.current.clusterRef); //remove cluster marker
      }
      layersAdded.current = false;
    }

    if (layersAdded.current) {
      return;
    }

    const markers = L.markerClusterGroup();
    if (data.length > 0) {
      data.forEach(child => {
        const marker = L.marker(child.location, { icon: customIcon(child.icon, child.color) });
        marker
          .bindPopup(
            `<div>
              <div>
                <span style="font-weight: 600;">${intl.formatMessage({
                  id: 'reports.userPositionHistory.chart.tooltip.name',
                })}: </span> ${child.eventName}
              </div>
              <div>
              <span style="font-weight: 600;">
              ${intl.formatMessage({
                id: 'reports.userPositionHistory.chart.tooltip.date',
              })}: </span> ${moment(child.timeAsSeconds).format('YYYY-MM-DD')}
              </div>
              <div>
                <span style="font-weight: 600;">
                  ${intl.formatMessage({
                    id: 'reports.userPositionHistory.chart.tooltip.time',
                  })}: </span> ${child.time}
              </div>
            </div>`,
          )
          .openPopup();

        markersRef.current.push(marker);

        if (eventMarkersRef.current && child?.eventId) {
          eventMarkersRef.current.markersByEvent[child.eventId] = marker;
        }
        markers.addLayer(marker);
      });
      map.addLayer(markers);
      eventMarkersRef.current.clusterRef = markers;
      layersAdded.current = true;
    }
  }, [data]);

  return null;
};

export default memo(EventsOnMap);
