import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment-timezone';

import styles from './index.module.less';

const EventDatetime = ({ datetime, className }) => (
  <h4 className={classnames(styles.text, className)}>
    {moment(datetime.toMillis()).format('D/MM/YYYY')}
    {' · '}
    {moment(datetime.toMillis()).format('HH:mm')}
    hs
  </h4>
);

EventDatetime.propTypes = {
  datetime: PropTypes.object.isRequired,

  className: PropTypes.string,
};

EventDatetime.defaultProps = {
  className: '',
};

export default React.memo(EventDatetime);
