import React from 'react';
import PropTypes from 'prop-types';
import { getInitialsName } from '../../packages/utils/functions';
import { Avatar, Col, List, Row, Space, Tooltip, Typography } from 'antd';
import styles from './styles.module.less';
import { ChildrenSchema } from 'packages/utils';
import IntlMessages from 'util/IntlMessages';
import { PlusCircleTwoTone, UserOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

const MemberList = ({ data, loadMore, limit, onSeeMore }) => {
  const intl = useIntl();
  const loadMoreButton = loadMore ? <div className={styles.loadingMore}>{loadMore}</div> : null;
  const users = data?.users || [];
  const groups = data?.groups || [];

  const amountUsers = users.length;
  const amountGroups = groups.length;

  let usersAndGroups = [...users, ...groups];
  usersAndGroups = usersAndGroups.map(item => {
    let newItem = item;
    newItem.isAGroup = item.hasOwnProperty('users');
    return newItem;
  });

  const usersLimited = usersAndGroups.slice(0, limit);

  const hasNoUsers = amountUsers === 0;
  const hasNoGroups = amountGroups === 0;

  const extraClass = hasNoUsers && hasNoGroups ? styles.memberEmptyState : null;

  const MemberAvatar = ({ data }) => {
    if (data.isAGroup) return <Avatar size={24} icon={<UserOutlined />} />;
    return (
      <Avatar size={24} src={data.photoURL} className={styles.avatarColor}>
        {getInitialsName(`${data?.firstName} ${data?.lastName}`)}
      </Avatar>
    );
  };

  return (
    <List
      loadMore={loadMoreButton}
      split={false}
      header={
        <Space className={styles.headerList}>
          <div className="gx-guarnic-label">
            <IntlMessages id="user.list.members.label" values={{ amountUsers, amountGroups }} />
          </div>
          {onSeeMore && (!hasNoUsers || !hasNoGroups) && (
            <Tooltip title={intl.formatMessage({ id: 'schedules.list.members.plus.label' })}>
              <PlusCircleTwoTone onClick={onSeeMore} />
            </Tooltip>
          )}
        </Space>
      }
      className={classnames(styles.listContainer, extraClass)}
      size="small"
      dataSource={limit > 0 ? usersLimited : usersAndGroups}
      renderItem={item => (
        <List.Item key={item?.email}>
          <Row>
            <Col span={2} className={styles.avatarContainer}>
              <MemberAvatar data={item} />
            </Col>
            <Col flex={2} span={18} className={styles.userContainer}>
              <Typography.Text className={styles.userContent}>
                {item.isAGroup ? item.name : `${item?.firstName} ${item?.lastName}`}
              </Typography.Text>
            </Col>
          </Row>
        </List.Item>
      )}
      locale={{ emptyText: intl.formatMessage({ id: 'member.empty.state' }) }}
    />
  );
};

MemberList.propTypes = {
  data: PropTypes.object.isRequired,
  loadMore: ChildrenSchema,
  limit: PropTypes.number,
  onSeeMore: PropTypes.func,
};

MemberList.defaultProps = {
  limit: 0,
};

export default MemberList;
