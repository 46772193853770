import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { useFirestoreQuery } from 'packages/utils';
import { db } from 'firebase/firebase';
import { collection, orderBy, query } from 'firebase/firestore';
import PropTypes from 'prop-types';

/**
 * Component to get all organization users
 */

const DropDownUsers = React.memo(({ value, onChange }) => {
  const orgId = useSelector(state => state.organizations.organization.id);
  const { data: users } = useFirestoreQuery(
    query(collection(db, 'organizations', orgId, 'users'), orderBy('displayName', 'asc')),
    [orgId],
  );
  return (
    <>
      <Select
        allowClear
        className="gx-w-100"
        mode="single"
        onChange={onChange}
        placeholder="Choose an user"
        value={value}
      >
        {users?.map(user => (
          <Select.Option key={user.id} value={user.id}>
            {`${user?.firstName} ${user?.lastName}`}
          </Select.Option>
        ))}
      </Select>
    </>
  );
});

DropDownUsers.defaultProps = {
  value: '',
};

DropDownUsers.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default DropDownUsers;
