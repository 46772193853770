export const LOCATION_DELETE = 'LOCATION_DELETE';
export const LOCATION_DELETE_ERROR = 'LOCATION_DELETE_ERROR';
export const LOCATION_DELETE_SUCCESS = 'LOCATION_DELETE_SUCCESS';
export const LOCATION_SAVE_FETCH = 'LOCATION_SAVE_FETCH';
export const LOCATION_MULTIPLE_CREATE = 'LOCATION_MULTIPLE_CREATE';
export const LOCATION_MULTIPLE_CREATE_ERROR = 'LOCATION_MULTIPLE_CREATE_ERROR';
export const LOCATION_MULTIPLE_CREATE_SUCCESS = 'LOCATION_MULTIPLE_CREATE_SUCCESS';
export const LOCATION_SAVE_FETCH_ERROR = 'LOCATION_SAVE_FETCH_ERROR';
export const LOCATION_SAVE_FETCH_SUCCESS = 'LOCATION_SAVE_FETCH_SUCCESS';

export const locationDelete = (organizationId, divisionId, locationId) => ({
  type: LOCATION_DELETE,
  payload: { organizationId, divisionId, locationId },
});

export const locationDeleteSuccess = () => ({
  type: LOCATION_DELETE_SUCCESS,
});

export const locationDeleteError = error => ({
  type: LOCATION_DELETE_ERROR,
  payload: error,
});

export const locationSaveFetch = (organizationId, divisionId, locationId, data) => ({
  type: LOCATION_SAVE_FETCH,
  payload: { organizationId, divisionId, locationId, data },
});

export const locationMultipleCreate = (organizationId, divisionId, amount, data) => ({
  type: LOCATION_MULTIPLE_CREATE,
  payload: { organizationId, divisionId, amount, data },
});

export const locationMultipleCreateSuccess = () => ({
  type: LOCATION_MULTIPLE_CREATE_SUCCESS,
});

export const locationMultipleCreateError = error => ({
  type: LOCATION_MULTIPLE_CREATE_ERROR,
  payload: error,
});

export const locationSaveFetchSuccess = location => ({
  type: LOCATION_SAVE_FETCH_SUCCESS,
  payload: location,
});

export const locationSaveFetchError = error => ({
  type: LOCATION_SAVE_FETCH_ERROR,
  payload: error,
});
