import { ACTIONS } from './actions';

export const getInitialState = () => ({
  dataByBatch: {},
  lastDocumentByBatch: {},
  loadedByBatch: { 0: false },
  loadingByBatch: { 0: false },
  gotNewDataByBatch: { 0: true },
  error: null,
  next: null,
});

export const reducer = (state = getInitialState(), { type, payload }) => {
  switch (type) {
    case ACTIONS.UTI_FQBP_FIRST_FETCH: {
      const updatedLoaded = { ...new Array(payload.batchesAmount).fill(false) };
      const updatedLoading = { ...new Array(payload.batchesAmount).fill(true) };

      return {
        ...getInitialState(),
        loadedByBatch: updatedLoaded,
        loadingByBatch: updatedLoading,
      };
    }
    case ACTIONS.UTI_FQBP_FIRST_FETCH_ERROR: {
      return {
        ...getInitialState(),
        error: payload,
        loadedByBatch: { 0: true },
      };
    }
    case ACTIONS.UTI_FQBP_NEXT_FETCH: {
      const updatedLoading = {};
      const updatedLoaded = {};
      payload.batchesIds.forEach(id => {
        updatedLoading[id] = true;
        updatedLoaded[id] = false;
      });

      return {
        ...state,
        error: null,
        loadedByBatch: updatedLoaded,
        loadingByBatch: updatedLoading,
      };
    }
    case ACTIONS.UTI_FQBP_NEXT_FETCH_ERROR: {
      return {
        ...getInitialState(),
        error: payload,
        loadedByBatch: { 0: true },
      };
    }
    case ACTIONS.UTI_FQBP_FETCH_BATCH_SUCCESS: {
      const { batchId } = payload;

      const updatedData = {
        ...state.dataByBatch,
        [batchId]: { ...state.dataByBatch[batchId], ...payload.data },
      };
      const updatedLastDoc = {
        ...state.lastDocumentByBatch,
        [batchId]: payload.lastDocument || state.lastDocumentByBatch[batchId],
      };
      const updatedGotNew = {
        ...state.gotNewDataByBatch,
        [batchId]: !!Object.keys(payload.data).length,
      };
      const updatedLoading = {
        ...state.loadingByBatch,
        [batchId]: false,
      };
      const updatedLoaded = {
        ...state.loadedByBatch,
        [batchId]: true,
      };

      return {
        ...getInitialState(),
        dataByBatch: updatedData,
        gotNewDataByBatch: updatedGotNew,
        lastDocumentByBatch: updatedLastDoc,
        loadedByBatch: updatedLoaded,
        loadingByBatch: updatedLoading,
        next: payload.next || state.next,
      };
    }
    case ACTIONS.UTI_FQBP_RESET: {
      return getInitialState();
    }
    default:
      throw new Error('useFirestoreQueryBatchedPagination: Invalid action');
  }
};
