import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth, db, serverTimestamp } from 'firebase/firebase';
import { doc, setDoc, collection } from 'firebase/firestore';
import { errorNotification } from 'appRedux/actions';

import {
  EVENT_TYPE_SAVE_FETCH,
  eventTypeSaveFetchError,
  eventTypeSaveFetchSuccess,
} from '../actions';

const eventTypeSaveFetchRequest = async (organizationId, divisionId, eventTypeId, data) => {
  const docData = {
    ...data,
    updatedAt: serverTimestamp(),
  };

  const orgRef = doc(db, 'organizations', organizationId);
  const divRef = !!divisionId && doc(orgRef, 'divisions', divisionId);

  if (eventTypeId) {
    await setDoc(doc(divRef || orgRef, 'event_types', eventTypeId), docData, {
      merge: true,
    });
    return Promise.resolve(docData);
  }

  const newDocRef = doc(collection(divRef || orgRef, 'event_types'));
  const newDocData = {
    ...docData,
    createdAt: serverTimestamp(),
    id: newDocRef.id,
    uid: auth.currentUser.uid,
  };
  await setDoc(newDocRef, newDocData);
  return Promise.resolve(newDocData);
};

function* eventTypeSaveFetch({ payload: { organizationId, divisionId, eventTypeId, data } }) {
  try {
    const response = yield call(
      eventTypeSaveFetchRequest,
      organizationId,
      divisionId,
      eventTypeId,
      data,
    );
    yield put(eventTypeSaveFetchSuccess(response));
    // @todo Send notification with transaltion
    // yield put(successNotification('The eventType has been updated successfully !'));
  } catch (error) {
    yield put(eventTypeSaveFetchError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* fetchEventTypeSave() {
  yield takeEvery(EVENT_TYPE_SAVE_FETCH, eventTypeSaveFetch);
}

export default function* rootSaga() {
  yield all([fork(fetchEventTypeSave)]);
}
