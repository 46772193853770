import moment from 'moment';
import { transform, camelCase, isArray, isObject } from 'lodash';

export const getDateTypeIndex = (seconds, groupBy, intl) => {
  switch (groupBy) {
    case 'hour':
      return moment(seconds).utc().format('DD-MM-YYYY hh:mm:ss');
    case 'month':
      return moment(seconds).utc().format('MMMM - YYYY');
    case 'quarter':
      return intl.formatMessage(
        {
          id: 'dashboards.dateIndex.quarter',
        },
        { date: moment(seconds).utc().format('Q - YYYY') },
      );
    case 'year':
      return moment(seconds).utc().format('YYYY');
    case 'week':
      return intl.formatMessage(
        {
          id: 'dashboards.dateIndex.week',
        },
        { date: moment(seconds).utc().format('DD-MM-YYYY') },
      );
    case 'day':
    default:
      return moment(seconds).utc().format('DD-MM-YYYY');
  }
};

export const getRange = (dateRange, dateType, isRelative, relativeAmount) => {
  let range = {};

  if (isRelative) {
    const relativeDate = moment().subtract(relativeAmount, dateType);

    range = {
      gte: relativeDate.format('X'),
      lte: moment().format('X'),
    };
  } else {
    const [gte, lte] = dateRange;

    const gteFormated = gte.toDate();
    const lteFormated = lte.toDate();

    range = {
      gte: moment(gteFormated).startOf(dateType).format('X'),
      lte: moment(lteFormated).endOf(dateType).format('X'),
    };
  }

  return range;
};

export const camelize = obj =>
  transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);

    acc[camelKey] = isObject(value) ? camelize(value) : value;
  });
