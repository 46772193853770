import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { MenuSelector, MenuOption } from 'packages/ui';
import { organizationFetch, organizationSelect } from 'appRedux/actions';
import IntlMessages from 'util/IntlMessages';
import { actions } from 'packages/divisions';

import styles from './styles.module.less';
import NewOrganizationModal from '../NewOrganziationModal';

const OrganizationSelector = () => {
  const dispatch = useDispatch();
  const hasMounted = useRef(false);
  const history = useHistory();
  const { orgId: paramOrgId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  const selectedOrgId = useSelector(({ organizations }) => organizations.organization.id);
  const userOrganizations = useSelector(({ user }) =>
    Object.values(user.profile.data?.organizations || {}).filter(
      // Only the ones in the claims
      org => user.access.data?.claims?.org?.[org.id],
    ),
  );

  const selectOrg = id => {
    dispatch(organizationSelect(id));
    dispatch(organizationFetch(id));
    dispatch(actions.assignedDivisionsFetch(id));
  };

  const onOrganizationChange = ({ currentTarget: { value } }) => {
    selectOrg(value);
  };

  const options = userOrganizations.map(org => ({ label: org.name, value: org.id }));

  const { pathname } = useSelector(({ common }) => common);

  const replaceOrgIdPath = newOrgId => {
    // Split the pathname /orgId?/-/rest/of/url to add the orgId if its null
    let divider = '/-';
    const [, rest = ''] = pathname.split(divider);
    const newPath = `/${newOrgId}${divider}${rest}`;
    history.replace(newPath);
  };

  useEffect(() => {
    // If the paramOrgId is missing then load one
    if (!paramOrgId && selectedOrgId) {
      replaceOrgIdPath(selectedOrgId);
    }
  }, []);

  // Dev note: this effect should be running before
  // AuthRoute effect has a chance to do the history.replace
  useEffect(() => {
    // If at the moment of the mount
    if (!hasMounted.current) {
      hasMounted.current = true;
      // and if paramOrgId and selected org id are different
      // then use the paramOrgId, and run a organizationSelect action
      if (!!paramOrgId && paramOrgId !== selectedOrgId) {
        // Only select the orgId if the user has access to it
        const userHasAccessToOrg = !!userOrganizations.find(org => org.id === paramOrgId);
        if (userHasAccessToOrg) {
          selectOrg(paramOrgId);
        }
      }
    }
  }, [selectedOrgId]);

  return (
    <div>
      <MenuSelector
        footer={
          <>
            <Divider
              className="gx-my-0 gx-w-100"
              style={{ borderTop: '1px solid rgb(255 255 255 / 60%)' }}
            />
            <MenuOption as="button" className="btn" onClick={handleOpen}>
              + Add Organization
            </MenuOption>
          </>
        }
        className={styles.wrapper}
        onChange={onOrganizationChange}
        options={options}
        placeholder={<IntlMessages id="config.division.organization" />}
        value={selectedOrgId}
      />
      <NewOrganizationModal closeModal={handleClose} isOpen={isModalOpen} />
    </div>
  );
};

export default OrganizationSelector;
