import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';

import IntlMessages from 'util/IntlMessages';
import * as actions from 'packages/locations/redux/actions';
import { db } from 'firebase/firebase';
import { doc } from 'firebase/firestore';
import { useFirestoreQuery, ALLOWED_ROLES, getDivsWithAccess, getFullUrl } from 'packages/utils';
import DivisionName from 'components/DivisionName';

import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import { LOC_TYPES, PATHS } from '../../constants';

const { Item } = Form;
const { Option } = Select;

const NfcLocation = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const intl = useIntl();
  const [newDivId, setNewDivId] = useState(null);
  const { orgId, locId, divId: paramDivId } = useParams();
  const formRef = useRef();
  const fromOrganization = history.location.state?.fromOrganization || false;
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);

  const isEditing = !!locId;
  const isCreatingAtDiv = !isEditing && !fromOrganization;
  const isEditingAtDiv = isEditing && !!paramDivId;
  const divId = paramDivId || newDivId;

  const allowedDivisions = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.CREATE,
    userOrgAccess,
    userOrgDivisions,
  );

  // This Refs are to cover all 4 cases:
  // - Creating a location at: Organization & Division
  // - Editing a location at: Organization & Division
  const orgRef = doc(db, 'organizations', orgId);
  const divRef = divId && doc(orgRef, 'divisions', divId);
  const locRef = isEditing && doc(divRef || orgRef, 'locations', locId);
  const { data: locationData } = useFirestoreQuery(locRef, [orgId, divId, locId]);

  const handleGoBack = () => history.push(getFullUrl(PATHS.BASE_URL, url));
  const onFinish = ({ name, token }) => {
    const location = {
      name,
      token,
      type: LOC_TYPES.NFC,
    };
    if (divId) {
      location.divId = divId;
    }

    dispatch(actions.locationSaveFetch(orgId, divId, locId, location));
    handleGoBack();
  };
  const handleSubmit = () => formRef.current.submit();
  useEffect(() => {
    if (locationData) {
      form.setFieldsValue({
        name: locationData.name,
        token: locationData.token,
      });
    }
  }, [form, locationData]);

  return (
    <BoxContainer>
      <BoxContainer content fixed shadow>
        <FilterContainer
          goBack={() => history.push(getFullUrl(PATHS.BASE_URL, url))}
          title={
            <Title
              value={
                <IntlMessages id={isEditing ? 'locations.nfc.edit' : 'locations.nfc.create'} />
              }
            />
          }
          actionButtons={[
            {
              label: <IntlMessages id="form.save" />,
              type: 'primary',
              action: handleSubmit,
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer content loading={isEditing && !locationData}>
        <Form ref={formRef} form={form} layout="vertical" onFinish={onFinish}>
          {isCreatingAtDiv && (
            <Item
              label={intl.formatMessage({ id: 'form.division' })}
              name="divId"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'form.division.requiredError' }),
                },
              ]}
            >
              <Select
                dropdownMatchSelectWidth={false}
                onChange={id => setNewDivId(id)}
                optionFilterProp="children"
                placeholder={<IntlMessages id="form.division.placeholder" />}
                showSearch
              >
                {allowedDivisions.map(div => (
                  <Option key={div.id} value={div.id}>
                    {div.name}
                  </Option>
                ))}
              </Select>
            </Item>
          )}
          {isEditingAtDiv && <DivisionName divId={divId} />}
          <Item
            name="name"
            label={intl.formatMessage({ id: 'locations.nfc.form.name' })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'locations.nfc.form.name.requiredError',
                }),
              },
              {
                max: 180,
                message: intl.formatMessage({ id: 'form.maxLength.msg' }, { amount: 180 }),
              },
            ]}
          >
            <Input
              placeholder={intl.formatMessage({ id: 'locations.nfc.form.name.placeholder' })}
            />
          </Item>
          <Item
            name="token"
            label={intl.formatMessage({ id: 'locations.nfc.form.token' })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'locations.nfc.form.token.requiredError',
                }),
              },
            ]}
          >
            <Input
              placeholder={intl.formatMessage({ id: 'locations.nfc.form.token.placeholder' })}
            />
          </Item>
        </Form>
      </BoxContainer>
    </BoxContainer>
  );
};

export default NfcLocation;
