import React from 'react';
import { InfoCardList } from 'components/InfoCard';
import EventName from 'components/TriggersList/component/EventName';
import PropTypes from 'prop-types';
import { eventTypeSummaryPropTypes } from 'packages/utils/proptypes/eventTypes';
import BoxContainer from 'components/BoxContainer';
import useLimitList from 'packages/utils/hooks/useLimitList';
import IntlMessages from 'util/IntlMessages';
import { Button } from 'antd';

export default function EventTypeList({ data = [], onRemove, onAdd, wrapperData = false }) {
  const {
    data: dataLimited,
    seeAll,
    totalToBeOpeneable,
    onSeeToggle,
    showButton,
  } = useLimitList({ data: data, limitForMobile: 1, limitForDesktop: 2 });

  const getActions = event => {
    const output = [];
    if (onAdd) {
      output.push({
        iconButton: 'add',
        action: () => onAdd(event),
        shape: 'circle',
        type: 'link',
        size: 'large',
      });
    }
    if (onRemove) {
      output.push({ iconButton: 'delete', action: () => onRemove(event.id), shape: 'circle' });
    }

    return output;
  };

  const buttonLabel = seeAll ? (
    <>
      <IntlMessages id="button.seeLess" />
    </>
  ) : (
    <>
      {totalToBeOpeneable > 0 && (
        <>
          <IntlMessages id="button.seeMore" />
          <span className="gx-pl-1">{`(${totalToBeOpeneable})`}</span>
        </>
      )}
    </>
  );

  const records = wrapperData ? dataLimited : data;

  return (
    <BoxContainer empty={data.length === 0}>
      {records.length > 0 &&
        records.map(event => (
          <React.Fragment key={event.id}>
            <InfoCardList
              title={
                <EventName
                  eventName={event?.name}
                  eventColor={event?.color}
                  eventIcon={event?.icon}
                />
              }
              actionButtons={getActions(event)}
            />
          </React.Fragment>
        ))}
      {wrapperData && showButton && (
        <div className="gx-flex-row gx-justify-content-center gx-w-100 gx-pb-2">
          <Button className="gx-mb-0" onClick={onSeeToggle} type="link">
            {buttonLabel}
          </Button>
        </div>
      )}
    </BoxContainer>
  );
}

EventTypeList.propTypes = {
  data: PropTypes.arrayOf(eventTypeSummaryPropTypes),
  onRemove: PropTypes.func,
  onAdd: PropTypes.func,
  wrapperData: PropTypes.bool,
};
