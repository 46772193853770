import React, { useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import classNames from 'classnames';
import { Col, Row, Tabs } from 'antd';
import { useSelector } from 'react-redux';

import { auth, db } from 'firebase/firebase';
import IntlMessages from 'util/IntlMessages';
import { get } from 'packages/utils/storage';
import BoxContainer from 'components/BoxContainer';
import { collection, query } from 'firebase/firestore';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import useCloudFunction from 'packages/utils/hooks/clouds/useCloudFunction';
import useGetDivisionsAllowed from 'packages/utils/hooks/useGetDivisionsAllowed';
import { useFirestoreQueryBatched, useIsMobile, useToggle } from 'packages/utils';
import { EVENTS_BY_POSITIONS, POSITIONS_DASHBOARD } from 'constants/cloudFunctionsName';
import {
  parseEventsPositions,
  parseUsersPositions,
  POSITION_HISTORY_STATE,
  POST_HOURS_BY_DEFAULT,
} from 'packages/reports/constants';

import styles from './styles.module.less';
import UserList from '../components/UserList';
import UserHistoryMap from '../components/UserHistoryMap';
import UserPositionFilters from '../components/UserPositionFilters';
import { EventEvolutions } from '../components/EventEvolutions';
import { SpeedByUser } from '../components/SpeedByUser';

const UserPositionHistory = () => {
  const { isMobile } = useIsMobile();

  const orgId = useSelector(({ organizations }) => organizations.organization.id);
  const now = moment();
  const { allowedSelectedDivs } = useGetDivisionsAllowed();
  const positionFiltersState = get(POSITION_HISTORY_STATE);

  const [refresh, toggleRefresh] = useToggle(false);
  const [usersActived, setUsersActived] = useState([]);
  const [filters, setFilters] = useState({
    date: moment(positionFiltersState?.date) ?? now,
    hours: positionFiltersState?.hours ?? POST_HOURS_BY_DEFAULT,
  });
  const [colorByUser, setColorByUser] = useState({});
  const [posByIndex, setPosByIndex] = useState({
    chart: null,
    cx: null,
    data: {
      location: null,
      eventId: null,
    },
  });

  // NOTE: Here get organization location, otherwise we use a by default
  const LOCATION_BY_DEFAULT = [-34.5979331, -58.6396274];

  const [locationData, setLocationData] = useState({
    radious: positionFiltersState?.radious ?? 5000,
    latLng: positionFiltersState?.latLng ?? LOCATION_BY_DEFAULT,
  });

  const { data, loading } = useFirestoreQueryBatched(
    [query(collection(db, 'organizations', orgId, 'users'))],
    [orgId],
  );

  const rangeDate = [moment(filters.date).subtract(filters.hours, 'hours'), filters?.date];

  const {
    data: userPositionsData,
    loading: userPositionLoading,
    error: usersPosError,
  } = useCloudFunction({
    params: {
      organizationId: orgId,
      divisionIds: allowedSelectedDivs,
      range: {
        gte: moment(filters.date).subtract(filters.hours, 'hours')?.valueOf(),
        lte: filters?.date?.valueOf(),
      },
      geoPoint: {
        lat: locationData.latLng[0],
        lon: locationData.latLng[1],
        distance: locationData.radious,
        distance_type: 'm',
      },
    },
    functionName: POSITIONS_DASHBOARD,
    dependencies: [refresh, !loading],
    parseData: parseUsersPositions,
  });

  const {
    data: eventsPositions,
    error: eventsError,
    loading: eventsLoading,
  } = useCloudFunction({
    params: {
      uid: auth.currentUser.uid,
      organizationId: orgId,
      divisionIds: allowedSelectedDivs,
      geoPoint: {
        lat: locationData.latLng[0],
        lon: locationData.latLng[1],
        distance: locationData.radious,
        distance_type: 'm',
      },
      range: {
        gte: moment(filters.date).subtract(filters.hours, 'hours')?.valueOf(),
        lte: filters?.date?.valueOf(),
      },
    },
    dependencies: [refresh, !loading],
    functionName: EVENTS_BY_POSITIONS,
    parseData: parseEventsPositions,
  });

  const mainLoading = !!userPositionLoading || !!loading || !!eventsLoading;
  const errorsByMainApi = !!usersPosError || !!eventsError;
  const mainUsers = React.useMemo(
    () => {
      if (userPositionsData.length === 0) return [];

      return data.map(usr => {
        let output = { ...usr };
        const positionByUser =
          userPositionsData && userPositionsData.find(pos => pos.id === usr.id);
        if (positionByUser) {
          output = {
            ...output,
            ...positionByUser,
          };
        }

        return output;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mainLoading, userPositionsData, colorByUser],
  );

  const userList = React.useMemo(() => {
    let output = mainUsers || [];
    // NOTE: Adding status to know if it's active or not (to know if include color or not)
    output = output.map(usr => {
      const color = colorByUser[usr?.id] ?? '#038fde';

      return {
        ...usr,
        status: usersActived?.includes(usr.id),
        color,
      };
    });
    // NOTE: filter by geoData due to we only need to users with positions
    output = output.filter(usr => usr?.geoData);

    return output;
  }, [colorByUser, mainUsers, usersActived]);

  const hasUsers = userList?.length > 0;

  const events = useMemo(() => {
    if (userList.length === 0 || eventsPositions.length === 0) return [];
    const userWithEvents = eventsPositions
      .map(event => {
        const user = userList.find(usr => event.userId === usr.id);

        return {
          user: `${user?.firstName} ${user?.lastName}`,
          time: event?.time,
          timeAsSeconds: event?.timeAsSeconds,
          eventName: event?.eventName,
          color: event?.color,
          icon: event?.icon,
          userId: user?.id,
          status: user?.status,
          location: event?.location,
          eventId: event?.eventId,
        };
      })
      .filter(record => record.status === true);

    return userWithEvents;
  }, [eventsPositions, userList]);

  const speedUserData = useMemo(
    () =>
      userList
        .filter(record => record.status === true) // NOTE: Only selected
        .map(usr => {
          const { geoData = [], firstName, lastName, color } = usr;
          const geoDataParsed = geoData
            .map(item => ({
              ...item,
              firstName,
              lastName,
              color,
            }))
            .sort((a, b) => a.timestamp - b.timestamp)
            .map(item => ({
              ...item,
              timestamp: item.timestamp,
            }));

          return {
            firstName,
            lastName,
            geoData: geoDataParsed,
            color,
          };
        }),
    [userList],
  );

  // NOTE: Each user is actived first time
  useEffect(() => {
    if (usersActived.length > 0) return;
    const firstUsers = mainUsers.map(record => record.id);
    setUsersActived(firstUsers);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainUsers]);

  useEffect(() => {
    if (locationData && filters && !mainLoading) {
      toggleRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData?.radious, locationData?.latLng, filters?.date, filters?.hours]);

  return (
    <BoxContainer>
      <BoxContainer content fixed shadow>
        <FilterContainer
          showHide
          title={<Title value={<IntlMessages id="reports.userPositionHistory.title" />} />}
          content={
            <UserPositionFilters values={filters} onChange={setFilters} loading={mainLoading} />
          }
        />
      </BoxContainer>
      {!isMobile ? (
        <BoxContainer error={errorsByMainApi} fullScreen={!isMobile}>
          <Row gutter={8}>
            <Col sm={10} md={10} lg={10} xl={7} xxl={5}>
              <UserList
                users={userList}
                loading={mainLoading}
                onSetFilters={setUsersActived}
                onChangeColor={setColorByUser}
              />
            </Col>
            <Col sm={14} md={14} lg={14} xl={17} xxl={19}>
              <UserHistoryMap
                users={userList}
                loading={mainLoading}
                locationData={locationData}
                onLocationData={setLocationData}
                events={events}
                relationByChart={posByIndex}
              />
              <SpeedByUser
                data={speedUserData}
                className={classNames(styles.chartStyles, 'gx-guarnic-mb-2')}
                loading={mainLoading}
                hasUsers={hasUsers}
                rangeDate={rangeDate}
                onSetPos={setPosByIndex}
                posByIndex={posByIndex}
              />
              <EventEvolutions
                loading={mainLoading}
                events={events}
                className={styles.chartStyles}
                hasUsers={hasUsers}
                rangeDate={rangeDate}
                onSetPos={setPosByIndex}
              />
            </Col>
          </Row>
        </BoxContainer>
      ) : (
        <div className={styles.mainTabsMobile}>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                label: `Map`,
                key: '1',
                children: (
                  <UserHistoryMap
                    users={userList}
                    loading={mainLoading}
                    locationData={locationData}
                    onLocationData={setLocationData}
                    events={events}
                    relationByChart={posByIndex}
                  />
                ),
              },
              {
                label: 'Users',
                key: '2',
                children: (
                  <UserList
                    users={userList}
                    loading={mainLoading}
                    onSetFilters={setUsersActived}
                    onChangeColor={setColorByUser}
                  />
                ),
              },
              {
                label: 'Charts',
                key: '3',
                children: (
                  <div>
                    <SpeedByUser
                      data={speedUserData}
                      className={classNames(styles.chartStyles, 'gx-guarnic-mb-2')}
                      loading={mainLoading}
                      hasUsers={hasUsers}
                      rangeDate={rangeDate}
                      onSetPos={setPosByIndex}
                      posByIndex={posByIndex}
                    />
                    <EventEvolutions
                      loading={mainLoading}
                      events={events}
                      className={styles.chartStyles}
                      hasUsers={hasUsers}
                      rangeDate={rangeDate}
                      onSetPos={setPosByIndex}
                    />
                  </div>
                ),
              },
            ]}
          />
        </div>
      )}
    </BoxContainer>
  );
};

export default UserPositionHistory;
