export const INVITE_SEND = 'INVITE_SEND';
export const INVITE_SEND_ERROR = 'INVITE_SEND_ERROR';
export const INVITE_SEND_SUCCESS = 'INVITE_SEND_SUCCESS';
export const INVITE_RESEND = 'INVITE_RESEND';
export const INVITE_RESEND_ERROR = 'INVITE_RESEND_ERROR';
export const INVITE_RESEND_SUCCESS = 'INVITE_RESEND_SUCCESS';
export const INVITE_REVOKE = 'INVITE_REVOKE';
export const INVITE_REVOKE_ERROR = 'INVITE_REVOKE_ERROR';
export const INVITE_REVOKE_SUCCESS = 'INVITE_REVOKE_SUCCESS';

export const invitationsSend = (organizationId, invitations) => ({
  type: INVITE_SEND,
  payload: { organizationId, invitations },
});

export const invitationsSendSuccess = invitation => ({
  type: INVITE_SEND_SUCCESS,
  payload: invitation,
});

export const invitationsSendError = error => ({
  type: INVITE_SEND_ERROR,
  payload: error,
});

export const invitationResend = (organizationId, invitationId) => ({
  type: INVITE_RESEND,
  payload: { organizationId, invitationId },
});

export const invitationResendSuccess = invitation => ({
  type: INVITE_RESEND_SUCCESS,
  payload: invitation,
});

export const invitationResendError = error => ({
  type: INVITE_RESEND_ERROR,
  payload: error,
});

export const invitationRevoke = (organizationId, invitationId) => ({
  type: INVITE_REVOKE,
  payload: { organizationId, invitationId },
});

export const invitationRevokeSuccess = () => ({
  type: INVITE_REVOKE_SUCCESS,
});

export const invitationRevokeError = error => ({
  type: INVITE_REVOKE_ERROR,
  payload: error,
});
