import { combineReducers } from 'redux';

import { REDUCER_INDEX } from '../../constants';

import location from './location';

export const reducer = combineReducers({
  location,
});

export const registerReducer = state => ({
  ...state,
  [REDUCER_INDEX]: reducer,
});

export const getState = state => state[REDUCER_INDEX];
export const getStateLocation = state => state[REDUCER_INDEX].location;
