import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import { userSignOut } from 'appRedux/actions/Auth';

import styles from './styles.module.less';

const AcceptWithExistingUser = ({ inviteId, email, organization, handleAddUser }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logedUserEmail = useSelector(({ user }) => user?.profile?.data?.email);

  const invitedUserMatchesAuth = logedUserEmail === email;

  useEffect(() => {
    if (invitedUserMatchesAuth) {
      handleAddUser(organization.id, inviteId);
    }
  }, [handleAddUser, inviteId, invitedUserMatchesAuth, organization.id]);

  const handleLogout = () => dispatch(userSignOut());
  const handleLogin = () => history.push('/');

  return (
    <div>
      {!logedUserEmail && (
        <>
          <h2 className={styles.subtitle}>
            <IntlMessages id="users.invitation.existingUser.welcomeBack" values={{ email }} />
          </h2>
          <h3>
            <IntlMessages id="users.invitation.existingUser.login" />
          </h3>
          <Button type="primary" onClick={handleLogin}>
            <IntlMessages id="button.login" />
          </Button>
        </>
      )}
      {logedUserEmail && !invitedUserMatchesAuth && (
        <>
          <h2 className={styles.subtitle}>
            <IntlMessages id="users.invitation.existingUser.welcomeBack" values={{ email }} />
          </h2>
          <h3>
            <IntlMessages id="users.invitation.existingUser.logout" />
          </h3>
          <Button danger onClick={handleLogout}>
            <IntlMessages id="button.logout" />
          </Button>
        </>
      )}
      {invitedUserMatchesAuth && (
        <>
          <h2 className={styles.subtitle}>
            <IntlMessages id="users.invitation.existingUser.welcomeBack" values={{ email }} />
          </h2>
          <h2 className={styles.subtitle}>
            <IntlMessages
              id="users.invitation.existingUser.done"
              values={{ name: <span className={styles.blueText}>{organization.name}</span> }}
            />
          </h2>
        </>
      )}
    </div>
  );
};

AcceptWithExistingUser.propTypes = {
  inviteId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  organization: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  handleAddUser: PropTypes.func.isRequired,
};

export default React.memo(AcceptWithExistingUser);
