import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from 'constants/ActionTypes';

export const userResetPassword = email => ({
  type: RESET_PASSWORD,
  payload: email,
});

export const userResetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const userResetPasswordError = () => ({
  type: RESET_PASSWORD_ERROR,
});
