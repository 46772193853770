import { eventChannel } from 'redux-saga';

import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';
import { auth, db } from 'firebase/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { errorNotification } from 'appRedux/actions';
import { getDocData } from 'packages/utils';
import { SIGNOUT_USER_SUCCESS } from 'constants/ActionTypes';

import {
  ASSIGNED_DIVS_FETCH,
  assignedDivisionsFetchError,
  assignedDivisionsFetchSuccess,
} from '../actions/assigned';
import { divisionsSelect } from '../actions/selector';

let assignedDivisionsChannel = null;

function closeEventChannel() {
  if (assignedDivisionsChannel) {
    assignedDivisionsChannel.close();
  }
  assignedDivisionsChannel = null;
}

function openEventChannel(orgId) {
  closeEventChannel();
  assignedDivisionsChannel = eventChannel(emit =>
    onSnapshot(
      doc(
        db,
        'organizations',
        orgId,
        'users',
        auth.currentUser.uid || localStorage.getItem('user_id'),
      ),
      snapshot => emit(getDocData(snapshot)),
    ),
  );

  return assignedDivisionsChannel;
}

function* listenerRegister({ payload: { orgId } }) {
  const channel = yield call(openEventChannel, orgId);

  while (true) {
    try {
      // Take means the saga will block until action is dispatched
      const response = yield take(channel);
      yield put(assignedDivisionsFetchSuccess(response));
      yield put(divisionsSelect(Object.keys(response.divisions)));
    } catch (error) {
      yield put(assignedDivisionsFetchError(error));
      yield put(errorNotification(error.toString()));
    }
  }
}

export function* listenerUnregister() {
  yield call(closeEventChannel);
}

export function* assignedListenerRegister() {
  yield takeEvery(ASSIGNED_DIVS_FETCH, listenerRegister);
}

export function* assignedListenerUnregister() {
  yield takeEvery(SIGNOUT_USER_SUCCESS, listenerUnregister);
}

export default function* rootSaga() {
  yield all([fork(assignedListenerRegister), fork(assignedListenerUnregister)]);
}
