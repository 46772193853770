import { useSelector } from 'react-redux';
import { ALLOWED_ROLES, getDivsWithAccess } from '../access';

// NOTE: Permitted divisions by organization
const useGetDivisionsAllowed = () => {
  const orgId = useSelector(({ organizations }) => organizations.organization.id);
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids || []);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);

  const allowedDivs = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LIST,
    userOrgAccess,
    userOrgDivisions,
  );
  const allowedDivsIds = allowedDivs.map(d => d.id);
  const allowedSelectedDivs = selectedDivsIds.filter(divId => allowedDivsIds.includes(divId));

  return {
    allowedSelectedDivs,
  };
};

export default useGetDivisionsAllowed;
