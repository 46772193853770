import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.less';
import { Divider, Grid, Typography } from 'antd';
import classNames from 'classnames';
import { generateKey } from '../../packages/utils/functions';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const TitleLinker = ({ titleElements }) => {
  const screens = useBreakpoint();
  const containMoreOneThanElement = titleElements.length > 1;
  // Exluding the first element
  const restElement = titleElements.filter((_, idx) => idx > 0);
  return (
    <div
      className={classNames(
        styles.titleLinkerContainer,
        screens.xs ? 'gx-flex-column' : 'gx-flex-row',
      )}
    >
      <Text className={styles.mainTitle} strong>
        {titleElements[0]}
      </Text>
      {containMoreOneThanElement &&
        restElement.map(item => (
          <div key={generateKey(item)}>
            {!screens.xs && item && (
              <Divider className={styles.divider} type="vertical" orientation="center" />
            )}
            <Text className={styles.division} type="secondary">
              {item}
            </Text>
          </div>
        ))}
    </div>
  );
};

TitleLinker.propTypes = {
  titleElements: PropTypes.array.isRequired,
};

TitleLinker.defaultProps = {
  titleElements: [],
};

export default TitleLinker;
