import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { ChildrenSchema } from 'packages/utils';
import classNames from 'classnames';
import styles from './styles.module.less';

const { Title: SubTitleAnd } = Typography;

const SubTitle = ({ level = 5, text, className }) => {
  return (
    <SubTitleAnd level={level} className={classNames(className, styles.subtitle)}>
      {text}
    </SubTitleAnd>
  );
};

SubTitle.propTypes = {
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  text: PropTypes.oneOfType([ChildrenSchema, PropTypes.string]).isRequired,
  className: PropTypes.string,
};

export default SubTitle;
