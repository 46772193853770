/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { COMPONENT_TYPES } from '../../../../../eventTypes/constants';

const Select = ({ component }) => (
  <div className="gx-mt-3">
    <h4>{component.label.name}</h4>
    <div>
      {component?.selectValue?.length > 0 && component.selectValue[0] ? (
        <Radio checked>{component.selectValue[0]}</Radio>
      ) : (
        <Radio disabled>
          <IntlMessages id="eventTypes.component.withoutSelection" />
        </Radio>
      )}
    </div>
  </div>
);

Select.propTypes = {
  component: PropTypes.shape({
    componentProps: PropTypes.shape({
      multiSelect: PropTypes.bool,
      options: PropTypes.array,
    }).isRequired,
    commonProps: PropTypes.shape({ required: PropTypes.bool.isRequired }).isRequired,
    label: PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
    type: COMPONENT_TYPES.Select,
    selectValue: PropTypes.string.isRequired,
  }).isRequired,
};

export default React.memo(Select);
