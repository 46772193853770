import React from 'react';
import styles from '../styles.module.less';
import Logo from '../../Logo';
import { Button } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { useHistory } from 'react-router-dom';

const Error = ({ type }) => {
  const history = useHistory();
  const isSubscribed = type === 'subscribed';

  return (
    <div className="gx-flex-row gx-justify-content-center gx-pt-4">
      <div className="gx-flex-column gx-justify-content-center">
        <div className="gx-flex-column gx-align-items-center gx-pb-4">
          <Logo />
          <div className={styles.bodyContainer}>
            <p className={styles.bodyTitle}>
              <IntlMessages id={`subscriptions.notification.${type}.error.title`} />
            </p>
            {!isSubscribed && (
              <p className={styles.subTitle}>
                <IntlMessages id="subscriptions.notification.unsubscribed.error.subTitle" />
              </p>
            )}
          </div>
        </div>
        <div className={styles.buttonC}>
          <Button block type="primary" onClick={() => history.push('/signin')}>
            <IntlMessages id="subscriptions.notification.enter" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Error;
