import { Button, Divider, Popconfirm, notification } from 'antd';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import IntlMessages from 'util/IntlMessages';
import ComponentBlocker from 'components/ComponentBlocker';
import { ALLOWED_ROLES } from 'packages/utils';
import EventTypeIcon from 'components/EventTypeIcon';

import { deleteDoc, doc } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import { useIntl } from 'react-intl';
import styles from './styles.module.less';

const EventType = ({
  onEdit,
  eventType: { divId, color, description, icon, name, id: eventTypeId },
  onRefresh,
}) => {
  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const division = useSelector(
    ({ user }) => user.profile.data.organizations[orgId]?.divisions?.[divId]?.name,
  );
  const intl = useIntl();

  const handleDelete = useCallback(() => {
    try {
      if (!eventTypeId) throw new Error('something it is wrong');
      deleteDoc(
        doc(db, 'organizations', orgId, 'divisions', divId, 'event_types', eventTypeId),
      ).then(() => {
        notification.success({
          message: intl.formatMessage({ id: 'general.save.successful.message' }),
          placement: 'topRight',
        });
        onRefresh(prev => !prev);
      });
    } catch (error) {
      notification.error({
        message: intl.formatMessage({ id: 'general.save.error.message' }),
        placement: 'topRight',
      });
    }
  }, [divId, eventTypeId, intl, orgId, onRefresh]);

  return (
    <div className={classnames('gx-d-flex gx-mb-3', styles.mainContainer)}>
      <EventTypeIcon color={color} icon={icon} />
      <div className={styles.info}>
        <div>
          <span className={styles.boldText}>{name}</span>
          {division && (
            <>
              <Divider className={styles.divider} type="vertical" orientation="center" />
              <span className={classnames('gx-flex-1', styles.division)}>{division}</span>
            </>
          )}
        </div>
        <span className={styles.description}>{description}</span>
      </div>
      <ComponentBlocker
        divId={divId}
        allowedRoles={
          divId
            ? ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.EVENT_TYPES.UPDATE
            : ALLOWED_ROLES.ORGANIZATIONS.EVENT_TYPES.UPDATE
        }
      >
        <Button type="text" className="gx-m-0" onClick={onEdit}>
          <IntlMessages id="button.edit" />
        </Button>
      </ComponentBlocker>
      <ComponentBlocker
        divId={divId}
        allowedRoles={
          divId
            ? ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.EVENT_TYPES.DELETE
            : ALLOWED_ROLES.ORGANIZATIONS.EVENT_TYPES.DELETE
        }
      >
        <Popconfirm
          okText={<IntlMessages id="button.ok" />}
          cancelText={<IntlMessages id="button.cancel" />}
          title={<IntlMessages id="eventTypes.delete.message" />}
          onConfirm={handleDelete}
        >
          <Button danger type="text" className="gx-m-0">
            <IntlMessages id="button.delete" />
          </Button>
        </Popconfirm>
      </ComponentBlocker>
    </div>
  );
};

EventType.propTypes = {
  eventType: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    color: PropTypes.shape({
      a: PropTypes.number.isRequired,
      b: PropTypes.number.isRequired,
      g: PropTypes.number.isRequired,
      r: PropTypes.number.isRequired,
    }),
    divId: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default React.memo(EventType);
