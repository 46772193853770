import React, { useLayoutEffect, useRef, useState } from 'react';

import { Drawer, Form, Tabs } from 'antd';

import IntlMessages from 'util/IntlMessages';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';

import PropTypes from 'prop-types';
import styles from './styles.module.less';
import {
  factoryTriggerToWorkTemplate,
  factoryTriggerToWorkTemplateExisting,
  FORM_ITEM_LAYOUT,
  FORMS,
  mergeTriggers,
} from '../constants';
import ExistingTriggerToWorkTemplate from './ExistingTriggerToWorkTemplate';
import NewTriggerToWorkTemplate from './NewTriggerToWorkTemplate';

const TemplateTriggers = ({ isVisible, onCancel, divisionId, initData = [] }) => {
  const [form] = Form.useForm();
  const tabRef = useRef('existing');

  const [triggers, setTriggers] = useState([]);
  const [formRequired, setFormRequired] = useState(false);

  const onSave = async () => {
    try {
      let newTriggers;
      if (tabRef.current === 'existing') {
        newTriggers = factoryTriggerToWorkTemplateExisting(triggers);
      } else {
        try {
          await form.validateFields({ force: true });
          newTriggers = [factoryTriggerToWorkTemplate(form.getFieldsValue(), divisionId)];
        } catch (validationError) {
          return null; // Return early if validation fails
        }
      }

      const triggersMerged = mergeTriggers(triggers, newTriggers);

      form.setFieldsValue({
        triggers: triggersMerged,
      });
      form.submit();
      return null;
    } catch (_) {
      return null;
    }
  };

  const addTrigger = element => {
    const newForms = [...triggers, element];
    setTriggers(newForms);
  };
  const removeTrigger = elementId => {
    setTriggers(triggers.filter(item => item.id !== elementId));
  };

  useLayoutEffect(() => {
    if (initData) setTriggers(initData);
  }, [initData]);

  return (
    <Drawer className={styles.mainContainer} open={isVisible} closable={false} footer={null}>
      <Form {...FORM_ITEM_LAYOUT} name={FORMS.TRIGGERS} form={form}>
        <BoxContainer>
          <BoxContainer content fixed>
            <FilterContainer
              goBack={() => onCancel(false)}
              title={
                <Title.Header
                  className="gx-pt-1 gx-pb-1"
                  value={<IntlMessages id="work.template.form.template.tasks.title" />}
                />
              }
              actionButtons={[
                {
                  label: <IntlMessages id="button.save" />,
                  type: 'primary',
                  action: () => {
                    onSave();
                  },
                },
              ]}
            />
          </BoxContainer>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                label: <IntlMessages id="work.template.form.template.tasks.existing" />,
                key: 'existing',
                children: (
                  <ExistingTriggerToWorkTemplate
                    divisionId={divisionId}
                    triggers={triggers}
                    removeTrigger={removeTrigger}
                    addTrigger={addTrigger}
                  />
                ),
              },
              {
                label: <IntlMessages id="work.template.form.template.tasks.new.task" />,
                key: 'news',
                children: (
                  <NewTriggerToWorkTemplate
                    form={form}
                    divisionId={divisionId}
                    activeForm={formRequired}
                  />
                ),
              },
            ]}
            onChange={tabName => {
              tabRef.current = tabName;
              const activeNewsForm = tabName === 'news';

              setFormRequired(activeNewsForm);
              form.resetFields();
            }}
          />
        </BoxContainer>
      </Form>
    </Drawer>
  );
};

TemplateTriggers.defaultProps = {
  form: null,
  initData: [],
};

TemplateTriggers.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  divisionId: PropTypes.string.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
    submit: PropTypes.func,
  }),
  initData: PropTypes.arrayOf(PropTypes.object),
};

export default TemplateTriggers;
