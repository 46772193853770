import {
  AUTH_TOKEN_LISTENER_REGISTER,
  AUTH_TOKEN_LISTENER_UNREGISTER,
} from 'constants/ActionTypes';

export const authTokenListenerRegister = () => ({
  type: AUTH_TOKEN_LISTENER_REGISTER,
  payload: null,
});

export const authTokenListenerUnregister = () => ({
  type: AUTH_TOKEN_LISTENER_UNREGISTER,
  payload: null,
});
