import {
  ORGANIZATION_FETCH,
  ORGANIZATION_FETCH_ERROR,
  ORGANIZATION_FETCH_SUCCESS,
  ORGANIZATION_SAVE_FETCH,
  ORGANIZATION_SAVE_FETCH_ERROR,
  ORGANIZATION_SAVE_FETCH_SUCCESS,
  ORGANIZATION_SELECT,
} from 'constants/ActionTypes';

const getInitialState = () => ({
  data: null,
  error: null,
  id: null,
  loaded: false,
  loading: false,
  save: {
    data: null,
    error: null,
    id: null,
    loaded: false,
    loading: false,
  },
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case ORGANIZATION_SELECT: {
      return {
        ...state,
        data: null,
        error: null,
        id: action.payload,
        loaded: false,
        loading: false,
      };
    }
    case ORGANIZATION_FETCH: {
      return {
        ...state,
        data: null,
        error: null,
        id: action.payload,
        loaded: false,
        loading: true,
      };
    }
    case ORGANIZATION_FETCH_ERROR: {
      return {
        ...state,
        data: null,
        error: action.payload,
        loaded: true,
        loading: false,
      };
    }
    case ORGANIZATION_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loaded: true,
        loading: false,
      };
    }

    case ORGANIZATION_SAVE_FETCH: {
      return {
        ...state,
        save: {
          data: null,
          error: null,
          id: action.payload.organizationId,
          loaded: false,
          loading: true,
          submitedData: action.payload.data,
        },
      };
    }
    case ORGANIZATION_SAVE_FETCH_ERROR: {
      return {
        ...state,
        save: {
          data: null,
          error: action.payload,
          loaded: true,
          loading: false,
        },
      };
    }
    case ORGANIZATION_SAVE_FETCH_SUCCESS: {
      return {
        ...state,
        data: { ...state.data, ...state.save.submitedData },
        id: state.id || action.payload.id,
        save: {
          data: action.payload,
          id: action.payload.id,
          loaded: true,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};
