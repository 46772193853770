import React, { useEffect } from 'react';
import { generatePath, Redirect, Route, useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { USER_ROLES, hasAnyAllowedRole } from 'packages/utils';

const AuthRoute = ({ exact, path, component, allowedRoles, onOrgChangeRedirectTo }) => {
  const { orgId: paramOrgId } = useParams();
  const history = useHistory();
  const loadingAccess = useSelector(({ user }) => user.access.loading);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[paramOrgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
  const selectedOrgId = useSelector(({ organizations }) => organizations.organization.id);

  const hasAccess = hasAnyAllowedRole(allowedRoles, userOrgAccess, userOrgDivisions);

  useEffect(() => {
    if (onOrgChangeRedirectTo && paramOrgId !== selectedOrgId) {
      // Ideally this should only run when the ids are different, because the
      // selected org changed by the call of OrganizationSelector.
      const path = generatePath(onOrgChangeRedirectTo, { orgId: selectedOrgId });
      history.replace(path);
    }
  }, [selectedOrgId]);

  return (
    <Route
      exact={exact}
      path={path}
      component={hasAccess || loadingAccess ? component : () => Redirect({ to: '/' })}
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  path: PropTypes.string.isRequired,
  onOrgChangeRedirectTo: PropTypes.string.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(USER_ROLES))),
  exact: PropTypes.bool,
};

AuthRoute.defaultProps = {
  exact: false,
  allowedRoles: null,
  divId: null,
};

export default React.memo(AuthRoute);
