import { useEffect, useCallback, useRef, useReducer } from 'react';

import { query, startAfter, limit, onSnapshot } from 'firebase/firestore';

import getDocData from '../../firebase/getDocData';

import {
  realtimePaginationFirstFetch,
  realtimePaginationFirstFetchError,
  realtimePaginationNextFetch,
  realtimePaginationNextFetchError,
  realtimePaginationFetchBatchSuccess,
  realtimePaginationReset,
} from './actions';
import { getInitialState, reducer } from './reducer';

const DEFAULT_PAGE_SIZE = 10;

// This hook helps to subscribe to data in your Firestore database in multiple queries without having to worry
// about state management and pagination. Instead of calling Firestore's onSnapshot(query(...), () => {}) in a loop
// you simply pass a query list to useFirestoreRealtimeBatchedPagination() and you get back everything you need,
// including loaded, loading, data, and error.
// Your component will re-render when data changes and your subscription will be
// automatically removed when the component unmounts.
// use it like so:
// const { data, error, loading, loaded, next } = useFirestoreRealtimeBatchedPagination(queryRef[], dependencies, pageSize);
// where next is a callback action that you can trigger when you need the next page

// ### YOU MIGHT NEED AN INDEX TO USE THIS ON PRODUCTION ! ###
const useFirestoreRealtimeBatchedPagination = (
  queryRefList,
  dependencies,
  pageSize = DEFAULT_PAGE_SIZE,
) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const stateRef = useRef();
  const lastQueryByBatchRef = useRef({});
  const unsubscribesRef = useRef([]);
  stateRef.current = state;
  const loading = Object.values(stateRef.current.loadingByBatch).some(i => i);
  const loaded = Object.values(stateRef.current.loadedByBatch).every(i => i);

  const unsubscribe = () => {
    unsubscribesRef.current.forEach(unsub => {
      unsub();
    });
    unsubscribesRef.current = [];
  };

  const onNextFetchError = useCallback(
    error => dispatch(realtimePaginationNextFetchError(error)),
    [dispatch],
  );
  const onNextFetchBatchSuccess = useCallback(
    (batchId, lastDocument, data) =>
      dispatch(realtimePaginationFetchBatchSuccess(batchId, lastDocument, data)),
    [dispatch],
  );

  const getNextFetch = useCallback(() => {
    if (!loading) {
      const lastDocs = stateRef.current.lastDocumentByBatch;
      const lastDocsBatchIds = Object.keys(lastDocs).filter(i => !!lastDocs[i]);

      dispatch(realtimePaginationNextFetch(lastDocsBatchIds));
      lastDocsBatchIds.forEach(batchId => {
        const currentLastDoc = stateRef.current.lastDocumentByBatch[batchId];
        const currentLastQuery = lastQueryByBatchRef.current[batchId];

        const newQuery = query(currentLastQuery, startAfter(currentLastDoc), limit(pageSize));
        lastQueryByBatchRef.current[batchId] = newQuery;

        const unsub = onSnapshot(
          newQuery,
          snapshot => {
            const lastDoc = snapshot.docs[snapshot.size - 1];
            const docs = {};
            snapshot.docs.forEach(i => {
              const doc = getDocData(i);
              docs[doc.id] = doc;
            });
            onNextFetchBatchSuccess(batchId, lastDoc, docs);
          },
          onNextFetchError,
        );
        unsubscribesRef.current.push(unsub);
      });
    }
  }, [loading, pageSize, onNextFetchBatchSuccess, onNextFetchError]);

  const onFirstFetchError = useCallback(
    error => dispatch(realtimePaginationFirstFetchError(error)),
    [dispatch],
  );
  const onFirstFetchBatchSuccess = useCallback(
    (batchId, lastDocument, data) =>
      dispatch(realtimePaginationFetchBatchSuccess(batchId, lastDocument, data, getNextFetch)),
    [dispatch, getNextFetch],
  );

  const getFirstFetch = useCallback(() => {
    dispatch(realtimePaginationFirstFetch(queryRefList.length));

    lastQueryByBatchRef.current = {};
    queryRefList.forEach((currentQry, idx) => {
      const newQuery = query(currentQry, limit(pageSize));
      lastQueryByBatchRef.current[idx] = newQuery;

      const unsub = onSnapshot(
        newQuery,
        snapshot => {
          const lastDoc = snapshot.docs[snapshot.size - 1];
          const docs = {};
          snapshot.docs.forEach(i => {
            const doc = getDocData(i);
            docs[doc.id] = doc;
          });
          onFirstFetchBatchSuccess(idx, lastDoc, docs);
        },
        onFirstFetchError,
      );
      unsubscribesRef.current.push(unsub);
    });
  }, [dispatch, onFirstFetchError, onFirstFetchBatchSuccess, pageSize, queryRefList]);

  useEffect(() => {
    // If the dependencies changes, then reset
    unsubscribe();
    dispatch(realtimePaginationReset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  useEffect(() => {
    if (!queryRefList.length) {
      return;
    }
    if (!loaded && !loading) {
      getFirstFetch();
    }
  }, [dispatch, getFirstFetch, loaded, loading, queryRefList]);

  // Unmount Effect
  useEffect(() => unsubscribe, []);

  const data = [];
  const currentState = stateRef.current;
  Object.values(currentState.dataByBatch).map(batch =>
    Object.values(batch).map(item => data.push(item)),
  );
  const shortState = {
    data,
    error: currentState.error,
    gotNewData: Object.values(currentState.gotNewDataByBatch).some(i => i),
    loaded: Object.values(currentState.loadedByBatch).every(i => i),
    loading: Object.values(currentState.loadingByBatch).some(i => i),
    next: currentState.next,
  };

  return shortState;
};

export default useFirestoreRealtimeBatchedPagination;
