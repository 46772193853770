// material-design icons

export const ICON_MAP = {
  search: { en: ['Search', 'Find', 'Lookup'], es: ['Buscar', 'Encontrar', 'Consultar'] },
  home: { en: ['Home', 'House'], es: ['Casa', 'Hogar'] },
  menu: { en: ['Menu', 'List', 'Options'], es: ['Menú', 'Lista', 'Opciones'] },
  close: { en: ['Close', 'Exit'], es: ['Cerrar', 'Salir'] },
  settings: { en: ['Settings', 'Configure'], es: ['Configuraciones', 'Configurar'] },
  done: { en: ['Done', 'Complete', 'Finished'], es: ['Hecho', 'Completo', 'Terminado'] },
  expand_more: { en: ['Expand', 'More', 'Enlarge'], es: ['Expandir', 'Más', 'Ampliar'] },
  check_circle: {
    en: ['Check', 'Approved', 'Verified'],
    es: ['Verificar', 'Aprobado', 'Confirmado'],
  },
  favorite: { en: ['Favorite', 'Like', 'Heart'], es: ['Favorito', 'Me Gusta', 'Corazón'] },
  add: { en: ['Add', 'New', 'Create'], es: ['Agregar', 'Nuevo', 'Crear'] },
  delete: { en: ['Delete', 'Remove', 'Erase'], es: ['Eliminar', 'Remover', 'Borrar'] },
  arrow_back: { en: ['Back', 'Return'], es: ['Atrás', 'Regresar'] },
  star: { en: ['Star', 'Favorite', 'Rate'], es: ['Estrella', 'Favorito', 'Calificar'] },
  chevron_right: { en: ['Right', 'Next'], es: ['Derecha', 'Siguiente'] },
  logout: { en: ['Logout', 'Sign Out'], es: ['Cerrar Sesión', 'Salir'] },
  arrow_forward_ios: { en: ['Forward', 'Next'], es: ['Adelante', 'Siguiente'] },
  add_circle: { en: ['Add', 'New', 'Create'], es: ['Agregar', 'Nuevo', 'Crear'] },
  cancel: { en: ['Cancel', 'Abort'], es: ['Cancelar', 'Abortar'] },
  arrow_back_ios: { en: ['Back', 'Previous'], es: ['Atrás', 'Previo'] },
  arrow_forward: { en: ['Forward', 'Next'], es: ['Adelante', 'Siguiente'] },
  arrow_drop_down: { en: ['Dropdown', 'Menu', 'Options'], es: ['Desplegar', 'Menú', 'Opciones'] },
  more_vert: { en: ['More', 'Options'], es: ['Más', 'Opciones'] },
  check: { en: ['Check', 'Verify'], es: ['Verificar', 'Comprobar'] },
  check_box: { en: ['Checkbox', 'Select'], es: ['Casilla', 'Seleccionar'] },
  toggle_on: { en: ['Toggle On', 'Enable'], es: ['Activar', 'Habilitar'] },
  grade: { en: ['Grade', 'Rate', 'Score'], es: ['Calificación', 'Puntuar', 'Puntuación'] },
  open_in_new: { en: ['Open', 'New Window'], es: ['Abrir', 'Nueva Ventana'] },
  check_box_outline_blank: { en: ['Checkbox', 'Unselected'], es: ['Casilla', 'No seleccionado'] },
  refresh: { en: ['Refresh', 'Reload', 'Update'], es: ['Refrescar', 'Recargar', 'Actualizar'] },
  login: { en: ['Login', 'Sign in', 'Enter'], es: ['Iniciar sesión', 'Entrar'] },
  chevron_left: { en: ['Left', 'Back', 'Previous'], es: ['Izquierda', 'Atrás', 'Previo'] },
  expand_less: { en: ['Collapse', 'Less'], es: ['Colapsar', 'Menos'] },
  radio_button_unchecked: { en: ['Unchecked', 'Off'], es: ['No marcado', 'Apagado'] },
  more_horiz: { en: ['More', 'Options'], es: ['Más', 'Opciones'] },
  apps: { en: ['Apps', 'Applications', 'Menu'], es: ['Aplicaciones', 'Apps', 'Menú'] },
  arrow_right_alt: { en: ['Right', 'Next'], es: ['Derecha', 'Siguiente'] },
  download: { en: ['Download', 'Get'], es: ['Descargar', 'Obtener'] },
  radio_button_checked: { en: ['Checked', 'On'], es: ['Marcado', 'Encendido'] },
  remove: { en: ['Remove', 'Delete'], es: ['Remover', 'Eliminar'] },
  bolt: { en: ['Bolt', 'Lightning', 'Fast'], es: ['Rayo', 'Relámpago', 'Rápido'] },
  toggle_off: { en: ['Toggle Off', 'Disable'], es: ['Desactivar', 'Apagar'] },
  arrow_upward: { en: ['Up', 'Rise'], es: ['Arriba', 'Subir'] },
  filter_list: { en: ['Filter', 'Sort'], es: ['Filtrar', 'Ordenar'] },
  delete_forever: {
    en: ['Delete', 'Remove Forever'],
    es: ['Eliminar definitivamente', 'Remover para siempre'],
  },
  autorenew: {
    en: ['Auto-Renew', 'Refresh', 'Update'],
    es: ['Auto-Renovar', 'Actualizar', 'Refrescar'],
  },
  key: { en: ['Key', 'Access'], es: ['Llave', 'Acceso'] },
  arrow_downward: { en: ['Down', 'Fall'], es: ['Abajo', 'Bajar'] },
  sync: { en: ['Sync', 'Update'], es: ['Sincronizar', 'Actualizar'] },
  block: { en: ['Block', 'Stop'], es: ['Bloquear', 'Detener'] },
  sort: { en: ['Sort', 'Order'], es: ['Ordenar', 'Clasificar'] },
  add_box: { en: ['Add', 'New', 'Create'], es: ['Agregar', 'Nuevo', 'Crear'] },
  arrow_back_ios_new: { en: ['Back', 'Previous'], es: ['Atrás', 'Previo'] },
  restart_alt: { en: ['Restart', 'Reload'], es: ['Reiniciar', 'Recargar'] },
  shopping_cart_checkout: { en: ['Checkout', 'Buy'], es: ['Pagar', 'Comprar'] },
  menu_open: { en: ['Menu', 'Open'], es: ['Menú', 'Abrir'] },
  expand_circle_down: { en: ['Expand', 'Open'], es: ['Expandir', 'Abrir'] },
  backspace: { en: ['Backspace', 'Delete'], es: ['Retroceso', 'Eliminar'] },
  undo: { en: ['Undo', 'Revert'], es: ['Deshacer', 'Revertir'] },
  arrow_circle_right: { en: ['Right', 'Next'], es: ['Derecha', 'Siguiente'] },
  done_all: { en: ['Complete', 'All', 'Done'], es: ['Completado', 'Todo', 'Hecho'] },
  arrow_right: { en: ['Right', 'Forward', 'Next'], es: ['Derecha', 'Adelante', 'Siguiente'] },
  do_not_disturb_on: { en: ['Busy', 'Do Not Disturb'], es: ['Ocupado', 'No molestar'] },
  open_in_full: { en: ['Expand', 'Full Screen'], es: ['Expandir', 'Pantalla completa'] },
  manage_search: { en: ['Manage', 'Search'], es: ['Administrar', 'Buscar'] },
  double_arrow: { en: ['Double Arrow', 'Continue'], es: ['Doble flecha', 'Continuar'] },
  sync_alt: { en: ['Sync', 'Refresh'], es: ['Sincronizar', 'Refrescar'] },
  zoom_in: { en: ['Zoom In', 'Enlarge'], es: ['Acercar', 'Agrandar'] },
  done_outline: { en: ['Done', 'Complete'], es: ['Hecho', 'Completo'] },
  drag_indicator: { en: ['Drag', 'Move'], es: ['Arrastrar', 'Mover'] },
  fullscreen: { en: ['Fullscreen', 'Expand'], es: ['Pantalla completa', 'Expandir'] },
  keyboard_double_arrow_right: { en: ['Next', 'Forward'], es: ['Siguiente', 'Adelante'] },
  star_half: { en: ['Half Star', 'Rating'], es: ['Media estrella', 'Calificación'] },
  ios_share: { en: ['Share', 'Distribute'], es: ['Compartir', 'Distribuir'] },
  settings_accessibility: {
    en: ['Settings', 'Accessibility'],
    es: ['Configuraciones', 'Accesibilidad'],
  },
  arrow_drop_up: { en: ['Arrow Up', 'Rise'], es: ['Flecha arriba', 'Subir'] },
  reply: { en: ['Reply', 'Respond'], es: ['Responder'] },
  exit_to_app: { en: ['Exit', 'Logout'], es: ['Salir', 'Cerrar sesión'] },
  unfold_more: { en: ['Expand', 'More'], es: ['Expandir', 'Más'] },
  library_add: { en: ['Library', 'Add'], es: ['Biblioteca', 'Agregar'] },
  cached: { en: ['Refresh', 'Update'], es: ['Refrescar', 'Actualizar'] },
  terminal: { en: ['Terminal', 'Console'], es: ['Terminal', 'Consola'] },
  change_circle: { en: ['Change', 'Modify'], es: ['Cambiar', 'Modificar'] },
  disabled_by_default: { en: ['Disabled', 'Off'], es: ['Deshabilitado', 'Apagado'] },
  swap_horiz: { en: ['Swap', 'Horizontal'], es: ['Intercambiar', 'Horizontal'] },
  swap_vert: { en: ['Swap', 'Vertical'], es: ['Intercambiar', 'Vertical'] },
  close_fullscreen: {
    en: ['Close', 'Exit Fullscreen'],
    es: ['Cerrar', 'Salir de pantalla completa'],
  },
  app_registration: { en: ['App', 'Register'], es: ['Aplicación', 'Registrar'] },
  download_for_offline: { en: ['Download', 'Offline'], es: ['Descargar', 'Sin conexión'] },
  arrow_circle_left: { en: ['Left', 'Back'], es: ['Izquierda', 'Atrás'] },
  arrow_circle_up: { en: ['Up', 'Raise'], es: ['Arriba', 'Elevar'] },
  file_open: { en: ['Open', 'File'], es: ['Abrir', 'Archivo'] },
  minimize: { en: ['Minimize', 'Reduce'], es: ['Minimizar', 'Reducir'] },
  dataset: { en: ['Data', 'Information'], es: ['Datos', 'Información'] },
  open_with: { en: ['Open', 'Application'], es: ['Abrir con', 'Aplicación'] },
  keyboard_double_arrow_left: { en: ['Back', 'Previous'], es: ['Atrás', 'Previo'] },
  add_task: { en: ['Add', 'Task'], es: ['Agregar', 'Tarea'] },
  start: { en: ['Start', 'Begin'], es: ['Iniciar', 'Comenzar'] },
  keyboard_double_arrow_down: { en: ['Down', 'Collapse'], es: ['Abajo', 'Colapsar'] },
  keyboard_voice: { en: ['Voice', 'Speak'], es: ['Voz', 'Hablar'] },
  create_new_folder: { en: ['Create', 'New Folder'], es: ['Crear', 'Nueva carpeta'] },
  forward: { en: ['Forward', 'Send'], es: ['Adelante', 'Enviar'] },
  downloading: { en: ['Downloading', 'Receiving'], es: ['Descargando'] },
  settings_applications: {
    en: ['Settings', 'Applications'],
    es: ['Configuraciones', 'Aplicaciones'],
  },
  compare_arrows: { en: ['Compare', 'Arrows'], es: ['Comparar', 'Flechas'] },
  redo: { en: ['Redo', 'Repeat'], es: ['Rehacer', 'Repetir'] },
  publish: { en: ['Publish', 'Post'], es: ['Publicar'] },
  zoom_out: { en: ['Zoom Out', 'Reduce'], es: ['Alejar'] },
  arrow_left: { en: ['Left', 'Back'], es: ['Izquierda', 'Atrás'] },
  html: { en: ['HTML', 'Web', 'Code'], es: ['HTML', 'Web', 'Código'] },
  token: { en: ['Token', 'Access', 'Key'], es: ['Token', 'Acceso', 'Clave'] },
  switch_access_shortcut: {
    en: ['Switch', 'Access', 'Shortcut'],
    es: ['Cambiar', 'Acceso', 'Atajo'],
  },
  fullscreen_exit: { en: ['Exit', 'Fullscreen'], es: ['Salir', 'Pantalla completa'] },
  arrow_circle_down: { en: ['Down', 'Download'], es: ['Abajo', 'Descargar'] },
  sort_by_alpha: { en: ['Sort', 'Alphabet'], es: ['Ordenar', 'Alfabeto'] },
  delete_sweep: { en: ['Delete', 'Clean'], es: ['Eliminar', 'Limpiar'] },
  indeterminate_check_box: { en: ['Checkbox', 'Indeterminate'], es: ['Casilla', 'Indeterminado'] },
  first_page: { en: ['First', 'Page'], es: ['Primero', 'Página'] },
  view_timeline: { en: ['Timeline', 'View'], es: ['Cronología', 'Vista'] },
  keyboard_double_arrow_up: { en: ['Up', 'Keyboard'], es: ['Arriba', 'Teclado'] },
  settings_backup_restore: { en: ['Settings', 'Restore'], es: ['Configuraciones', 'Restaurar'] },
  assistant_navigation: { en: ['Assistant', 'Navigation'], es: ['Asistente', 'Navegación'] },
  arrow_drop_down_circle: { en: ['Dropdown', 'Menu'], es: ['Desplegable', 'Menú'] },
  sync_problem: { en: ['Sync', 'Error'], es: ['Sincronizar', 'Error'] },
  clear_all: { en: ['Clear', 'All'], es: ['Limpiar', 'Todo'] },
  density_medium: { en: ['Density', 'Medium'], es: ['Densidad', 'Medio'] },
  filter_alt_off: { en: ['Filter', 'Off'], es: ['Filtrar', 'Apagar'] },
  expand: { en: ['Expand', 'Enlarge'], es: ['Expandir', 'Agrandar'] },
  last_page: { en: ['Last', 'Page'], es: ['Último', 'Página'] },
  subdirectory_arrow_right: { en: ['Next', 'Directory'], es: ['Siguiente', 'Directorio'] },
  unfold_less: { en: ['Collapse', 'Less'], es: ['Colapsar', 'Menos'] },
  arrow_outward: { en: ['Outward', 'Expand'], es: ['Hacia afuera', 'Expandir'] },
  download_done: { en: ['Download', 'Complete'], es: ['Descargar', 'Completo'] },
  123: { en: ['123', 'numbers', 'digits'], es: ['123', 'números', 'dígitos'] },
  swipe_left: { en: ['swipe', 'left', 'slide'], es: ['deslizar', 'izquierda', 'deslizamiento'] },
  saved_search: { en: ['saved', 'search', 'find'], es: ['guardado', 'buscar', 'encontrar'] },
  system_update_alt: {
    en: ['system', 'update', 'upgrade'],
    es: ['sistema', 'actualización', 'mejora'],
  },
  search_off: { en: ['search', 'off', 'disable'], es: ['buscar', 'apagar', 'deshabilitar'] },
  maximize: { en: ['maximize', 'expand', 'full'], es: ['maximizar', 'expandir', 'completo'] },
  javascript: { en: ['javascript', 'code', 'language'], es: ['javascript', 'código', 'lenguaje'] },
  output: { en: ['output', 'result', 'outcome'], es: ['salida', 'resultado', 'producto'] },
  swipe_up: { en: ['swipe', 'up', 'slide'], es: ['deslizar', 'arriba', 'deslizamiento'] },
  fit_screen: { en: ['fit', 'screen', 'adjust'], es: ['ajustar', 'pantalla', 'adaptar'] },
  select_all: { en: ['select', 'all', 'choose'], es: ['seleccionar', 'todo', 'elegir'] },
  dynamic_form: {
    en: ['dynamic', 'form', 'interactive'],
    es: ['dinámico', 'formulario', 'interactivo'],
  },
  hide_source: { en: ['hide', 'source', 'conceal'], es: ['ocultar', 'fuente', 'oculto'] },
  swipe_right: { en: ['swipe', 'right', 'slide'], es: ['deslizar', 'derecha', 'deslizamiento'] },
  switch_access_shortcut_add: {
    en: ['Switch', 'Access', 'Shortcut', 'Add'],
    es: ['Cambiar', 'Acceso', 'Atajo', 'Agregar'],
  },
  browse_gallery: { en: ['Browse', 'Gallery', 'View'], es: ['Navegar', 'Galería', 'Ver'] },
  density_small: { en: ['Density', 'Small', 'Compact'], es: ['Densidad', 'Pequeño', 'Compacto'] },
  css: { en: ['CSS', 'Stylesheet'], es: ['CSS', 'Hoja de estilos'] },
  assistant_direction: {
    en: ['Assistant', 'Direction', 'Guide'],
    es: ['Asistente', 'Dirección', 'Guía'],
  },
  move_up: { en: ['Move', 'Up', 'Raise'], es: ['Mover', 'Arriba', 'Elevar'] },
  file_download_done: { en: ['File', 'Download', 'Done'], es: ['Archivo', 'Descargar', 'Hecho'] },
  youtube_searched_for: {
    en: ['YouTube', 'Search', 'Find'],
    es: ['YouTube', 'Buscar', 'Encontrar'],
  },
  swap_horizontal_circle: {
    en: ['Swap', 'Horizontal', 'Exchange'],
    es: ['Intercambiar', 'Horizontal', 'Cambiar'],
  },
  data_thresholding: { en: ['Data', 'Threshold', 'Limit'], es: ['Datos', 'Umbral', 'Límite'] },
  install_mobile: { en: ['Install', 'Mobile', 'Setup'], es: ['Instalar', 'Móvil', 'Configurar'] },
  abc: { en: ['ABC', 'Alphabet', 'Letters'], es: ['ABC', 'Alfabeto', 'Letras'] },
  dataset_linked: {
    en: ['Dataset', 'Linked', 'Connected'],
    es: ['Conjunto de datos', 'Vinculado', 'Conectado'],
  },
  move_down: { en: ['Move', 'Down', 'Lower'], es: ['Mover', 'Abajo', 'Bajar'] },
  restore_from_trash: {
    en: ['Restore', 'Trash', 'Recover'],
    es: ['Restaurar', 'Papelera', 'Recuperar'],
  },
  install_desktop: {
    en: ['Install', 'Desktop', 'Setup'],
    es: ['Instalar', 'Escritorio', 'Configurar'],
  },
  keyboard_command_key: { en: ['Keyboard', 'Command', 'Key'], es: ['Teclado', 'Comando', 'Tecla'] },
  view_kanban: { en: ['View', 'Kanban', 'Organize'], es: ['Ver', 'Kanban', 'Organizar'] },
  reply_all: { en: ['Reply', 'All', 'Respond'], es: ['Responder', 'Todos'] },
  person: { en: ['Person', 'User', 'Profile'], es: ['Persona', 'Usuario', 'Perfil'] },
  group: { en: ['Group', 'Team', 'Users'], es: ['Grupo', 'Equipo', 'Usuarios'] },
  share: { en: ['Share', 'Distribute', 'Send'], es: ['Compartir', 'Distribuir', 'Enviar'] },
  thumb_up: { en: ['Thumb', 'Up', 'Like'], es: ['Pulgar', 'Arriba', 'Me gusta'] },
  groups: { en: ['Groups', 'Communities', 'Teams'], es: ['Grupos', 'Comunidades', 'Equipos'] },
  public: { en: ['Public', 'Open', 'Community'], es: ['Público', 'Abierto', 'Comunidad'] },
  person_add: { en: ['Person', 'Add', 'Join'], es: ['Persona', 'Agregar', 'Unirse'] },
  handshake: {
    en: ['Handshake', 'Agreement', 'Deal'],
    es: ['Apretón de manos', 'Acuerdo', 'Trato'],
  },
  support_agent: { en: ['Support', 'Agent', 'Help'], es: ['Soporte', 'Agente', 'Ayuda'] },
  face: { en: ['Face', 'Person', 'User'], es: ['Cara', 'Persona', 'Usuario'] },
  sentiment_satisfied: {
    en: ['Sentiment', 'Satisfied', 'Happy'],
    es: ['Sentimiento', 'Satisfecho', 'Feliz'],
  },
  rocket_launch: { en: ['Rocket', 'Launch', 'Start'], es: ['Cohete', 'Lanzamiento', 'Inicio'] },
  group_add: { en: ['Group', 'Add', 'Join'], es: ['Grupo', 'Agregar', 'Unirse'] },
  workspace_premium: {
    en: ['Workspace', 'Premium', 'Exclusive'],
    es: ['Espacio de trabajo', 'Premium', 'Exclusivo'],
  },
  psychology: {
    en: ['Psychology', 'Mind', 'Behavior'],
    es: ['Psicología', 'Mente', 'Comportamiento'],
  },
  diversity_3: {
    en: ['Diversity', 'Inclusion', 'Variety'],
    es: ['Diversidad', 'Inclusión', 'Variedad'],
  },
  emoji_objects: { en: ['Emoji', 'Objects', 'Items'], es: ['Emoji', 'Objetos', 'Elementos'] },
  water_drop: { en: ['Water', 'Drop', 'Liquid'], es: ['Agua', 'Gota', 'Líquido'] },
  eco: { en: ['Eco', 'Nature', 'Environment'], es: ['Eco', 'Naturaleza', 'Medio ambiente'] },
  pets: { en: ['Pets', 'Animals', 'Companions'], es: ['Mascotas', 'Animales', 'Compañeros'] },
  travel_explore: {
    en: ['Travel', 'Explore', 'Adventure'],
    es: ['Viajar', 'Explorar', 'Aventura'],
  },
  mood: { en: ['Mood', 'Feeling', 'Emotion'], es: ['Humor', 'Sensación', 'Emoción'] },
  quiz: { en: ['Quiz', 'Test', 'Examination'], es: ['Cuestionario', 'Test', 'Examen'] },
  health_and_safety: {
    en: ['Health', 'Safety', 'Wellbeing'],
    es: ['Salud', 'Seguridad', 'Bienestar'],
  },
  sentiment_dissatisfied: {
    en: ['Sentiment', 'Dissatisfied', 'Unhappy'],
    es: ['Sentimiento', 'Insatisfecho', 'Infeliz'],
  },
  sentiment_very_satisfied: {
    en: ['Sentiment', 'Very Satisfied', 'Happy'],
    es: ['Sentimiento', 'Muy Satisfecho', 'Feliz'],
  },
  military_tech: { en: ['Military', 'Tech', 'Defense'], es: ['Militar', 'Tecnología', 'Defensa'] },
  thumb_down: { en: ['Thumb', 'Down', 'Dislike'], es: ['Pulgar', 'Abajo', 'No me gusta'] },
  gavel: { en: ['Gavel', 'Law', 'Justice'], es: ['Mazo', 'Ley', 'Justicia'] },
  recycling: {
    en: ['Recycling', 'Environment', 'Sustainability'],
    es: ['Reciclaje', 'Medio Ambiente', 'Sostenibilidad'],
  },
  diamond: { en: ['Diamond', 'Gem', 'Luxury'], es: ['Diamante', 'Gema', 'Lujo'] },
  monitor_heart: { en: ['Monitor', 'Heart', 'Health'], es: ['Monitor', 'Corazón', 'Salud'] },
  emoji_people: { en: ['Emoji', 'People', 'Faces'], es: ['Emoji', 'Gente', 'Caras'] },
  diversity_1: {
    en: ['Diversity', 'Variety', 'Inclusion'],
    es: ['Diversidad', 'Variedad', 'Inclusión'],
  },
  workspaces: {
    en: ['Workspaces', 'Offices', 'Rooms'],
    es: ['Espacios de trabajo', 'Oficinas', 'Habitaciones'],
  },
  vaccines: {
    en: ['Vaccines', 'Immunization', 'Health'],
    es: ['Vacunas', 'Inmunización', 'Salud'],
  },
  compost: { en: ['Compost', 'Organic', 'Waste'], es: ['Composta', 'Orgánico', 'Residuos'] },
  forest: { en: ['Forest', 'Trees', 'Nature'], es: ['Bosque', 'Árboles', 'Naturaleza'] },
  recommend: { en: ['Recommend', 'Suggest', 'Advise'], es: ['Recomendar', 'Sugerir', 'Aconsejar'] },
  waving_hand: { en: ['Waving', 'Hand', 'Hello'], es: ['Saludar', 'Mano', 'Hola'] },
  person_remove: { en: ['Person', 'Remove', 'Delete'], es: ['Persona', 'Eliminar', 'Borrar'] },
  wc: { en: ['Water Closet', 'Toilet', 'Restroom'], es: ['Servicio', 'Baño'] },
  sentiment_neutral: {
    en: ['Sentiment', 'Neutral', 'Okay'],
    es: ['Sentimiento', 'Neutral', 'Okay'],
  },
  medication: { en: ['Medication', 'Drugs', 'Pharmacy'], es: ['Medicación', 'Drogas', 'Farmacia'] },
  group_work: { en: ['Group', 'Work', 'Team'], es: ['Grupo', 'Trabajo', 'Equipo'] },
  sentiment_very_dissatisfied: {
    en: ['Sentiment', 'Very Dissatisfied', 'Unhappy'],
    es: ['Sentimiento', 'Muy Insatisfecho', 'Infeliz'],
  },
  diversity_2: {
    en: ['Diversity', 'Inclusion', 'Community'],
    es: ['Diversidad', 'Inclusión', 'Comunidad'],
  },
  front_hand: { en: ['Front', 'Hand', 'Palm'], es: ['Frente', 'Mano', 'Palma'] },
  psychology_alt: { en: ['Psychology', 'Mind', 'Study'], es: ['Psicología', 'Mente', 'Estudio'] },
  cruelty_free: { en: ['Cruelty', 'Free', 'Ethical'], es: ['Sin Crueldad', 'Ético'] },
  man: { en: ['Man', 'Male', 'Person'], es: ['Hombre', 'Masculino', 'Persona'] },
  medical_information: {
    en: ['Medical', 'Information', 'Health'],
    es: ['Médico', 'Información', 'Salud'],
  },
  coronavirus: {
    en: ['Coronavirus', 'Virus', 'Pandemic'],
    es: ['Coronavirus', 'Virus', 'Pandemia'],
  },
  add_reaction: { en: ['Add', 'Reaction', 'Respond'], es: ['Agregar', 'Reacción', 'Responder'] },
  rocket: { en: ['Rocket', 'Launch', 'Space'], es: ['Cohete', 'Lanzamiento', 'Espacio'] },
  female: { en: ['Female', 'Woman', 'Gender'], es: ['Femenino', 'Mujer', 'Género'] },
  emoji_nature: {
    en: ['Emoji', 'Nature', 'Environment'],
    es: ['Emoji', 'Naturaleza', 'Medio ambiente'],
  },
  person_off: {
    en: ['Person', 'Off', 'Unavailable'],
    es: ['Persona', 'Desconectado', 'No disponible'],
  },
  cookie: { en: ['Cookie', 'Sweet', 'Biscuit'], es: ['Galleta', 'Dulce'] },
  connect_without_contact: {
    en: ['Connect', 'Without', 'Contact'],
    es: ['Conectar', 'Sin', 'Contacto'],
  },
  woman: { en: ['Woman', 'Female', 'Person'], es: ['Mujer', 'Femenino', 'Persona'] },
  groups_2: { en: ['Groups', 'People', 'Team'], es: ['Grupos', 'Personas', 'Equipo'] },
  solar_power: { en: ['Solar', 'Power', 'Energy'], es: ['Solar', 'Energía'] },
  thunderstorm: { en: ['Thunderstorm', 'Weather', 'Storm'], es: ['Tormenta', 'Clima'] },
  thumbs_up_down: {
    en: ['Thumbs', 'Up', 'Down', 'Vote'],
    es: ['Pulgares', 'Arriba', 'Abajo', 'Votar'],
  },
  emoji_flags: { en: ['Emoji', 'Flags', 'Symbols'], es: ['Emoji', 'Banderas', 'Símbolos'] },
  masks: { en: ['Masks', 'Protection', 'Safety'], es: ['Máscaras', 'Protección', 'Seguridad'] },
  hive: { en: ['Hive', 'Bees', 'Nature'], es: ['Colmena', 'Abejas', 'Naturaleza'] },
  heart_broken: { en: ['Heart', 'Broken', 'Sad'], es: ['Corazón', 'Roto', 'Triste'] },
  boy: { en: ['Boy', 'Child', 'Male'], es: ['Niño'] },
  whatshot: { en: ['Hot', 'Trending', 'Popular'], es: ['Caliente', 'Tendencia', 'Popular'] },
  emoji_food_beverage: { en: ['Emoji', 'Food', 'Beverage'], es: ['Emoji', 'Comida', 'Bebida'] },
  wind_power: { en: ['Wind', 'Power', 'Energy'], es: ['Viento', 'Energía'] },
  emoji_transportation: {
    en: ['Emoji', 'Transportation', 'Vehicles'],
    es: ['Emoji', 'Transporte', 'Vehículos'],
  },
  elderly: { en: ['Elderly', 'Old', 'Senior'], es: ['Anciano', 'Viejo', 'Mayor'] },
  face_6: { en: ['Face', 'Emotion', 'Expression'], es: ['Cara', 'Emoción', 'Expresión'] },
  reduce_capacity: { en: ['Reduce', 'Capacity', 'Limit'], es: ['Reducir', 'Capacidad', 'Límite'] },
  sick: { en: ['Sick', 'Ill', 'Unwell'], es: ['Enfermo'] },
  face_3: { en: ['Face', 'Emotion', 'Expression'], es: ['Cara', 'Emoción', 'Expresión'] },
  pregnant_woman: { en: ['Pregnant', 'Woman', 'Mother'], es: ['Embarazada', 'Mujer', 'Madre'] },
  bloodtype: { en: ['Blood', 'Type', 'Medical'], es: ['Sangre', 'Tipo', 'Médico'] },
  egg: { en: ['Egg', 'Food', 'Birth'], es: ['Huevo', 'Comida'] },
  group_remove: { en: ['Group', 'Remove', 'Leave'], es: ['Grupo', 'Remover', 'Salir'] },
  groups_3: { en: ['Groups', 'People', 'Team'], es: ['Grupos', 'Personas', 'Equipo'] },
  medication_liquid: {
    en: ['Medication', 'Liquid', 'Drug'],
    es: ['Medicación', 'Líquido', 'Droga'],
  },
  co2: { en: ['CO2', 'Carbon', 'Dioxide'], es: ['CO2', 'Carbono', 'Dióxido'] },
  follow_the_signs: {
    en: ['Follow', 'Signs', 'Direction'],
    es: ['Seguir', 'Señales', 'Dirección'],
  },
  face_4: { en: ['Face', 'Expression', 'Emoji'], es: ['Cara', 'Expresión', 'Emoji'] },
  emoji_events: { en: ['Emoji', 'Events', 'Celebration'], es: ['Emoji', 'Eventos', 'Celebración'] },
  elderly_woman: { en: ['Elderly', 'Woman', 'Old'], es: ['Anciana', 'Mujer', 'Vieja'] },
  oil_barrel: { en: ['Oil', 'Barrel', 'Container'], es: ['Petróleo', 'Barril', 'Contenedor'] },
  transgender: {
    en: ['Transgender', 'Gender', 'Identity'],
    es: ['Transgénero', 'Género', 'Identidad'],
  },
  sanitizer: { en: ['Sanitizer', 'Clean', 'Hygiene'], es: ['Desinfectante', 'Limpiar', 'Higiene'] },
  clean_hands: { en: ['Clean', 'Hands', 'Wash'], es: ['Limpiar', 'Manos', 'Lavar'] },
  person_2: { en: ['Person', 'Individual', 'Human'], es: ['Persona', 'Individuo', 'Humano'] },
  public_off: { en: ['Public', 'Off', 'Closed'], es: ['Público', 'Apagar', 'Cerrado'] },
  face_2: { en: ['Face', 'Expression', 'Emoji'], es: ['Cara', 'Expresión', 'Emoji'] },
  social_distance: {
    en: ['Social', 'Distance', 'Safety'],
    es: ['Social', 'Distancia', 'Seguridad'],
  },
  sign_language: {
    en: ['Sign', 'Language', 'Communication'],
    es: ['Signo', 'Lenguaje', 'Comunicación'],
  },
  south_america: { en: ['South', 'America', 'Continent'], es: ['Sur', 'América', 'Continente'] },
  emoji_symbols: { en: ['Emoji', 'Symbols', 'Icons'], es: ['Emoji', 'Símbolos', 'Iconos'] },
  flood: { en: ['Flood', 'Water', 'Overflow'], es: ['Inundación', 'Agua', 'Desbordamiento'] },
  face_5: { en: ['Face', 'Expression', 'Emoji'], es: ['Cara', 'Expresión', 'Emoji'] },
  egg_alt: { en: ['Egg', 'Alternative', 'Food'], es: ['Huevo', 'Alternativo', 'Comida'] },
  cyclone: { en: ['Cyclone', 'Storm', 'Weather'], es: ['Ciclón', 'Tormenta', 'Clima'] },
  girl: { en: ['Girl', 'Female', 'Child'], es: ['Niña', 'Femenino', 'Infantil'] },
  person_4: { en: ['Person', 'Individual', 'Human'], es: ['Persona', 'Individuo', 'Humano'] },
  tsunami: { en: ['Tsunami', 'Wave', 'Disaster'], es: ['Tsunami', 'Ola', 'Desastre'] },
  group_off: { en: ['Group', 'Off', 'People'], es: ['Grupo', 'Apagar', 'Gente'] },
  severe_cold: { en: ['Severe', 'Cold', 'Temperature'], es: ['Severo', 'Frío', 'Temperatura'] },
  person_3: { en: ['Person', 'Individual', 'Human'], es: ['Persona', 'Individuo', 'Humano'] },
  tornado: { en: ['Tornado', 'Storm', 'Disaster'], es: ['Tornado', 'Tormenta', 'Desastre'] },
  safety_divider: {
    en: ['Safety', 'Divider', 'Protection'],
    es: ['Seguridad', 'Divisor', 'Protección'],
  },
  landslide: {
    en: ['Landslide', 'Disaster', 'Slide'],
    es: ['Deslizamiento', 'Desastre', 'Deslizar'],
  },
  vaping_rooms: { en: ['Vaping', 'Rooms', 'Smoke'], es: ['Vapear', 'Habitaciones', 'Humo'] },
  mood_bad: { en: ['Mood', 'Bad', 'Feeling'], es: ['Humor', 'Mal', 'Sensación'] },
  male: { en: ['Male', 'Gender', 'Man'], es: ['Masculino', 'Género', 'Hombre'] },
  bedtime: { en: ['Bedtime', 'Sleep', 'Night'], es: ['Hora de dormir', 'Dormir', 'Noche'] },
  woman_2: { en: ['Woman', 'Female', 'Person'], es: ['Mujer', 'Femenina', 'Persona'] },
  no_adult_content: { en: ['No', 'Adult', 'Content'], es: ['No', 'Adulto', 'Contenido'] },
  volcano: { en: ['Volcano', 'Eruption', 'Nature'], es: ['Volcán', 'Erupción', 'Naturaleza'] },
  '6_ft_apart': { en: ['6 feet', 'Apart', 'Distance'], es: ['6 pies', 'Aparte', 'Distancia'] },
  man_2: { en: ['Man', 'Male', 'Person'], es: ['Hombre', 'Masculino', 'Persona'] },
  blind: { en: ['Blind', 'Vision', 'Disability'], es: ['Ciego', 'Visión', 'Discapacidad'] },
  '18_up_rating': { en: ['18+', 'Rating', 'Age'], es: ['18+', 'Clasificación', 'Edad'] },
  not_accessible: {
    en: ['Not', 'Accessible', 'Unavailable'],
    es: ['No', 'Accesible', 'No disponible'],
  },
  vape_free: { en: ['Vape', 'Free', 'No smoking'], es: ['Vapear', 'Libre', 'No fumar'] },
  man_4: { en: ['Man', 'Male', 'Person'], es: ['Hombre', 'Masculino', 'Persona'] },
  bedtime_off: { en: ['Bedtime', 'Off', 'Sleep'], es: ['Hora de dormir', 'Apagar', 'Dormir'] },
  man_3: { en: ['Man', 'Male', 'Person'], es: ['Hombre', 'Masculino', 'Persona'] },
  account_circle: { en: ['Account', 'User', 'Profile'], es: ['Cuenta', 'Usuario', 'Perfil'] },
  mail: { en: ['Mail', 'Email', 'Message'], es: ['Correo', 'Email', 'Mensaje'] },
  edit: { en: ['Edit', 'Modify', 'Change'], es: ['Editar', 'Modificar', 'Cambiar'] },
  shopping_cart: { en: ['Shopping', 'Cart', 'Purchase'], es: ['Compras', 'Carrito', 'Compra'] },
  pin_drop: { en: ['Pin', 'Location', 'Map'], es: ['Pin', 'Ubicación', 'Mapa'] },
  description: {
    en: ['Description', 'Details', 'Information'],
    es: ['Descripción', 'Detalles', 'Información'],
  },
  play_arrow: { en: ['Play', 'Arrow', 'Start'], es: ['Jugar', 'Flecha', 'Empezar'] },
  local_shipping: { en: ['Local', 'Shipping', 'Delivery'], es: ['Local', 'Envío', 'Entrega'] },
  school: { en: ['School', 'Education', 'Learning'], es: ['Escuela', 'Educación', 'Aprendizaje'] },
  phone_iphone: { en: ['Phone', 'iPhone', 'Mobile'], es: ['Teléfono', 'iPhone', 'Móvil'] },
  dark_mode: { en: ['Dark', 'Mode', 'Display'], es: ['Oscuro', 'Modo', 'Pantalla'] },
  badge: { en: ['Badge', 'Award', 'Recognition'], es: ['Insignia', 'Premio', 'Reconocimiento'] },
  apartment: {
    en: ['Apartment', 'Building', 'Residence'],
    es: ['Apartamento', 'Edificio', 'Residencia'],
  },
  house: { en: ['House', 'Home', 'Residence'], es: ['Casa', 'Hogar', 'Residencia'] },
  '3d_rotation': { en: ['3D', 'Rotation', 'Turn'], es: ['3D', 'Rotación', 'Girar'] },
  ac_unit: { en: ['AC', 'Unit', 'Cooling'], es: ['AC', 'Unidad', 'Refrigeración'] },
  access_alarm: { en: ['Access', 'Alarm', 'Warning'], es: ['Acceso', 'Alarma', 'Advertencia'] },
  access_alarms: {
    en: ['Access', 'Alarms', 'Warnings'],
    es: ['Acceso', 'Alarmas', 'Advertencias'],
  },
  access_time: { en: ['Access', 'Time', 'Clock'], es: ['Acceso', 'Tiempo', 'Reloj'] },
  accessibility: {
    en: ['Accessibility', 'Inclusive', 'Usability'],
    es: ['Accesibilidad', 'Inclusivo', 'Usabilidad'],
  },
  accessible: { en: ['Accessible', 'Easy', 'Usable'], es: ['Accesible', 'Fácil', 'Utilizable'] },
  account_balance: { en: ['Account', 'Balance', 'Bank'], es: ['Cuenta', 'Balance', 'Banco'] },
  account_balance_wallet: {
    en: ['Account', 'Wallet', 'Finance'],
    es: ['Cuenta', 'Billetera', 'Finanzas'],
  },
  account_box: { en: ['Account', 'Box', 'Profile'], es: ['Cuenta', 'Caja', 'Perfil'] },
  adb: { en: ['Adb', 'Android', 'Debug'], es: ['Adb', 'Android', 'Depurar'] },
  add_a_photo: { en: ['Add', 'Photo', 'Image'], es: ['Agregar', 'Foto', 'Imagen'] },
  add_alarm: { en: ['Add', 'Alarm', 'Reminder'], es: ['Agregar', 'Alarma', 'Recordatorio'] },
  add_alert: { en: ['Add', 'Alert', 'Warning'], es: ['Agregar', 'Alerta', 'Advertencia'] },
  add_circle_outline: { en: ['Add', 'Circle', 'New'], es: ['Agregar', 'Círculo', 'Nuevo'] },
  add_location: { en: ['Add', 'Location', 'Map'], es: ['Agregar', 'Ubicación', 'Mapa'] },
  add_shopping_cart: { en: ['Add', 'Shopping', 'Purchase'], es: ['Agregar', 'Compras', 'Compra'] },
  add_to_photos: { en: ['Add', 'Photos', 'Gallery'], es: ['Agregar', 'Fotos', 'Galería'] },
  add_to_queue: { en: ['Add', 'Queue', 'List'], es: ['Agregar', 'Cola', 'Lista'] },
  adjust: { en: ['Adjust', 'Settings', 'Control'], es: ['Ajustar', 'Configuraciones', 'Control'] },
  airline_seat_flat: { en: ['Airline', 'Seat', 'Flat'], es: ['Aerolínea', 'Asiento', 'Plano'] },
  airline_seat_flat_angled: {
    en: ['Airline', 'Seat', 'Angled'],
    es: ['Aerolínea', 'Asiento', 'Inclinado'],
  },
  airline_seat_individual_suite: {
    en: ['Airline', 'Suite', 'Privacy'],
    es: ['Aerolínea', 'Suite', 'Privacidad'],
  },
  airline_seat_legroom_extra: {
    en: ['Airline', 'Legroom', 'Extra'],
    es: ['Aerolínea', 'Espacio para las piernas', 'Extra'],
  },
  airline_seat_legroom_normal: {
    en: ['Airline', 'Legroom', 'Normal'],
    es: ['Aerolínea', 'Espacio para las piernas', 'Normal'],
  },
  airline_seat_legroom_reduced: {
    en: ['Airline', 'Legroom', 'Reduced'],
    es: ['Aerolínea', 'Espacio para las piernas', 'Reducido'],
  },
  airline_seat_recline_extra: {
    en: ['Airline', 'Recline', 'Extra'],
    es: ['Aerolínea', 'Reclinable', 'Extra'],
  },
  airline_seat_recline_normal: {
    en: ['Airline', 'Recline', 'Normal'],
    es: ['Aerolínea', 'Reclinable', 'Normal'],
  },
  airplanemode_active: { en: ['Airplane', 'Mode', 'Active'], es: ['Modo avión', 'Modo', 'Activo'] },
  airplanemode_inactive: {
    en: ['Airplane', 'Mode', 'Inactive'],
    es: ['Modo avión', 'Modo', 'Inactivo'],
  },
  airplay: { en: ['Airplay', 'Stream', 'Broadcast'], es: ['Airplay', 'Transmitir', 'Difusión'] },
  airport_shuttle: {
    en: ['Airport', 'Shuttle', 'Transport'],
    es: ['Aeropuerto', 'Traslado', 'Transporte'],
  },
  alarm: { en: ['Alarm', 'Clock', 'Time'], es: ['Alarma', 'Reloj', 'Tiempo'] },
  alarm_add: { en: ['Alarm', 'Add', 'New'], es: ['Alarma', 'Agregar', 'Nuevo'] },
  alarm_off: { en: ['Alarm', 'Off', 'Disable'], es: ['Alarma', 'Apagar', 'Deshabilitar'] },
  alarm_on: { en: ['Alarm', 'On', 'Enable'], es: ['Alarma', 'Encender', 'Habilitar'] },
  album: { en: ['Album', 'Music', 'Collection'], es: ['Álbum', 'Música', 'Colección'] },
  all_inclusive: { en: ['All', 'Inclusive', 'Full'], es: ['Todo', 'Incluido', 'Completo'] },
  all_out: { en: ['All', 'Out', 'Full'], es: ['Todo', 'Fuera', 'Completo'] },
  android: { en: ['Android', 'OS', 'Robot'], es: ['Android', 'SO', 'Robot'] },
  announcement: { en: ['Announcement', 'News', 'Info'], es: ['Anuncio', 'Noticias', 'Info'] },
  archive: { en: ['Archive', 'Files', 'Storage'], es: ['Archivo', 'Archivos', 'Almacenamiento'] },
  art_track: { en: ['Art', 'Track', 'Design'], es: ['Arte', 'Pista', 'Diseño'] },
  aspect_ratio: { en: ['Aspect', 'Ratio', 'Size'], es: ['Aspecto', 'Proporción', 'Tamaño'] },
  assignment: { en: ['Assignment', 'Task', 'Work'], es: ['Asignación', 'Tarea', 'Trabajo'] },
  assignment_ind: {
    en: ['Assignment', 'Individual', 'Task'],
    es: ['Asignación', 'Individual', 'Tarea'],
  },
  assignment_late: { en: ['Assignment', 'Late', 'Delay'], es: ['Asignación', 'Tarde', 'Retraso'] },
  assignment_return: {
    en: ['Assignment', 'Return', 'Submit'],
    es: ['Asignación', 'Devolver', 'Enviar'],
  },
  assignment_returned: {
    en: ['Assignment', 'Returned', 'Received'],
    es: ['Asignación', 'Devuelto', 'Recibido'],
  },
  assignment_turned_in: {
    en: ['Assignment', 'Submitted', 'Complete'],
    es: ['Asignación', 'Enviado', 'Completo'],
  },
  assistant: { en: ['Assistant', 'Helper', 'Support'], es: ['Asistente', 'Ayudante', 'Apoyo'] },
  assistant_photo: { en: ['Assistant', 'Photo', 'Image'], es: ['Asistente', 'Foto', 'Imagen'] },
  attach_file: { en: ['Attach', 'File', 'Document'], es: ['Adjuntar', 'Archivo', 'Documento'] },
  attach_money: { en: ['Attach', 'Money', 'Currency'], es: ['Adjuntar', 'Dinero', 'Moneda'] },
  attachment: { en: ['Attachment', 'File', 'Link'], es: ['Adjunto', 'Archivo', 'Enlace'] },
  audiotrack: { en: ['Audio', 'Track', 'Music'], es: ['Audio', 'Pista', 'Música'] },
  av_timer: { en: ['AV', 'Timer', 'Clock'], es: ['AV', 'Temporizador', 'Reloj'] },
  backup: { en: ['Backup', 'Save', 'Store'], es: ['Respaldo', 'Guardar', 'Almacenar'] },
  battery_alert: { en: ['Battery', 'Alert', 'Warning'], es: ['Batería', 'Alerta', 'Advertencia'] },
  battery_charging_full: {
    en: ['Battery', 'Charging', 'Full'],
    es: ['Batería', 'Cargando', 'Lleno'],
  },
  battery_full: { en: ['Battery', 'Full', 'Complete'], es: ['Batería', 'Lleno', 'Completo'] },
  battery_std: { en: ['Battery', 'Standard', 'Normal'], es: ['Batería', 'Estándar', 'Normal'] },
  battery_unknown: {
    en: ['Battery', 'Unknown', 'Uncertain'],
    es: ['Batería', 'Desconocido', 'Incierto'],
  },
  beach_access: { en: ['Beach', 'Access', 'Entry'], es: ['Playa', 'Acceso', 'Entrada'] },
  beenhere: { en: ['Been', 'Here', 'Location'], es: ['Estado', 'Aquí', 'Ubicación'] },
  bluetooth: {
    en: ['Bluetooth', 'Wireless', 'Connection'],
    es: ['Bluetooth', 'Inalámbrico', 'Conexión'],
  },
  bluetooth_audio: { en: ['Bluetooth', 'Audio', 'Sound'], es: ['Bluetooth', 'Audio', 'Sonido'] },
  bluetooth_connected: {
    en: ['Bluetooth', 'Connected', 'Paired'],
    es: ['Bluetooth', 'Conectado', 'Emparejado'],
  },
  bluetooth_disabled: {
    en: ['Bluetooth', 'Disabled', 'Off'],
    es: ['Bluetooth', 'Deshabilitado', 'Apagado'],
  },
  bluetooth_searching: {
    en: ['Bluetooth', 'Searching', 'Looking'],
    es: ['Bluetooth', 'Buscando', 'Mirando'],
  },
  blur_circular: { en: ['Blur', 'Circular', 'Effect'], es: ['Desenfoque', 'Circular', 'Efecto'] },
  blur_linear: { en: ['Blur', 'Linear', 'Effect'], es: ['Desenfoque', 'Lineal', 'Efecto'] },
  blur_off: { en: ['Blur', 'Off', 'Clear'], es: ['Desenfoque', 'Apagar', 'Claro'] },
  blur_on: { en: ['Blur', 'On', 'Effect'], es: ['Desenfoque', 'Encender', 'Efecto'] },
  book: { en: ['Book', 'Read', 'Literature'], es: ['Libro', 'Leer', 'Literatura'] },
  bookmark: { en: ['Bookmark', 'Save', 'Mark'], es: ['Marcador', 'Guardar', 'Marcar'] },
  bookmark_border: { en: ['Bookmark', 'Border', 'Outline'], es: ['Marcador', 'Borde', 'Contorno'] },
  border_all: { en: ['All Borders', 'Full Border'], es: ['Todos los Bordes', 'Borde Completo'] },
  border_bottom: { en: ['Bottom Border'], es: ['Borde Inferior'] },
  border_clear: { en: ['Clear Border', 'No Border'], es: ['Borde Claro', 'Sin Borde'] },
  border_color: { en: ['Border Color'], es: ['Color de Borde'] },
  border_horizontal: { en: ['Horizontal Border'], es: ['Borde Horizontal'] },
  border_inner: { en: ['Inner Border'], es: ['Borde Interno'] },
  border_left: { en: ['Left Border'], es: ['Borde Izquierdo'] },
  border_outer: { en: ['Outer Border'], es: ['Borde Externo'] },
  border_right: { en: ['Right Border'], es: ['Borde Derecho'] },
  border_style: { en: ['Border Style'], es: ['Estilo de Borde'] },
  border_top: { en: ['Top Border'], es: ['Borde Superior'] },
  border_vertical: { en: ['Vertical Border'], es: ['Borde Vertical'] },
  branding_watermark: { en: ['Branding', 'Watermark'], es: ['Marca', 'Marca de Agua'] },
  brightness_1: { en: ['Brightness 1'], es: ['Brillo 1'] },
  brightness_2: { en: ['Brightness 2'], es: ['Brillo 2'] },
  brightness_3: { en: ['Brightness 3'], es: ['Brillo 3'] },
  brightness_4: { en: ['Brightness 4'], es: ['Brillo 4'] },
  brightness_5: { en: ['Brightness 5'], es: ['Brillo 5'] },
  brightness_6: { en: ['Brightness 6'], es: ['Brillo 6'] },
  brightness_7: { en: ['Brightness 7'], es: ['Brillo 7'] },
  brightness_auto: { en: ['Auto Brightness'], es: ['Brillo Automático'] },
  brightness_high: { en: ['Brightness', 'High', 'Light'], es: ['Brillo', 'Alto', 'Luz'] },
  brightness_low: { en: ['Brightness', 'Low', 'Light'], es: ['Brillo', 'Bajo', 'Luz'] },
  brightness_medium: { en: ['Brightness', 'Medium', 'Light'], es: ['Brillo', 'Medio', 'Luz'] },
  broken_image: { en: ['Broken', 'Image', 'Error'], es: ['Roto', 'Imagen', 'Error'] },
  brush: { en: ['Brush', 'Paint', 'Art'], es: ['Pincel', 'Pintar', 'Arte'] },
  bubble_chart: { en: ['Bubble', 'Chart', 'Graph'], es: ['Burbuja', 'Gráfico'] },
  bug_report: { en: ['Bug', 'Report', 'Issue'], es: ['Error', 'Reporte', 'Problema'] },
  build: { en: ['Build', 'Construct', 'Develop'], es: ['Construir', 'Desarrollar'] },
  burst_mode: { en: ['Burst', 'Mode', 'Photography'], es: ['Ráfaga', 'Modo', 'Fotografía'] },
  business: { en: ['Business', 'Company', 'Corporate'], es: ['Negocio', 'Empresa'] },
  business_center: {
    en: ['Business', 'Center', 'Corporate'],
    es: ['Negocio', 'Centro', 'Corporativo'],
  },
  cake: { en: ['Cake', 'Dessert', 'Celebration'], es: ['Pastel', 'Postre', 'Celebración'] },
  call: { en: ['Call', 'Phone', 'Communicate'], es: ['Llamada', 'Teléfono', 'Comunicar'] },
  call_end: { en: ['Call', 'End', 'Hang Up'], es: ['Llamada', 'Finalizar', 'Colgar'] },
  call_made: { en: ['Call', 'Made', 'Outgoing'], es: ['Llamada', 'Realizada'] },
  call_merge: { en: ['Call', 'Merge', 'Combine'], es: ['Llamada', 'Fusionar', 'Combinar'] },
  call_missed: { en: ['Call', 'Missed', 'Lost'], es: ['Llamada', 'Perdida'] },
  call_missed_outgoing: {
    en: ['Call', 'Missed', 'Outgoing'],
    es: ['Llamada', 'Perdida', 'Saliente'],
  },
  call_received: { en: ['Call', 'Received', 'Incoming'], es: ['Llamada', 'Recibida', 'Entrante'] },
  call_split: { en: ['Call', 'Split', 'Separate'], es: ['Llamada', 'Dividir', 'Separar'] },
  call_to_action: { en: ['Call', 'Action', 'Prompt'], es: ['Llamado', 'Acción', 'Prompt'] },
  camera: { en: ['Camera', 'Photography', 'Capture'], es: ['Cámara', 'Fotografía', 'Capturar'] },
  camera_alt: {
    en: ['Camera', 'Alternative', 'Photography'],
    es: ['Cámara', 'Alternativa', 'Fotografía'],
  },
  camera_enhance: { en: ['Camera', 'Enhance', 'Improve'], es: ['Cámara', 'Mejorar'] },
  camera_front: { en: ['Camera', 'Front', 'Selfie'], es: ['Cámara', 'Frente', 'Selfie'] },
  camera_rear: { en: ['Camera', 'Rear', 'Back'], es: ['Cámara', 'Trasera'] },
  camera_roll: { en: ['Camera', 'Roll', 'Gallery'], es: ['Cámara', 'Rollo', 'Galería'] },
  card_giftcard: { en: ['Card', 'Gift', 'Present'], es: ['Tarjeta', 'Regalo'] },
  card_membership: {
    en: ['Card', 'Membership', 'Subscription'],
    es: ['Tarjeta', 'Membresía', 'Suscripción'],
  },
  card_travel: { en: ['Card', 'Travel', 'Journey'], es: ['Tarjeta', 'Viaje'] },
  casino: { en: ['Casino', 'Gambling', 'Game'], es: ['Casino', 'Juego', 'Apuesta'] },
  cast: { en: ['Cast', 'Stream', 'Broadcast'], es: ['Transmitir', 'Emitir'] },
  cast_connected: {
    en: ['Cast', 'Connected', 'Linked'],
    es: ['Transmitir', 'Conectado', 'Vinculado'],
  },
  center_focus_strong: { en: ['Center', 'Focus', 'Strong'], es: ['Centro', 'Foco', 'Fuerte'] },
  center_focus_weak: { en: ['Center', 'Focus', 'Weak'], es: ['Centro', 'Foco', 'Débil'] },
  change_history: {
    en: ['Change', 'History', 'Modify'],
    es: ['Cambiar', 'Historial', 'Modificar'],
  },
  chat: { en: ['Chat', 'Message', 'Conversation'], es: ['Chat', 'Mensaje', 'Conversación'] },
  chat_bubble: { en: ['Chat', 'Bubble', 'Message'], es: ['Chat', 'Burbuja', 'Mensaje'] },
  chat_bubble_outline: { en: ['Chat', 'Bubble', 'Outline'], es: ['Chat', 'Burbuja', 'Contorno'] },
  child_care: { en: ['Child', 'Care', 'Nursery'], es: ['Niño', 'Cuidado', 'Guardería'] },
  child_friendly: { en: ['Child', 'Friendly', 'Safe'], es: ['Niño', 'Amigable', 'Seguro'] },
  chrome_reader_mode: { en: ['Chrome', 'Reader', 'Mode'], es: ['Chrome', 'Lector', 'Modo'] },
  class: { en: ['Class', 'Education', 'School'], es: ['Clase', 'Educación', 'Escuela'] },
  clear: { en: ['Clear', 'Remove', 'Delete'], es: ['Borrar', 'Eliminar', 'Quitar'] },
  closed_caption: {
    en: ['Closed', 'Caption', 'Subtitle'],
    es: ['Subtítulo', 'Caption', 'Cerrado'],
  },
  cloud: { en: ['Cloud', 'Storage', 'Online'], es: ['Nube', 'Almacenamiento', 'En línea'] },
  cloud_circle: { en: ['Cloud', 'Circle', 'Storage'], es: ['Nube', 'Círculo', 'Almacenamiento'] },
  cloud_done: { en: ['Cloud', 'Done', 'Complete'], es: ['Nube', 'Hecho', 'Completo'] },
  cloud_download: { en: ['Cloud', 'Download', 'Retrieve'], es: ['Nube', 'Descargar', 'Recuperar'] },
  cloud_off: { en: ['Cloud', 'Off', 'Disable'], es: ['Nube', 'Apagar', 'Desactivar'] },
  cloud_queue: { en: ['Cloud', 'Queue', 'Waiting'], es: ['Nube', 'Cola', 'Esperando'] },
  cloud_upload: { en: ['Cloud', 'Upload', 'Send'], es: ['Nube', 'Subir', 'Enviar'] },
  code: {
    en: ['Code', 'Programming', 'Development'],
    es: ['Código', 'Programación', 'Desarrollo'],
  },
  collections: { en: ['Collections', 'Gallery', 'Album'], es: ['Colecciones', 'Galería', 'Álbum'] },
  collections_bookmark: {
    en: ['Collections', 'Bookmark', 'Save'],
    es: ['Colecciones', 'Marcador', 'Guardar'],
  },
  color_lens: { en: ['Color', 'Lens', 'Filter'], es: ['Color', 'Lente', 'Filtro'] },
  colorize: { en: ['Colorize', 'Color', 'Edit'], es: ['Colorear', 'Color', 'Editar'] },
  comment: {
    en: ['Comment', 'Feedback', 'Message'],
    es: ['Comentario', 'Retroalimentación', 'Mensaje'],
  },
  compare: { en: ['Compare', 'Contrast', 'Differ'], es: ['Comparar', 'Contraste', 'Diferenciar'] },
  computer: { en: ['Computer', 'PC', 'Technology'], es: ['Computadora', 'PC', 'Tecnología'] },
  confirmation_number: {
    en: ['Confirmation', 'Number', 'Code'],
    es: ['Confirmación', 'Número', 'Código'],
  },
  contact_mail: { en: ['Contact', 'Mail'], es: ['Contacto', 'Correo'] },
  contact_phone: { en: ['Contact', 'Phone'], es: ['Contacto', 'Teléfono'] },
  contacts: { en: ['Contacts', 'Address Book'], es: ['Contactos', 'Agenda'] },
  content_copy: { en: ['Copy', 'Duplicate'], es: ['Copiar', 'Duplicar'] },
  content_cut: { en: ['Cut', 'Remove'], es: ['Cortar', 'Eliminar'] },
  content_paste: { en: ['Paste', 'Insert'], es: ['Pegar', 'Insertar'] },
  control_point: { en: ['Control', 'Adjust'], es: ['Control', 'Ajustar'] },
  control_point_duplicate: {
    en: ['Duplicate', 'Control Point'],
    es: ['Duplicar', 'Punto de control'],
  },
  copyright: { en: ['Copyright', 'Legal'], es: ['Derechos de autor', 'Legal'] },
  create: { en: ['Create', 'New'], es: ['Crear', 'Nuevo'] },
  credit_card: { en: ['Credit Card', 'Payment'], es: ['Tarjeta de crédito', 'Pago'] },
  crop: { en: ['Crop', 'Adjust'], es: ['Recortar', 'Ajustar'] },
  crop_16_9: { en: ['Crop', 'Widescreen'], es: ['Recortar', 'Pantalla ancha'] },
  crop_3_2: { en: ['Crop', 'Aspect Ratio'], es: ['Recortar', 'Relación de aspecto'] },
  crop_5_4: { en: ['Crop', 'Aspect Ratio'], es: ['Recortar', 'Relación de aspecto'] },
  crop_7_5: { en: ['Crop', 'Aspect Ratio'], es: ['Recortar', 'Relación de aspecto'] },
  crop_din: { en: ['Crop', 'Aspect Ratio'], es: ['Recortar', 'Relación de aspecto'] },
  crop_free: { en: ['Crop', 'Freeform'], es: ['Recortar', 'Forma libre'] },
  crop_landscape: { en: ['Crop', 'Landscape'], es: ['Recortar', 'Paisaje'] },
  crop_original: { en: ['Crop', 'Original'], es: ['Recortar', 'Original'] },
  crop_portrait: { en: ['Crop', 'Portrait'], es: ['Recortar', 'Retrato'] },
  crop_rotate: { en: ['Crop', 'Rotate'], es: ['Recortar', 'Rotar'] },
  crop_square: { en: ['Crop', 'Square'], es: ['Recortar', 'Cuadrado'] },
  dashboard: { en: ['Dashboard', 'Controls'], es: ['Tablero', 'Controles'] },
  data_usage: { en: ['Data', 'Usage'], es: ['Datos', 'Uso'] },
  date_range: { en: ['Date', 'Range'], es: ['Fecha', 'Rango'] },
  dehaze: { en: ['Dehaze', 'Clear'], es: ['Despejar', 'Claro'] },
  desktop_mac: { en: ['Desktop', 'Mac'], es: ['Escritorio', 'Mac'] },
  desktop_windows: { en: ['Desktop', 'Windows'], es: ['Escritorio', 'Windows'] },
  details: { en: ['Details', 'Information'], es: ['Detalles', 'Información'] },
  developer_board: { en: ['Developer', 'Board'], es: ['Desarrollador', 'Tablero'] },
  developer_mode: { en: ['Developer', 'Mode'], es: ['Desarrollador', 'Modo'] },
  device_hub: { en: ['Device', 'Hub'], es: ['Dispositivo', 'Concentrador'] },
  devices: { en: ['Devices', 'Electronics'], es: ['Dispositivos', 'Electrónicos'] },
  devices_other: {
    en: ['Other Devices', 'Electronics'],
    es: ['Otros dispositivos', 'Electrónicos'],
  },
  dialer_sip: { en: ['Dialer', 'SIP'], es: ['Marcador', 'SIP'] },
  dialpad: { en: ['Dialpad', 'Numbers'], es: ['Teclado', 'Números'] },
  directions: { en: ['Directions', 'Navigation'], es: ['Direcciones', 'Navegación'] },
  directions_bike: { en: ['Bike', 'Directions'], es: ['Bicicleta', 'Direcciones'] },
  directions_boat: { en: ['Boat', 'Directions'], es: ['Barco', 'Direcciones'] },
  directions_bus: { en: ['Bus', 'Directions'], es: ['Autobús', 'Direcciones'] },
  directions_car: { en: ['Car', 'Directions'], es: ['Coche', 'Direcciones'] },
  directions_railway: { en: ['Railway', 'Directions'], es: ['Ferrocarril', 'Direcciones'] },
  directions_run: { en: ['Run', 'Directions'], es: ['Correr', 'Direcciones'] },
  directions_subway: { en: ['Subway', 'Directions'], es: ['Metro', 'Direcciones'] },
  directions_transit: { en: ['Transit', 'Directions'], es: ['Tránsito', 'Direcciones'] },
  directions_walk: { en: ['Walk', 'Directions'], es: ['Caminar', 'Direcciones'] },
  disc_full: { en: ['Disc', 'Full'], es: ['Disco', 'Lleno'] },
  dns: { en: ['DNS', 'Domain'], es: ['DNS', 'Dominio'] },
  do_not_disturb: { en: ['Do Not Disturb', 'Silence'], es: ['No molestar', 'Silencio'] },
  do_not_disturb_alt: { en: ['Do Not Disturb', 'Alternate'], es: ['No molestar', 'Alternativo'] },
  dock: { en: ['Dock', 'Connector'], es: ['Muelle', 'Conector'] },
  domain: { en: ['Domain', 'Website'], es: ['Dominio', 'Sitio web'] },
  donut_large: { en: ['Donut', 'Large'], es: ['Donut', 'Grande'] },
  donut_small: { en: ['Donut', 'Small'], es: ['Donut', 'Pequeño'] },
  drafts: { en: ['Drafts', 'Messages'], es: ['Borradores', 'Mensajes'] },
  drag_handle: { en: ['Drag', 'Handle'], es: ['Arrastrar', 'Manejar'] },
  drive_eta: { en: ['Drive', 'ETA'], es: ['Conducir', 'ETA'] },
  dvr: { en: ['DVR', 'Record'], es: ['DVR', 'Grabar'] },
  edit_location: { en: ['Edit', 'Location'], es: ['Editar', 'Ubicación'] },
  email: { en: ['Email', 'Message'], es: ['Correo electrónico', 'Mensaje'] },
  enhanced_encryption: { en: ['Encryption', 'Security'], es: ['Cifrado', 'Seguridad'] },
  equalizer: { en: ['Equalizer', 'Settings'], es: ['Ecualizador', 'Configuraciones'] },
  error: { en: ['Error', 'Warning'], es: ['Error', 'Advertencia'] },
  error_outline: { en: ['Error', 'Outline'], es: ['Error', 'Esquema'] },
  euro_symbol: { en: ['Euro', 'Currency'], es: ['Euro', 'Moneda'] },
  ev_station: { en: ['Electric Vehicle', 'Station'], es: ['Vehículo eléctrico', 'Estación'] },
  event: { en: ['Event', 'Calendar'], es: ['Evento', 'Calendario'] },
  event_available: { en: ['Event', 'Available'], es: ['Evento', 'Disponible'] },
  event_busy: { en: ['Event', 'Busy'], es: ['Evento', 'Ocupado'] },
  event_note: { en: ['Event', 'Note'], es: ['Evento', 'Nota'] },
  event_seat: { en: ['Seat', 'Reservation'], es: ['Asiento', 'Reservación'] },
  explicit: { en: ['Explicit', 'Content'], es: ['Explícito', 'Contenido'] },
  explore: { en: ['Explore', 'Discover'], es: ['Explorar', 'Descubrir'] },
  exposure: { en: ['Exposure', 'Light'], es: ['Exposición', 'Luz'] },
  exposure_neg_1: { en: ['Exposure', 'Decrease'], es: ['Exposición', 'Disminuir'] },
  exposure_neg_2: { en: ['Exposure', 'Decrease More'], es: ['Exposición', 'Disminuir más'] },
  exposure_plus_1: { en: ['Exposure', 'Increase'], es: ['Exposición', 'Aumentar'] },
  exposure_plus_2: { en: ['Exposure', 'Increase More'], es: ['Exposición', 'Aumentar más'] },
  exposure_zero: { en: ['Exposure', 'Neutral'], es: ['Exposición', 'Neutro'] },
  extension: { en: ['Extension', 'Add-on'], es: ['Extensión', 'Complemento'] },
  fast_forward: { en: ['Fast', 'Forward'], es: ['Rápido', 'Adelantar'] },
  fast_rewind: { en: ['Rewind', 'Backward'], es: ['Retroceder', 'Atrás'] },
  favorite_border: { en: ['Favorite', 'Like'], es: ['Favorito', 'Me gusta'] },
  featured_play_list: { en: ['Playlist', 'Featured'], es: ['Lista de reproducción', 'Destacado'] },
  featured_video: { en: ['Video', 'Featured'], es: ['Video', 'Destacado'] },
  feedback: { en: ['Feedback', 'Review'], es: ['Retroalimentación', 'Revisión'] },
  fiber_dvr: { en: ['Record', 'DVR'], es: ['Grabar', 'DVR'] },
  fiber_manual_record: { en: ['Record', 'Manual'], es: ['Grabar', 'Manual'] },
  fiber_new: { en: ['New', 'Fiber'], es: ['Nuevo', 'Fibra'] },
  fiber_pin: { en: ['Pin', 'Save'], es: ['Anclar', 'Guardar'] },
  fiber_smart_record: {
    en: ['Smart Record', 'Automate'],
    es: ['Grabación inteligente', 'Automatizar'],
  },
  file_download: { en: ['Download', 'File'], es: ['Descargar', 'Archivo'] },
  file_upload: { en: ['Upload', 'File'], es: ['Subir', 'Archivo'] },
  filter: { en: ['Filter', 'Adjust'], es: ['Filtrar', 'Ajustar'] },
  filter_1: { en: ['Filter', 'One'], es: ['Filtrar', 'Uno'] },
  filter_2: { en: ['Filter', 'Two'], es: ['Filtrar', 'Dos'] },
  filter_3: { en: ['Filter', 'Three'], es: ['Filtrar', 'Tres'] },
  filter_4: { en: ['Filter', 'Four'], es: ['Filtrar', 'Cuatro'] },
  filter_5: { en: ['Filter', 'Five'], es: ['Filtrar', 'Cinco'] },
  filter_6: { en: ['Filter', 'Six'], es: ['Filtrar', 'Seis'] },
  filter_7: { en: ['Filter', 'Seven'], es: ['Filtrar', 'Siete'] },
  filter_8: { en: ['Filter', 'Eight'], es: ['Filtrar', 'Ocho'] },
  filter_9: { en: ['Filter', 'Nine'], es: ['Filtrar', 'Nueve'] },
  filter_9_plus: { en: ['Filter', 'More'], es: ['Filtrar', 'Más'] },
  filter_b_and_w: { en: ['Black and White', 'Filter'], es: ['Blanco y negro', 'Filtrar'] },
  filter_center_focus: { en: ['Focus', 'Center'], es: ['Enfocar', 'Centro'] },
  filter_drama: { en: ['Drama', 'Effect'], es: ['Drama', 'Efecto'] },
  filter_frames: { en: ['Frames', 'Borders'], es: ['Marcos', 'Bordes'] },
  filter_hdr: { en: ['Filter', 'HDR'], es: ['Filtro', 'HDR'] },
  filter_none: { en: ['Filter', 'None'], es: ['Filtro', 'Ninguno'] },
  filter_tilt_shift: { en: ['Filter', 'Tilt Shift'], es: ['Filtro', 'Inclinación'] },
  filter_vintage: { en: ['Filter', 'Vintage'], es: ['Filtro', 'Vintage'] },
  find_in_page: { en: ['Find', 'In Page'], es: ['Buscar', 'En Página'] },
  find_replace: { en: ['Find', 'Replace'], es: ['Buscar', 'Reemplazar'] },
  fingerprint: { en: ['Fingerprint', 'Identity'], es: ['Huella dactilar', 'Identidad'] },
  fitness_center: { en: ['Fitness', 'Gym'], es: ['Fitness', 'Gimnasio'] },
  flag: { en: ['Flag', 'Mark'], es: ['Bandera', 'Marcar'] },
  flare: { en: ['Flare', 'Brightness'], es: ['Destello', 'Brillo'] },
  flash_auto: { en: ['Flash', 'Auto'], es: ['Flash', 'Auto'] },
  flash_off: { en: ['Flash', 'Off'], es: ['Flash', 'Apagar'] },
  flash_on: { en: ['Flash', 'On'], es: ['Flash', 'Encender'] },
  flight: { en: ['Flight', 'Plane'], es: ['Vuelo', 'Avión'] },
  flight_land: { en: ['Flight', 'Land'], es: ['Vuelo', 'Aterrizar'] },
  flight_takeoff: { en: ['Flight', 'Takeoff'], es: ['Vuelo', 'Despegar'] },
  flip: { en: ['Flip', 'Rotate'], es: ['Voltear', 'Rotar'] },
  flip_to_back: { en: ['Flip', 'Back'], es: ['Voltear', 'Atrás'] },
  flip_to_front: { en: ['Flip', 'Front'], es: ['Voltear', 'Adelante'] },
  folder: { en: ['Folder', 'Directory'], es: ['Carpeta', 'Directorio'] },
  folder_open: { en: ['Folder', 'Open'], es: ['Carpeta', 'Abrir'] },
  folder_shared: { en: ['Folder', 'Shared'], es: ['Carpeta', 'Compartida'] },
  folder_special: { en: ['Folder', 'Special'], es: ['Carpeta', 'Especial'] },
  font_download: { en: ['Font', 'Download'], es: ['Fuente', 'Descargar'] },
  format_align_center: { en: ['Format', 'Center'], es: ['Formato', 'Centrar'] },
  format_align_justify: { en: ['Format', 'Justify'], es: ['Formato', 'Justificar'] },
  format_align_left: { en: ['Format', 'Left'], es: ['Formato', 'Izquierda'] },
  format_align_right: { en: ['Format', 'Right'], es: ['Formato', 'Derecha'] },
  format_bold: { en: ['Format', 'Bold'], es: ['Formato', 'Negrita'] },
  format_clear: { en: ['Format', 'Clear'], es: ['Formato', 'Limpiar'] },
  format_color_fill: { en: ['Format', 'Color Fill'], es: ['Formato', 'Relleno de color'] },
  format_color_reset: { en: ['Format', 'Color Reset'], es: ['Formato', 'Reiniciar color'] },
  format_color_text: { en: ['Format', 'Color Text'], es: ['Formato', 'Texto de color'] },
  format_indent_decrease: {
    en: ['Format', 'Decrease Indent'],
    es: ['Formato', 'Disminuir sangría'],
  },
  format_indent_increase: {
    en: ['Format', 'Increase Indent'],
    es: ['Formato', 'Aumentar sangría'],
  },
  format_italic: { en: ['Format', 'Italic'], es: ['Formato', 'Cursiva'] },
  format_line_spacing: {
    en: ['Format', 'Line Spacing'],
    es: ['Formato', 'Espaciado entre líneas'],
  },
  format_list_bulleted: { en: ['Format', 'Bullets'], es: ['Formato', 'Viñetas'] },
  format_list_numbered: { en: ['Format', 'Numbers'], es: ['Formato', 'Números'] },
  format_paint: { en: ['Format', 'Paint'], es: ['Formato', 'Pintar'] },
  format_quote: { en: ['Format', 'Quote'], es: ['Formato', 'Cita'] },
  format_shapes: { en: ['Format', 'Shapes'], es: ['Formato', 'Formas'] },
  format_size: { en: ['Format', 'Size'], es: ['Formato', 'Tamaño'] },
  format_strikethrough: { en: ['Format', 'Strikethrough'], es: ['Formato', 'Tachado'] },
  format_textdirection_l_to_r: {
    en: ['Text Direction', 'Left to Right'],
    es: ['Dirección del texto', 'Izquierda a derecha'],
  },
  format_textdirection_r_to_l: {
    en: ['Text Direction', 'Right to Left'],
    es: ['Dirección del texto', 'Derecha a izquierda'],
  },
  format_underlined: { en: ['Format', 'Underline'], es: ['Formato', 'Subrayado'] },
  forum: { en: ['Forum', 'Discussion'], es: ['Foro', 'Discusión'] },
  forward_10: { en: ['Forward', '10 Seconds'], es: ['Adelante', '10 segundos'] },
  forward_30: { en: ['Forward', '30 Seconds'], es: ['Adelante', '30 segundos'] },
  forward_5: { en: ['Forward', '5 Seconds'], es: ['Adelante', '5 segundos'] },
  free_breakfast: { en: ['Coffee', 'Breakfast'], es: ['Café', 'Desayuno'] },
  functions: { en: ['Functions', 'Math'], es: ['Funciones', 'Matemáticas'] },
  g_translate: { en: ['Translate', 'Language'], es: ['Traducir', 'Idioma'] },
  gamepad: { en: ['Gamepad', 'Gaming'], es: ['Control', 'Juegos'] },
  games: { en: ['Games', 'Play'], es: ['Juegos', 'Jugar'] },
  gesture: { en: ['Gesture', 'Hand'], es: ['Gesto', 'Mano'] },
  get_app: { en: ['Download', 'App'], es: ['Descargar', 'Aplicación'] },
  gif: { en: ['Gif', 'Image'], es: ['Gif', 'Imagen'] },
  golf_course: { en: ['Golf', 'Sports'], es: ['Golf', 'Deportes'] },
  gps_fixed: { en: ['GPS', 'Location'], es: ['GPS', 'Ubicación'] },
  gps_not_fixed: { en: ['GPS', 'Searching'], es: ['GPS', 'Buscando'] },
  gps_off: { en: ['GPS', 'Off'], es: ['GPS', 'Apagado'] },
  gradient: { en: ['Gradient', 'Colors'], es: ['Gradiente', 'Colores'] },
  grain: { en: ['Grain', 'Texture'], es: ['Grano', 'Textura'] },
  graphic_eq: { en: ['Graphic', 'Equalizer'], es: ['Gráfico', 'Ecualizador'] },
  grid_off: { en: ['Grid', 'Off'], es: ['Cuadrícula', 'Apagado'] },
  grid_on: { en: ['Grid', 'On'], es: ['Cuadrícula', 'Encendido'] },
  hd: { en: ['HD', 'High Definition'], es: ['HD', 'Alta definición'] },
  hdr_off: { en: ['HDR', 'Off'], es: ['HDR', 'Apagado'] },
  hdr_on: { en: ['HDR', 'On'], es: ['HDR', 'Encendido'] },
  hdr_strong: { en: ['HDR', 'Strong'], es: ['HDR', 'Fuerte'] },
  hdr_weak: { en: ['HDR', 'Weak'], es: ['HDR', 'Débil'] },
  headset: { en: ['Headset', 'Audio'], es: ['Auriculares', 'Audio'] },
  headset_mic: { en: ['Headset', 'Microphone'], es: ['Auriculares', 'Micrófono'] },
  healing: { en: ['Healing', 'Health'], es: ['Sanar', 'Salud'] },
  hearing: { en: ['Hearing', 'Audio'], es: ['Escuchar', 'Audio'] },
  help: { en: ['Help', 'Information'], es: ['Ayuda', 'Información'] },
  help_outline: { en: ['Help', 'Guide'], es: ['Ayuda', 'Guía'] },
  high_quality: { en: ['Quality', 'HD'], es: ['Calidad', 'HD'] },
  highlight: { en: ['Highlight', 'Focus'], es: ['Destacar', 'Enfocar'] },
  highlight_off: { en: ['Highlight', 'Remove'], es: ['Destacar', 'Remover'] },
  history: { en: ['History', 'Past'], es: ['Historia', 'Pasado'] },
  hot_tub: { en: ['Relax', 'Spa'], es: ['Relajar', 'Spa'] },
  hotel: { en: ['Hotel', 'Accommodation'], es: ['Hotel', 'Alojamiento'] },
  hourglass_empty: { en: ['Hourglass', 'Time'], es: ['Reloj de arena', 'Tiempo'] },
  hourglass_full: { en: ['Hourglass', 'Waiting'], es: ['Reloj de arena', 'Esperando'] },
  http: { en: ['Internet', 'Protocol'], es: ['Internet', 'Protocolo'] },
  https: { en: ['Secure', 'Protocol'], es: ['Seguro', 'Protocolo'] },
  image: { en: ['Image', 'Photo'], es: ['Imagen', 'Foto'] },
  image_aspect_ratio: { en: ['Aspect Ratio', 'Image'], es: ['Proporción', 'Imagen'] },
  import_contacts: { en: ['Contacts', 'Import'], es: ['Contactos', 'Importar'] },
  import_export: { en: ['Transfer', 'Data'], es: ['Transferir', 'Datos'] },
  important_devices: { en: ['Devices', 'Important'], es: ['Dispositivos', 'Importante'] },
  inbox: { en: ['Inbox', 'Messages'], es: ['Bandeja de entrada', 'Mensajes'] },
  info: { en: ['Info', 'Details'], es: ['Info', 'Detalles'] },
  input: { en: ['Input', 'Enter'], es: ['Entrada', 'Ingresar'] },
  insert_chart: { en: ['Chart', 'Data'], es: ['Gráfico', 'Datos'] },
  insert_comment: { en: ['Comment', 'Feedback'], es: ['Comentario', 'Opinión'] },
  insert_drive_file: { en: ['File', 'Upload'], es: ['Archivo', 'Subir'] },
  insert_emoticon: { en: ['Emoticon', 'Emoji'], es: ['Emoticono', 'Emoji'] },
  insert_invitation: { en: ['Invitation', 'Event'], es: ['Invitación', 'Evento'] },
  insert_link: { en: ['Link', 'Connect'], es: ['Enlace', 'Conectar'] },
  insert_photo: { en: ['Photo', 'Insert'], es: ['Foto', 'Insertar'] },
  invert_colors: { en: ['Invert', 'Colors'], es: ['Invertir', 'Colores'] },
  invert_colors_off: { en: ['Colors', 'Off'], es: ['Colores', 'Apagado'] },
  iso: { en: ['ISO', 'Standard', 'International'], es: ['ISO', 'Estándar', 'Internacional'] },
  keyboard: { en: ['Keyboard', 'Type', 'Input'], es: ['Teclado', 'Tipo', 'Entrada'] },
  keyboard_arrow_down: {
    en: ['Down', 'Arrow', 'Dropdown'],
    es: ['Abajo', 'Flecha', 'Desplegable'],
  },
  keyboard_arrow_left: { en: ['Left', 'Arrow', 'Back'], es: ['Izquierda', 'Flecha', 'Atrás'] },
  keyboard_arrow_right: {
    en: ['Right', 'Arrow', 'Forward'],
    es: ['Derecha', 'Flecha', 'Adelante'],
  },
  keyboard_arrow_up: { en: ['Up', 'Arrow', 'Rise'], es: ['Arriba', 'Flecha', 'Subir'] },
  keyboard_backspace: {
    en: ['Backspace', 'Delete', 'Keyboard'],
    es: ['Retroceso', 'Eliminar', 'Teclado'],
  },
  keyboard_capslock: { en: ['Caps Lock', 'Keyboard'], es: ['Bloq Mayús', 'Teclado'] },
  keyboard_hide: { en: ['Hide', 'Keyboard'], es: ['Ocultar', 'Teclado'] },
  keyboard_return: { en: ['Return', 'Enter', 'Keyboard'], es: ['Retorno', 'Entrar', 'Teclado'] },
  keyboard_tab: { en: ['Tab', 'Keyboard'], es: ['Tabulador', 'Teclado'] },
  kitchen: { en: ['Kitchen', 'Food'], es: ['Cocina'] },
  label: { en: ['Label', 'Tag'], es: ['Etiqueta'] },
  landscape: { en: ['Landscape', 'Nature'], es: ['Paisaje'] },
  language: { en: ['Language', 'Translate'], es: ['Idioma', 'Traducir'] },
  laptop: { en: ['Laptop', 'Computer'], es: ['Laptop', 'Computadora'] },
  laptop_chromebook: { en: ['Laptop', 'Chromebook'], es: ['Laptop', 'Chromebook'] },
  laptop_mac: { en: ['Laptop', 'Mac'], es: ['Laptop', 'Mac'] },
  laptop_windows: { en: ['Laptop', 'Windows'], es: ['Laptop', 'Windows'] },
  launch: { en: ['Launch', 'Start'], es: ['Lanzar', 'Iniciar'] },
  layers: { en: ['Layers', 'Stack'], es: ['Capas'] },
  layers_clear: { en: ['Clear', 'Layers'], es: ['Borrar', 'Capas'] },
  leak_add: { en: ['Leak', 'Add'], es: ['Fuga', 'Agregar'] },
  leak_remove: { en: ['Leak', 'Remove'], es: ['Fuga', 'Eliminar'] },
  lens: { en: ['Lens', 'Focus'], es: ['Lente', 'Foco'] },
  library_books: { en: ['Library', 'Books'], es: ['Biblioteca', 'Libros'] },
  library_music: { en: ['Library', 'Music'], es: ['Biblioteca', 'Música'] },
  line_style: { en: ['Line', 'Style'], es: ['Línea', 'Estilo'] },
  line_weight: { en: ['Line', 'Weight'], es: ['Línea', 'Peso'] },
  linear_scale: { en: ['Scale', 'Adjust'], es: ['Escala', 'Ajustar'] },
  link: { en: ['Link', 'Connect'], es: ['Enlace', 'Conectar'] },
  linked_camera: { en: ['Camera', 'Link'], es: ['Cámara', 'Enlace'] },
  list: { en: ['List', 'Items'], es: ['Lista', 'Elementos'] },
  live_help: { en: ['Help', 'Support'], es: ['Ayuda', 'Soporte'] },
  live_tv: { en: ['Live', 'TV'], es: ['En vivo', 'TV'] },
  local_activity: { en: ['Activity', 'Local'], es: ['Actividad', 'Local'] },
  local_airport: { en: ['Airport', 'Travel'], es: ['Aeropuerto', 'Viaje'] },
  local_atm: { en: ['ATM', 'Money'], es: ['Cajero automático', 'Dinero'] },
  local_bar: { en: ['Bar', 'Drink'], es: ['Bar', 'Bebida'] },
  local_cafe: { en: ['Cafe', 'Coffee'], es: ['Café'] },
  local_car_wash: { en: ['Car Wash', 'Clean'], es: ['Lavado de coche', 'Limpiar'] },
  local_convenience_store: { en: ['Store', 'Shop'], es: ['Tienda', 'Comprar'] },
  local_dining: { en: ['Dining', 'Food'], es: ['Comedor', 'Comida'] },
  local_drink: { en: ['Drink', 'Beverage'], es: ['Bebida'] },
  local_florist: { en: ['Florist', 'Flowers'], es: ['Floristería', 'Flores'] },
  local_gas_station: { en: ['Gas Station', 'Fuel'], es: ['Estación de gasolina', 'Combustible'] },
  local_grocery_store: { en: ['Grocery Store', 'Food'], es: ['Tienda de comestibles', 'Comida'] },
  local_hospital: { en: ['Hospital', 'Health'], es: ['Hospital', 'Salud'] },
  local_hotel: { en: ['Hotel', 'Stay'], es: ['Hotel', 'Estancia'] },
  local_laundry_service: { en: ['Laundry', 'Service'], es: ['Lavandería', 'Servicio'] },
  local_library: { en: ['Library', 'Books'], es: ['Biblioteca', 'Libros'] },
  local_mall: { en: ['Mall', 'Shopping'], es: ['Centro comercial', 'Compras'] },
  local_movies: { en: ['Movies', 'Cinema'], es: ['Películas', 'Cine'] },
  local_offer: { en: ['Offer', 'Deal'], es: ['Oferta', 'Trato'] },
  local_parking: { en: ['Parking', 'Car'], es: ['Estacionamiento', 'Coche'] },
  local_pharmacy: { en: ['Pharmacy', 'Medicine'], es: ['Farmacia', 'Medicina'] },
  local_phone: { en: ['Phone', 'Call'], es: ['Teléfono', 'Llamada'] },
  local_pizza: { en: ['Pizza', 'Food'], es: ['Pizza', 'Comida'] },
  local_play: { en: ['Play', 'Theater'], es: ['Jugar', 'Teatro'] },
  local_post_office: { en: ['Post Office', 'Mail'], es: ['Oficina de correos', 'Correo'] },
  local_printshop: { en: ['Print', 'Shop'], es: ['Imprimir', 'Tienda'] },
  local_see: { en: ['View', 'See'], es: ['Ver', 'Mirar'] },
  local_taxi: { en: ['Taxi', 'Cab'], es: ['Taxi'] },
  location_city: { en: ['City', 'Urban'], es: ['Ciudad', 'Urbano'] },
  location_disabled: { en: ['Location', 'Disabled'], es: ['Ubicación', 'Deshabilitado'] },
  location_off: { en: ['Location', 'Off'], es: ['Ubicación', 'Apagado'] },
  location_on: { en: ['Location', 'On'], es: ['Ubicación', 'Encendido'] },
  location_searching: { en: ['Location', 'Searching'], es: ['Ubicación', 'Buscando'] },
  lock: { en: ['Lock', 'Secure'], es: ['Cerradura', 'Seguro'] },
  lock_open: { en: ['Unlock', 'Open'], es: ['Desbloquear', 'Abrir'] },
  looks: { en: ['Looks', 'Appearance'], es: ['Apariencia'] },
  looks_3: { en: ['Looks', 'Three'], es: ['Apariencia', 'Tres'] },
  looks_4: { en: ['Looks', 'Four'], es: ['Apariencia', 'Cuatro'] },
  looks_5: { en: ['Looks', 'Five'], es: ['Apariencia', 'Cinco'] },
  looks_6: { en: ['Looks', 'Six'], es: ['Apariencia', 'Seis'] },
  looks_one: { en: ['Looks', 'One'], es: ['Apariencia', 'Uno'] },
  looks_two: { en: ['Looks', 'Two'], es: ['Apariencia', 'Dos'] },
  loop: { en: ['Repeat', 'Loop'], es: ['Repetir', 'Bucle'] },
  loupe: { en: ['Magnify', 'Loupe'], es: ['Ampliar', 'Lupa'] },
  low_priority: { en: ['Low', 'Priority'], es: ['Baja', 'Prioridad'] },
  loyalty: { en: ['Loyalty', 'Faithful'], es: ['Lealtad', 'Fiel'] },
  mail_outline: { en: ['Mail', 'Outline'], es: ['Correo', 'Contorno'] },
  map: { en: ['Map', 'Navigate'], es: ['Mapa', 'Navegar'] },
  markunread: { en: ['Mail', 'Unread'], es: ['Correo', 'No leído'] },
  markunread_mailbox: { en: ['Mailbox', 'Unread'], es: ['Buzón', 'No leído'] },
  memory: { en: ['Memory', 'RAM'], es: ['Memoria'] },
  merge_type: { en: ['Merge', 'Type'], es: ['Combinar', 'Tipo'] },
  message: { en: ['Message', 'Chat'], es: ['Mensaje', 'Chat'] },
  mic: { en: ['Microphone', 'Voice'], es: ['Micrófono', 'Voz'] },
  mic_none: { en: ['Microphone', 'Mute'], es: ['Micrófono', 'Mudo'] },
  mic_off: { en: ['Microphone', 'Off'], es: ['Micrófono', 'Apagado'] },
  mms: { en: ['MMS', 'Multimedia'], es: ['MMS', 'Multimedia'] },
  mode_comment: { en: ['Comment', 'Feedback'], es: ['Comentario', 'Retroalimentación'] },
  mode_edit: { en: ['Edit', 'Modify'], es: ['Editar', 'Modificar'] },
  monetization_on: { en: ['Money', 'Monetize'], es: ['Dinero', 'Monetizar'] },
  money_off: { en: ['Money', 'Off'], es: ['Dinero', 'Descuento'] },
  monochrome_photos: { en: ['Photos', 'Monochrome'], es: ['Fotos', 'Monocromo'] },
  more: { en: ['More', 'Additional'], es: ['Más', 'Adicional'] },
  motorcycle: { en: ['Motorcycle', 'Bike'], es: ['Motocicleta'] },
  mouse: { en: ['Mouse', 'Click'], es: ['Ratón', 'Clic'] },
  move_to_inbox: { en: ['Move', 'Inbox'], es: ['Mover', 'Buzón'] },
  movie: { en: ['Movie', 'Film'], es: ['Película'] },
  movie_creation: { en: ['Movie', 'Creation'], es: ['Película', 'Creación'] },
  movie_filter: { en: ['Movie', 'Filter'], es: ['Película', 'Filtro'] },
  multiline_chart: { en: ['Chart', 'Multiline'], es: ['Gráfico', 'Multilínea'] },
  music_note: { en: ['Music', 'Note'], es: ['Música', 'Nota'] },
  music_video: { en: ['Music', 'Video'], es: ['Música', 'Vídeo'] },
  my_location: { en: ['My Location', 'Here'], es: ['Mi ubicación', 'Aquí'] },
  nature: { en: ['Nature', 'Environment'], es: ['Naturaleza', 'Medio ambiente'] },
  nature_people: { en: ['Nature', 'People'], es: ['Naturaleza', 'Personas'] },
  navigate_before: { en: ['Navigate', 'Previous'], es: ['Navegar', 'Anterior'] },
  navigate_next: { en: ['Navigate', 'Next'], es: ['Navegar', 'Siguiente'] },
  navigation: { en: ['Navigation', 'Map'], es: ['Navegación', 'Mapa'] },
  near_me: { en: ['Near Me', 'Closeby'], es: ['Cerca de mí'] },
  network_cell: { en: ['Network', 'Cell'], es: ['Red', 'Celda'] },
  network_check: { en: ['Network', 'Check'], es: ['Red', 'Verificar'] },
  network_locked: { en: ['Network', 'Locked'], es: ['Red', 'Bloqueado'] },
  network_wifi: { en: ['Network', 'Wifi'], es: ['Red', 'Wifi'] },
  new_releases: { en: ['New', 'Release'], es: ['Nuevo', 'Lanzamiento'] },
  next_week: { en: ['Next', 'Week'], es: ['Próxima', 'Semana'] },
  nfc: { en: ['NFC', 'Technology'], es: ['NFC', 'Tecnología'] },
  no_encryption: { en: ['No', 'Encryption'], es: ['Sin', 'Cifrado'] },
  no_sim: { en: ['No', 'Sim Card'], es: ['Sin', 'Tarjeta SIM'] },
  not_interested: { en: ['Not Interested', 'Ignore'], es: ['No interesado', 'Ignorar'] },
  note: { en: ['Note', 'Text', 'Write'], es: ['Nota', 'Texto', 'Escribir'] },
  note_add: { en: ['Note', 'Add', 'Create'], es: ['Nota', 'Añadir', 'Crear'] },
  notifications: { en: ['Notifications', 'Alerts'], es: ['Notificaciones', 'Alertas'] },
  notifications_active: {
    en: ['Notifications', 'Active', 'Alerts'],
    es: ['Notificaciones', 'Activo', 'Alertas'],
  },
  notifications_none: {
    en: ['Notifications', 'None', 'Silent'],
    es: ['Notificaciones', 'Ninguna', 'Silencio'],
  },
  notifications_off: {
    en: ['Notifications', 'Off', 'Disable'],
    es: ['Notificaciones', 'Apagar', 'Desactivar'],
  },
  notifications_paused: {
    en: ['Notifications', 'Paused', 'Hold'],
    es: ['Notificaciones', 'Pausado', 'Espera'],
  },
  offline_pin: { en: ['Offline', 'Pin', 'Save'], es: ['Sin conexión', 'Anclar', 'Guardar'] },
  ondemand_video: { en: ['Video', 'On Demand'], es: ['Video', 'A pedido'] },
  opacity: { en: ['Opacity', 'Visibility'], es: ['Opacidad', 'Visibilidad'] },
  open_in_browser: { en: ['Open', 'Browser', 'Web'], es: ['Abrir', 'Navegador', 'Web'] },
  pages: { en: ['Pages', 'Documents'], es: ['Páginas', 'Documentos'] },
  pageview: { en: ['Page View', 'Browse'], es: ['Vista de página', 'Navegar'] },
  palette: { en: ['Palette', 'Colors'], es: ['Paleta', 'Colores'] },
  pan_tool: { en: ['Pan', 'Move', 'Drag'], es: ['Desplazar', 'Mover', 'Arrastrar'] },
  panorama: { en: ['Panorama', 'View'], es: ['Panorama', 'Vista'] },
  panorama_fish_eye: { en: ['Fish Eye', 'Lens'], es: ['Ojo de pez', 'Lente'] },
  panorama_horizontal: {
    en: ['Horizontal Panorama', 'Landscape'],
    es: ['Panorama horizontal', 'Paisaje'],
  },
  panorama_vertical: {
    en: ['Vertical Panorama', 'Portrait'],
    es: ['Panorama vertical', 'Retrato'],
  },
  panorama_wide_angle: { en: ['Wide Angle', 'View'], es: ['Ángulo amplio', 'Vista'] },
  party_mode: { en: ['Party', 'Fun'], es: ['Fiesta', 'Diversión'] },
  pause: { en: ['Pause', 'Stop', 'Wait'], es: ['Pausa', 'Detener', 'Esperar'] },
  pause_circle_filled: { en: ['Pause', 'Circle'], es: ['Pausa', 'Círculo'] },
  pause_circle_outline: { en: ['Pause', 'Outline'], es: ['Pausa', 'Contorno'] },
  payment: { en: ['Payment', 'Money', 'Transaction'], es: ['Pago', 'Dinero', 'Transacción'] },
  people: { en: ['People', 'Users', 'Group'], es: ['Personas', 'Usuarios', 'Grupo'] },
  people_outline: { en: ['People', 'Outline', 'Users'], es: ['Personas', 'Contorno', 'Usuarios'] },
  perm_camera_mic: {
    en: ['Camera', 'Microphone', 'Permissions'],
    es: ['Cámara', 'Micrófono', 'Permisos'],
  },
  perm_contact_calendar: {
    en: ['Contacts', 'Calendar', 'Permissions'],
    es: ['Contactos', 'Calendario', 'Permisos'],
  },
  perm_data_setting: {
    en: ['Data', 'Settings', 'Permissions'],
    es: ['Datos', 'Configuraciones', 'Permisos'],
  },
  perm_device_information: {
    en: ['Device', 'Information', 'Permissions'],
    es: ['Dispositivo', 'Información', 'Permisos'],
  },
  perm_identity: { en: ['Profile', 'User', 'Identity'], es: ['Perfil', 'Usuario', 'Identidad'] },
  perm_media: { en: ['Media', 'Gallery', 'Photos'], es: ['Medios', 'Galería', 'Fotos'] },
  perm_phone_msg: {
    en: ['Phone', 'Message', 'Communication'],
    es: ['Teléfono', 'Mensaje', 'Comunicación'],
  },
  perm_scan_wifi: {
    en: ['WiFi', 'Scan', 'Connectivity'],
    es: ['WiFi', 'Escanear', 'Conectividad'],
  },
  person_outline: { en: ['Person', 'User', 'Profile'], es: ['Persona', 'Usuario', 'Perfil'] },
  person_pin: { en: ['Location', 'Person', 'Map'], es: ['Ubicación', 'Persona', 'Mapa'] },
  person_pin_circle: { en: ['Location', 'Pin', 'Map'], es: ['Ubicación', 'Pin', 'Mapa'] },
  personal_video: { en: ['Video', 'Personal', 'Media'], es: ['Video', 'Personal', 'Medios'] },
  phone: { en: ['Phone', 'Call', 'Communication'], es: ['Teléfono', 'Llamada', 'Comunicación'] },
  phone_android: { en: ['Phone', 'Android', 'Device'], es: ['Teléfono', 'Android', 'Dispositivo'] },
  phone_bluetooth_speaker: {
    en: ['Phone', 'Bluetooth', 'Speaker'],
    es: ['Teléfono', 'Bluetooth', 'Altavoz'],
  },
  phone_forwarded: { en: ['Phone', 'Forward', 'Call'], es: ['Teléfono', 'Reenviar', 'Llamada'] },
  phone_in_talk: {
    en: ['Phone', 'Call', 'In Talk'],
    es: ['Teléfono', 'Llamada', 'En conversación'],
  },
  phone_locked: { en: ['Phone', 'Locked'], es: ['Teléfono', 'Bloqueado'] },
  phone_missed: { en: ['Phone', 'Missed Call'], es: ['Teléfono', 'Llamada perdida'] },
  phone_paused: { en: ['Phone', 'Paused'], es: ['Teléfono', 'Pausado'] },
  phonelink: { en: ['Phone', 'Link'], es: ['Teléfono', 'Enlace'] },
  phonelink_erase: { en: ['Phone', 'Erase'], es: ['Teléfono', 'Borrar'] },
  phonelink_lock: { en: ['Phone', 'Lock'], es: ['Teléfono', 'Bloquear'] },
  phonelink_off: { en: ['Phone', 'Disconnected'], es: ['Teléfono', 'Desconectado'] },
  phonelink_ring: { en: ['Phone', 'Ring'], es: ['Teléfono', 'Sonar'] },
  phonelink_setup: { en: ['Phone', 'Setup'], es: ['Teléfono', 'Configuración'] },
  photo: { en: ['Photo', 'Image'], es: ['Foto', 'Imagen'] },
  photo_album: { en: ['Photo Album'], es: ['Álbum de fotos'] },
  photo_camera: { en: ['Camera', 'Photo'], es: ['Cámara', 'Foto'] },
  photo_filter: { en: ['Photo', 'Filter'], es: ['Foto', 'Filtro'] },
  photo_library: { en: ['Photo', 'Library'], es: ['Foto', 'Biblioteca'] },
  photo_size_select_actual: { en: ['Photo', 'Actual Size'], es: ['Foto', 'Tamaño actual'] },
  photo_size_select_large: { en: ['Photo', 'Large Size'], es: ['Foto', 'Tamaño grande'] },
  photo_size_select_small: { en: ['Photo', 'Small Size'], es: ['Foto', 'Tamaño pequeño'] },
  picture_as_pdf: { en: ['Picture', 'PDF'], es: ['Imagen', 'PDF'] },
  picture_in_picture: { en: ['Picture in Picture'], es: ['Imagen en imagen'] },
  picture_in_picture_alt: {
    en: ['Alternative', 'Picture in Picture'],
    es: ['Alternativa', 'Imagen en imagen'],
  },
  pie_chart: { en: ['Pie Chart'], es: ['Gráfico circular'] },
  place: { en: ['Place', 'Location'], es: ['Lugar', 'Ubicación'] },
  play_for_work: { en: ['Work', 'Play'], es: ['Trabajar', 'Jugar'] },
  playlist_add: { en: ['Playlist', 'Add'], es: ['Lista de reproducción', 'Agregar'] },
  playlist_add_check: { en: ['Playlist', 'Check'], es: ['Lista de reproducción', 'Verificar'] },
  playlist_play: { en: ['Playlist', 'Play'], es: ['Lista de reproducción', 'Reproducir'] },
  plus_one: { en: ['Plus One'], es: ['Más uno'] },
  poll: { en: ['Poll', 'Survey'], es: ['Encuesta'] },
  polymer: { en: ['Polymer', 'Molecule'], es: ['Polímero', 'Molécula'] },
  pool: { en: ['Pool', 'Swimming'], es: ['Piscina', 'Natación'] },
  portable_wifi_off: { en: ['Wifi', 'Offline'], es: ['Wifi', 'Sin conexión'] },
  portrait: { en: ['Portrait', 'Picture'], es: ['Retrato', 'Imagen'] },
  power: { en: ['Power', 'On/Off'], es: ['Poder', 'Encender/Apagar'] },
  power_input: { en: ['Power Input'], es: ['Entrada de energía'] },
  power_settings_new: { en: ['Power Settings'], es: ['Configuraciones de energía'] },
  present_to_all: { en: ['Presentation', 'Share'], es: ['Presentación', 'Compartir'] },
  print: { en: ['Print', 'Output'], es: ['Imprimir'] },
  priority_high: { en: ['Priority', 'High'], es: ['Prioridad', 'Alta'] },
  query_builder: { en: ['Query', 'Builder', 'Search'], es: ['Consulta', 'Constructor', 'Buscar'] },
  question_answer: { en: ['Question', 'Answer'], es: ['Pregunta', 'Respuesta'] },
  queue: { en: ['Queue', 'Line'], es: ['Cola', 'Fila'] },
  queue_music: { en: ['Queue', 'Music'], es: ['Cola', 'Música'] },
  queue_play_next: { en: ['Next', 'Play', 'Queue'], es: ['Siguiente', 'Reproducir', 'Cola'] },
  radio: { en: ['Radio', 'Broadcast'], es: ['Radio', 'Transmisión'] },
  rate_review: { en: ['Rate', 'Review'], es: ['Calificar', 'Revisar'] },
  receipt: { en: ['Receipt', 'Invoice'], es: ['Recibo', 'Factura'] },
  recent_actors: { en: ['Recent', 'Actors'], es: ['Reciente', 'Actores'] },
  record_voice_over: { en: ['Record', 'Voice'], es: ['Grabar', 'Voz'] },
  redeem: { en: ['Redeem', 'Coupon'], es: ['Canjear', 'Cupón'] },
  remove_circle: { en: ['Remove', 'Delete'], es: ['Remover', 'Eliminar'] },
  remove_circle_outline: { en: ['Remove', 'Outline'], es: ['Remover', 'Contorno'] },
  remove_from_queue: { en: ['Remove', 'Queue'], es: ['Remover', 'Cola'] },
  remove_red_eye: { en: ['Remove', 'Eye', 'View'], es: ['Remover', 'Ojo', 'Ver'] },
  remove_shopping_cart: { en: ['Remove', 'Cart'], es: ['Remover', 'Carrito'] },
  reorder: { en: ['Reorder', 'Organize'], es: ['Reorganizar'] },
  repeat: { en: ['Repeat', 'Again'], es: ['Repetir'] },
  repeat_one: { en: ['Repeat', 'One'], es: ['Repetir', 'Uno'] },
  replay: { en: ['Replay', 'Again'], es: ['Repetir'] },
  replay_10: { en: ['Replay', '10 Seconds'], es: ['Repetir', '10 Segundos'] },
  replay_30: { en: ['Replay', '30 Seconds'], es: ['Repetir', '30 Segundos'] },
  replay_5: { en: ['Replay', '5 Seconds'], es: ['Repetir', '5 Segundos'] },
  report: { en: ['Report', 'Notify'], es: ['Reportar', 'Notificar'] },
  report_problem: { en: ['Report', 'Problem'], es: ['Reportar', 'Problema'] },
  restaurant: { en: ['Restaurant', 'Food'], es: ['Restaurante', 'Comida'] },
  restaurant_menu: { en: ['Menu', 'Food'], es: ['Menú', 'Comida'] },
  restore: { en: ['Restore', 'Reload'], es: ['Restaurar', 'Recargar'] },
  restore_page: { en: ['Restore', 'Page'], es: ['Restaurar', 'Página'] },
  ring_volume: { en: ['Ring', 'Volume'], es: ['Anillo', 'Volumen'] },
  room: { en: ['Room', 'Space'], es: ['Habitación', 'Espacio'] },
  room_service: { en: ['Room', 'Service'], es: ['Habitación', 'Servicio'] },
  rotate_90_degrees_ccw: { en: ['Rotate', '90 Degrees'], es: ['Rotar', '90 Grados'] },
  rotate_left: { en: ['Rotate', 'Left'], es: ['Rotar', 'Izquierda'] },
  rotate_right: { en: ['Rotate', 'Right'], es: ['Rotar', 'Derecha'] },
  rounded_corner: { en: ['Rounded', 'Corner'], es: ['Esquina', 'Redondeada'] },
  router: { en: ['Router', 'Internet'], es: ['Router', 'Internet'] },
  rowing: { en: ['Rowing', 'Sport'], es: ['Remo'] },
  rss_feed: { en: ['RSS', 'Feed'], es: ['RSS', 'Fuente'] },
  rv_hookup: { en: ['RV', 'Hookup'], es: ['RV', 'Conexión'] },
  satellite: { en: ['Satellite', 'Space'], es: ['Satélite', 'Espacio'] },
  save: { en: ['Save', 'Download'], es: ['Guardar', 'Descargar'] },
  scanner: { en: ['Scanner', 'Scan'], es: ['Escáner', 'Escanear'] },
  schedule: { en: ['Schedule', 'Plan'], es: ['Horario', 'Plan'] },
  screen_lock_landscape: { en: ['Screen', 'Lock'], es: ['Pantalla', 'Bloqueo'] },
  screen_lock_portrait: { en: ['Screen', 'Portrait'], es: ['Pantalla', 'Retrato'] },
  screen_lock_rotation: { en: ['Screen', 'Rotation'], es: ['Pantalla', 'Rotación'] },
  screen_rotation: { en: ['Screen', 'Rotate'], es: ['Pantalla', 'Rotar'] },
  screen_share: { en: ['Share', 'Screen'], es: ['Compartir', 'Pantalla'] },
  sd_card: { en: ['SD Card', 'Storage'], es: ['Tarjeta SD', 'Almacenamiento'] },
  sd_storage: { en: ['SD Storage', 'Memory'], es: ['Almacenamiento SD', 'Memoria'] },
  security: { en: ['Security', 'Protection'], es: ['Seguridad', 'Protección'] },
  send: { en: ['Send', 'Forward'], es: ['Enviar'] },
  settings_bluetooth: { en: ['Bluetooth', 'Settings'], es: ['Bluetooth', 'Configuraciones'] },
  settings_brightness: { en: ['Brightness', 'Settings'], es: ['Brillo', 'Configuraciones'] },
  settings_cell: { en: ['Cellular', 'Settings'], es: ['Celular', 'Configuraciones'] },
  settings_ethernet: { en: ['Ethernet', 'Settings'], es: ['Ethernet', 'Configuraciones'] },
  settings_input_antenna: { en: ['Antenna', 'Input'], es: ['Antena', 'Entrada'] },
  settings_input_component: { en: ['Component', 'Input'], es: ['Componente', 'Entrada'] },
  settings_input_composite: { en: ['Composite', 'Input'], es: ['Compuesto', 'Entrada'] },
  settings_input_hdmi: { en: ['HDMI', 'Input'], es: ['HDMI', 'Entrada'] },
  settings_input_svideo: { en: ['S-Video', 'Input'], es: ['S-Video', 'Entrada'] },
  settings_overscan: { en: ['Overscan', 'Settings'], es: ['Overscan', 'Configuraciones'] },
  settings_phone: { en: ['Phone', 'Settings'], es: ['Teléfono', 'Configuraciones'] },
  settings_power: { en: ['Power', 'Settings'], es: ['Energía', 'Configuraciones'] },
  settings_remote: { en: ['Remote', 'Settings'], es: ['Remoto', 'Configuraciones'] },
  settings_system_daydream: { en: ['Daydream', 'System'], es: ['Daydream', 'Sistema'] },
  settings_voice: { en: ['Voice', 'Settings'], es: ['Voz', 'Configuraciones'] },
  shop: { en: ['Shop', 'Buy'], es: ['Tienda', 'Comprar'] },
  shop_two: { en: ['Shop', 'Market'], es: ['Tienda', 'Mercado'] },
  shopping_basket: { en: ['Shopping', 'Basket'], es: ['Compra', 'Cesta'] },
  short_text: { en: ['Text', 'Short'], es: ['Texto', 'Corto'] },
  show_chart: { en: ['Chart', 'Display'], es: ['Gráfico', 'Mostrar'] },
  shuffle: { en: ['Shuffle', 'Random'], es: ['Aleatorio'] },
  signal_cellular_4_bar: { en: ['Signal', 'Cellular'], es: ['Señal', 'Celular'] },
  signal_cellular_connected_no_internet_4_bar: {
    en: ['No Internet', 'Signal'],
    es: ['Sin Internet', 'Señal'],
  },
  signal_cellular_no_sim: { en: ['No SIM', 'Signal'], es: ['Sin SIM', 'Señal'] },
  signal_cellular_null: { en: ['No Signal', 'Cellular'], es: ['Sin Señal', 'Celular'] },
  signal_cellular_off: { en: ['Off', 'Signal'], es: ['Apagar', 'Señal'] },
  signal_wifi_4_bar: { en: ['WiFi', 'Signal'], es: ['WiFi', 'Señal'] },
  signal_wifi_4_bar_lock: { en: ['WiFi', 'Lock'], es: ['WiFi', 'Bloqueo'] },
  signal_wifi_off: { en: ['WiFi Off', 'No Signal'], es: ['WiFi Apagado', 'Sin Señal'] },
  sim_card: { en: ['SIM Card'], es: ['Tarjeta SIM'] },
  sim_card_alert: { en: ['SIM Alert', 'Warning'], es: ['Alerta SIM', 'Advertencia'] },
  skip_next: { en: ['Next', 'Forward'], es: ['Siguiente', 'Adelante'] },
  skip_previous: { en: ['Previous', 'Back'], es: ['Previo', 'Atrás'] },
  slideshow: { en: ['Slideshow', 'Presentation'], es: ['Presentación'] },
  slow_motion_video: { en: ['Video', 'Slow Motion'], es: ['Video', 'Cámara lenta'] },
  smartphone: { en: ['Smartphone', 'Mobile'], es: ['Smartphone', 'Móvil'] },
  smoke_free: { en: ['Smoke Free', 'No Smoking'], es: ['Sin humo', 'No fumar'] },
  smoking_rooms: { en: ['Smoking', 'Permitted'], es: ['Fumar', 'Permitido'] },
  sms: { en: ['SMS', 'Text Message'], es: ['SMS', 'Mensaje de texto'] },
  sms_failed: { en: ['SMS Failed', 'Error'], es: ['SMS Fallido', 'Error'] },
  snooze: { en: ['Snooze', 'Delay'], es: ['Posponer'] },
  spa: { en: ['Spa', 'Relax'], es: ['Spa', 'Relajarse'] },
  space_bar: { en: ['Space', 'Empty'], es: ['Espacio', 'Vacío'] },
  speaker: { en: ['Speaker', 'Audio'], es: ['Altavoz'] },
  speaker_group: { en: ['Speakers', 'Group'], es: ['Altavoces', 'Grupo'] },
  speaker_notes: { en: ['Notes', 'Speaker'], es: ['Notas'] },
  speaker_notes_off: { en: ['Mute Notes', 'Off'], es: ['Notas silenciadas'] },
  speaker_phone: { en: ['Speakerphone', 'Call'], es: ['Manos libres', 'Llamada'] },
  spellcheck: { en: ['Spellcheck', 'Correct'], es: ['Corrector ortográfico'] },
  star_border: { en: ['Star', 'Outline'], es: ['Estrella', 'Contorno'] },
  stars: { en: ['Stars', 'Favorite'], es: ['Estrellas', 'Favorito'] },
  stay_current_landscape: { en: ['Landscape', 'Orientation'], es: ['Paisaje', 'Orientación'] },
  stay_current_portrait: { en: ['Portrait', 'Orientation'], es: ['Retrato', 'Orientación'] },
  stay_primary_landscape: { en: ['Primary', 'Landscape'], es: ['Primario', 'Paisaje'] },
  stay_primary_portrait: { en: ['Primary', 'Portrait'], es: ['Primario', 'Retrato'] },
  stop: { en: ['Stop', 'Halt'], es: ['Detener'] },
  stop_screen_share: { en: ['Stop Sharing', 'Screen'], es: ['Detener compartir', 'Pantalla'] },
  storage: { en: ['Storage', 'Save'], es: ['Almacenamiento'] },
  store: { en: ['Store', 'Shop'], es: ['Tienda'] },
  store_mall_directory: { en: ['Mall', 'Directory'], es: ['Centro comercial', 'Directorio'] },
  straighten: { en: ['Straighten', 'Align'], es: ['Enderezar', 'Alinear'] },
  streetview: { en: ['Street', 'View'], es: ['Vista de calle'] },
  strikethrough_s: { en: ['Strikethrough', 'Cancel'], es: ['Tachado'] },
  style: { en: ['Style', 'Design'], es: ['Estilo', 'Diseño'] },
  subject: { en: ['Subject', 'Topic'], es: ['Asunto', 'Tema'] },
  subscriptions: { en: ['Subscriptions', 'Follow'], es: ['Suscripciones', 'Seguir'] },
  subtitles: { en: ['Subtitles', 'Text'], es: ['Subtítulos', 'Texto'] },
  subway: { en: ['Subway', 'Transport'], es: ['Metro', 'Transporte'] },
  supervisor_account: { en: ['Supervisor', 'Admin'], es: ['Supervisor', 'Admin'] },
  surround_sound: { en: ['Sound', 'Audio'], es: ['Sonido envolvente'] },
  swap_calls: { en: ['Swap', 'Calls'], es: ['Intercambiar', 'Llamadas'] },
  switch_camera: { en: ['Switch', 'Camera'], es: ['Cambiar', 'Cámara'] },
  switch_video: { en: ['Switch', 'Video'], es: ['Cambiar', 'Video'] },
  system_update: { en: ['Update', 'System'], es: ['Actualizar', 'Sistema'] },
  tab: { en: ['Tab', 'Section'], es: ['Pestaña', 'Sección'] },
  tab_unselected: { en: ['Tab', 'Unselected'], es: ['Pestaña', 'No seleccionada'] },
  tablet: { en: ['Tablet', 'Device'], es: ['Tableta', 'Dispositivo'] },
  tablet_android: { en: ['Android', 'Device'], es: ['Android', 'Dispositivo'] },
  tablet_mac: { en: ['Mac', 'Device'], es: ['Mac', 'Dispositivo'] },
  tag_faces: { en: ['Face', 'Emotion'], es: ['Cara', 'Emoción'] },
  tap_and_play: { en: ['Play', 'Stream'], es: ['Jugar', 'Transmitir'] },
  terrain: { en: ['Terrain', 'Map'], es: ['Terreno', 'Mapa'] },
  text_fields: { en: ['Text', 'Field'], es: ['Texto', 'Campo'] },
  text_format: { en: ['Format', 'Text'], es: ['Formato', 'Texto'] },
  textsms: { en: ['Text', 'Message'], es: ['Texto', 'Mensaje'] },
  texture: { en: ['Texture', 'Pattern'], es: ['Textura', 'Patrón'] },
  theaters: { en: ['Theater', 'Cinema'], es: ['Teatro', 'Cine'] },
  time_to_leave: { en: ['Leave', 'Time'], es: ['Salir', 'Tiempo'] },
  timelapse: { en: ['Timelapse', 'Time'], es: ['Timelapse', 'Tiempo'] },
  timeline: { en: ['Timeline', 'Track'], es: ['Línea de tiempo', 'Seguimiento'] },
  timer: { en: ['Timer', 'Clock'], es: ['Temporizador', 'Reloj'] },
  timer_10: { en: ['10 Minutes', 'Timer'], es: ['10 Minutos', 'Temporizador'] },
  timer_3: { en: ['3 Minutes', 'Timer'], es: ['3 Minutos', 'Temporizador'] },
  timer_off: { en: ['Off', 'Timer'], es: ['Apagar', 'Temporizador'] },
  title: { en: ['Title', 'Heading'], es: ['Título', 'Encabezado'] },
  toc: { en: ['Table of Contents', 'Menu'], es: ['Tabla de contenido', 'Menú'] },
  today: { en: ['Today', 'Date'], es: ['Hoy', 'Fecha'] },
  toll: { en: ['Toll', 'Charge'], es: ['Peaje', 'Cargo'] },
  tonality: { en: ['Tone', 'Color'], es: ['Tono', 'Color'] },
  touch_app: { en: ['Touch', 'App'], es: ['Tocar', 'Aplicación'] },
  toys: { en: ['Toys', 'Play'], es: ['Juguetes', 'Jugar'] },
  track_changes: { en: ['Track', 'Changes'], es: ['Rastrear', 'Cambios'] },
  traffic: { en: ['Traffic', 'Cars'], es: ['Tráfico', 'Coches'] },
  train: { en: ['Train', 'Transport'], es: ['Tren', 'Transporte'] },
  tram: { en: ['Tram', 'Transport'], es: ['Tranvía', 'Transporte'] },
  transfer_within_a_station: { en: ['Transfer', 'Station'], es: ['Transferir', 'Estación'] },
  transform: { en: ['Transform', 'Change'], es: ['Transformar', 'Cambiar'] },
  translate: { en: ['Translate', 'Language'], es: ['Traducir', 'Idioma'] },
  trending_down: { en: ['Down', 'Trending'], es: ['Bajando', 'Tendencia'] },
  trending_flat: { en: ['Flat', 'Stable'], es: ['Plano', 'Estable'] },
  trending_up: { en: ['Up', 'Trending'], es: ['Subiendo', 'Tendencia'] },
  tune: { en: ['Tune', 'Settings'], es: ['Afinar', 'Configuraciones'] },
  turned_in: { en: ['Submit', 'Turn In'], es: ['Enviar', 'Entregar'] },
  turned_in_not: { en: ['Not Submitted', 'Pending'], es: ['No enviado', 'Pendiente'] },
  tv: { en: ['TV', 'Television'], es: ['TV', 'Televisión'] },
  unarchive: { en: ['Unarchive', 'Restore'], es: ['Desarchivar', 'Restaurar'] },
  update: { en: ['Update', 'Refresh'], es: ['Actualizar', 'Refrescar'] },
  usb: { en: ['USB', 'Connection'], es: ['USB', 'Conexión'] },
  verified_user: { en: ['Verified', 'User'], es: ['Verificado', 'Usuario'] },
  vertical_align_bottom: { en: ['Align', 'Bottom'], es: ['Alinear', 'Abajo'] },
  vertical_align_center: { en: ['Align', 'Center'], es: ['Alinear', 'Centro'] },
  vertical_align_top: { en: ['Align', 'Top'], es: ['Alinear', 'Arriba'] },
  vibration: { en: ['Vibration', 'Alert'], es: ['Vibración', 'Alerta'] },
  video_call: { en: ['Video', 'Call'], es: ['Video', 'Llamada'] },
  video_label: { en: ['Video', 'Label'], es: ['Video', 'Etiqueta'] },
  video_library: { en: ['Video', 'Library'], es: ['Video', 'Biblioteca'] },
  videocam: { en: ['Camera', 'Video'], es: ['Cámara', 'Video'] },
  videocam_off: { en: ['Camera Off', 'Video'], es: ['Cámara apagada', 'Video'] },
  videogame_asset: { en: ['Videogame', 'Game', 'Play'], es: ['Videojuego', 'Jugar'] },
  view_agenda: { en: ['Agenda', 'Schedule'], es: ['Agenda', 'Horario'] },
  view_array: { en: ['Array', 'List', 'Grid'], es: ['Arreglo', 'Lista', 'Grilla'] },
  view_carousel: { en: ['Carousel', 'Images'], es: ['Carrusel', 'Imágenes'] },
  view_column: { en: ['Column', 'Layout'], es: ['Columna', 'Diseño'] },
  view_comfy: { en: ['Comfy', 'Layout', 'Grid'], es: ['Cómodo', 'Diseño', 'Grilla'] },
  view_compact: { en: ['Compact', 'Small'], es: ['Compacto', 'Pequeño'] },
  view_day: { en: ['Day', 'Daily'], es: ['Día', 'Diario'] },
  view_headline: { en: ['Headline', 'Title'], es: ['Titular', 'Título'] },
  view_list: { en: ['List', 'Items'], es: ['Lista', 'Elementos'] },
  view_module: { en: ['Module', 'Grid'], es: ['Módulo', 'Grilla'] },
  view_quilt: { en: ['Quilt', 'Grid'], es: ['Colcha', 'Grilla'] },
  view_stream: { en: ['Stream', 'Flow'], es: ['Transmitir', 'Flujo'] },
  view_week: { en: ['Week', 'Schedule'], es: ['Semana', 'Horario'] },
  vignette: { en: ['Vignette', 'Effect'], es: ['Viñeta', 'Efecto'] },
  visibility: { en: ['Visibility', 'See', 'Show'], es: ['Visibilidad', 'Ver', 'Mostrar'] },
  visibility_off: { en: ['Visibility Off', 'Hide'], es: ['Visibilidad apagada', 'Ocultar'] },
  voice_chat: { en: ['Voice', 'Chat', 'Talk'], es: ['Voz', 'Chat', 'Hablar'] },
  voicemail: { en: ['Voicemail', 'Message'], es: ['Correo de voz', 'Mensaje'] },
  volume_down: { en: ['Volume', 'Down', 'Lower'], es: ['Volumen', 'Bajar'] },
  volume_mute: { en: ['Mute', 'Silent'], es: ['Silenciar', 'Sin sonido'] },
  volume_off: { en: ['Volume Off', 'Silent'], es: ['Volumen apagado', 'Sin sonido'] },
  volume_up: { en: ['Volume', 'Up', 'Raise'], es: ['Volumen', 'Subir'] },
  vpn_key: { en: ['VPN', 'Key', 'Security'], es: ['VPN', 'Clave', 'Seguridad'] },
  vpn_lock: { en: ['VPN', 'Lock', 'Secure'], es: ['VPN', 'Bloquear', 'Seguro'] },
  wallpaper: { en: ['Wallpaper', 'Background'], es: ['Fondo de pantalla'] },
  warning: { en: ['Warning', 'Alert'], es: ['Advertencia', 'Alerta'] },
  watch: { en: ['Watch', 'Time'], es: ['Reloj', 'Tiempo'] },
  watch_later: { en: ['Watch Later', 'Save'], es: ['Ver después', 'Guardar'] },
  wb_auto: { en: ['Auto', 'White Balance'], es: ['Auto', 'Balance de blancos'] },
  wb_cloudy: { en: ['Cloudy', 'Weather'], es: ['Nublado', 'Clima'] },
  wb_incandescent: { en: ['Light', 'Brightness'], es: ['Luz', 'Brillo'] },
  wb_iridescent: { en: ['Iridescent', 'Color'], es: ['Iridiscente', 'Color'] },
  wb_sunny: { en: ['Sunny', 'Weather'], es: ['Soleado', 'Clima'] },
  web: { en: ['Web', 'Browser'], es: ['Web', 'Navegador'] },
  web_asset: { en: ['Web Asset', 'Online'], es: ['Activo web', 'En línea'] },
  weekend: { en: ['Weekend', 'Relax'], es: ['Fin de semana', 'Relajarse'] },
  widgets: { en: ['Widgets', 'Tools'], es: ['Widgets', 'Herramientas'] },
  wifi: { en: ['Wifi', 'Internet', 'Connection'], es: ['Wifi', 'Internet', 'Conexión'] },
  wifi_lock: { en: ['Wifi Lock', 'Secure'], es: ['Wifi bloqueado', 'Seguro'] },
  wifi_tethering: { en: ['Wifi Tethering', 'Share'], es: ['Wifi anclaje', 'Compartir'] },
  work: { en: ['Work', 'Job'], es: ['Trabajar', 'Trabajo'] },
  wrap_text: { en: ['Wrap Text', 'Format'], es: ['Ajustar texto', 'Formato'] },
  zoom_out_map: { en: ['Zoom Out', 'Map', 'Expand'], es: ['Alejar', 'Mapa', 'Expandir'] },
};
