import {
  USER_ACCESS_FETCH,
  USER_ACCESS_FETCH_ERROR,
  USER_ACCESS_FETCH_SUCCESS,
  USER_ACCESS_RESET,
} from 'constants/ActionTypes';

export const userAccessFetch = () => ({
  type: USER_ACCESS_FETCH,
  payload: null,
});

export const userAccessFetchSuccess = claims => ({
  type: USER_ACCESS_FETCH_SUCCESS,
  payload: claims,
});

export const userAccessFetchError = error => ({
  type: USER_ACCESS_FETCH_ERROR,
  payload: error,
});

export const userAccessReset = () => ({
  type: USER_ACCESS_RESET,
  payload: null,
});
