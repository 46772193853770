import React from 'react';
import PropTypes from 'prop-types';
import { ChildrenSchema } from 'packages/utils';
import styles from './styles.module.less';
import classNames from 'classnames';

const Title = ({ value, className }) => {
  return (
    value && (
      <div className={className}>
        <span className="gx-fs-2xl gx-font-weight-medium">{value}</span>
      </div>
    )
  );
};

Title.LabelForm = ({ value, className, required = false }) =>
  value && (
    <div className={className}>
      <span className={classNames('gx-guarnic-label', required && styles.isRequired)}>{value}</span>
    </div>
  );

Title.Header = ({ value, className }) =>
  value && (
    <div className={className}>
      <span className="gx-guarnic-header">{value}</span>
    </div>
  );

Title.Filter = ({ value, className, required = false }) =>
  value && (
    <div className={className}>
      <span className={classNames('gx-guarnic-title-filter', required && styles.isRequired)}>
        {value}
      </span>
    </div>
  );

Title.SubTitle = ({ value, className }) =>
  value && (
    <div className={className}>
      <span className="gx-guarnic-headline-2">{value}</span>
    </div>
  );

Title.defaultProps = {
  className: null,
};
Title.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, ChildrenSchema]).isRequired,
  className: PropTypes.any,
};

export default Title;
