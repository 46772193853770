import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import { getCollectionData } from 'packages/utils';
import { DIVISIONS, ORGANIZATIONS, SCHEDULE_TEMPLATES } from '../constants';

const getScheduleTemplateById = async (orgId, divId, templateId) =>
  getDoc(doc(db, ORGANIZATIONS, orgId, DIVISIONS, divId, SCHEDULE_TEMPLATES, templateId)).then(
    snapshot => snapshot.data(),
  );

const getScheduleTemplates = async (orgId, divId) =>
  getDocs(collection(db, ORGANIZATIONS, orgId, DIVISIONS, divId, SCHEDULE_TEMPLATES)).then(
    snapshot => getCollectionData(snapshot),
  );

export { getScheduleTemplateById, getScheduleTemplates };
