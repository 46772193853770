export const EventShape = props => {
  const { cx, cy } = props;
  const radius = 12;
  return (
    <g>
      <circle cx={cx} cy={cy} r={radius} fill={props?.color} />
      <foreignObject x={cx - radius} y={cy - radius} width={2 * radius} height={2 * radius}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <span className="material-icons-outlined" style={{ fontSize: 12, color: '#fff' }}>
            {props?.icon}
          </span>
        </div>
      </foreignObject>
    </g>
  );
};
