export const buttonCommonClass = ({ props }) => ({
  key: props?.label,
  type: props?.type,
  danger: props?.danger || false,
  onClick: props?.action,
  size: props?.size || 'small',
  disabled: props?.disabled || false,
  loading: props?.loading || false,
  hidden: props?.hidden || false,
  htmlType: props?.htmlType,
  shape: props?.shape,
});
