import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useIntl } from 'react-intl';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Switch,
  TimePicker,
} from 'antd';

import IntlMessages from 'util/IntlMessages';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import DropDownDivisions from 'components/DropDownDivision';
import Title from 'components/BoxContainer/components/Title';
import { ALLOWED_ROLES, getDivsWithAccess, useToggle } from 'packages/utils';
import {
  factoryWorkTemplate,
  FORM_ITEM_LAYOUT,
  FORMS,
  MAX_FORMS,
  MAX_TEMPLATE_TRIGGERS,
} from 'packages/workTemplates/constants';

import { createScheduleTemplate } from 'util/firebase-operations/scheduleTemplates/create';
import TemplateForms from 'packages/workTemplates/components/TemplateForms';
import EventTypeList from 'components/Events/EventTypeList';
import TemplateTriggers from 'packages/workTemplates/components/TemplateTriggers';
import TriggersList from 'components/TriggersList';
import { getScheduleTemplateById } from 'util/firebase-operations/scheduleTemplates/get';
import { updateScheduleTemplae } from 'util/firebase-operations/scheduleTemplates/update';
import styles from './styles.module.less';

const { Item } = Form;

const WorkTemplate = () => {
  const navigate = useHistory();
  const [form] = Form.useForm();
  const intl = useIntl();
  const { divId, templateId } = useParams();
  const [templateFormsVisible, setTemplateFormsVisible] = useToggle(false);
  const [templateTriggerVisible, setTemplateTriggerVisible] = useToggle(false);

  const [templateForms, setTemplateForms] = useState([]);
  const [templateTriggers, setTemplateTriggers] = useState([]);
  const [newDivisionId, setNewDivisionId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disablePermittedOverTime, setDisablePermittedOverTime] = useState(false);
  const [taskOrderRequired, setTaskOrderRequired] = useState(false);

  const profile = useSelector(({ user }) => user.profile);
  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const { userOrgDivisions } = useSelector(
    ({
      divisions: {
        assigned: { data },
      },
    }) => ({
      userOrgDivisions: data.divisions,
    }),
  );
  const allowedDivisions = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.WORK_TEMPLATES.CREATE,
    userOrgAccess,
    userOrgDivisions,
  );

  const divisionId = divId || newDivisionId;

  const getInitialData = useMemo(
    () => ({
      divId: divId || null,
      name: '',
      startDateOptional: true,
      startTimeOptional: true,
      duration: moment().startOf('day').add(60, 'minutes'),
      durationReadonly: false,
      minUsers: 1,
      triggers: [],
      forms: [],
      timezone: profile.data?.timeZone,
      timezoneReadonly: false,
      isExpiredAllowed: true,
      expiredAllowedTime: moment().startOf('day').add(5, 'minutes'),
    }),
    [divId, profile.data?.timeZone],
  );

  const clearForm = () => {
    form.setFieldValue(getInitialData);
    setTemplateForms([]);
    setTemplateTriggers([]);
  };

  const onRemoveForms = elementId => {
    setTemplateForms(templateForms.filter(item => item.id !== elementId));
  };
  const onRemoveTrigger = elementId => {
    setTemplateTriggers(templateTriggers.filter(item => item.id !== elementId));
  };

  const onFormFinish = useCallback(
    async (name, { values, forms }) => {
      const currentDivId = divId || values.divId;

      if (name === FORMS.FORMS) {
        setTemplateForms(forms[FORMS.FORMS].getFieldsValue(['forms'])?.forms || []);
        setTemplateFormsVisible(false);
      }

      if (name === FORMS.TRIGGERS) {
        setTemplateTriggers(forms[FORMS.TRIGGERS].getFieldsValue(['triggers'])?.triggers || []);
        setTemplateTriggerVisible(false);
      }

      if (FORMS.WORK_TEMPLATE === name) {
        try {
          setLoading(true);
          const scheduleTemplateBody = factoryWorkTemplate({
            data: values,
            currentDivId,
            forms: templateForms,
            triggers: templateTriggers,
            divisionId,
          });
          const apiCall = templateId ? updateScheduleTemplae : createScheduleTemplate;
          await apiCall({
            data: scheduleTemplateBody,
            orgId,
            divId: currentDivId,
            templateId,
          });
          notification.success({
            message: intl.formatMessage({ id: 'general.save.successful.message' }),
            placement: 'topRight',
          });
          setLoading(false);
          navigate.goBack();
        } catch (error) {
          notification.error({
            message: intl.formatMessage({ id: 'general.save.error.message' }),
            placement: 'topRight',
            description: error?.message,
          });

          setLoading(false);
        }
      }
    },
    [
      divId,
      divisionId,
      intl,
      navigate,
      orgId,
      setTemplateFormsVisible,
      setTemplateTriggerVisible,
      templateForms,
      templateId,
      templateTriggers,
    ],
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const scheduleTemplate = await getScheduleTemplateById(orgId, divisionId, templateId);

      setTemplateForms(scheduleTemplate?.forms || []);
      setTemplateTriggers(scheduleTemplate?.triggers || []);
      setTaskOrderRequired(scheduleTemplate?.triggersFollowIndex || false);

      setLoading(false);

      form.setFieldsValue({
        divId: scheduleTemplate.divId,
        name: scheduleTemplate.name,
        duration: moment()
          .startOf('day')
          .add(scheduleTemplate.duration || 0, 'minutes'),
        durationReadonly: scheduleTemplate.durationReadonly,
        startDateOptional: scheduleTemplate.startDateOptional,
        startTimeOptional: scheduleTemplate.startTimeOptional,
        minUsers: scheduleTemplate.minUsers,
        timezone: scheduleTemplate.timezone,
        timezoneReadonly: scheduleTemplate.timezoneReadonly,
        triggers: scheduleTemplate.triggers || [],
        form: scheduleTemplate.forms || [],
        isExpiredAllowed: scheduleTemplate.isExpiredAllowed,
        expiredAllowedTime: moment()
          .startOf('day')
          .add(scheduleTemplate.expiredAllowedTime || 0, 'minutes'),
        triggersFollowIndex: scheduleTemplate?.triggersFollowIndex || false,
      });
    };

    if (templateId) fetchData();
  }, [divisionId, form, orgId, templateId]);

  return (
    <BoxContainer>
      <BoxContainer content fixed shadow>
        <FilterContainer
          title={<Title.Header value={<IntlMessages id="work.template.form.title" />} />}
          goBack={() => navigate.goBack()}
          actionButtons={[
            {
              label: <IntlMessages id="form.save" />,
              type: 'primary',
              action: form.submit,
              disabled: loading,
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer content loading={loading}>
        <Form.Provider onFormFinish={onFormFinish}>
          <Form
            name={FORMS.WORK_TEMPLATE}
            initialValues={getInitialData}
            form={form}
            {...FORM_ITEM_LAYOUT}
          >
            <Row gutter={16}>
              {!divId && (
                <Col xs={24} xl={12} xxl={12}>
                  <Title.LabelForm
                    required
                    className="gx-guarnic-pb-1"
                    value={intl.formatMessage({ id: 'work.template.form.divId.label' })}
                  />
                  <Item
                    name="divId"
                    required
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'form.required',
                        }),
                      },
                    ]}
                  >
                    <DropDownDivisions
                      optionFilterProp="children"
                      getPopupContainer={trigger => trigger.parentNode}
                      dropdownMatchSelectWidth={false}
                      showSearch
                      placeholder={<IntlMessages id="divisionSelector.placeholder" />}
                      options={allowedDivisions}
                      onChange={val => {
                        clearForm();
                        setNewDivisionId(val);
                      }}
                    />
                  </Item>
                </Col>
              )}
              <Col xs={24} xl={12} xxl={12}>
                <Title.LabelForm
                  required
                  className="gx-guarnic-pb-1"
                  value={intl.formatMessage({ id: 'work.template.form.name.label' })}
                />
                <Item
                  name="name"
                  required
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'form.required',
                      }),
                    },
                  ]}
                >
                  <Input />
                </Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24} className="gx-pt-2 gx-guarnic-pb-2">
                <span className="gx-guarnic-label">
                  <IntlMessages id="work.template.form.sectionTitle.whenShouldOccur" />
                </span>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} xl={24} xxl={24}>
                <Title.LabelForm
                  value={<IntlMessages id="work.template.form.include.startdate" />}
                  className="gx-guarnic-pb-1"
                />
                <Item name="startDateOptional" valuePropName="checked">
                  <Switch />
                </Item>
              </Col>
              <Col xs={24} xl={24} xxl={24}>
                <Title.LabelForm
                  value={<IntlMessages id="work.template.form.include.time" />}
                  className="gx-guarnic-pb-1"
                />
                <Item name="startTimeOptional" valuePropName="checked">
                  <Switch />
                </Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={12} xl={12} xxl={12}>
                <Title.LabelForm
                  required
                  value={<IntlMessages id="work.template.form.duration.label" />}
                  className="gx-guarnic-pb-1"
                />
                <Item
                  name="duration"
                  required
                  rules={[
                    {
                      validator: async (_, value) => {
                        if (value < 15) {
                          return Promise.reject(intl.formatMessage({ id: 'form.required' }));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <TimePicker
                    allowClear={false}
                    format="HH:mm"
                    // inputReadOnly avoids virtual keyboard on touch devices
                    inputReadOnly
                    minuteStep={1}
                    render
                    showNow={false}
                    showSecond={false}
                    suffixIcon={null}
                    getPopupContainer={trigger => trigger.parentNode}
                    className="gx-w-100"
                    placeholder="00:00"
                  />
                </Item>
              </Col>
              <Col xs={12} xl={12} xxl={12}>
                <Title.LabelForm
                  value={<IntlMessages id="work.template.form.modifiable" />}
                  className="gx-guarnic-pb-1"
                />
                <Item name="durationReadonly" valuePropName="checked">
                  <Switch />
                </Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={12} xl={12} xxl={12}>
                <Title.LabelForm
                  required
                  value={<IntlMessages id="work.template.form.timeZone.label" />}
                  className="gx-guarnic-pb-1"
                />
                <Item name="timezone" required>
                  <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    id="timeZone"
                    showSearch
                  >
                    {moment.tz.names().map(tz => (
                      <Select.Option key={tz} value={tz}>
                        {tz} - <em>GMT {moment.tz(tz).format('Z')}</em>
                      </Select.Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Col xs={12} xl={12} xxl={12}>
                <Title.LabelForm
                  value={<IntlMessages id="work.template.form.modifiable" />}
                  className="gx-guarnic-pb-1"
                />
                <Item name="timezoneReadonly" valuePropName="checked">
                  <Switch />
                </Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24} className="gx-pt-2 gx-guarnic-pb-2">
                <span className="gx-guarnic-label">
                  <IntlMessages id="work.order.section.compliance.section" />
                </span>
              </Col>
              <Col xs={24} xl={12} xxl={12}>
                <Title.LabelForm
                  value={<IntlMessages id="work.order.form.complianceAllow.label" />}
                  className="gx-guarnic-pb-1"
                />
                <Form.Item name="isExpiredAllowed" valuePropName="checked">
                  <Switch onChange={val => setDisablePermittedOverTime(!val)} />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12} xxl={12}>
                <Title.LabelForm
                  value={<IntlMessages id="work.order.form.excessTimeAllowed.label" />}
                  className="gx-guarnic-pb-1"
                />
                <Form.Item name="expiredAllowedTime">
                  <TimePicker
                    disabled={disablePermittedOverTime}
                    getPopupContainer={trigger => trigger.parentNode}
                    className="gx-w-100"
                    allowClear={false}
                    format="HH:mm"
                    inputReadOnly
                    minuteStep={1}
                    render
                    showNow={false}
                    showSecond={false}
                    suffixIcon={null}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24} className="gx-pt-2 gx-guarnic-pb-2">
                <span className="gx-guarnic-label">
                  <IntlMessages id="work.template.form.sectionTitle.amountUser" />
                </span>
              </Col>
              <Col xs={24} xl={12} xxl={12}>
                <Title.LabelForm
                  required
                  value={<IntlMessages id="work.template.form.amount.label" />}
                  className={styles.minUsersLabel}
                />
                <Item
                  required
                  rules={[
                    {
                      required: true,
                      validator: async (_, value) => {
                        if (!value) {
                          return Promise.reject(intl.formatMessage({ id: 'form.required' }));
                        }
                        if (value < 1) {
                          return Promise.reject(
                            intl.formatMessage({ id: 'work.template.form.minUsers.requiredError' }),
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  name="minUsers"
                >
                  <InputNumber className="gx-w-100" type="number" />
                </Item>
              </Col>
            </Row>
            {divisionId && (
              <Row gutter={16}>
                <Col span={24} className="gx-pt-2 gx-guarnic-pb-2">
                  <Title.LabelForm
                    value={<IntlMessages id="work.template.form.associated.forms" />}
                  />
                  <Item
                    name="forms"
                    required
                    rules={[
                      {
                        validator: async () => {
                          if (templateForms.length < 1) {
                            return Promise.reject(
                              intl.formatMessage({
                                id: 'work.template.form.forms.noAssigned',
                              }),
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                      {
                        validator: async () => {
                          if (templateForms.length > MAX_FORMS) {
                            return Promise.reject(
                              intl.formatMessage({
                                id: 'work.template.form.forms.maxForms',
                              }),
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <div>
                      <EventTypeList data={templateForms} onRemove={onRemoveForms} wrapperData />
                      <Button
                        className="gx-mb-0"
                        type="primary"
                        block
                        onClick={() =>
                          templateForms.length < MAX_FORMS ? setTemplateFormsVisible(true) : null
                        }
                      >
                        <IntlMessages id="button.add" />
                      </Button>
                    </div>
                  </Item>
                </Col>

                <Col span={24} className="gx-pt-2 gx-guarnic-pb-2">
                  <div className={styles.taskTitleContainer}>
                    <Title.LabelForm
                      value={<IntlMessages id="work.template.form.task.execute" />}
                    />
                    <div className="gx-flex-row">
                      <Title.LabelForm
                        value={<IntlMessages id="work.template.form.task.order.required" />}
                        className="gx-flex-column gx-justify-content-center gx-pr-2"
                      />
                      <Item className="gx-mb-0" name="triggersFollowIndex" valuePropName="checked">
                        <Switch onChange={val => setTaskOrderRequired(val)} />
                      </Item>
                    </div>
                  </div>
                  <Item
                    name="triggers"
                    required
                    rules={[
                      {
                        validator: async () => {
                          if (templateTriggers.length < 1) {
                            return Promise.reject(
                              intl.formatMessage({
                                id: 'work.template.form.forms.noAssigned',
                              }),
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                      {
                        validator: async () => {
                          if (templateForms.length > MAX_TEMPLATE_TRIGGERS) {
                            return Promise.reject(
                              intl.formatMessage({
                                id: 'work.template.form.template.tasks.maxForms',
                              }),
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <>
                      {taskOrderRequired ? (
                        <TriggersList.Sortable
                          data={templateTriggers}
                          onRemove={onRemoveTrigger}
                          onMove={setTemplateTriggers}
                        />
                      ) : (
                        <TriggersList.Simple
                          data={templateTriggers}
                          onRemove={onRemoveTrigger}
                          wrapperData
                        />
                      )}
                      <Button
                        className="gx-mb-0"
                        type="primary"
                        block
                        onClick={() =>
                          templateTriggers.length < MAX_TEMPLATE_TRIGGERS
                            ? setTemplateTriggerVisible(true)
                            : null
                        }
                      >
                        <IntlMessages id="button.add" />
                      </Button>
                    </>
                  </Item>
                </Col>
              </Row>
            )}
          </Form>

          {orgId && divisionId && templateFormsVisible && (
            <TemplateForms
              divisionId={divisionId}
              organizationId={orgId}
              isVisible={templateFormsVisible}
              onCancel={setTemplateFormsVisible}
              form={form}
              initData={templateForms}
            />
          )}

          {orgId && divisionId && templateTriggerVisible && (
            <TemplateTriggers
              divisionId={divisionId}
              organizationId={orgId}
              isVisible={templateTriggerVisible}
              onCancel={setTemplateTriggerVisible}
              form={form}
              initData={templateTriggers}
            />
          )}
        </Form.Provider>
      </BoxContainer>
    </BoxContainer>
  );
};

export default WorkTemplate;
