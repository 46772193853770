import { useEffect, useCallback, useRef, useReducer } from 'react';

import { query, onSnapshot } from 'firebase/firestore';

import getDocData from '../../firebase/getDocData';

import {
  realtimeFetch,
  realtimeFetchError,
  realtimeFetchBatchSuccess,
  realtimeReset,
} from './actions';
import { getInitialState, reducer } from './reducer';

// This hook helps to subscribe to data in your Firestore database in multiple queries without having to worry
// about state management. Instead of calling Firestore's onSnapshot(query(...), () => {}) in a loop
// you simply pass a query list to useFirestoreRealtimeBatched() and you get back everything you need,
// including loaded, loading, data, and error.
// Your component will re-render when data changes and your subscription will be
// automatically removed when the component unmounts.
// use it like so:
// const { data, error, loading, loaded } = useFirestoreRealtimeBatched(queryRef[], dependencies);

// ### YOU MIGHT NEED AN INDEX TO USE THIS ON PRODUCTION ! ###
const useFirestoreRealtimeBatched = (queryRefList, dependencies) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const stateRef = useRef();
  const lastQueryByBatchRef = useRef({});
  const unsubscribesRef = useRef([]);
  stateRef.current = state;
  const loading = Object.values(stateRef.current.loadingByBatch).some(i => i);
  const loaded = Object.values(stateRef.current.loadedByBatch).every(i => i);

  const unsubscribe = () => {
    unsubscribesRef.current.forEach(unsub => {
      unsub();
    });
    unsubscribesRef.current = [];
  };

  const onFetchError = useCallback(error => dispatch(realtimeFetchError(error)), [dispatch]);
  const onFetchBatchSuccess = useCallback(
    (batchId, lastDocument, data) =>
      dispatch(realtimeFetchBatchSuccess(batchId, lastDocument, data)),
    [dispatch],
  );

  const getFetch = useCallback(() => {
    dispatch(realtimeFetch(queryRefList.length));

    lastQueryByBatchRef.current = {};
    queryRefList.forEach((currentQry, idx) => {
      const newQuery = query(currentQry);
      lastQueryByBatchRef.current[idx] = newQuery;
      const unsub = onSnapshot(
        newQuery,
        snapshot => {
          const lastDoc = snapshot.docs[snapshot.size - 1];
          const docs = {};
          snapshot.docs.forEach(i => {
            const doc = getDocData(i);
            docs[doc.id] = doc;
          });
          onFetchBatchSuccess(idx, lastDoc, docs);
        },
        onFetchError,
      );

      unsubscribesRef.current.push(unsub);
    });
  }, [dispatch, onFetchError, onFetchBatchSuccess, queryRefList]);

  useEffect(() => {
    // If the dependencies changes, then reset
    unsubscribe();
    dispatch(realtimeReset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  useEffect(() => {
    if (!queryRefList.length) {
      return;
    }
    if (!loaded && !loading) {
      getFetch();
    }
  }, [dispatch, getFetch, loaded, loading, queryRefList]);

  const data = [];
  const currentState = stateRef.current;
  Object.values(currentState.dataByBatch).map(batch =>
    Object.values(batch).map(item => data.push(item)),
  );
  const shortState = {
    data,
    error: currentState.error,
    gotNewData: Object.values(currentState.gotNewDataByBatch).some(i => i),
    loaded: Object.values(currentState.loadedByBatch).every(i => i),
    loading: Object.values(currentState.loadingByBatch).some(i => i),
  };

  return shortState;
};

export default useFirestoreRealtimeBatched;
