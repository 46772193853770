import * as actions from '../actions/selector';

const getInitialState = () => ({
  ids: [],
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case actions.DIVS_SELECT: {
      localStorage.setItem('selectedDivs', action.payload.ids);

      return {
        ids: action.payload.ids,
      };
    }

    default:
      return state;
  }
};
