import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { auth, db, serverTimestamp } from 'firebase/firebase';
import { doc, deleteDoc, setDoc, collection, writeBatch } from 'firebase/firestore';
import { errorNotification } from 'appRedux/actions';

import {
  LOCATION_DELETE,
  LOCATION_SAVE_FETCH,
  LOCATION_MULTIPLE_CREATE,
  locationDeleteError,
  locationDeleteSuccess,
  locationSaveFetchError,
  locationSaveFetchSuccess,
  locationMultipleCreateSuccess,
  locationMultipleCreateError,
} from '../actions';

const locationDeleteRequest = async (organizationId, divisionId, locationId) => {
  if (divisionId) {
    return deleteDoc(
      doc(db, 'organizations', organizationId, 'divisions', divisionId, 'locations', locationId),
    );
  }

  return deleteDoc(doc(db, 'organizations', organizationId, 'locations', locationId));
};

function* locationDelete({ payload: { organizationId, divisionId, locationId } }) {
  try {
    const response = yield call(locationDeleteRequest, organizationId, divisionId, locationId);
    yield put(locationDeleteSuccess(response));
    // @todo Send notification with transaltion
    // yield put(successNotification('The location has been deleted successfully !'));
  } catch (error) {
    yield put(locationDeleteError(error.toString()));
    yield put(errorNotification(error.toString()));
  }
}

export function* deleteLocation() {
  yield takeEvery(LOCATION_DELETE, locationDelete);
}

const locationSaveFetchRequest = async (organizationId, divisionId, locationId, data) => {
  const docData = {
    ...data,
    updatedAt: serverTimestamp(),
  };

  const orgRef = doc(db, 'organizations', organizationId);
  const divRef = !!divisionId && doc(orgRef, 'divisions', divisionId);

  if (locationId) {
    await setDoc(doc(divRef || orgRef, 'locations', locationId), docData, {
      merge: true,
    });
    return Promise.resolve(docData);
  }

  const newDocRef = doc(collection(divRef || orgRef, 'locations'));
  const newDocData = {
    ...docData,
    id: newDocRef.id,
    uid: auth.currentUser.uid,
    createdAt: serverTimestamp(),
  };
  await setDoc(newDocRef, newDocData);
  return Promise.resolve(newDocData);
};

function* locationSaveFetch({ payload: { organizationId, divisionId, locationId, data } }) {
  try {
    const response = yield call(
      locationSaveFetchRequest,
      organizationId,
      divisionId,
      locationId,
      data,
    );
    yield put(locationSaveFetchSuccess(response));
    // @todo Send notification with transaltion
    // yield put(successNotification('The location has been updated successfully !'));
  } catch (error) {
    yield put(locationSaveFetchError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* fetchLocationSave() {
  yield takeEvery(LOCATION_SAVE_FETCH, locationSaveFetch);
}

const locationMultipleCreateRequest = async (orgId, divId, amount, data) => {
  const batch = writeBatch(db);

  for (let i = 1; i <= amount; i += 1) {
    // Dev: for now we only create multiple locations at divisions,
    // but i leave it flexible in case this changes in the future,
    // so it accepts multiple creation at organizations
    let rootRef = doc(db, 'organizations', orgId);
    rootRef = !divId ? rootRef : doc(rootRef, 'divisions', divId);

    const newDocRef = doc(collection(rootRef, 'locations'));
    const newId = newDocRef.id;

    const newLoc = {
      ...data,
      name: `${data.name}-${i}`,
      token: newId,
      id: newId,
      updatedAt: serverTimestamp(),
      createdAt: serverTimestamp(),
      uid: auth.currentUser.uid,
    };

    batch.set(newDocRef, newLoc);
  }

  return batch.commit();
};

function* locationMultipleCreate({ payload: { organizationId, divisionId, amount, data } }) {
  try {
    const response = yield call(
      locationMultipleCreateRequest,
      organizationId,
      divisionId,
      amount,
      data,
    );

    yield put(locationMultipleCreateSuccess(response));
    // @todo Send notification with transaltion
    // yield put(successNotification('The location has been updated successfully !'));
  } catch (error) {
    yield put(locationMultipleCreateError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* fetchLocationMultipleCreate() {
  yield takeEvery(LOCATION_MULTIPLE_CREATE, locationMultipleCreate);
}

export default function* rootSaga() {
  yield all([fork(deleteLocation), fork(fetchLocationSave), fork(fetchLocationMultipleCreate)]);
}
