import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Divider, InputNumber, Select, Row, Col, Radio } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { functions, auth } from 'firebase/firebase';
import { httpsCallable } from 'firebase/functions';
import IntlMessages from 'util/IntlMessages';

import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import styles from './styles.module.less';
import ColumnChart from './components/ColumnChart';
import SchedulesSelector from './components/ScheduleSelector';
import ProgressItem from './components/ProgressItem';
import { camelize, getDateTypeIndex, getRange } from '../../utils';

import { dateTypes } from './constats';
import LabelValue from '../../../../components/LabelValue';

const { RangePicker } = DatePicker;
const { Option } = Select;

const SchedulesDashboard = () => {
  const intl = useIntl();

  const [aggregations, setAggregations] = useState([]);
  const [isRelative, setIsRelative] = useState(true);
  const [relativeAmount, setRelativeAmount] = useState(1);
  const [dateType, setDateType] = useState('day');
  const [dateRange, setDateRange] = useState([moment().subtract(6, 'month'), moment()]);
  const [groupBy, setGroupBy] = useState('day');
  const [selectedSchedules, setSelectedSchedules] = useState({ byId: {}, selectedAll: false });
  const [loading, setLoading] = useState(false);

  const selectedDivs = useSelector(({ divisions }) => divisions.selector.ids || []);
  const orgId = useSelector(state => state.organizations.organization.id);

  const addMessage = useCallback(
    () => httpsCallable(functions, 'functions-dashboard-schedulesDashboard'),
    [],
  );

  // Can't select dates after today
  const disabledDate = date => moment().isBefore(date);
  const dateTypeOptions = [
    {
      label: intl.formatMessage({ id: 'dashboards.schedules.relative' }),
      value: true,
    },
    {
      label: intl.formatMessage({ id: 'dashboards.schedules.absolute' }),
      value: false,
    },
  ];

  const fetch = useCallback(() => {
    const isValidRequest = !!orgId && !!selectedDivs.length;
    if (!isValidRequest) {
      return;
    }

    const paramsSent = {
      organizationId: orgId,
      divisionIds: selectedDivs,
      uid: auth.currentUser.uid,
      range: getRange(dateRange, dateType, isRelative, relativeAmount),
      interval: groupBy,
      scheduleIds: selectedSchedules.selectedAll ? [] : Object.keys(selectedSchedules.byId),
    };

    setLoading(true);
    addMessage()(paramsSent)
      .then(result => {
        const { data } = camelize(result);
        setAggregations(data.aggregations.timeRange.histogram.buckets);
        setLoading(false);
      })
      .catch(() => {
        setAggregations([]);
        setLoading(false);
      });
  }, [
    addMessage,
    dateRange,
    dateType,
    groupBy,
    isRelative,
    orgId,
    relativeAmount,
    selectedDivs,
    selectedSchedules,
  ]);

  // Only re-fetch when the selected schedules change
  useEffect(() => {
    if (Object.keys(selectedSchedules.byId).length > 0) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchedules]);

  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer
          showHide
          actionButtons={[
            {
              label: <IntlMessages id="form.runQuery" />,
              action: fetch,
              type: 'primary',
              disabled: loading,
            },
          ]}
          title={<Title value={<IntlMessages id="dashboards.schedules.title" />} />}
          content={
            <Row justify="start" gutter={[8, 8]}>
              <Col xs={24} xl={isRelative ? 12 : 14} xxl={11}>
                <LabelValue
                  vertical
                  name="Filtros"
                  value={
                    <>
                      <Radio.Group
                        options={dateTypeOptions}
                        onChange={e => setIsRelative(e.target.value)}
                        value={isRelative}
                        optionType="button"
                        className="gx-mr-3"
                      />

                      {isRelative ? (
                        <InputNumber min={1} value={relativeAmount} onChange={setRelativeAmount} />
                      ) : (
                        <span className="gx-mr-2">
                          <IntlMessages id="dashboards.schedules.between" />
                        </span>
                      )}

                      <Select
                        className="gx-mr-2"
                        value={dateType}
                        onChange={setDateType}
                        dropdownMatchSelectWidth={false}
                      >
                        {dateTypes.map(d => (
                          <Option key={d} value={d}>
                            <IntlMessages
                              id={`dateTypes.${d}`}
                              values={{ amount: relativeAmount }}
                            />
                          </Option>
                        ))}
                      </Select>

                      {isRelative ? (
                        <span>
                          <IntlMessages id="dashboards.schedules.beforeUntilToday" />
                        </span>
                      ) : (
                        <RangePicker
                          value={dateRange}
                          picker={dateType === 'day' ? 'date' : dateType}
                          onChange={setDateRange}
                          disabledDate={disabledDate}
                        />
                      )}
                    </>
                  }
                />
              </Col>
              <Col xs={24} xl={isRelative ? 6 : 4} xxl={6}>
                <LabelValue
                  vertical
                  name={<IntlMessages id="dashboards.schedules.groupResultsBy" />}
                  value={
                    <Select
                      id="groupBy"
                      onChange={setGroupBy}
                      className="gx-mr-5"
                      value={groupBy}
                      dropdownMatchSelectWidth={false}
                    >
                      {dateTypes.map(d => (
                        <Option key={d} value={d}>
                          <IntlMessages id={`dateTypes.${d}`} values={{ amount: 1 }} />
                        </Option>
                      ))}
                    </Select>
                  }
                />
              </Col>
              <Col xs={24} xl={6} xxl={6}>
                <LabelValue
                  vertical
                  name="Cronogramas"
                  value={
                    <SchedulesSelector
                      selectedSchedules={selectedSchedules}
                      setSelected={setSelectedSchedules}
                      setLoading={setLoading}
                    />
                  }
                />
              </Col>
            </Row>
          }
        />
      </BoxContainer>
      <BoxContainer loading={loading} content>
        <ColumnChart data={aggregations} schedulesById={selectedSchedules.byId} />
        <Divider className="gx-mt-5 gx-mb-5" />
        <div className={styles.progressItemsList}>
          {aggregations.map(day => {
            const hasSchedule = !!day.bySchedule.buckets.length;
            return (
              hasSchedule && (
                <div key={day.key} className={styles.dayContainer}>
                  <h2 className={styles.day}>{getDateTypeIndex(day.key, groupBy, intl)}</h2>
                  {day.bySchedule.buckets.map(schAggregation => (
                    <ProgressItem
                      key={schAggregation.key}
                      schedule={selectedSchedules.byId[schAggregation.key]}
                      aggregation={schAggregation}
                    />
                  ))}
                </div>
              )
            );
          })}
        </div>
      </BoxContainer>
    </BoxContainer>
  );
};

export default SchedulesDashboard;
