import { combineReducers } from 'redux';

import { REDUCER_INDEX } from '../../constants';
import event from './event';

export const reducer = combineReducers({
  event,
});

export const registerReducer = state => ({
  ...state,
  [REDUCER_INDEX]: reducer,
});

export const getState = state => state[REDUCER_INDEX];
export const getStateEvent = state => state[REDUCER_INDEX].event;
