import React from 'react';
import { Avatar, Button } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { CloseOutlined } from '@ant-design/icons';

import styles from './styles.module.less';

const Member = ({ imageURL, name, comment, onRemove, className }) => (
  <div className={classnames(styles.mainContainer, className)}>
    <Avatar src={imageURL} />
    <div className={styles.infoContainer}>
      <span>{name}</span>
      {!!comment && <span>{comment}</span>}
    </div>
    {!!onRemove && (
      <Button type="text" icon={<CloseOutlined />} className="gx-mb-0" onClick={onRemove} />
    )}
  </div>
);

Member.defaultProps = {
  className: '',
  comment: '',
  imageURL: '',
  onRemove: null,
};

Member.propTypes = {
  name: PropTypes.string.isRequired,

  className: PropTypes.string,
  comment: PropTypes.string,
  imageURL: PropTypes.string,
  onRemove: PropTypes.func,
};

export default Member;
