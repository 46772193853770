import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { doc, deleteDoc, setDoc, collection } from 'firebase/firestore';
import { auth, db, serverTimestamp } from 'firebase/firebase';
import { errorNotification } from 'appRedux/actions';

import {
  GRP_SAVE_FETCH,
  GRP_DIVISION_USER_DELETE,
  groupSaveFetchError,
  groupSaveFetchSuccess,
  groupDeleteError,
  groupDeleteSuccess,
} from '../actions';

const groupSaveFetchRequest = async (organizationId, divisionId, groupId, data) => {
  const docData = {
    ...data,
    updatedAt: serverTimestamp(),
  };

  const collectionRef = collection(
    db,
    'organizations',
    organizationId,
    'divisions',
    divisionId,
    'groups',
  );

  if (groupId) {
    await setDoc(doc(collectionRef, groupId), docData, {
      merge: true,
    });
    return Promise.resolve(docData);
  }

  const newDocRef = doc(collectionRef);
  const newDocData = {
    ...docData,
    id: newDocRef.id,
    uid: auth.currentUser.uid,
    createdAt: serverTimestamp(),
  };
  await setDoc(newDocRef, newDocData);
  return Promise.resolve(newDocData);
};

function* groupSaveFetch({ payload: { organizationId, divisionId, groupId, data } }) {
  try {
    const response = yield call(groupSaveFetchRequest, organizationId, divisionId, groupId, data);
    yield put(groupSaveFetchSuccess(response));
    // @todo Send notification with transaltion
    // yield put(successNotification('The group has been updated successfully !'));
  } catch (error) {
    yield put(groupSaveFetchError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* fetchGroupSave() {
  yield takeEvery(GRP_SAVE_FETCH, groupSaveFetch);
}

const groupDeleteRequest = async (organizationId, divisionId, groupId) =>
  deleteDoc(doc(db, 'organizations', organizationId, 'divisions', divisionId, 'groups', groupId));

function* groupDelete({ payload: { organizationId, divisionId, groupId } }) {
  try {
    const response = yield call(groupDeleteRequest, organizationId, divisionId, groupId);
    yield put(groupDeleteSuccess(response));
    // @todo Send notification with transaltion
    // yield put(successNotification('The group has been deleted successfully !'));
  } catch (error) {
    yield put(groupDeleteError(error.toString()));
    yield put(errorNotification(error.toString()));
  }
}

export function* deleteGroup() {
  yield takeEvery(GRP_DIVISION_USER_DELETE, groupDelete);
}

export default function* rootSaga() {
  yield all([fork(fetchGroupSave), fork(deleteGroup)]);
}
