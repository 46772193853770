import { useEffect, useCallback, useRef, useReducer } from 'react';

import { query, startAfter, limit, onSnapshot } from 'firebase/firestore';

import {
  realtimePaginationFirstBatch,
  realtimePaginationFirstBatchError,
  realtimePaginationNextBatch,
  realtimePaginationNextBatchError,
  realtimePaginationBatchSuccess,
  realtimePaginationReset,
} from './actions';
import { getInitialState, reducer } from './reducer';
import getDocData from '../../firebase/getDocData';

const DEFAULT_PAGE_SIZE = 10;

// This hook helps to subscribe to data in your Firestore database without having to worry
// about state management and pagination. Instead of calling Firestore's onSnapshot(query(...), () => {})
// you simply pass a query to useFirestoreRealtimePagination() and you get back everything you need,
// including loaded, loading, data, and error.
// Your component will re-render when data changes and your subscription will be
// automatically removed when the component unmounts.
// use it like so:
// const { data, error, loading, loaded, next } = useFirestoreRealtimePagination(queryRef, dependencies, pageSize);
// where next is a callback action that you can trigger when you need the next page

// ### YOU MIGHT NEED AN INDEX TO USE THIS ON PRODUCTION ! ###
const useFirestoreRealtimePagination = (queryRef, pageSize = DEFAULT_PAGE_SIZE) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const stateRef = useRef();
  const lastQueryRef = useRef();
  const unsubscribesRef = useRef([]);
  stateRef.current = state;

  const unsubscribe = () => {
    unsubscribesRef.current.forEach(unsub => {
      unsub();
    });
    unsubscribesRef.current = [];
  };

  const onNextBatchError = useCallback(
    error => dispatch(realtimePaginationNextBatchError(error)),
    [dispatch],
  );
  const onNextBatchSuccess = useCallback(
    (lastDocument, data) => dispatch(realtimePaginationBatchSuccess(lastDocument, data)),
    [dispatch],
  );

  const getNextBatch = useCallback(() => {
    // If there is no lastDocument ref, dont fetch the next batch
    if (!stateRef.current.lastDocument) {
      return;
    }
    if (!stateRef.current.loading) {
      dispatch(realtimePaginationNextBatch());
      const nextBatch = query(
        lastQueryRef.current,
        startAfter(stateRef.current.lastDocument),
        limit(pageSize),
      );
      const unsub = onSnapshot(
        nextBatch,
        snapshot => {
          const lastDoc = snapshot.docs[snapshot.size - 1];
          const docs = {};
          snapshot.docs.forEach(i => {
            const doc = getDocData(i);
            docs[doc.id] = doc;
          });
          onNextBatchSuccess(lastDoc, docs);
        },
        onNextBatchError,
      );
      unsubscribesRef.current.push(unsub);
    }
  }, [dispatch, onNextBatchError, onNextBatchSuccess, pageSize]);

  const onFirstBatchError = useCallback(
    error => dispatch(realtimePaginationFirstBatchError(error)),
    [dispatch],
  );
  const onFirstBatchSuccess = useCallback(
    (lastDocument, data) =>
      dispatch(realtimePaginationBatchSuccess(lastDocument, data, getNextBatch)),
    [dispatch, getNextBatch],
  );

  const getFirstBatch = useCallback(() => {
    dispatch(realtimePaginationFirstBatch());
    lastQueryRef.current = query(queryRef, limit(pageSize));
    const unsub = onSnapshot(
      lastQueryRef.current,
      snapshot => {
        const lastDoc = snapshot.docs[snapshot.size - 1];
        const docs = {};
        snapshot.docs.forEach(i => {
          const doc = getDocData(i);
          docs[doc.id] = doc;
        });
        onFirstBatchSuccess(lastDoc, docs);
      },
      onFirstBatchError,
    );
    unsubscribesRef.current.push(unsub);
  }, [dispatch, onFirstBatchError, onFirstBatchSuccess, pageSize, queryRef]);

  useEffect(() => {
    // If the dependencies changes, then reset
    unsubscribe();
    dispatch(realtimePaginationReset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryRef.path]);

  useEffect(() => {
    if (!queryRef) {
      return;
    }
    if (!stateRef.current.loaded && !stateRef.current.loading) {
      getFirstBatch();
    }
  }, [dispatch, getFirstBatch, queryRef]);

  // Unmount Effect
  useEffect(() => unsubscribe, []);

  return { ...stateRef.current, data: Object.values(stateRef.current.data) };
};

export default useFirestoreRealtimePagination;
