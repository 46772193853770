import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import IntlMessages from 'util/IntlMessages';
import { COMPONENT_TYPES } from '../../../../../eventTypes/constants';

import styles from './index.module.less';

const Number = ({ component, labelClassname }) => (
  <div className="gx-mt-3">
    <h4 className={classnames(styles.title, labelClassname)}>{component.label.name}</h4>
    {component.numberValue ? (
      <p>{component.numberValue}</p>
    ) : (
      <IntlMessages id="eventTypes.component.notAdded" />
    )}
  </div>
);

Number.propTypes = {
  component: PropTypes.shape({
    componentProps: PropTypes.shape({
      autocomplete: PropTypes.bool,
      checked: PropTypes.bool,
      format: PropTypes.string,
      isDecimal: PropTypes.bool,
      max: PropTypes.number, // max number value
      maxCount: PropTypes.number,
      maxLength: PropTypes.number, // max length of characters
      maxSize: PropTypes.number,
      min: PropTypes.number, // min number value
      minLength: PropTypes.number, // min length of characters
      pattern: PropTypes.string,
      placeholder: PropTypes.string,
    }).isRequired,
    commonProps: PropTypes.shape({ required: PropTypes.bool.isRequired }).isRequired,
    label: PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
    type: COMPONENT_TYPES.NUMBER,
    numberValue: PropTypes.number.isRequired,
  }).isRequired,

  labelClassname: PropTypes.string,
};

Number.defaultProps = {
  labelClassname: '',
};

export default React.memo(Number);
