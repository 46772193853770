import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { registerReducer as divisionsReducer } from 'packages/divisions';
import { registerReducer as eventTypesReducer } from 'packages/eventTypes';
import { registerReducer as eventsReducer } from 'packages/events';
import { registerReducer as groupsReducer } from 'packages/groups';
import { registerReducer as locationsReducer } from 'packages/locations';
import { registerReducer as schedulesReducer } from 'packages/schedules';
import { registerReducer as usersReducer } from 'packages/users';
import { registerReducer as utilsReducer } from 'packages/utils';
import Settings from './Settings';
import Auth from './Auth';
import Notes from './Notes';
import Contact from './Contact';
import Common from './Common';

import organizations from './organizations';
import resetPassword from './resetPassword';
import user from './user';

const reducersRegisterList = [
  divisionsReducer,
  eventTypesReducer,
  eventsReducer,
  groupsReducer,
  locationsReducer,
  schedulesReducer,
  usersReducer,
  utilsReducer,
];

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    notes: Notes,
    contact: Contact,
    common: Common,

    organizations,
    resetPassword,
    user,

    ...reducersRegisterList.reduce((store, reducer) => reducer(store), {}),
  });

export default createRootReducer;
