import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth, db, serverTimestamp } from 'firebase/firebase';
import { doc, getDoc, setDoc, collection, deleteDoc } from 'firebase/firestore';
import { errorNotification } from 'appRedux/actions';

import {
  DIV_FETCH,
  DIV_SAVE_FETCH,
  DIV_DELETE,
  divisionDeleteError,
  divisionDeleteSuccess,
  divisionFetchError,
  divisionFetchSuccess,
  divisionSaveFetchError,
  divisionSaveFetchSuccess,
} from '../actions/division';
import { divisionListUpdateDivision } from '../actions/list';

const divisionFetchRequest = async (organizationId, divisionId) =>
  getDoc(doc(db, 'organizations', organizationId, 'divisions', divisionId)).then(snapshot =>
    snapshot.data(),
  );

function* divisionFetch({ payload: { organizationId, divisionId } }) {
  try {
    const response = yield call(divisionFetchRequest, organizationId, divisionId);
    yield put(divisionFetchSuccess(response));
  } catch (error) {
    yield put(divisionFetchError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* fetchDivision() {
  yield takeEvery(DIV_FETCH, divisionFetch);
}

const divisionSaveFetchRequest = async (organizationId, divisionId, data) => {
  const serverData = {
    ...data,
    updatedAt: serverTimestamp(),
  };

  const collectionRef = collection(db, 'organizations', organizationId, 'divisions');

  if (divisionId) {
    await setDoc(doc(collectionRef, divisionId), serverData, {
      merge: true,
    });
    return Promise.resolve(serverData);
  }

  const newDocRef = doc(collectionRef);
  const newDocData = {
    ...serverData,
    id: newDocRef.id,
    createdAt: serverTimestamp(),
    uid: auth.currentUser.uid,
  };
  await setDoc(newDocRef, newDocData);
  return Promise.resolve(newDocData);
};

function* divisionSaveFetch({ payload: { organizationId, divisionId, data } }) {
  try {
    const response = yield call(divisionSaveFetchRequest, organizationId, divisionId, data);
    yield put(divisionSaveFetchSuccess(response));
    yield put(divisionListUpdateDivision(response));
  } catch (error) {
    yield put(divisionSaveFetchError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* fetchDivisionSave() {
  yield takeEvery(DIV_SAVE_FETCH, divisionSaveFetch);
}

const divisionDeleteRequest = async (organizationId, divisionId) =>
  deleteDoc(doc(db, 'organizations', organizationId, 'divisions', divisionId));

function* divisionDelete({ payload: { organizationId, divisionId } }) {
  try {
    const response = yield call(divisionDeleteRequest, organizationId, divisionId);
    yield put(divisionDeleteSuccess(response));
  } catch (error) {
    yield put(divisionDeleteError(error.toString()));
    yield put(errorNotification(error.toString()));
  }
}

export function* deleteDivision() {
  yield takeEvery(DIV_DELETE, divisionDelete);
}
export default function* rootSaga() {
  yield all([fork(fetchDivision), fork(fetchDivisionSave), fork(deleteDivision)]);
}
