import React from 'react';
import PropTypes from 'prop-types';
import { Col, DatePicker, Row, Segmented } from 'antd';
import { KeyValueWidget } from 'components/KeyValueWidget';
import Title from 'components/BoxContainer/components/Title';
import IntlMessages from 'util/IntlMessages';
import {
  POSITION_HISTORY_STATE,
  POST_HOURS,
  POST_HOURS_BY_DEFAULT,
} from 'packages/reports/constants';
import moment from 'moment';
import { get, set } from 'packages/utils/storage';
import styles from './styles.module.less';

const UserPositionFilters = ({ values, onChange, loading }) => {
  const postHoursOption = Object.keys(POST_HOURS).map(item => ({
    label: POST_HOURS[item],
    value: item,
  }));

  return (
    <Row justify="start" gutter={[8, 8]}>
      <Col xs={24} sm={4} md={4} xl={4} xxl={4}>
        <KeyValueWidget
          label={
            <Title.Filter value={<IntlMessages id="reports.userPositionHistory.day.filter" />} />
          }
          value={
            <DatePicker
              onChange={d => {
                onChange(prev => ({
                  ...prev,
                  date: d,
                }));
                // set localStorage
                set(POSITION_HISTORY_STATE, {
                  ...get(POSITION_HISTORY_STATE),
                  date: d,
                });
              }}
              format="YYYY-MM-DD HH:mm"
              value={values.date}
              className="gx-w-100"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              showNow={false}
              clearIcon={false}
              disabled={loading}
            />
          }
        />
      </Col>
      <Col xs={24} sm={5} md={5} xl={5} xxl={5}>
        <KeyValueWidget
          label={
            <Title.Filter value={<IntlMessages id="reports.userPositionHistory.hours.filter" />} />
          }
          value={
            <Segmented
              className={styles.hourPicker}
              value={values.hours}
              onChange={e => {
                onChange(prev => ({
                  ...prev,
                  hours: e,
                }));
                set(POSITION_HISTORY_STATE, {
                  ...get(POSITION_HISTORY_STATE),
                  hours: e,
                });
              }}
              defaultValue={POST_HOURS_BY_DEFAULT}
              options={postHoursOption}
              disabled={loading}
            />
          }
        />
      </Col>
    </Row>
  );
};

UserPositionFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    date: PropTypes.instanceOf(moment),
    hours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default UserPositionFilters;
