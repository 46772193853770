import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Carousel, Image, Spin } from 'antd';
import classnames from 'classnames';

import { storage } from 'firebase/firebase';
import { ref, getDownloadURL } from 'firebase/storage';

import IntlMessages from 'util/IntlMessages';
import { COMPONENT_TYPES } from '../../../../../eventTypes/constants';

import styles from './index.module.less';

const Photos = ({ component, labelClassname }) => {
  const getImage = async imageUrl => getDownloadURL(ref(storage, imageUrl));

  const [isLoading, setLoading] = useState(true);
  const [urls, setUrls] = useState([]);
  const [error, setError] = useState(false);

  const loadImages = async () => {
    const promises = [];
    let loadedUrls = [];
    component.photoValue?.forEach(img => {
      promises.push(getImage(img.url));
    });

    try {
      loadedUrls = await Promise.all(promises);
    } catch (e) {
      setError(true);
      Error(e);
    }

    setUrls(loadedUrls);
    setLoading(false);
  };

  const warningMessage = error ? (
    <IntlMessages id="eventTypes.component.notFound" />
  ) : (
    urls.length === 0 && <IntlMessages id="eventTypes.component.notLoaded" />
  );

  useEffect(() => {
    loadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="gx-mt-3">
      <h4 className={classnames(styles.title, labelClassname)}>{component.label.name}</h4>
      {warningMessage}
      {!error && isLoading && <Spin />}
      {!error && urls.length > 0 && (
        <Carousel className={styles.carousel}>
          {urls.map(fileName => (
            <Image
              key={fileName}
              wrapperClassName={classnames('gx-mr-2', styles.wrapper)}
              src={fileName}
            />
          ))}
        </Carousel>
      )}
    </div>
  );
};

Photos.propTypes = {
  component: PropTypes.shape({
    componentProps: PropTypes.shape({
      autocomplete: PropTypes.bool,
      checked: PropTypes.bool,
      format: PropTypes.string,
      isDecimal: PropTypes.bool,
      max: PropTypes.number, // max number value
      maxCount: PropTypes.number,
      maxLength: PropTypes.number, // max length of characters
      maxSize: PropTypes.number,
      min: PropTypes.number, // min number value
      minLength: PropTypes.number, // min length of characters
      pattern: PropTypes.string,
      placeholder: PropTypes.string,
    }).isRequired,
    commonProps: PropTypes.shape({ required: PropTypes.bool.isRequired }).isRequired,
    label: PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
    type: COMPONENT_TYPES.PHOTO,
    photoValue: PropTypes.arrayOf(
      PropTypes.shape({
        size: PropTypes.number.isRequired,
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
        url: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,

  labelClassname: PropTypes.string,
};

Photos.defaultProps = {
  labelClassname: '',
};

export default React.memo(Photos);
