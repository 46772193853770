export const EVT_EVENT_FETCH = 'EVT_EVENT_FETCH';
export const EVT_EVENT_FETCH_ERROR = 'EVT_EVENT_FETCH_ERROR';
export const EVT_EVENT_FETCH_SUCCESS = 'EVT_EVENT_FETCH_SUCCESS';
export const EVT_EVENT_RESET = 'EVT_EVENT_RESET';
export const EVT_EVENT_SAVE_FETCH = 'EVT_EVENT_SAVE_FETCH';
export const EVT_EVENT_SAVE_FETCH_ERROR = 'EVT_EVENT_SAVE_FETCH_ERROR';
export const EVT_EVENT_SAVE_FETCH_SUCCESS = 'EVT_EVENT_SAVE_FETCH_SUCCESS';

export const eventReset = () => ({
  type: EVT_EVENT_RESET,
});

export const eventFetch = (organizationId, divisionId, eventId) => ({
  type: EVT_EVENT_FETCH,
  payload: { organizationId, divisionId, eventId },
});

export const eventFetchSuccess = event => ({
  type: EVT_EVENT_FETCH_SUCCESS,
  payload: event,
});

export const eventFetchError = error => ({
  type: EVT_EVENT_FETCH_ERROR,
  payload: error,
});

export const eventSaveFetch = (organizationId, divisionId, eventId, data) => ({
  type: EVT_EVENT_SAVE_FETCH,
  payload: { organizationId, divisionId, eventId, data },
});

export const eventSaveFetchSuccess = event => ({
  type: EVT_EVENT_SAVE_FETCH_SUCCESS,
  payload: event,
});

export const eventSaveFetchError = error => ({
  type: EVT_EVENT_SAVE_FETCH_ERROR,
  payload: error,
});
