import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import IntlMessages from 'util/IntlMessages';

import Title from 'components/BoxContainer/components/Title';
import { InfoCardList } from 'components/InfoCard';
import MemberAvatar from 'components/MemberList/MemberAvatar';

const GroupsSelected = ({ className, groups, onRemoveGroup }) => (
  <div className={classnames(className, 'gx-flex-column')}>
    <span className="gx-guarnic-pb-1">
      <Title.LabelForm value={<IntlMessages id="schedule.form.members.modal.groups.title" />} />
    </span>
    {groups.length === 0 ? (
      <span>
        <IntlMessages id="schedule.form.members.modal.groups.noGroups" />
      </span>
    ) : (
      groups.map(group => (
        <InfoCardList
          key={group.id}
          avatar={<MemberAvatar user={group} />}
          title={group.name}
          actionButtons={[
            {
              iconButton: 'delete',
              action: () => onRemoveGroup(group.id),
              shape: 'circle',
            },
          ]}
        />
      ))
    )}
  </div>
);

GroupsSelected.defaultProps = {
  className: '',
  onRemoveGroup: null,
};

GroupsSelected.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      avatarURL: PropTypes.string,
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onRemoveGroup: PropTypes.func,
  className: PropTypes.string,
};

export default GroupsSelected;
