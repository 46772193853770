export const ACTIONS = {
  UTI_FQBP_FIRST_FETCH: 'UTI_FQBP_FIRST_FETCH',
  UTI_FQBP_FIRST_FETCH_ERROR: 'UTI_FQBP_FIRST_FETCH_ERROR',

  UTI_FQBP_NEXT_FETCH: 'UTI_FQBP_NEXT_FETCH',
  UTI_FQBP_NEXT_FETCH_ERROR: 'UTI_FQBP_NEXT_FETCH_ERROR',

  UTI_FQBP_FETCH_BATCH_SUCCESS: 'UTI_FQBP_FETCH_BATCH_SUCCESS',
  UTI_FQBP_RESET: 'UTI_FQBP_RESET',
};

export const queryPaginationFirstFetch = batchesAmount => ({
  payload: { batchesAmount },
  type: ACTIONS.UTI_FQBP_FIRST_FETCH,
});

export const queryPaginationFirstFetchError = error => ({
  payload: error,
  type: ACTIONS.UTI_FQBP_FIRST_FETCH_ERROR,
});

export const queryPaginationNextFetch = batchesIds => ({
  payload: { batchesIds },
  type: ACTIONS.UTI_FQBP_NEXT_FETCH,
});

export const queryPaginationNextFetchError = error => ({
  payload: error,
  type: ACTIONS.UTI_FQBP_NEXT_FETCH_ERROR,
});

export const queryPaginationFetchBatchSuccess = (batchId, lastDocument, data, next) => ({
  payload: { batchId, lastDocument, data, next },
  type: ACTIONS.UTI_FQBP_FETCH_BATCH_SUCCESS,
});

export const queryPaginationReset = () => ({
  type: ACTIONS.UTI_FQBP_RESET,
});
