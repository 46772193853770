// export const REDUCER_INDEX = 'aggregations';
const BASE_URL = 'dashboard';

export const PATHS = {
  BASE_URL,
  SCHEDULES: `${BASE_URL}/schedules`,
  ACTIVE_SCHEDULES: `${BASE_URL}/active_schedules`,
  EVENTS: `${BASE_URL}/events`,
  EDIT_CHART: `${BASE_URL}/edit`,
};
