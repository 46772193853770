import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SwitchInput from '../SwitchInput';
import { AVAILABLE_PROPS, COMMON_PROPS, PROPS_INTL } from '../../constants';

import styles from './styles.module.less';

const ComponentProperties = ({ id, fieldKey, extraProps, type, form }) => {
  const commonProps = { ...extraProps, className: classnames('gx-mr-4', styles.propInput) };
  const componentProps = { ...extraProps, className: classnames('gx-mr-4', styles.propInput) };

  const auxClass = {
    select: styles.selectAux,
  };

  return (
    <div className={classnames(styles.propsContainer, auxClass[type])}>
      {Object.values(COMMON_PROPS).map(prop => (
        <SwitchInput
          extraProps={commonProps}
          fieldKey={[fieldKey, prop]}
          form={form}
          key={`${fieldKey}-${prop}`}
          label={PROPS_INTL[prop]}
          name={[id, 'commonProps', prop]}
        />
      ))}
      {!!type &&
        Object.values(AVAILABLE_PROPS[type] || {}).map(input =>
          input(id, fieldKey, componentProps, form),
        )}
    </div>
  );
};

ComponentProperties.defaultProps = {
  type: null,
};

ComponentProperties.propTypes = {
  extraProps: PropTypes.shape({
    isListField: PropTypes.bool,
  }).isRequired,
  fieldKey: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired,

  type: PropTypes.oneOf(Object.keys(AVAILABLE_PROPS)),
};

export default ComponentProperties;
