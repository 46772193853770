import React, { useLayoutEffect, useMemo, useState } from 'react';

import { Col, Drawer, Form, Input, notification, Row } from 'antd';

import { db } from 'firebase/firebase';
import IntlMessages from 'util/IntlMessages';
import { collection } from 'firebase/firestore';
import BoxContainer from 'components/BoxContainer';
import { useFirestoreQuery } from 'packages/utils';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';

import PropTypes from 'prop-types';
import EventTypeList from 'components/Events/EventTypeList';
import { useIntl } from 'react-intl';
import styles from './styles.module.less';
import { COMPONENTS_NOT_ALLOWED, FORMS } from '../constants';

const TemplateForms = ({ isVisible, onCancel, organizationId, divisionId, initData }) => {
  const [form] = Form.useForm();
  const intl = useIntl();

  const [eventTypes, setEventTypes] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const {
    data: divTypes = [],
    error: divTypesError,
    loading: divTypesLoading,
  } = useFirestoreQuery(
    organizationId &&
      divisionId &&
      collection(db, 'organizations', organizationId, 'divisions', divisionId, 'event_types'),
    [organizationId, divisionId],
  );

  const onSave = () => {
    if (form) {
      form.setFieldsValue({
        forms: eventTypes,
      });
      form.submit();
    }
  };

  const addForm = element => {
    const hasSomeComponentNotAllowed = element.components.some(component =>
      COMPONENTS_NOT_ALLOWED.includes(component.type),
    );
    if (hasSomeComponentNotAllowed) {
      return notification.warning({
        message: intl.formatMessage(
          { id: 'work.order.form.components.not.allowed' },
          { name: element?.name },
        ),
        placement: 'topRight',
      });
    }
    const newForms = [...eventTypes, element];
    return setEventTypes(newForms);
  };
  const removeForm = elementId => {
    setEventTypes(eventTypes.filter(item => item.id !== elementId));
  };

  const formsAssociated = useMemo(() => eventTypes, [eventTypes]);

  const formsAvailable = useMemo(
    () =>
      divTypes
        .filter(type => !formsAssociated.find(item => item.id === type.id))
        .filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase())),
    [divTypes, formsAssociated, searchValue],
  );

  useLayoutEffect(() => {
    if (initData) setEventTypes(initData);
  }, [initData]);

  return (
    <Drawer className={styles.mainContainer} open={isVisible} closable={false} footer={null}>
      <Form name={FORMS.FORMS} form={form}>
        <BoxContainer>
          <BoxContainer content fixed>
            <FilterContainer
              goBack={() => onCancel(false)}
              title={
                <Title.Header
                  className="gx-pt-1 gx-pb-1"
                  value={<IntlMessages id="work.template.form.template.forms.title" />}
                />
              }
              actionButtons={[
                {
                  label: <IntlMessages id="button.save" />,
                  type: 'primary',
                  action: () => {
                    onSave();
                  },
                },
              ]}
            />
          </BoxContainer>
          <BoxContainer content loading={divTypesLoading} error={!!divTypesError}>
            <Row gutter={16}>
              <Col span={24}>
                <Title.LabelForm
                  value={<IntlMessages id="work.template.form.template.forms.associated.title" />}
                />
                <div className="gx-guarnic-pt-2">
                  <EventTypeList data={formsAssociated} onRemove={removeForm} />
                </div>
              </Col>
            </Row>
            <Row className="gx-guarnic-pt-2" gutter={16}>
              <Col span={24}>
                <Input.Search
                  placeholder={intl.formatMessage({
                    id: 'work.template.form.forms.searchByForm.placeholder',
                  })}
                  onChange={e => setSearchValue(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Title.LabelForm
                  value={<IntlMessages id="work.template.form.template.forms.available.title" />}
                />
                <div className="gx-guarnic-pt-2">
                  <EventTypeList data={formsAvailable} onAdd={addForm} />
                </div>
              </Col>
            </Row>
          </BoxContainer>
        </BoxContainer>
      </Form>
    </Drawer>
  );
};

TemplateForms.defaultProps = {
  form: null,
  initData: [],
};

TemplateForms.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  divisionId: PropTypes.string.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
    submit: PropTypes.func,
  }),
  initData: PropTypes.arrayOf(PropTypes.object),
};

export default TemplateForms;
