import { Divider } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.less';

const Card = ({ children, footer, classname }) => {
  return (
    <div className={classnames(styles.mainContainer, classname)}>
      <div className={styles.floatingCard}>
        <div className={styles.content}>{children}</div>
        <Divider />
        <div className={styles.footer}>{footer}</div>
      </div>
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  footer: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  classname: PropTypes.string,
};

Card.defaultProps = {
  classname: '',
};

export default Card;
