import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ForgotPassword from './screens/ForgotPassword';
import SignIn from './screens/SignIn';
import SignUp from './screens/SignUp';

const Auth = () => (
  <Switch>
    <Route exact path="/signin" component={SignIn} />
    <Route exact path="/signup" component={SignUp} />
    <Route exact path="/forgot_password" component={ForgotPassword} />
  </Switch>
);

export default React.memo(Auth);
