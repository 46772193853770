export const resizeImage = (file, saveImage, fileName, maxWidth = 200) => {
  if (file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = event => {
      const img = document.createElement('img');
      img.src = event.target.result;
      img.onload = imgEvent => {
        const canvas = document.createElement('canvas');
        const scaleSize = maxWidth / imgEvent.target.width;
        canvas.width = maxWidth;
        canvas.height = imgEvent.target.height * scaleSize;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(imgEvent.target, 0, 0, canvas.width, canvas.height);

        ctx.canvas.toBlob(blob => {
          const resizedFile = new File([blob], `${fileName}.jpg`, { type: 'image/jpeg' });
          saveImage(resizedFile);
        }, 'image/jpeg');
      };
    };
  }
};
