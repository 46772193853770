// Aggregations options to display in the chart
export const aggOptions = ['min', 'max', 'count', 'sum', 'avg'];

export const aggIntl = {
  min: 'dashboards.chart.min',
  max: 'dashboards.chart.max',
  count: 'dashboards.chart.count',
  sum: 'dashboards.chart.sum',
  avg: 'dashboards.chart.avg',
};

export const aggregationFactory = (aggregations, colors, chartTypes) => {
  let response = {};

  Object.entries(aggregations).forEach(element => {
    const key = element[0];
    const objectValue = element[1];

    response = {
      ...response,
      [key]: {
        ...objectValue,
        color: colors[key],
        displayType: chartTypes[key],
      },
    };
  });

  return response;
};

export const DEFAULT_CHART_TYPE = 'bar';
