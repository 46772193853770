import React from 'react';
import { Avatar } from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';
import { getInitialsName } from 'packages/utils';
import { groupsFromMembers, usersFromMembers } from 'packages/utils/proptypes/user';
import styles from './styles.module.less';
import PropTypes from 'prop-types';

const MemberAvatar = ({ user }) => {
  if (user.hasOwnProperty('users'))
    return <Avatar className={styles.avatarGroup} size={24} icon={<UsergroupAddOutlined />} />;
  return (
    <Avatar size={24} src={user.photoURL} className={styles.avatarColor}>
      {getInitialsName(`${user?.firstName} ${user?.lastName}`)}
    </Avatar>
  );
};

MemberAvatar.defaultProps = {
  user: [],
};
MemberAvatar.propTypes = {
  user: PropTypes.oneOfType([usersFromMembers, groupsFromMembers]),
};

export default MemberAvatar;
