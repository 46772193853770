import { useState } from 'react';
import useIsMobile from './useIsMobile';

const useLimitList = ({ data = [], limitForMobile, limitForDesktop }) => {
  const [seeMore, setSeeMore] = useState(false);
  const { isMobile } = useIsMobile();

  const seeAll = seeMore;
  const currentListView = isMobile ? limitForMobile : limitForDesktop;
  const onSeeMore = () => setSeeMore(prev => !prev);
  const totalToBeOpeneable = data.length - currentListView || 0;
  const membersLimited = seeAll ? data : data.slice(0, currentListView);
  const showButton = data.length > currentListView;

  return {
    seeAll: seeMore,
    data: membersLimited,
    onSeeToggle: onSeeMore,
    totalToBeOpeneable,
    currentListView,
    showButton,
  };
};

export default useLimitList;
