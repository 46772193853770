import { ACTIONS } from './actions';

export const getInitialState = () => ({
  data: [],
  error: null,
  lastDocument: null,
  loaded: false,
  loading: false,
  next: null,
  gotNewData: true,
});

export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ACTIONS.UTI_FQP_FIRST_BATCH: {
      return {
        ...getInitialState(),
        loading: true,
      };
    }
    case ACTIONS.UTI_FQP_FIRST_BATCH_ERROR: {
      return {
        ...getInitialState(),
        error: action.payload,
        loaded: true,
      };
    }
    case ACTIONS.UTI_FQP_RESET: {
      return getInitialState();
    }
    case ACTIONS.UTI_FQP_NEXT_BATCH: {
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    }
    case ACTIONS.UTI_FQP_NEXT_BATCH_ERROR: {
      return {
        ...getInitialState(),
        error: action.payload,
        loaded: true,
      };
    }
    case ACTIONS.UTI_FQP_BATCH_SUCCESS: {
      return {
        ...getInitialState(),
        lastDocument: action.payload.lastDocument || state.lastDocument,
        data: { ...state.data, ...action.payload.data },
        gotNewData: !!Object.values(action.payload.data).length,
        next: action.payload.next || state.next,
        loaded: true,
      };
    }
    default:
      throw new Error('useFirestoreQueryPagination: Invalid action');
  }
};
