export const defaultAggsConfig = {
  min: {
    color: '#FE9E15',
    displayType: 'bar',
  },
  max: {
    color: '#003366',
    displayType: 'bar',
  },
  count: {
    color: '#DDAFFF',
    displayType: 'area',
  },
  sum: {
    color: '#35AFFF',
    displayType: 'area',
  },
  avg: {
    color: '#59AA2B',
    displayType: 'line',
  },
};
export const LIST_TIMER_OPTIONS = [1, 3, 5];

export const CHART_TYPES = ['bar', 'line', 'area'];

export const METRICS_FILTER = 'metrics_filter';

export const makeSubtitle = ({ isRelative, dateType }) => {
  let intMessage = 'dashboard.chart.subtitle.';

  if (isRelative) {
    intMessage += 'relative';
    intMessage += `.${dateType}`;
  } else intMessage += 'absolute';

  return intMessage;
};
