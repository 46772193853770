import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.less';
import PropTypes from 'prop-types';

const KeyValueWidget = ({ label, value, className, keyProp, compactValue, oneLine }) => {
  return (
    <div key={keyProp} className={classNames(className, oneLine && styles.oneLine)}>
      {label && <div className={classNames('gx-guarnic-label', oneLine && 'gx-pr-2')}>{label}</div>}
      {value && (
        <div className={classNames('gx-guarnic-body', compactValue && styles.cutText)}>{value}</div>
      )}
    </div>
  );
};

KeyValueWidget.defaultProps = {
  label: null,
  value: null,
  className: null,
  keyProp: null,
  compactValue: false,
  oneLine: false,
};

KeyValueWidget.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  className: PropTypes.string,
  keyProp: PropTypes.any,
  compactValue: PropTypes.bool,
  oneLine: PropTypes.bool,
};

export { KeyValueWidget };
