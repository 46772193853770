import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { doc, setDoc, collection, deleteDoc } from 'firebase/firestore';
import { auth, db, serverTimestamp } from 'firebase/firebase';
import { errorNotification } from 'appRedux/actions';

import {
  SCHEDULE_DELETE,
  SCHEDULE_SAVE_FETCH,
  scheduleDeleteError,
  scheduleDeleteSuccess,
  scheduleSaveFetchError,
  scheduleSaveFetchSuccess,
} from '../actions/schedule';

const scheduleSaveFetchRequest = async (organizationId, divId, data) => {
  const serverData = {
    ...data,
    updatedAt: serverTimestamp(),
  };

  const collectionRef = collection(
    db,
    'organizations',
    organizationId,
    'divisions',
    divId,
    'schedules',
  );

  if (data.id) {
    await setDoc(doc(collectionRef, data.id), serverData, { merge: true });
    return Promise.resolve(serverData);
  }

  const newDocRef = doc(collectionRef);
  const newDocData = {
    ...serverData,
    id: newDocRef.id,
    uid: auth.currentUser.uid,
    createdAt: serverTimestamp(),
  };
  await setDoc(newDocRef, newDocData);
  return Promise.resolve(newDocData);
};

function* scheduleSaveFetch({ payload: { organizationId, divId, data } }) {
  try {
    const response = yield call(scheduleSaveFetchRequest, organizationId, divId, data);
    yield put(scheduleSaveFetchSuccess(response));
  } catch (error) {
    yield put(scheduleSaveFetchError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* fetchScheduleSave() {
  yield takeEvery(SCHEDULE_SAVE_FETCH, scheduleSaveFetch);
}

const scheduleDeleteRequest = async (organizationId, divisionId, scheduleId) =>
  deleteDoc(
    doc(db, 'organizations', organizationId, 'divisions', divisionId, 'schedules', scheduleId),
  );

function* scheduleDelete({ payload: { organizationId, divisionId, scheduleId } }) {
  try {
    const response = yield call(scheduleDeleteRequest, organizationId, divisionId, scheduleId);
    yield put(scheduleDeleteSuccess(response));
  } catch (error) {
    yield put(scheduleDeleteError(error.toString()));
    yield put(errorNotification(error.toString()));
  }
}

export function* deleteSchedule() {
  yield takeEvery(SCHEDULE_DELETE, scheduleDelete);
}

export default function* rootSaga() {
  yield all([fork(fetchScheduleSave), fork(deleteSchedule)]);
}
