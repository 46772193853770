import React from 'react';
import { groupsFromMembers, usersFromMembers } from 'packages/utils/proptypes/user';
import { KeyValueWidget } from 'components/KeyValueWidget';
import { generateKey } from 'packages/utils';
import styles from './styles.module.less';
import classNames from 'classnames';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
import MemberAvatar from './MemberAvatar';

const Members = ({ users, groups, keyProp, className }) => {
  let usersAndGroups = [...users, ...groups];
  const amountUsers = users.length;
  const amountGroups = groups.length;

  usersAndGroups = usersAndGroups.map(item => {
    let newItem = item;
    newItem.isAGroup = item.hasOwnProperty('users');
    return newItem;
  });

  return (
    <div className={className} key={keyProp}>
      <div className="gx-pb-2">
        <KeyValueWidget
          keyProp={generateKey()}
          label={
            <span className="gx-guarnic-headline-2">
              <IntlMessages id="user.list.members.label" values={{ amountUsers, amountGroups }} />
            </span>
          }
        />
      </div>
      <div className={styles.membersContainer}>
        {usersAndGroups.map(usr => (
          <React.Fragment key={usr?.id}>
            {usr.isAGroup ? (
              <KeyValueWidget
                keyProp={usr.id}
                value={
                  <div className="gx-flex-row gx-w-100 gx-flex-nowrap">
                    <div className="gx-mr-2">
                      <MemberAvatar user={usr} />
                    </div>
                    <div className={classNames('gx-align-self-center', styles.cutText)}>
                      {usr.name}
                    </div>
                  </div>
                }
              />
            ) : (
              <KeyValueWidget
                keyProp={usr.id}
                value={
                  <div className="gx-flex-row gx-w-100 gx-flex-nowrap">
                    <div className="gx-mr-2">
                      <MemberAvatar user={usr} />
                    </div>
                    <div className={classNames('gx-align-self-center', styles.cutText)}>
                      {`${usr?.firstName} ${usr?.lastName}`}
                    </div>
                  </div>
                }
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

Members.defaultProps = {
  users: [],
  groups: [],
};

Members.propTypes = {
  users: PropTypes.arrayOf(usersFromMembers),
  groups: PropTypes.arrayOf(groupsFromMembers),
  keyProp: PropTypes.any.isRequired,
};

export default Members;
