import { useEffect, useCallback, useRef, useReducer } from 'react';

import { query, getDocs } from 'firebase/firestore';

import getDocData from '../../firebase/getDocData';

import { queryFetch, queryFetchError, queryFetchBatchSuccess, queryReset } from './actions';
import { getInitialState, reducer } from './reducer';

// This hook helps to fetch data in your Firestore database in multiple queries without having to worry
// about state management. Instead of calling Firestore's getDocs(query(...), () => {}) in a loop
// you simply pass a query list to useFirestoreQueryBatched() and you get back everything you need,
// including loaded, loading, data, and error.
// use it like so:
// const { data, error, loading, loaded } = useFirestoreQueryBatched(queryRef[], dependencies);

// ### YOU MIGHT NEED AN INDEX TO USE THIS ON PRODUCTION ! ###
const useFirestoreQueryBatched = (queryRefList, dependencies) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const stateRef = useRef();
  const lastQueryByBatchRef = useRef({});
  stateRef.current = state;
  const loading = Object.values(stateRef.current.loadingByBatch).some(i => i);
  const loaded = Object.values(stateRef.current.loadedByBatch).every(i => i);

  const onFetchError = useCallback(error => dispatch(queryFetchError(error)), [dispatch]);
  const onFetchBatchSuccess = useCallback(
    (batchId, lastDocument, data) => dispatch(queryFetchBatchSuccess(batchId, lastDocument, data)),
    [dispatch],
  );

  const getFetch = useCallback(async () => {
    dispatch(queryFetch(queryRefList.length));
    lastQueryByBatchRef.current = {};
    await queryRefList.map(async (currentQry, idx) => {
      try {
        const newQuery = query(currentQry);
        lastQueryByBatchRef.current[idx] = newQuery;

        const data = await getDocs(newQuery);
        const lastDoc = data.docs[data.size - 1];
        const docs = {};
        data.docs.forEach(i => {
          const doc = getDocData(i);
          docs[doc.id] = doc;
        });
        onFetchBatchSuccess(idx, lastDoc, docs);
      } catch (error) {
        onFetchError(error);
      }
    });
  }, [dispatch, onFetchError, onFetchBatchSuccess, queryRefList]);

  useEffect(() => {
    // If the dependencies changes, then reset
    dispatch(queryReset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  useEffect(() => {
    if (!queryRefList.length) {
      return;
    }
    if (!loaded && !loading) {
      getFetch();
    }
  }, [dispatch, getFetch, loaded, loading, queryRefList]);

  const data = [];
  const currentState = stateRef.current;
  Object.values(currentState.dataByBatch).map(batch =>
    Object.values(batch).map(item => data.push(item)),
  );
  const shortState = {
    data,
    error: currentState.error,
    loaded: Object.values(currentState.loadedByBatch).every(i => i),
    loading: Object.values(currentState.loadingByBatch).some(i => i),
  };

  return shortState;
};

export default useFirestoreQueryBatched;
