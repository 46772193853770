import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import EventTypeIcon from 'components/EventTypeIcon';

import styles from '../styles.module.less';
import { EyeInvisibleOutlined } from '@ant-design/icons';

const EventName = ({ eventColor, eventIcon, eventName }) => {
  if (!eventName && !eventIcon) {
    return (
      <div className="gx-flex-row gx-justify-content-center">
        <Avatar size={64} icon={<EyeInvisibleOutlined />} />
      </div>
    );
  }
  return (
    <div className={styles.triggerNameContainer}>
      {eventColor && eventIcon ? (
        <div className={styles.triggerNameIcon}>
          <EventTypeIcon color={eventColor} icon={eventIcon} />
        </div>
      ) : (
        <Avatar size={64} icon={<EyeInvisibleOutlined />} />
      )}
      <div className={styles.triggerNameTitle}>
        <span className="gx-mr-2 gx-ml-2">{eventName}</span>
      </div>
    </div>
  );
};

EventName.propTypes = {
  eventColor: PropTypes.object,
  eventIcon: PropTypes.string,
  eventName: PropTypes.string.isRequired,
};

export default EventName;
