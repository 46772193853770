import React from 'react';
import { Col, Tabs } from 'antd';
import { collection, query, where, orderBy } from 'firebase/firestore';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { db } from 'firebase/firebase';
import IntlMessages from 'util/IntlMessages';
import Title from 'components/BoxContainer/components/Title';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import ExecutionList from '../../components/executionList';
import { sortByField } from '../../../utils/functions';
import { useFirestoreRealtimeBatched } from '../../../utils/hooks';
import { getActiveAndNextExecutions } from '../../../utils/functions/executions';

const Home = () => {
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids);
  const orgId = useSelector(state => state.organizations.organization.id);
  const userId = useSelector(({ user }) => user.profile.data.id);

  const queryByDivId = {};
  selectedDivsIds.forEach(divId => {
    queryByDivId[divId] = query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'executions'),
      where('usersWithAccess', 'array-contains', userId),
      where('endAt', '>', moment().subtract(1, 'day').toDate()),
      orderBy('endAt', 'desc'),
    );
  });

  const { data, loading } = useFirestoreRealtimeBatched(Object.values(queryByDivId), [
    selectedDivsIds,
  ]);

  const { activeExecutions, nextExecutions } = getActiveAndNextExecutions(data);

  const executionsActiveSorted = sortByField(activeExecutions, 'createdAt');
  const nextExecutionsSorted = sortByField(nextExecutions, 'createdAt');

  const items = [
    {
      key: '1',
      label: <IntlMessages id="home.container.active.title" />,
      children: (
        <Col span={24}>
          <ExecutionList loading={loading} executions={executionsActiveSorted} />
        </Col>
      ),
    },
    {
      key: '2',
      label: <IntlMessages id="home.container.nextExecutions.title" />,
      children: (
        <Col span={24}>
          <ExecutionList nextExecutions loading={loading} executions={nextExecutionsSorted} />
        </Col>
      ),
    },
  ];

  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer title={<Title value={<IntlMessages id="general.home" />} />} />
      </BoxContainer>
      <BoxContainer content>
        <Tabs defaultActiveKey="1" items={items} />
      </BoxContainer>
    </BoxContainer>
  );
};

export default Home;
