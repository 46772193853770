import React from 'react';
import PropTypes from 'prop-types';

const RADIUS_SELECTED = 6;
const RADIUS_UNSELECTED = 3;
const COLOR_SELECTED = '#038fde';

export const CustomDot = props => {
  const { cx, cy, active, fill } = props;

  return !active ? (
    <circle cx={cx} cy={cy} r={RADIUS_UNSELECTED} fill={fill} />
  ) : (
    <>
      <circle cx={cx} cy={cy} r={RADIUS_SELECTED / 2} fill={COLOR_SELECTED} />
      <circle cx={cx} cy={cy} r={RADIUS_SELECTED} fill="none" stroke={COLOR_SELECTED} />
    </>
  );
};

CustomDot.propTypes = {
  cx: PropTypes.number.isRequired,
  cy: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  fill: PropTypes.string.isRequired,
};
