/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { useIntl } from 'react-intl';
import { DownOutlined, MenuOutlined, RightOutlined, DeleteTwoTone } from '@ant-design/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { TYPES_INTL } from '../../constants';
import ComponentInformation from '../../components/ComponentInformation';
import ComponentProperties from '../../components/ComponentProperties';
import styles from './index.module.less';

const EventComponentCollapse = ({ remove, component, handleTypeSelect, form, onDragDisabled }) => {
  const intl = useIntl();
  const components = form.getFieldValue(['components']);

  const { key, name: id, fieldKey, ...restField } = component;

  return (
    <Collapse
      expandIcon={({ isActive }) => (
        <div className="gx-flex-row gx-w-100">
          <MenuOutlined
            onTouchStartCapture={() => onDragDisabled()}
            onMouseMove={() => onDragDisabled()}
            className={styles.dragAndDropIcon}
          />
          <div className="gx-mt-1">{isActive ? <DownOutlined /> : <RightOutlined />}</div>
        </div>
      )}
      className="gx-mb-3"
    >
      <Collapse.Panel
        key={id}
        extra={
          <DeleteTwoTone
            onClick={e => {
              e.stopPropagation();
              remove(id);
            }}
            twoToneColor="#eb2f96"
          />
        }
        header={intl.formatMessage(
          {
            id: 'eventTypes.eventType.form.components.item.header',
          },
          {
            name:
              components?.[id]?.label?.name ||
              intl.formatMessage({
                id: 'eventTypes.eventType.form.components.item.new',
              }),
            type:
              components?.[id]?.type && intl.formatMessage({ id: TYPES_INTL[components[id].type] }),
          },
        )}
      >
        <ComponentInformation
          extraProps={restField}
          fieldKey={fieldKey}
          id={id}
          onTypeSelect={() => handleTypeSelect(id)}
        />
        <ComponentProperties
          extraProps={restField}
          fieldKey={fieldKey}
          form={form}
          id={id}
          type={components?.[id]?.type}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

const SortableItem = SortableElement(({ value, sortIndex }) => (
  <li tabIndex={sortIndex}>{value}</li>
));

const SortableList = SortableContainer(({ items, dragDisabled }) => (
  <ul className={styles.sorteableList}>
    {items?.map((value, index) => (
      <SortableItem
        disabled={dragDisabled}
        key={index}
        index={index}
        value={value}
        sortIndex={index}
      />
    ))}
  </ul>
));

export const SortableComponentMemo = React.memo(
  ({ items, remove, handleTypeSelect, form, onMove }) => {
    const [records, setRecords] = useState([]);
    const [dragDisabled, setDragDisabled] = useState(true);

    const element = document.getElementsByClassName('gx-main-content-wrapper');

    // onMove method will help to order by index when we are using drag and drop
    const handleSortEnd = ({ oldIndex, newIndex }) => {
      onMove(oldIndex, newIndex);
      element[0].style.overflow = 'auto';
      setDragDisabled(true);
    };

    const onDragDisabled = () => {
      setDragDisabled(false);
    };

    useEffect(() => {
      if (items?.length !== records?.length) {
        setRecords(items);
      }
    }, [items, records]);

    const newItems = records?.map(item => (
      <EventComponentCollapse
        onDragDisabled={onDragDisabled}
        form={form}
        handleTypeSelect={handleTypeSelect}
        component={item}
        remove={remove}
      />
    ));

    return (
      <SortableList
        dragDisabled={dragDisabled}
        onSortStart={(_, e) => {
          e.preventDefault();
          element[0].style.overflow = 'hidden';
          onDragDisabled();
        }}
        onSortEnd={(data, e) => {
          e.preventDefault();
          handleSortEnd(data);
        }}
        items={newItems}
      />
    );
  },
);

EventComponentCollapse.propTypes = {
  remove: PropTypes.func.isRequired,
  component: PropTypes.any.isRequired,
  handleTypeSelect: PropTypes.func.isRequired,
  form: PropTypes.any.isRequired,
};

export default EventComponentCollapse;
