import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, List } from 'antd';
import TriggerElement from './component/TriggerElement';
import { eventTypeSummaryPropTypes } from '../../packages/utils/proptypes/eventTypes';
import BoxContainer from 'components/BoxContainer';
import useLimitList from 'packages/utils/hooks/useLimitList';
import IntlMessages from 'util/IntlMessages';
import EventName from './component/EventName';
import { InfoCardList } from 'components/InfoCard';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import styles from './styles.module.less';
import { MenuOutlined } from '@ant-design/icons';
import LocationList from 'components/LocationList';

const TriggersList = ({ data, divId, className, onEdit, onDelete }) => {
  return (
    <List
      itemLayout="vertical"
      split={false}
      id={data.id}
      size="small"
      dataSource={data}
      renderItem={trigger => (
        <TriggerElement
          className={className}
          divId={divId}
          trigger={trigger}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      )}
    />
  );
};

TriggersList.Simple = ({
  data = [],
  onRemove,
  onAdd,
  wrapperData = false,
  className,
  includeLocations = false,
  initLimitTosee = 2,
}) => {
  const {
    data: dataLimited,
    seeAll,
    totalToBeOpeneable,
    onSeeToggle,
    showButton,
  } = useLimitList({ data: data, limitForMobile: 1, limitForDesktop: initLimitTosee });
  const getActions = event => {
    const output = [];
    if (onAdd) {
      output.push({
        iconButton: 'add',
        action: () => onAdd(event),
        shape: 'circle',
        type: 'link',
        size: 'large',
      });
    }
    if (onRemove) {
      output.push({ iconButton: 'delete', action: () => onRemove(event.id), shape: 'circle' });
    }

    return output;
  };

  const buttonLabel = seeAll ? (
    <>
      <IntlMessages id="button.seeLess" />
    </>
  ) : (
    <>
      {totalToBeOpeneable > 0 && (
        <>
          <IntlMessages id="button.seeMore" />
          <span className="gx-pl-1">{`(${totalToBeOpeneable})`}</span>
        </>
      )}
    </>
  );

  const records = wrapperData ? dataLimited : data;
  return (
    <BoxContainer className={className} empty={data.length === 0}>
      {records.length > 0 &&
        records.map(trigger => (
          <React.Fragment key={trigger.id}>
            <InfoCardList
              extraRightContent={
                includeLocations && (
                  <>
                    {trigger?.locations?.map(loc => (
                      <LocationList withoutBackGround key={loc.id} loc={loc} />
                    ))}
                  </>
                )
              }
              title={
                <EventName
                  eventName={trigger?.name}
                  eventColor={trigger.eventType?.color}
                  eventIcon={trigger.eventType?.icon}
                />
              }
              actionButtons={getActions(trigger)}
            />
          </React.Fragment>
        ))}
      {wrapperData && showButton && (
        <div className="gx-flex-row gx-justify-content-center gx-w-100 gx-pb-2">
          <Button className="gx-mb-0" onClick={onSeeToggle} type="link">
            {buttonLabel}
          </Button>
        </div>
      )}
    </BoxContainer>
  );
};

TriggersList.Sortable = React.memo(({ data = [], onRemove, onAdd, onMove }) => {
  const [dragDisabled, setDragDisabled] = useState(true);

  const onDragDisabled = () => {
    setDragDisabled(false);
  };
  const getActions = event => {
    const output = [];
    if (onAdd) {
      output.push({
        iconButton: 'add',
        action: () => onAdd(event),
        shape: 'circle',
        type: 'link',
        size: 'large',
      });
    }
    if (onRemove) {
      output.push({ iconButton: 'delete', action: () => onRemove(event.id), shape: 'circle' });
    }

    return output;
  };

  const records = data;

  const SortableItem = SortableElement(({ value: trigger, sortIndex }) => (
    <li style={{ listStyle: 'none' }} tabIndex={sortIndex}>
      <div className={styles.sortableItem}>
        <MenuOutlined
          className="gx-mr-3"
          onTouchStartCapture={() => onDragDisabled()}
          onMouseMove={() => onDragDisabled()}
        />
        <React.Fragment key={trigger.id}>
          <InfoCardList
            title={
              <EventName
                eventName={trigger?.name}
                eventColor={trigger.eventType?.color}
                eventIcon={trigger.eventType?.icon}
              />
            }
            actionButtons={getActions(trigger)}
            className="gx-w-100"
          />
        </React.Fragment>
      </div>
    </li>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul className={styles.sorteableList}>
        {items?.length > 0 &&
          items?.map((value, index) => (
            <SortableItem
              disabled={dragDisabled}
              key={index}
              sortIndex={index}
              index={index}
              value={value}
            />
          ))}
      </ul>
    );
  });

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    const updatedRecords = arrayMove(records, oldIndex, newIndex);
    onMove(updatedRecords);
    setDragDisabled(true);
  };

  const arrayMove = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  return (
    <BoxContainer empty={data.length === 0}>
      <SortableList
        items={records}
        onSortStart={(_, e) => {
          e.preventDefault();
          onDragDisabled();
        }}
        onSortEnd={(data, e) => {
          e.preventDefault();
          handleSortEnd(data);
        }}
      />
    </BoxContainer>
  );
});

TriggersList.defaultProps = {
  divId: null,
  actions: [],
  onEdit: null,
  onDelete: null,
};

TriggersList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      avoidConsecutiveTriggering: PropTypes.bool.isRequired,
      cooldown: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      eventType: eventTypeSummaryPropTypes.isRequired,
      expectedExecutions: PropTypes.number,
      id: PropTypes.string.isRequired,
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          createdAt: PropTypes.object,
          distance: PropTypes.shape({
            meters: PropTypes.number,
            outside: PropTypes.bool,
          }),
          divId: PropTypes.string,
          id: PropTypes.string,
          name: PropTypes.string,
          position: PropTypes.shape({
            lat: PropTypes.number,
            lng: PropTypes.number,
            lon: PropTypes.number,
          }),
          type: PropTypes.string,
          uid: PropTypes.string,
          updatedAt: PropTypes.object,
        }),
      ),
      name: PropTypes.string.isRequired,
    }),
  ),
  divId: PropTypes.string,
  actions: PropTypes.array,
  className: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default TriggersList;
