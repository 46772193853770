import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Button, Col, Grid, Row, Spin } from 'antd';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import { ChildrenSchema } from 'packages/utils';

import { ArrowLeftOutlined } from '@ant-design/icons';
import ComponentBlocker from 'components/ComponentBlocker';
import styles from './index.module.less';
import { generateKey } from '../../../utils/functions';
import FloatingContent from '../../../../components/FloatingContent';

const { useBreakpoint } = Grid;

const ContentContainer = ({
  breadcrumb,
  children,
  className,
  title,
  subtitle,
  actions,
  loading,
  filterMode,
  withBackground,
  bottomButtons,
  topButtons,
  onBack,
  filters,
  floatingSession,
}) => {
  const screens = useBreakpoint();
  const isMobile = screens.xs;
  const {
    content,
    position,
    subtitle: floatingSubtitle,
    title: floatingTitle,
    open,
    actions: floatActions,
    onBack: floatingOnBack,
  } = floatingSession || {};
  const isAFloatingMobile = isMobile && floatingSession;
  return (
    <div className={classnames(styles.wrapper, 'gx-w-100', className)}>
      <div className={classnames(styles.content, withBackground && styles.withBackground)}>
        {/* TODO breadcrumb will disappear */}
        {!!breadcrumb && (
          <Row>
            <Col span={24}>
              <Breadcrumb className={styles.breadcrumb}>
                <Breadcrumb.Item>
                  {breadcrumb.length > 0 && (
                    <Link to="/">
                      <IntlMessages id="general.home" />
                    </Link>
                  )}
                </Breadcrumb.Item>
                {breadcrumb.map(bc => (
                  <Breadcrumb.Item key={`${bc.path}-${bc.name}`}>
                    {bc.path ? <Link to={bc.path}>{bc.name}</Link> : bc.name}
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
            </Col>
          </Row>
        )}
        {isAFloatingMobile ? (
          <FloatingContent className={styles.floatingContentCustom} position={position}>
            <Row gutter={[2, 2]}>
              <Col className="gx-pb-2" span={24}>
                <div className="gx-flex-row">
                  {floatingOnBack && (
                    <span className="gx-pr-4 gx-pb-2">
                      <Button
                        onClick={() => floatingOnBack()}
                        shape="circle"
                        icon={<ArrowLeftOutlined />}
                      />
                    </span>
                  )}
                  {floatingTitle && <div className={styles.floatingTitle}>{floatingTitle}</div>}
                </div>
              </Col>
              <Col span={24}>
                {content && open && (
                  <div className={classnames(styles.floatingContent)}>{content}</div>
                )}
              </Col>
              <Col span={24}>
                <div className="gx-d-flex gx-justify-content-between">
                  {floatingSubtitle && (
                    <div className={styles.floatingSubtitle}>{floatingSubtitle}</div>
                  )}
                  <div className="gx-d-flex gx-justify-content-end">
                    {floatActions?.length > 0 &&
                      floatActions.map((bt, idx) => {
                        if (bt?.hide) return null;
                        return (
                          <React.Fragment key={generateKey(idx)}>
                            {bt?.allowedRole && bt.allowedRole.length > 0 ? (
                              <ComponentBlocker allowedRole={bt.allowedRole}>
                                <Button
                                  disabled={bt.disabled || false}
                                  type={bt.type || 'link'}
                                  size={bt.size || 'small'}
                                  onClick={bt.onClick}
                                >
                                  {bt?.label}
                                </Button>
                              </ComponentBlocker>
                            ) : (
                              <Button
                                disabled={bt.disabled || false}
                                type={bt?.type || 'link'}
                                size={bt?.size || 'small'}
                                onClick={bt?.onClick}
                              >
                                {bt?.label}
                              </Button>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </div>
                </div>
              </Col>
            </Row>
          </FloatingContent>
        ) : (
          <Row gutter={0} className="gx-pb-4">
            <div className={classnames('gx-flex-row gx-w-100', filterMode && styles.filterMode)}>
              <Col span={isMobile ? 24 : 12}>
                {title && (
                  <div className="gx-flex-row gx-justify-content-left">
                    {onBack && (
                      <span className="gx-pr-4 gx-pb-2">
                        <Button
                          onClick={() => onBack()}
                          shape="circle"
                          icon={<ArrowLeftOutlined />}
                        />
                      </span>
                    )}
                    <div className="gx-flex-column">
                      <span>{!!title && <h1 className={styles.title}>{title}</h1>}</span>
                      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                    </div>
                  </div>
                )}
              </Col>
              <Col span={isMobile ? 24 : 12}>
                {/* ACTIONS WILL BE DEPRECATED , WE NEED CHANGE THAT BY TOP BUTTONS */}
                {actions && (
                  <Row style={{ flexDirection: 'row-reverse' }}>
                    <Col>{actions}</Col>
                  </Row>
                )}
                {topButtons?.length > 0 && (
                  <Row gutter={8} justify="end">
                    {topButtons.map(bt => {
                      if (bt.hide) return null;

                      return (
                        <Col key={generateKey(bt.label)}>
                          {bt?.allowedRole && bt.allowedRole.length > 0 ? (
                            <ComponentBlocker allowedRole={bt.allowedRole}>
                              <Button disabled={bt.disabled} type={bt.type} onClick={bt.onClick}>
                                {bt.label}
                              </Button>
                            </ComponentBlocker>
                          ) : (
                            <Button disabled={bt.disabled} type={bt.type} onClick={bt.onClick}>
                              {bt.label}
                            </Button>
                          )}
                        </Col>
                      );
                    })}
                  </Row>
                )}
              </Col>
              {/* FILTERS */}
              {filters && <Col span={24}>{filters}</Col>}
            </div>
          </Row>
        )}

        <Row gutter={0} className={isAFloatingMobile && styles.childrenByFloating}>
          {loading ? (
            <Col className="gx-pl-0 gx-pr-0" span={24}>
              <Spin size="large">{children}</Spin>
            </Col>
          ) : (
            <Col className="gx-pl-0 gx-pr-0" span={24}>
              {children}
            </Col>
          )}
        </Row>

        {bottomButtons?.length > 0 && (
          <Row gutter={8} justify="end" className="gx-mt-5">
            {bottomButtons.map(bt => (
              <Col key={generateKey(bt.label)}>
                {bt?.allowedRole && bt.allowedRole.length > 0 ? (
                  <ComponentBlocker allowedRole={bt.allowedRole}>
                    <Button disabled={bt?.disabled} type={bt?.type} onClick={bt?.onClick}>
                      {bt.label}
                    </Button>
                  </ComponentBlocker>
                ) : (
                  <Button disabled={bt?.disabled} type={bt?.type} onClick={bt?.onClick}>
                    {bt?.label}
                  </Button>
                )}
              </Col>
            ))}
          </Row>
        )}
      </div>
    </div>
  );
};

ContentContainer.propTypes = {
  children: ChildrenSchema.isRequired,

  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      name: ChildrenSchema.isRequired,
      path: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
  title: ChildrenSchema,
  subtitle: PropTypes.oneOfType([ChildrenSchema, PropTypes.string]),
  actions: ChildrenSchema,
  loading: PropTypes.bool,
  withBackground: PropTypes.bool,
  bottomButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      type: PropTypes.string,
      disabled: PropTypes.bool,
      allowedRole: PropTypes.array,
    }),
  ),
  topButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      type: PropTypes.string,
      disabled: PropTypes.bool,
      allowedRole: PropTypes.array,
      hide: PropTypes.bool,
    }),
  ),
  onBack: PropTypes.func,
  filterMode: PropTypes.bool,
  filters: ChildrenSchema,
  floatingSession: PropTypes.shape({
    content: ChildrenSchema,
    position: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, ChildrenSchema]),
    subtitle: PropTypes.oneOfType([PropTypes.string, ChildrenSchema]),
    open: PropTypes.bool,
    actions: PropTypes.array,
  }),
};

ContentContainer.defaultProps = {
  breadcrumb: null,
  className: '',
  title: '',
  subtitle: null,
  actions: null,
  loading: false,
  withBackground: false,
  bottomButtons: [],
  topButtons: [],
  onBack: null,
  filterMode: false,
  filters: null,
  floatingSession: null,
};

export default React.memo(ContentContainer);
