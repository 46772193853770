import PropTypes from 'prop-types';
import { eventTypeSummaryPropTypes } from '../eventTypes';

export const triggerPropType = PropTypes.shape({
  avoidConsecutiveTriggering: PropTypes.bool.isRequired,
  cooldown: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  eventType: eventTypeSummaryPropTypes.isRequired,
  expectedExecutions: PropTypes.number,
  id: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.object,
      distance: PropTypes.shape({
        meters: PropTypes.number,
        outside: PropTypes.bool,
      }),
      divId: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      position: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
        lon: PropTypes.number,
      }),
      type: PropTypes.string,
      uid: PropTypes.string,
      updatedAt: PropTypes.object,
    }),
  ),
  name: PropTypes.string.isRequired,
});
