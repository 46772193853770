import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth, db, serverTimestamp } from 'firebase/firebase';
import { doc, getDoc, setDoc, collection } from 'firebase/firestore';

import { EVT_EVENT_FETCH, EVT_EVENT_SAVE_FETCH } from '../actions/event';

import {
  eventFetchError,
  eventFetchSuccess,
  eventSaveFetchError,
  eventSaveFetchSuccess,
} from '../actions';

const eventFetchRequest = async (organizationId, divisionId, eventId) =>
  getDoc(doc(db, 'organizations', organizationId, 'divisions', divisionId, 'events', eventId)).then(
    snapshot => snapshot.data(),
  );

function* eventFetch({ payload: { organizationId, divisionId, eventId } }) {
  try {
    const response = yield call(eventFetchRequest, organizationId, divisionId, eventId);
    yield put(eventFetchSuccess(response));
  } catch (error) {
    yield put(eventFetchError(error));
  }
}

export function* fetchEvent() {
  yield takeEvery(EVT_EVENT_FETCH, eventFetch);
}

const eventSaveFetchRequest = async (organizationId, divisionId, eventId, data) => {
  const docData = {
    ...data,
    updatedAt: serverTimestamp(),
  };

  const collectionRef = collection(
    db,
    'organizations',
    organizationId,
    'divisions',
    divisionId,
    'events',
  );

  if (eventId) {
    await setDoc(doc(collectionRef, eventId), docData, { merge: true });
    return Promise.resolve(docData);
  }
  const newDocRef = doc(collectionRef);
  const newDocData = {
    ...docData,
    id: newDocRef.id,
    uid: auth.currentUser.uid,
    createdAt: serverTimestamp(),
  };
  await setDoc(newDocRef, newDocData);
  return Promise.resolve(newDocData);
};

function* eventSaveFetch({ payload: { organizationId, divisionId, eventId, data } }) {
  try {
    const response = yield call(eventSaveFetchRequest, organizationId, divisionId, eventId, data);
    yield put(eventSaveFetchSuccess(response));
  } catch (error) {
    yield put(eventSaveFetchError(error));
  }
}

export function* fetchEventSave() {
  yield takeEvery(EVT_EVENT_SAVE_FETCH, eventSaveFetch);
}

export default function* rootSaga() {
  yield all([fork(fetchEvent), fork(fetchEventSave)]);
}
