import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { db } from 'firebase/firebase';
import { getDocs, collection } from 'firebase/firestore';
import { errorNotification } from 'appRedux/actions';
import { getCollectionData } from 'packages/utils';

import {
  DIV_LIST_FETCH,
  divisionsListFetchError,
  divisionsListFetchSuccess,
} from '../actions/list';

const divisionsListFetchRequest = async organizationId =>
  getDocs(collection(db, 'organizations', organizationId, 'divisions')).then(getCollectionData);

function* divisionsListFetch({ payload: { organizationId } }) {
  try {
    const response = yield call(divisionsListFetchRequest, organizationId);
    yield put(divisionsListFetchSuccess(response));
  } catch (error) {
    yield put(divisionsListFetchError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* fetchList() {
  yield takeEvery(DIV_LIST_FETCH, divisionsListFetch);
}

export default function* rootSaga() {
  yield all([fork(fetchList)]);
}
