import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import IntlMessages from 'util/IntlMessages';
import { db } from 'firebase/firebase';
import {
  useFirestoreQueryBatchedPagination,
  ALLOWED_ROLES,
  getDivsWithAccess,
} from 'packages/utils';
import { collection, query } from 'firebase/firestore';
import InfiniteScroll from 'components/InfiniteScroll';

import Title from 'components/BoxContainer/components/Title';
import FilterContainer from 'components/FilterContainer';
import BoxContainer from 'components/BoxContainer';
import { getRouteToCreateEventType, getRouteToEditEventType } from '../../constants';
import EventType from '../../components/EventType';

const EventTypesList = () => {
  const history = useHistory();
  const [refresh, setRefresh] = useState(false);

  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids);
  const loadedEventTypes = useSelector(({ eventTypes }) => eventTypes.eventType.save.loaded);
  /*
  const orgEventTypesRef = collection(db, 'organizations', orgId, 'event_types');

  const canListOrgETs = hasAnyAllowedRole(
    ALLOWED_ROLES.ORGANIZATIONS.EVENT_TYPES.LIST,
    userOrgAccess,
  );

   const {
    data: orgEventTypes,
    loading: orgEventTypesLoading,
    error: orgEventTypesError,
  } = useFirestoreQuery(canListOrgETs && orgEventTypesRef, [canListOrgETs, orgId]); */

  const allowedDivs = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.EVENT_TYPES.LIST,
    userOrgAccess,
    userOrgDivisions,
  );
  const allowedDivsIds = allowedDivs.map(d => d.id);

  const allowedSelectedDivs = selectedDivsIds.filter(divId => allowedDivsIds.includes(divId));
  const queriesList = allowedSelectedDivs.map(divId =>
    query(collection(db, 'organizations', orgId, 'divisions', divId, 'event_types')),
  );

  const handleCreateEventType = atOrg => history.push(getRouteToCreateEventType(atOrg));

  const handleEditEventType = (eventTypeId, divId) =>
    history.push(getRouteToEditEventType(eventTypeId, divId));

  const {
    data: divEventTypes,
    loading: divEventTypesLoading,
    next,
    gotNewData,
    error,
  } = useFirestoreQueryBatchedPagination(queriesList, [selectedDivsIds, loadedEventTypes, refresh]);
  const divEventTypesOrdered = divEventTypes.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer
          title={<Title value={<IntlMessages id="sidebar.menu.forms" />} />}
          actionButtons={[
            {
              label: <IntlMessages id="button.create" />,
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.EVENT_TYPES.CREATE,
              type: 'primary',
              action: () => handleCreateEventType(),
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer content loading={divEventTypesLoading} error={error && !divEventTypesLoading}>
        {/* Commented this block of code for now, to disable the Organization Event Types */}
        {/*       <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.EVENT_TYPES.LIST}>
        <h1 className="gx-mr-2">
          <IntlMessages id="eventTypes.list.organizationEventTypes" />
        </h1>
      </ComponentBlocker>
      <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.EVENT_TYPES.CREATE}>
        <Button
          className={classnames('gx-mb-3', styles.createButton)}
          onClick={() => handleCreateEventType(true)}
        >
          <IntlMessages id="button.create" />
        </Button>
      </ComponentBlocker>
      {!orgEventTypesLoading &&
        orgEventTypes?.map(type => (
          <EventType eventType={type} key={type.id} onEdit={() => handleEditEventType(type.id)} />
        ))}
      {orgEventTypesLoading && <Spin className="gx-mt-3" />}
      {orgEventTypesError && (
        <h2>
          <IntlMessages id="notification.somethingWentWrong" />
        </h2>
          <Divider />
      )} */}
        {divEventTypesOrdered.map(type => (
          <EventType
            eventType={type}
            key={type.id}
            onEdit={() => handleEditEventType(type.id, type.divId)}
            onRefresh={setRefresh}
          />
        ))}
        <InfiniteScroll condition={!divEventTypesLoading && gotNewData} callback={next} />
      </BoxContainer>
    </BoxContainer>
  );
};

export default EventTypesList;
