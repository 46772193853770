export const ASSIGNED_DIVS_FETCH = 'ASSIGNED_DIVS_FETCH';
export const ASSIGNED_DIVS_FETCH_ERROR = 'ASSIGNED_DIVS_FETCH_ERROR';
export const ASSIGNED_DIVS_FETCH_SUCCESS = 'ASSIGNED_DIVS_FETCH_SUCCESS';

export const assignedDivisionsFetch = orgId => ({
  type: ASSIGNED_DIVS_FETCH,
  payload: { orgId },
});

export const assignedDivisionsFetchSuccess = divisions => ({
  type: ASSIGNED_DIVS_FETCH_SUCCESS,
  payload: divisions,
});

export const assignedDivisionsFetchError = error => ({
  type: ASSIGNED_DIVS_FETCH_ERROR,
  payload: error,
});
