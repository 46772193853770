export const USR_FETCH = 'USR_FETCH';
export const USR_FETCH_ERROR = 'USR_FETCH_ERROR';
export const USR_FETCH_SUCCESS = 'USR_FETCH_SUCCESS';
export const USR_REMOVE_FETCH = 'USR_REMOVE_FETCH';
export const USR_REMOVE_FETCH_ERROR = 'USR_REMOVE_FETCH_ERROR';
export const USR_REMOVE_FETCH_SUCCESS = 'USR_REMOVE_FETCH_SUCCESS';
export const USR_SAVE_FETCH = 'USR_SAVE_FETCH';
export const USR_SAVE_FETCH_ERROR = 'USR_SAVE_FETCH_ERROR';
export const USR_SAVE_FETCH_SUCCESS = 'USR_SAVE_FETCH_SUCCESS';

export const userFetch = (organizationId, userId) => ({
  type: USR_FETCH,
  payload: { organizationId, userId },
});

export const userFetchSuccess = userId => ({
  type: USR_FETCH_SUCCESS,
  payload: userId,
});

export const userFetchError = error => ({
  type: USR_FETCH_ERROR,
  payload: error,
});

export const userRemoveFetch = (organizationId, userId) => ({
  type: USR_REMOVE_FETCH,
  payload: { organizationId, userId },
});

export const userRemoveFetchSuccess = user => ({
  type: USR_REMOVE_FETCH_SUCCESS,
  payload: user,
});

export const userRemoveFetchError = error => ({
  type: USR_REMOVE_FETCH_ERROR,
  payload: error,
});

export const userSaveFetch = (organizationId, userId, data) => ({
  type: USR_SAVE_FETCH,
  payload: { organizationId, userId, data },
});

export const userSaveFetchSuccess = user => ({
  type: USR_SAVE_FETCH_SUCCESS,
  payload: user,
});

export const userSaveFetchError = error => ({
  type: USR_SAVE_FETCH_ERROR,
  payload: error,
});
