import { Avatar } from 'antd';

import { UserOutlined } from '@ant-design/icons';
import { KeyValueWidget } from 'components/KeyValueWidget';
import styles from './styles.module.less';

export const UserLegend = props => {
  const { payload } = props;

  const parsePayload = data => {
    const user = data?.payload?.data[0];

    return {
      color: data?.color,
      displayName: `${user?.firstName} ${user?.lastName}`,
    };
  };

  return (
    <div className={styles.mainLegend}>
      {payload.map((item, idx) => (
        <div key={idx} className="gx-mr-3">
          <KeyValueWidget
            key={item.color}
            oneLine
            label={
              <Avatar
                style={{ background: parsePayload(item).color }}
                icon={<UserOutlined />}
                size={24}
              />
            }
            value={parsePayload(item).displayName}
          />
        </div>
      ))}
    </div>
  );
};
