import React from 'react';

import moment from 'moment';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';

import IntlMessages from 'util/IntlMessages';
import { getInitialsName } from 'packages/utils';
import { KeyValueWidget } from 'components/KeyValueWidget';

import styles from './styles.module.less';

export const UserDetail = ({ user }) => {
  const { geoData } = user;
  const phoneDetails = {
    altitude: `${geoData?.alt ?? 0} m`,
    accuracy: `${geoData?.accuracy ?? 0} m`,
    speed: `${geoData?.speed ?? 0} km/h`,
    time: moment(geoData?.timestamp).format('h:mm'),
  };
  const fullName = `${user?.firstName} ${user?.lastName}`;

  return (
    <div className={styles.userDetailMain}>
      <div className={styles.section}>
        <KeyValueWidget
          className={styles.customAvatar}
          oneLine
          label={
            <Avatar size={24} src={user.photoURL} className={styles.avatarColor}>
              {getInitialsName(fullName)}
            </Avatar>
          }
          value={fullName}
        />
      </div>
      <div className={styles.sectionB}>
        <KeyValueWidget
          compactValue
          oneLine
          label={<IntlMessages id="common.time" />}
          value={phoneDetails.time}
        />
        <KeyValueWidget
          compactValue
          oneLine
          label={<IntlMessages id="common.accuracy" />}
          value={phoneDetails.accuracy}
        />
        <KeyValueWidget
          compactValue
          oneLine
          label={<IntlMessages id="common.speed" />}
          value={phoneDetails.speed}
        />
      </div>
    </div>
  );
};

UserDetail.propTypes = {
  user: PropTypes.any.isRequired, // TODO: set properties
};
