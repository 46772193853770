import { all, fork, takeEvery } from 'redux-saga/effects';
import {
  ERROR_NOTIFICATION,
  INFO_NOTIFICATION,
  SUCCESS_NOTIFICATION,
  WARNING_NOTIFICATION,
} from 'constants/ActionTypes';
import { NotificationManager } from 'react-notifications';

function createErrorNotification({ payload: { message, title, timeOut, callback, priority } }) {
  NotificationManager.error(message, title, timeOut, callback, priority);
}

export function* errorNotification() {
  yield takeEvery(ERROR_NOTIFICATION, createErrorNotification);
}

function createInfoNotification({ payload: { message, title, timeOut, callback, priority } }) {
  NotificationManager.info(message, title, timeOut, callback, priority);
}

export function* infoNotification() {
  yield takeEvery(INFO_NOTIFICATION, createInfoNotification);
}

function createSuccessNotification({ payload: { message, title, timeOut, callback, priority } }) {
  NotificationManager.success(message, title, timeOut, callback, priority);
}

export function* successNotification() {
  yield takeEvery(SUCCESS_NOTIFICATION, createSuccessNotification);
}

function createWarningNotification({ payload: { message, title, timeOut, callback, priority } }) {
  NotificationManager.warning(message, title, timeOut, callback, priority);
}

export function* warningNotification() {
  yield takeEvery(WARNING_NOTIFICATION, createWarningNotification);
}

export default function* rootSaga() {
  yield all([
    fork(errorNotification),
    fork(infoNotification),
    fork(successNotification),
    fork(warningNotification),
  ]);
}
