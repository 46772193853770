import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Select, Radio, Slider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';

import IntlMessages from 'util/IntlMessages';
import * as actions from 'packages/locations/redux/actions';
import { db } from 'firebase/firebase';
import { collection, doc } from 'firebase/firestore';
import { useFirestoreQuery, ALLOWED_ROLES, getDivsWithAccess, getFullUrl } from 'packages/utils';
import DivisionName from 'components/DivisionName';

import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import { LOC_TYPES, PATHS } from '../../constants';

const { Item } = Form;
const { Option } = Select;
const { Group } = Radio;

const QrLocation = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const intl = useIntl();
  const formRef = useRef();
  const [newDivId, setNewDivId] = useState(null);
  const [isMultiple, setIsMultiple] = useState(false);

  const { orgId, locId, divId: paramDivId } = useParams();
  const fromOrganization = history.location.state?.fromOrganization || false;
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);

  const isEditing = !!locId;
  const isCreatingAtDiv = !isEditing && !fromOrganization;
  const isEditingAtDiv = isEditing && !!paramDivId;
  const divId = paramDivId || newDivId;

  const allowedDivisions = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.CREATE,
    userOrgAccess,
    userOrgDivisions,
  );

  // This Refs are to cover all 4 cases:
  // - Creating a location at: Organization & Division
  // - Editing a location at: Organization & Division
  let rootRef = doc(db, 'organizations', orgId);
  rootRef = divId ? doc(rootRef, 'divisions', divId) : rootRef;
  const locRef = isEditing && doc(rootRef, 'locations', locId);

  const { data: locationData } = useFirestoreQuery(locRef, [orgId, divId, locId]);

  const handleGoBack = () => history.push(getFullUrl(PATHS.BASE_URL, url));

  const handleSingleOrMultipleChange = ({ target: { value } }) =>
    setIsMultiple(value === 'multiple');

  const onFinish = ({ name, amount }) => {
    const locationBaseData = {
      name,
      type: LOC_TYPES.QR,
      ...(isEditing ? { token: locationData.token } : {}),
    };

    if (divId) {
      locationBaseData.divId = divId;
    }

    if (isMultiple) {
      dispatch(actions.locationMultipleCreate(orgId, divId, amount, locationBaseData));
    } else {
      dispatch(
        actions.locationSaveFetch(orgId, divId, locId, {
          token: doc(collection(rootRef, 'locations')).id, // FB generated unique id
          ...locationBaseData, // override token if already existed (editing)
        }),
      );
    }

    // TODO give some feedback to the user about the loading state of the dispatch,
    // maybe dont reditect him
    handleGoBack();
  };
  const handleSubmit = () => formRef.current.submit();

  useEffect(() => {
    if (locationData) {
      form.setFieldsValue({
        name: locationData.name,
        singleOrMultiple: 'single',
      });
    }
  }, [form, locationData]);

  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer
          goBack={handleGoBack}
          title={
            <Title
              value={<IntlMessages id={isEditing ? 'locations.qr.edit' : 'locations.qr.create'} />}
            />
          }
          actionButtons={[
            {
              label: <IntlMessages id="form.save" />,
              type: 'primary',
              action: handleSubmit,
              htmlType: 'submit',
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer content loading={isEditing && !locationData}>
        <Form
          form={form}
          ref={formRef}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            singleOrMultiple: 'single',
            amount: 1,
          }}
        >
          {isCreatingAtDiv && (
            <Item
              label={intl.formatMessage({ id: 'form.division' })}
              name="divId"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: 'form.division.requiredError' }),
                },
              ]}
            >
              <Select
                dropdownMatchSelectWidth={false}
                onChange={setNewDivId}
                optionFilterProp="children"
                placeholder={<IntlMessages id="form.division.placeholder" />}
                showSearch
              >
                {allowedDivisions.map(div => (
                  <Option key={div.id} value={div.id}>
                    {div.name}
                  </Option>
                ))}
              </Select>
            </Item>
          )}
          {!isEditing && (
            <>
              <Item
                name="singleOrMultiple"
                label={intl.formatMessage({ id: 'locations.qr.form.singleOrMultiple' })}
              >
                <Group onChange={handleSingleOrMultipleChange}>
                  <Radio value="single">
                    <IntlMessages id="locations.qr.form.single" />
                  </Radio>
                  <Radio value="multiple">
                    <IntlMessages id="locations.qr.form.multiple" />
                  </Radio>
                </Group>
              </Item>
              {isMultiple && (
                <Form.Item
                  name="amount"
                  label={intl.formatMessage({ id: 'locations.qr.form.createAmount' })}
                  extra={<IntlMessages id="locations.qr.form.createAmount.description" />}
                >
                  <Slider
                    max={500}
                    min={1}
                    marks={{
                      1: '1',
                      100: '100',
                      200: '200',
                      300: '300',
                      400: '400',
                      500: '500',
                    }}
                  />
                </Form.Item>
              )}
            </>
          )}
          {isEditingAtDiv && <DivisionName divId={divId} />}
          <Item
            name="name"
            label={intl.formatMessage({ id: 'locations.qr.form.name' })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'locations.qr.form.name.requiredError',
                }),
              },
              {
                max: 180,
                message: intl.formatMessage({ id: 'form.maxLength.msg' }, { amount: 180 }),
              },
            ]}
          >
            <Input placeholder={intl.formatMessage({ id: 'locations.qr.form.name.placeholder' })} />
          </Item>
        </Form>
      </BoxContainer>
    </BoxContainer>
  );
};

export default QrLocation;
