import { combineReducers } from 'redux';

import { REDUCER_INDEX } from '../../constants';

import schedule from './schedule';

export const reducer = combineReducers({
  schedule,
});

export const registerReducer = state => ({
  ...state,
  [REDUCER_INDEX]: reducer,
});

export const getState = state => state[REDUCER_INDEX];
