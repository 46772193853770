import PropTypes from 'prop-types';

export const chartProptype = PropTypes.shape({
  queryParams: PropTypes.shape({
    componentId: PropTypes.string.isRequired,
    interval: PropTypes.oneOf(['day', 'month', 'quarter', 'year', 'week']),
    range: PropTypes.shape({
      dateRange: PropTypes.array,
      dateType: PropTypes.oneOf(['day', 'month', 'quarter', 'year']),
      isRelative: PropTypes.bool.isRequired,
      relativeAmount: PropTypes.number.isRequired,
    }),
    triggerId: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  layout: PropTypes.shape({
    h: PropTypes.number.isRequired,
    w: PropTypes.number.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  aggregations: PropTypes.shape({
    avg: PropTypes.shape({
      color: PropTypes.string,
      displayType: PropTypes.string,
    }),
    max: PropTypes.shape({
      color: PropTypes.string,
      displayType: PropTypes.string,
    }),
    min: PropTypes.shape({
      color: PropTypes.string,
      displayType: PropTypes.string,
    }),
    count: PropTypes.shape({
      color: PropTypes.string,
      displayType: PropTypes.string,
    }),
    sum: PropTypes.shape({
      color: PropTypes.string,
      displayType: PropTypes.string,
    }),
  }).isRequired,
  uid: PropTypes.string.isRequired,
});

export const chartEventProptype = PropTypes.shape({
  components: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
  ),
  name: PropTypes.string,
});

export const chartTriggerProptype = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
});

export const filtersProptype = PropTypes.shape({
  relative: PropTypes.shape({
    isRelative: PropTypes.bool,
    dateType: PropTypes.string,
    relativeAmount: PropTypes.number,
  }),
  dateRange: PropTypes.any,
});
