import React from 'react';
import { Col, Input, Row, Select } from 'antd';
import Title from 'components/BoxContainer/components/Title';
import { KeyValueWidget } from 'components/KeyValueWidget';
import { useIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
import { WORK_ORDER_STATUS } from 'packages/workOrders/constants';

const WorkOrderFilter = React.memo(({ handleFilters, filters, onRefresh }) => {
  const intl = useIntl();
  const statusOptions = WORK_ORDER_STATUS.map(status => ({
    label: intl.formatMessage({ id: `work.order.status.${status}` }),
    value: status,
  }));

  return (
    <Row justify="start" gutter={[16, 8]}>
      <Col xs={24} sm={6} md={6} xl={6} xxl={6}>
        <KeyValueWidget
          label={<Title.Filter value={<IntlMessages id="general.name" />} />}
          value={
            <Input
              value={filters?.name || ''}
              placeholder={intl.formatMessage({ id: 'work.order.filter.name' })}
              onInput={e =>
                handleFilters(prev => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
            />
          }
        />
      </Col>
      <Col xs={24} sm={6} md={6} xl={6} xxl={6}>
        <KeyValueWidget
          label={<Title.Filter value={<IntlMessages id="general.status" />} />}
          value={
            <Select
              allowClear
              maxTagCount="responsive"
              mode="multiple"
              className="gx-w-100"
              value={filters?.status || ''}
              defaultValue={filters?.status || ''}
              placeholder={intl.formatMessage({ id: 'work.order.filter.status' })}
              onChange={value => {
                handleFilters(prev => ({
                  ...prev,
                  status: value,
                }));
                onRefresh();
              }}
              options={statusOptions}
            />
          }
        />
      </Col>
    </Row>
  );
});

WorkOrderFilter.propTypes = {
  handleFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.array,
  }).isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default WorkOrderFilter;
