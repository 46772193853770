import React, { useCallback, useEffect, useState } from 'react';
import {
  Area,
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Button, Card, Empty, Grid, Modal, Popover, Spin } from 'antd';
import { useCurrentPng } from 'recharts-to-png';
import FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import { IntlProvider, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  InfoCircleTwoTone,
} from '@ant-design/icons';
import { deleteDoc, doc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import IntlMessages from 'util/IntlMessages';
import { auth, functions, db } from 'firebase/firebase';
import ComponentBlocker from 'components/ComponentBlocker';
import { ALLOWED_ROLES } from 'packages/utils';

import { generateKey } from 'packages/utils/functions';
import AppLocale from 'lngProvider';
import moment from 'moment';
import styles from './styles.module.less';
import { camelize, getDateTypeIndex, getRange } from '../../../../utils';
import { chartProptype, filtersProptype } from '../../../../../utils/proptypes/charts';
import ChartInfo from '../ChartInfo';

const { useBreakpoint } = Grid;
const ChartCard = ({ chart, onEdit, refresh, filters }) => {
  const {
    queryParams,
    title,
    id,
    aggregations: { min, max, count, avg, sum },
  } = chart;

  const intl = useIntl();
  const screens = useBreakpoint();
  const { locale } = useSelector(({ settings }) => settings);

  const isMobile = screens.xs;
  const chartGroupBy = filters.groupBy;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    metrics: [],
    info: {
      totalEvents: 0,
      division: {},
      eventType: {},
      trigger: {},
    },
  });

  const [getComposedPng, { ref, isLoading: downloadingPng }] = useCurrentPng();
  const handleDownload = useCallback(async () => {
    const png = await getComposedPng();
    if (png) {
      const noSpacesTitle = title.replaceAll(' ', '_');
      FileSaver.saveAs(png, `${noSpacesTitle}.png`);
    }
  }, [getComposedPng, title]);

  const selectedDivs = useSelector(({ divisions }) => divisions.selector.ids || []);
  let ownDivisionsCount = useSelector(({ divisions }) => divisions.assigned.data.divisions || {});
  ownDivisionsCount = Object.entries(ownDivisionsCount).length;
  const allDivisionSelected = selectedDivs.length >= ownDivisionsCount;

  const orgId = useSelector(state => state.organizations.organization.id);
  const addMessage = () => httpsCallable(functions, 'functions-search-numberMetricsDashboard');

  useEffect(() => {
    const isValidRequest = !!orgId && !!selectedDivs.length;
    if (!isValidRequest) {
      return;
    }

    const { componentId, triggerId } = queryParams;
    const {
      dateRange,
      relative: { dateType, relativeAmount, isRelative },
    } = filters;

    // Date parsed to manage the getRange
    const fromByDefault = moment().subtract(7, 'days');

    let dateRangeFormated = dateRange || [fromByDefault, moment()];
    if (dateRange?.length === 2) {
      dateRangeFormated = [moment(dateRange[0]), moment(dateRange[1])];
    }

    const paramsSent = {
      organizationId: orgId,
      divisionIds: allDivisionSelected ? [] : selectedDivs,
      componentId,
      triggerId,
      interval: chartGroupBy,
      range: getRange(dateRangeFormated, dateType, isRelative, relativeAmount),
      uid: auth.currentUser.uid,
    };

    setLoading(true);
    addMessage()(paramsSent)
      .then(async result => {
        let { data: agg } = camelize(result);
        const hits = agg?.hits || {};
        const totalEvents = hits?.total?.value || 0;
        const extraInfo = hits?.hits[0]?.source; // Take the first index since the query in elastic search was set up with size 1

        agg = agg.aggregations.timeRange.histogram.buckets;

        const formatedData = agg.map(({ key, componentsNested }) => {
          const { numberStats } = componentsNested.filterEventType;
          const aggregationParsed = {
            avg: numberStats?.avg || 0,
            count: numberStats?.count || 0,
            max: numberStats?.max || 0,
            min: numberStats?.min || 0,
            sum: numberStats?.sum || 0,
          };
          return {
            key: getDateTypeIndex(key, chartGroupBy, intl),
            ...aggregationParsed,
          };
        });

        setData({
          metrics: formatedData,
          info: {
            totalEvents,
            division: extraInfo?.division || {},
            eventType: extraInfo?.eventType || {},
            trigger: extraInfo.trigger || {},
          },
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    // TODO check the dependecy array
  }, [orgId, selectedDivs, intl, queryParams, refresh, allDivisionSelected, filters, chartGroupBy]);

  const handleDelete = () => {
    // The ID of this default dashboard is "events"
    const dashboardId = 'events';
    deleteDoc(doc(db, 'organizations', orgId, 'dashboards', dashboardId, 'charts', id));
  };

  const handleEdit = () => {
    onEdit(chart);
  };

  const chartTypes = {
    bar: ({ key, color }) => <Bar dataKey={key} barSize={20} fill={color} />,
    line: ({ key, color }) => <Line dataKey={key} type="monotone" stroke={color} />,
    area: ({ key, color }) => <Area dataKey={key} type="monotone" fill={color} stroke={color} />,
  };

  // Check and build charts to mapping
  let aggregationsMapping = [
    {
      ...min,
      aggregation: 'min',
    },
    {
      ...max,
      aggregation: 'max',
    },
    {
      ...avg,
      aggregation: 'avg',
    },
    {
      ...sum,
      aggregation: 'sum',
    },
    {
      ...count,
      aggregation: 'count',
    },
  ];
  aggregationsMapping = aggregationsMapping.filter(
    e => e !== undefined && Boolean(e?.displayType) === true,
  );

  // Only when it is Mobile device
  const currentAppLocale = AppLocale[locale.locale];
  const getLocalizedComponent = idIntl => (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <div className={styles.triggerTitle}>
        <IntlMessages id={idIntl} />
      </div>
    </IntlProvider>
  );

  const handleTriggerInfo = () => {
    const titleInfo = getLocalizedComponent('triggers.view.information');
    const content = (
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <ChartInfo trigger={data.info.trigger} event={data.info.eventType} />{' '}
      </IntlProvider>
    );
    const okText = getLocalizedComponent('button.ok');

    Modal.info({
      title: titleInfo,
      content,
      okText,
      centered: true,
      width: '90%',
    });
  };

  return (
    <Card className={styles.card}>
      <div className="gx-d-flex gx-flex-column gx-h-100">
        <div className={styles.chartButtons}>
          <div className="gx-flex-row gx-pb-2">
            <div className="gx-flex-column gx-overflow-auto">
              <span className={styles.chartTitle}>{title}</span>
              <span className={styles.chartTotalEvents}>
                <IntlMessages
                  id="dashboards.chart.totalEvents"
                  values={{ total: data.info.totalEvents }}
                />
              </span>
            </div>
            {!isMobile && (
              <Popover
                arrowPointAtCenter
                content={<ChartInfo trigger={data.info.trigger} event={data.info.eventType} />}
                title={
                  <div className={styles.triggerTitle}>
                    <IntlMessages id="triggers.view.information" />
                  </div>
                }
                trigger="hover"
                placement="bottom"
                overlayStyle={{
                  width: '371px',
                }}
              >
                <InfoCircleTwoTone className={styles.extraInfo} />
              </Popover>
            )}
          </div>
          <div className="cancelDrag gx-pb-2">
            <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.CHARTS.UPDATE}>
              <Button shape="circle" icon={<EditOutlined />} onClick={handleEdit} />
            </ComponentBlocker>
            <Button
              shape="circle"
              icon={<DownloadOutlined />}
              loading={downloadingPng}
              onClick={handleDownload}
            />
            <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.CHARTS.DELETE}>
              <Button danger shape="circle" icon={<DeleteOutlined />} onClick={handleDelete} />
            </ComponentBlocker>
            {isMobile && (
              <Button
                onClick={() => handleTriggerInfo()}
                icon={<InfoCircleTwoTone />}
                shape="circle"
                size="large"
              />
            )}
          </div>
        </div>
        {loading && <Spin size="large" />}
        {!loading && !!data.metrics.length && (
          <ResponsiveContainer width="100%" height="100%" minHeight={155} className="cancelDrag">
            <ComposedChart data={data.metrics} ref={ref}>
              <XAxis dataKey="key" />
              <YAxis />
              <Tooltip />
              <Legend />
              <CartesianGrid stroke="#F5F5F5" />
              {aggregationsMapping.map(type => (
                <React.Fragment key={generateKey(type.displayType)}>
                  {chartTypes[type.displayType]({
                    key: type.aggregation,
                    color: type.color,
                  })}
                </React.Fragment>
              ))}
              <Brush dataKey="key" height={30} stroke="#8884D8" />
            </ComposedChart>
          </ResponsiveContainer>
        )}
        {!loading && !data.metrics.length && (
          <Empty
            className="gx-m-auto"
            description={<IntlMessages id="components.empty.description" />}
          />
        )}
      </div>
    </Card>
  );
};

ChartCard.propTypes = {
  chart: chartProptype.isRequired,
  onEdit: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  filters: filtersProptype.isRequired,
};

export default ChartCard;
