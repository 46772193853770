/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import { groupBy } from 'lodash';

import { rgbToHex } from 'packages/utils';
import { convertToDate } from 'packages/utils/dates';

const BASE_URL = 'reports';

export const PATHS = {
  BASE_URL,
  USER_POSITIONS_HISTORY: `${BASE_URL}/userPositionHistory`,
};
export const USER_PER_LIST = 4;
export const POST_HOURS = {
  1: '1 hr',
  6: '6 hrs',
  12: '12 hrs',
  24: '24 hrs',
};
export const POST_HOURS_BY_DEFAULT = '1';
export const MPS_TO_KPH = 3.6;
export const POSITION_HISTORY_STATE = 'position_history_state';
export const CHARTS = {
  speed_by_user: 'speedByUser',
  event_evolution: 'eventEvolutions',
};

export const getCurrentPosByUser = (user, currentIndex = 0) => {
  const currentPos = user.geoData[currentIndex];

  return {
    ...user,
    geoData: currentPos,
  };
};

export const parseUsersPositions = data => {
  const hits = data?.hits?.hits || [];
  if (hits.length === 0) {
    return [];
  }
  // eslint-disable-next-line no-underscore-dangle
  const dataGroupedById = groupBy(hits, hit => hit._source.uid) || [];

  return Object.entries(dataGroupedById).map(([id, record]) => ({
    id,
    geoData: record.map(item => ({
      ...item._source,
      speed: parseFloat((item?._source?.speed * MPS_TO_KPH).toFixed(2)) || 0,
      time: moment(item?._source?.timestamp).format('HH:mm:ss') || null,
      id: item._id,
    })),
  }));
};

export const parseEventsPositions = data => {
  const hits = data?.hits?.hits || [];
  if (hits.length === 0) {
    return [];
  }

  return hits.map(item => {
    // eslint-disable-next-line no-underscore-dangle
    const source = item._source;
    const hexColor = source?.eventType?.color ? rgbToHex(source.eventType.color) : '#000';
    // eslint-disable-next-line no-underscore-dangle
    const eventDate = convertToDate(source.createdAt._seconds, source.createdAt._nanoseconds);
    const time = moment(eventDate).format('hh:mm:ss');
    return {
      eventId: source?.id,
      userId: source?.user?.id,
      location: [source?.position?.location?.lat, source?.position?.location?.lon],
      color: hexColor,
      icon: source?.eventType?.icon,
      eventName: source?.eventType?.name,
      time,
      timeAsSeconds: eventDate.getTime(),
    };
  });
};

export const parseGeoData = geoData => geoData?.map(rc => [rc?.location?.lat, rc?.location?.lon]);
