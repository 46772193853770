import { eventChannel } from 'redux-saga';
import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';
import { auth, db, serverTimestamp } from 'firebase/firebase';
import { getDocData } from 'packages/utils';
import { doc, onSnapshot, setDoc } from 'firebase/firestore';

import {
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  USER_PROFILE_FETCH,
  USER_PROFILE_SAVE_FETCH,
} from 'constants/ActionTypes';
import {
  errorNotification,
  switchLanguage,
  switchTimeZone,
  userProfileFetchError,
  userProfileFetchSuccess,
  userProfileReset,
  userProfileSaveFetchError,
  userProfileSaveFetchSuccess,
} from 'appRedux/actions';
import languageData from '../../../containers/Topbar/languageData';

let profileDocumentChannel = null;

function closeEventChannel() {
  if (profileDocumentChannel) {
    profileDocumentChannel.close();
  }
  profileDocumentChannel = null;
}

function openEventChannel() {
  if (!profileDocumentChannel) {
    profileDocumentChannel = eventChannel(emit =>
      onSnapshot(
        doc(db, 'users', auth.currentUser.uid || localStorage.getItem('user_id')),
        snapshot => emit(getDocData(snapshot)),
      ),
    );
  }
  return profileDocumentChannel;
}

export const refreshAccessToken = updatedAccessAt => {
  // Should refresh token when accesses changed
  auth.currentUser.getIdToken(true);
  localStorage.setItem('updatedAccessAt', updatedAccessAt);
};

// @todo this is a temporary helper for token refresh testing
// We should remove it before 2021-06-01 ... I guess
window.RAT = refreshAccessToken;

function* listenerRegister() {
  const channel = yield call(openEventChannel);

  while (true) {
    try {
      // Take means the saga will block until action is dispatched
      const response = yield take(channel);
      if (response) {
        const lastUpdatedAccessAt = +localStorage.getItem('updatedAccessAt') || 0;
        const updatedAccessAt = response?.updatedAccessAt?.seconds || 0;
        if (updatedAccessAt > lastUpdatedAccessAt) {
          // Should refresh token when accesses changed
          refreshAccessToken(updatedAccessAt);
        }
        // set timezone from the browser only when it has not been set
        if (!response?.timeZone) {
          const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          response.timeZone = timeZone;
        }
        yield put(userProfileFetchSuccess(response));
        const language = languageData.find(l => l.locale === response.locale);
        if (language) {
          yield put(switchLanguage(language));
        }
        yield put(switchTimeZone(response.timeZone));
      } else {
        yield put(userProfileReset());
      }
    } catch (error) {
      yield put(userProfileFetchError(error));
      yield put(errorNotification(error.toString()));
    }
  }
}

export function* listenerUnregister() {
  yield call(closeEventChannel);
}

export function* filterListenerRegister() {
  yield takeEvery(USER_PROFILE_FETCH, listenerRegister);
  yield takeEvery(SIGNIN_USER_SUCCESS, listenerRegister);
}

function* filterListenerUnregister() {
  yield takeEvery(SIGNOUT_USER_SUCCESS, listenerUnregister);
}

const userProfileSaveFetchRequest = async user => {
  const serverData = {
    ...user,
    displayName: `${user.firstName.split(' ').shift()} ${user.lastName.split(' ').shift()}`,
    updatedAt: serverTimestamp(),
  };
  await setDoc(doc(db, 'users', localStorage.getItem('user_id')), serverData, {
    merge: true,
  });
  return Promise.resolve(serverData);
};

function* userProfileSaveFetch({ payload }) {
  try {
    const response = yield call(userProfileSaveFetchRequest, payload);
    yield put(userProfileSaveFetchSuccess(response));
  } catch (error) {
    yield put(userProfileSaveFetchError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* fetchUserProfileSave() {
  yield takeEvery(USER_PROFILE_SAVE_FETCH, userProfileSaveFetch);
}

export default function* rootSaga() {
  yield all([
    fork(filterListenerRegister),
    fork(filterListenerUnregister),
    fork(fetchUserProfileSave),
  ]);
}
