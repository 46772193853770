import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

import { ArrowRightOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';
import logo from 'assets/images/logo.png';

import styles from './styles.module.less';

const Welcome = ({ match }) => {
  const history = useHistory();

  const handleContinue = () => history.push(`${match.path}/wizard`);

  useEffect(() => {
    const listener = e => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.code === 'ArrowRight') {
        e.preventDefault();
        handleContinue();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <>
      <img src={logo} className={`m-bottom-13 ${styles.logo}`} alt="Guarnic" />
      <h1 className={`m-bottom-8 ${styles.title}`}>
        <IntlMessages id="onboarding.welcome.title" />
      </h1>
      <h2 className={`m-bottom-12 ${styles.subtitleMedium}`}>
        <IntlMessages id="onboarding.welcome.description" />
      </h2>
      <h3 className={styles.subtitleSmall}>
        <IntlMessages id="onboarding.welcome.subtitle" />
      </h3>
      <div className={styles.button}>
        <Button type="primary" shape="circle" onClick={handleContinue}>
          <ArrowRightOutlined />
        </Button>
      </div>
    </>
  );
};

export default Welcome;
