import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Input } from 'antd';
import { useIntl } from 'react-intl';

import IntlMessages from 'util/IntlMessages';
import styles from './styles.module.less';

const AcceptInvitationForm = ({ email, isUserIn, loading, onSign, organizationName }) => {
  const intl = useIntl();
  const disableInputs = loading || isUserIn;

  return (
    <div>
      <h2 className={styles.subtitle}>
        <IntlMessages
          id="users.invitation.accept.title"
          values={{ name: <span className={styles.blueText}>{organizationName}</span> }}
        />
      </h2>
      <Form
        initialValues={{
          acceptedTerms: null,
          email,
          password: '',
        }}
        layout="vertical"
        requiredMark={false}
        name="basic"
        onFinish={onSign}
        className="gx-signin-form gx-form-row0"
      >
        <Form.Item
          label={intl.formatMessage({ id: 'onboarding.stepOne.name' })}
          name="firstName"
          rules={[
            { required: true, message: intl.formatMessage({ id: 'form.required.msg' }) },
            {
              min: 3,
              message: intl.formatMessage({ id: 'form.minCharLength' }, { length: 3 }),
            },
            {
              pattern: /^([A-Za-zÀ-ÿ][a-zA-Z]+[ ]*)+$/g,
              message: intl.formatMessage({ id: 'form.noSpecialCharacters' }),
            },
          ]}
        >
          <Input disabled={disableInputs} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'onboarding.stepOne.lastname' })}
          name="lastName"
          rules={[
            { required: true, message: intl.formatMessage({ id: 'form.required.msg' }) },
            {
              min: 3,
              message: intl.formatMessage({ id: 'form.minCharLength' }, { length: 3 }),
            },
            {
              pattern: /^([A-Za-zÀ-ÿ][a-zA-Z]+[ ]*)+$/g,
              message: intl.formatMessage({ id: 'form.noSpecialCharacters' }),
            },
          ]}
        >
          <Input disabled={disableInputs} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'appModule.email' })}
          name="email"
          rules={[
            {
              type: 'email',
              message: intl.formatMessage({ id: 'form.format.msg' }),
            },
            { required: true, message: intl.formatMessage({ id: 'form.required.msg' }) },
            {
              validator: (_, value) => {
                if (value !== email) {
                  return Promise.reject(
                    new Error(intl.formatMessage({ id: 'users.invitation.accept.email.corrupt' })),
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input disabled readOnly autoComplete="username" />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'appModule.password' })}
          name="password"
          rules={[{ required: true, message: intl.formatMessage({ id: 'form.required.msg' }) }]}
        >
          <Input.Password autoComplete="current-password" disabled={disableInputs} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            className={classnames('gx-mb-0', styles.submitButton, isUserIn && 'gx-btn-cyan')}
            htmlType="submit"
            loading={disableInputs}
            disabled={disableInputs}
          >
            <IntlMessages id={isUserIn ? 'app.userAuth.loading' : 'users.invitation.accept.sign'} />
          </Button>
        </Form.Item>
        <div className={styles.subpanel}>
          <Form.Item
            name="acceptedTerms"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error(intl.formatMessage({ id: 'form.required.msg' }))),
              },
            ]}
          >
            <Checkbox disabled={disableInputs}>
              <IntlMessages id="auth.signUp.iAccept" />{' '}
              <a
                className="gx-login-form-forgot"
                href="https://www.operonda.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IntlMessages id="auth.signUp.termsAndConditions" />
              </a>
            </Checkbox>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

AcceptInvitationForm.propTypes = {
  email: PropTypes.string.isRequired,
  onSign: PropTypes.func.isRequired,
  organizationName: PropTypes.string.isRequired,

  isUserIn: PropTypes.bool,
  loading: PropTypes.bool,
};

AcceptInvitationForm.defaultProps = {
  isUserIn: false,
  loading: false,
};

export default React.memo(AcceptInvitationForm);
