/// Given a list of items and a groupSize
/// returns the items grouped by an amount of groupSize
/// Example: groupList( ['a', 'b', 'c'], 2 );

// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import { read, utils } from 'xlsx';

export const isLocalhost = window.location.hostname === 'localhost';

/// returns [ ['a', 'b'], ['c'] ]
const WHERE_IN_FILTER_LIMIT = 10;
export const groupList = (items, groupSize = WHERE_IN_FILTER_LIMIT) => {
  const groups = [];

  for (let i = 0; i < items.length; i += groupSize) {
    const end = i + groupSize > items.length ? items.length : i + groupSize;
    const sublist = items.slice(i, end);
    groups.push(sublist);
  }

  return groups;
};

export const getFullUrl = (nextUrl, currentUrl) => {
  // Examples - Current url
  // 1- "someOrgId/-/eventTypes/event_type/edit/someEventTypeId/someDivId"
  // OR 2- "someOrgId/-"

  // Next Url is "eventTypes"
  // returns "someOrgId/-/eventTypes"
  const divider = '/-';
  const root = currentUrl.split(divider)[0];
  const newURL = `${root}${divider}/${nextUrl}`;
  return newURL;
};

export const convertMinutesToHoursAndMinutes = minutes => {
  const hours = Math.floor(minutes / 60);
  let remainingMinutes = minutes % 60;
  remainingMinutes = remainingMinutes === 0 ? '00' : remainingMinutes;

  return [String(hours), String(remainingMinutes)];
};

export const getInitialsName = name =>
  name &&
  name
    .split(' ')
    .map(item => item.slice(0, 1))
    .join('');

export const sortByField = (data, field) => data.sort((a, b) => (a[field] < b[field] ? 1 : -1));
// NOTE: Generate key was created to create unique ids and to do ref by each element html
export const generateKey = preData => `${preData}_${new Date().getTime()}`;

export const parserFile = (file, sheetIndex = 0) =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = e => {
        const fileObj = e.target.result;
        const wb = read(fileObj, { type: rABS ? 'binary' : 'array' });
        const wsname = wb.SheetNames[sheetIndex];
        const ws = wb.Sheets[wsname];
        const data = utils.sheet_to_json(ws, { header: 1 });
        resolve(data);
      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    } catch (error) {
      reject(error);
    }
  });
// Convert to hexadecimal
function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}
export const rgbToHex = ({ r, g, b }) =>
  `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;

// Hex to RGB Object
export const hexToRgbaObject = (hex, alpha = 1) => {
  const output = hex.replace('#', '');

  const r = parseInt(output.substring(0, 2), 16);
  const g = parseInt(output.substring(2, 4), 16);
  const b = parseInt(output.substring(4, 6), 16);

  const rgbaObject = {
    r: r.toString(),
    g: g.toString(),
    b: b.toString(),
    a: alpha.toString(),
  };

  return rgbaObject;
};

export const deleteDuplicated = array =>
  array.filter((element, index) => array.indexOf(element) === index);

export const generateDelay = async time => new Promise(resolve => setTimeout(resolve, time));

export const getRandomColor = () => {
  // generate a random number between 0 and 16777215 (inclusive)
  const randomHex = Math.floor(Math.random() * 16777215);
  // convert the number to a hexadecimal string and pad with leading zeros
  return `#${randomHex.toString(16).padStart(6, '0')}`;
};

export const getUserTimeZoneDate = (millisDate, fromTimeZone, toTimeZone) => {
  const targetDate = moment.tz(millisDate, fromTimeZone).clone().tz(toTimeZone);
  const formatDate = targetDate.format('YYYY-MM-DD HH:mm:ss');

  return {
    format: formatDate,
    jsDate: new Date(formatDate),
    momentDate: moment(formatDate),
  };
};

// Functio to convert moment date to minutes
export const convertDateToMinutes = date => {
  const currentTime = date.valueOf();
  return Math.round((currentTime - moment().startOf('day').valueOf()) / 60000);
};

// It Was created to use in the SortableComponent
export const arrayMove = (arr, oldIndex, newIndex) => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    // eslint-disable-next-line no-plusplus
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};
