import React from 'react';
import { Layout, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import AppNotification from 'components/AppNotification';
import MailNotification from 'components/MailNotification';
import UserInfo from 'components/UserInfo';
import { toggleCollapsedSideNav } from 'appRedux/actions/Setting';

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from '../../constants/ThemeSetting';
import DivisionSelector from './components/DivisionSelector';
import styles from './style.module.less';

const { Header } = Layout;

const Topbar = () => {
  const { navStyle } = useSelector(({ settings }) => settings);
  const { navCollapsed, width } = useSelector(({ common }) => common);
  const dispatch = useDispatch();

  return (
    <Header className={styles.customHeader}>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}
      <DivisionSelector />

      <ul className="gx-header-notifications">
        <li className="gx-user-nav">
          <UserInfo />
        </li>
      </ul>
    </Header>
  );
};

export default Topbar;
