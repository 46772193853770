import React, { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import IntlMessages from 'util/IntlMessages';

import styles from './styles.module.less';

const CustomInput = ({
  className,
  label,
  textClassName,
  errors,
  register,
  onKeyDown,
  autoFocus,
}) => {
  const name = register.name;

  return (
    <div className={classnames(styles.mainContainer, className)}>
      <label className={classnames('m-bottom-2', textClassName)} htmlFor={name}>
        {label}
      </label>
      <input
        autoComplete="off"
        className={classnames(
          'm-bottom-1 ant-input',
          styles.input,
          !!errors[name]?.message && styles.error,
        )}
        {...register}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
      />
      {!!errors[name]?.message && <span className={styles.errorText}>{errors[name]?.message}</span>}
      {register.required && (
        <span className={classnames(styles.required, textClassName)}>
          <IntlMessages id="form.required" />
        </span>
      )}
    </div>
  );
};

CustomInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  register: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    ref: PropTypes.any.isRequired,

    disabled: PropTypes.bool,
    max: PropTypes.number,
    maxLength: PropTypes.number,
    min: PropTypes.number,
    minLength: PropTypes.number,
    pattern: PropTypes.string,
    required: PropTypes.bool,
  }).isRequired,
  errors: PropTypes.objectOf(
    PropTypes.shape({
      message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
  ).isRequired,

  onKeyDown: PropTypes.func,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  textClassName: PropTypes.string,
};

CustomInput.defaultProps = {
  className: '',
  textClassName: '',
  onKeyDown: () => {},
  autoFocus: false,
};

export default memo(CustomInput);
