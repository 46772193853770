import { useCallback, useEffect, useState } from 'react';

const useGetCurrentLocation = dependencies => {
  const [currentLocation, setCurrentLocation] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchCurrentLocation = useCallback(() => {
    setLoading(true);
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(
        pos => resolve([pos.coords.latitude, pos.coords.longitude]),
        err => reject(err),
      ),
    ).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (dependencies.length > 0) {
      fetchCurrentLocation()
        .then(res => setCurrentLocation(res))
        .catch(err => setError(err));
    }

    // Limpiar el error al desmontar el componente
    return () => setError(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return {
    currentLocation,
    error,
    loading,
  };
};

export default useGetCurrentLocation;
