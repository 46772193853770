import { PATHS } from './constants';
import Home from './screens/main';

export default [
  {
    component: Home,
    path: PATHS.BASE_URL,
    onOrgChangeRedirectTo: `/:orgId/-${PATHS.BASE_URL}`,
  },
];
