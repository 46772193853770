import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth, db, serverTimestamp } from 'firebase/firebase';
import { doc, collection, deleteDoc, writeBatch, setDoc } from 'firebase/firestore';
import { errorNotification } from 'appRedux/actions';

import {
  INVITE_RESEND,
  INVITE_REVOKE,
  INVITE_SEND,
  invitationResendError,
  invitationResendSuccess,
  invitationRevokeError,
  invitationRevokeSuccess,
  invitationsSendError,
  invitationsSendSuccess,
} from '../actions';

const invitationRevokeRequest = async (organizationId, invitationId) =>
  deleteDoc(doc(db, 'organizations', organizationId, 'invitations', invitationId));

function* invitationRevoke({ payload: { organizationId, invitationId } }) {
  try {
    const response = yield call(invitationRevokeRequest, organizationId, invitationId);
    yield put(invitationRevokeSuccess(response));
  } catch (error) {
    yield put(invitationRevokeError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* revokeInvite() {
  yield takeEvery(INVITE_REVOKE, invitationRevoke);
}

const invitationsSendRequest = async (organizationId, invitations) => {
  const batch = writeBatch(db);

  invitations.forEach(invitation => {
    const docRef = doc(collection(db, 'organizations', organizationId, 'invitations')); // generate unique id
    batch.set(docRef, {
      ...invitation,
      id: docRef.id,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      uid: auth.currentUser.uid,
    });
  });

  return batch.commit();
};

function* invitationsSend({ payload: { organizationId, invitations } }) {
  try {
    const response = yield call(invitationsSendRequest, organizationId, invitations);
    yield put(invitationsSendSuccess(response));
  } catch (error) {
    yield put(invitationsSendError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* sendInvite() {
  yield takeEvery(INVITE_SEND, invitationsSend);
}

const invitationResendRequest = async (organizationId, invitationId) =>
  setDoc(
    doc(db, 'organizations', organizationId, 'invitations', invitationId),
    {
      sentAt: null,
      uid: auth.currentUser.uid,
    },
    { merge: true },
  );

function* invitationResend({ payload: { organizationId, invitationId } }) {
  try {
    const response = yield call(invitationResendRequest, organizationId, invitationId);
    yield put(invitationResendSuccess(response));
  } catch (error) {
    yield put(invitationResendError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* resendInvite() {
  yield takeEvery(INVITE_RESEND, invitationResend);
}

export default function* rootSaga() {
  yield all([fork(revokeInvite), fork(sendInvite), fork(resendInvite)]);
}
