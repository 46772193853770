import React from 'react';
import { Col, Row } from 'antd';
import { useIntl } from 'react-intl';
import LabelValue from '../../../../../../components/LabelValue';
import { chartEventProptype, chartTriggerProptype } from '../../../../../utils/proptypes/charts';
import styles from './styles.module.less';

const ChartInfo = ({ trigger, event }) => {
  const intl = useIntl();

  const { name: eventName = '' } = trigger || {};
  const formName = event?.name || '';

  const components = event?.components?.length > 0 && event?.components[0];
  const componentName = components?.label?.name || '';

  return (
    <Row gutter={[8, 8]}>
      {eventName && (
        <Col span={24}>
          <LabelValue
            className={styles.labelValueWrapper}
            name={intl.formatMessage({ id: 'triggers.view.form.eventType' })}
            value={eventName}
          />
        </Col>
      )}
      {formName && (
        <Col span={24}>
          <LabelValue
            className={styles.labelValueWrapper}
            name={intl.formatMessage({ id: 'triggers.view.title' })}
            value={formName}
          />
        </Col>
      )}
      {componentName && (
        <Col span={24}>
          <LabelValue
            className={styles.labelValueWrapper}
            name={intl.formatMessage({ id: 'form.view.components' })}
            value={componentName}
          />
        </Col>
      )}
    </Row>
  );
};

ChartInfo.defaultProps = {
  trigger: {},
};

ChartInfo.propTypes = {
  trigger: chartTriggerProptype,
  event: chartEventProptype.isRequired,
};

export default ChartInfo;
