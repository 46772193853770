import React, { useState } from 'react';
import classnames from 'classnames';
import { Button, Table, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { ACCESS_TYPES, ACCESS_TYPES_INTL } from 'packages/utils';
import IntlMessages from 'util/IntlMessages';

import styles from './styles.module.less';

import { userSaveFetch } from '../../../../redux/actions';

const getInitialPermission = (user, defaultAccessType) => user?.access || defaultAccessType;

const OrganizationPermissions = ({ user, orgName }) => {
  const { orgId, userId } = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [permission, setPermission] = useState(getInitialPermission(user, ACCESS_TYPES.USER));
  const loggedUserAccess = useSelector(
    ({ user: loggedUser }) => loggedUser.access.data?.claims.org[orgId],
  );

  const onSave = () => {
    dispatch(userSaveFetch(orgId, userId, { ...user, access: permission }));
  };

  const setAccess = access => {
    setPermission(access);
  };

  const orgAvailableAccess = {
    [ACCESS_TYPES.OWNER]: [
      ACCESS_TYPES.OWNER,
      ACCESS_TYPES.ADMIN,
      ACCESS_TYPES.READER,
      ACCESS_TYPES.USER,
    ],
    [ACCESS_TYPES.ADMIN]: [ACCESS_TYPES.ADMIN, ACCESS_TYPES.READER, ACCESS_TYPES.USER],
  };

  const accessList = orgAvailableAccess[loggedUserAccess].map(ac => ({
    key: ac,
    name: intl.formatMessage({ id: ACCESS_TYPES_INTL[ac].name }),
  }));

  const columns = [
    ...accessList.map((access, index) => ({
      colSpan: index === 0 ? accessList.length : 0,
      dataIndex: access.key,
      title: orgName,
    })),
  ];

  const dataSource = [
    {
      key: orgId,
      name: orgName,
      ...accessList.reduce(
        (obj, ac) => ({
          ...obj,
          [ac.key]: (
            <Radio checked={permission === ac.key} value={ac.key} onClick={() => setAccess(ac.key)}>
              {ac.name}
            </Radio>
          ),
        }),
        {},
      ),
    },
  ];

  return (
    <>
      {orgAvailableAccess[loggedUserAccess].includes(user.access) ? (
        <Table columns={columns} dataSource={dataSource} bordered pagination={false} />
      ) : (
        <h2>
          <IntlMessages id="users.permissions.notEnoughAccess" />
        </h2>
      )}
      {permission === ACCESS_TYPES.OWNER && permission !== user.access && (
        <h4 className="gx-mt-2">
          <IntlMessages
            id="users.permissions.owner.warning"
            values={{ user: `${user?.firstName} ${user?.lastName}` }}
          />
        </h4>
      )}
      {permission !== user.access && (
        <div className={classnames('m-top-6', styles.buttonsPanel)}>
          <Button type="primary" onClick={onSave}>
            <IntlMessages id="form.save" />
          </Button>
        </div>
      )}
    </>
  );
};

OrganizationPermissions.propTypes = {
  user: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    divisions: PropTypes.objectOf(
      PropTypes.shape({
        access: PropTypes.oneOf(Object.values(ACCESS_TYPES)).isRequired,
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        descriptionn: PropTypes.string,
        avatarURL: PropTypes.string,
      }),
    ).isRequired,
    access: PropTypes.oneOf(Object.values(ACCESS_TYPES)).isRequired,

    photoURL: PropTypes.string,
  }).isRequired,
  orgName: PropTypes.string.isRequired,
};

export default OrganizationPermissions;
