export const GRP_DIVISION_USER_DELETE = 'GRP_DIVISION_USER_DELETE ';
export const GRP_DIVISION_USER_DELETE_ERROR = 'GRP_DIVISION_USER_DELETE_ERROR';
export const GRP_DIVISION_USER_DELETE_SUCCESS = 'GRP_DIVISION_USER_DELETE_SUCCESS';

export const groupDelete = (organizationId, divisionId, groupId) => ({
  type: GRP_DIVISION_USER_DELETE,
  payload: { organizationId, divisionId, groupId },
});

export const groupDeleteSuccess = () => ({
  type: GRP_DIVISION_USER_DELETE_SUCCESS,
});

export const groupDeleteError = error => ({
  type: GRP_DIVISION_USER_DELETE_ERROR,
  payload: error,
});
