import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { db } from 'firebase/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { errorNotification } from 'appRedux/actions';
import { getCollectionData } from 'packages/utils';

import { USR_LIST_FETCH, usersListFetchError, usersListFetchSuccess } from '../actions/list';

const usersListFetchRequest = async organizationId =>
  getDocs(collection(db, 'organizations', organizationId, 'users')).then(getCollectionData);

function* usersListFetch({ payload: { organizationId } }) {
  try {
    const response = yield call(usersListFetchRequest, organizationId);
    yield put(usersListFetchSuccess(response));
  } catch (error) {
    yield put(usersListFetchError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* fetchList() {
  yield takeEvery(USR_LIST_FETCH, usersListFetch);
}

export default function* rootSaga() {
  yield all([fork(fetchList)]);
}
