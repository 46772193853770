import React, { useCallback } from 'react';
import { Empty, Modal, Space } from 'antd';
import { ALLOWED_ROLES } from 'packages/utils';

import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import ComponentBlocker from 'components/ComponentBlocker';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
import { locationDelete } from '../../redux/actions';
import { getRouteToEditLocation } from '../../constants';
import styles from './styles.module.less';
import LocationList from '../../../../components/LocationList';

const { confirm } = Modal;

const OrganizationList = ({ data: orgLocations = [], loading, refresh }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);

  const showDeleteConfirm = useCallback(
    (loc, locDivId) => {
      confirm({
        cancelText: intl.formatMessage({ id: 'button.no' }),
        content: intl.formatMessage({ id: 'locations.list.deleteModal.content' }),
        icon: <ExclamationCircleOutlined />,
        okText: intl.formatMessage({ id: 'button.yes' }),
        okType: 'danger',
        onOk() {
          dispatch(locationDelete(orgId, locDivId, loc.id));
          refresh(prev => !prev);
        },
        title: intl.formatMessage(
          { id: 'locations.list.deleteModal.title' },
          { location: loc.name },
        ),
      });
    },
    [dispatch, intl, orgId, refresh],
  );

  const handleEditLocation = (type, locId, divId) =>
    history.push(getRouteToEditLocation(type, locId, divId), {
      fromOrganization: true,
    });
  return (
    <>
      {!loading && orgLocations?.length > 0 ? (
        orgLocations.map(loc => (
          <div key={loc.id} className={styles.locationElement}>
            <LocationList
              key={loc.id}
              loc={loc}
              actions={[
                <ComponentBlocker
                  divId={loc.divId}
                  allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.UPDATE}
                >
                  <Space
                    className={styles.locationActionButtons}
                    onClick={() => handleEditLocation(loc.type, loc.id, loc.divId)}
                  >
                    <EditTwoTone />
                    <IntlMessages id="button.edit" />
                  </Space>
                </ComponentBlocker>,

                <ComponentBlocker
                  divId={loc.divId}
                  allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.DELETE}
                >
                  <Space
                    className={styles.locationActionButtons}
                    onClick={() => showDeleteConfirm(loc)}
                  >
                    <DeleteTwoTone twoToneColor="#eb2f96" />
                    <IntlMessages id="button.delete" />
                  </Space>
                </ComponentBlocker>,
              ]}
              hasGpsDescription
            />
          </div>
        ))
      ) : (
        <Empty description={intl.formatMessage({ id: 'location.empty.state' })} />
      )}
    </>
  );
};

OrganizationList.defaultProps = {
  data: [],
};
OrganizationList.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default OrganizationList;
