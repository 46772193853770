import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { db, serverTimestamp } from 'firebase/firebase';
import { doc, getDoc, deleteDoc, setDoc, collection } from 'firebase/firestore';
import { errorNotification } from 'appRedux/actions';

import {
  userFetchError,
  userFetchSuccess,
  userRemoveFetchError,
  userRemoveFetchSuccess,
  userSaveFetchError,
  userSaveFetchSuccess,
  USR_FETCH,
  USR_REMOVE_FETCH,
  USR_SAVE_FETCH,
} from '../actions/user';

const userFetchRequest = async (organizationId, userId) =>
  getDoc(doc(db, 'organizations', organizationId, 'users', userId)).then(snapshot =>
    snapshot.data(),
  );

function* userFetch({ payload: { organizationId, userId } }) {
  try {
    const response = yield call(userFetchRequest, organizationId, userId);
    yield put(userFetchSuccess(response));
  } catch (error) {
    yield put(userFetchError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* fetchUser() {
  yield takeEvery(USR_FETCH, userFetch);
}

const userSaveFetchRequest = async (organizationId, userId, data) => {
  const serverData = {
    ...data,
    updatedAt: serverTimestamp(),
  };

  const collectionRef = collection(db, 'organizations', organizationId, 'users');

  if (userId) {
    await setDoc(doc(collectionRef, userId), serverData, {
      merge: false,
    });
    return Promise.resolve(serverData);
  }
  const newDocRef = doc(collectionRef);
  const newDocData = {
    ...serverData,
    id: newDocRef.id,
    createdAt: serverTimestamp(),
  };
  await setDoc(newDocRef, newDocData);
  return Promise.resolve(newDocData);
};

function* userSaveFetch({ payload: { organizationId, userId, data } }) {
  try {
    const response = yield call(userSaveFetchRequest, organizationId, userId, data);
    yield put(userSaveFetchSuccess(response));
  } catch (error) {
    yield put(userSaveFetchError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* fetchUserSave() {
  yield takeEvery(USR_SAVE_FETCH, userSaveFetch);
}

const userRemoveFetchRequest = async (organizationId, userId) =>
  deleteDoc(doc(db, 'organizations', organizationId, 'users', userId));

function* userRemoveFetch({ payload: { organizationId, userId } }) {
  try {
    const response = yield call(userRemoveFetchRequest, organizationId, userId);
    yield put(userRemoveFetchSuccess(response));
  } catch (error) {
    yield put(userRemoveFetchError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* fetchUserRemove() {
  yield takeEvery(USR_REMOVE_FETCH, userRemoveFetch);
}

export default function* rootSaga() {
  yield all([fork(fetchUser), fork(fetchUserSave), fork(fetchUserRemove)]);
}
