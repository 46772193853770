import React from 'react';

import PropTypes from 'prop-types';
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import IntlMessages from 'util/IntlMessages';
import { KeyValueWidget } from 'components/KeyValueWidget';
import Title from 'components/BoxContainer/components/Title';
import moment from 'moment';
import { Empty, Spin } from 'antd';
import { UserYAxis } from 'components/charts/UserYAxis';
import { EventShape } from 'components/charts/EventShape';
import { getScaleByHours } from 'components/charts/constants';
import { CHARTS } from 'packages/reports/constants';
import styles from './styles.module.less';

export const EventEvolutions = React.memo(
  ({ events = [], className, loading, hasUsers, rangeDate, onSetPos }) => {
    const from = moment(moment(rangeDate[0]).format('YYYY-MM-DD HH:mm:ss'));
    const to = moment(moment(rangeDate[1]).format('YYYY-MM-DD HH:mm:ss'));

    const handleMouseDown = e => {
      onSetPos(prev => ({
        ...prev,
        chart: CHARTS.event_evolution,
        cx: e.cx,
        data: {
          location: e.location,
          eventId: e.eventId,
        },
      }));
    };

    const renderChart = () => (
      <ResponsiveContainer width="100%" height={200}>
        <ScatterChart margin={{ top: 5, right: 0, left: 80, bottom: 0 }}>
          <CartesianGrid
            strokeDasharray="2"
            horizontalCoordinatesGenerator={props =>
              // eslint-disable-next-line react/prop-types
              props.height > 250 ? [75, 150, 225] : [100, 200]
            }
          />
          <Tooltip content={<CustomTooltip />} cursor={{ strokeDasharray: '3 3' }} />
          <XAxis
            dataKey="timeAsSeconds"
            domain={[from.valueOf(), to.valueOf()]}
            name="time"
            tickFormatter={unixTime => moment(unixTime).format('HH:mm:ss')}
            type="number"
            ticks={getScaleByHours(from.valueOf(), to.valueOf())}
          />
          <YAxis
            tick={<UserYAxis />}
            type="category"
            dataKey="user"
            name="user"
            allowDuplicatedCategory={false}
          />
          <Scatter
            name="Event evolutions"
            data={events}
            fill="color"
            shape={<EventShape />}
            onMouseDown={handleMouseDown}
          />
        </ScatterChart>
      </ResponsiveContainer>
    );

    const renderEmptyState = () => {
      const message = hasUsers ? (
        <IntlMessages id="general.users.without.records.unselected" />
      ) : (
        <IntlMessages id="general.users.without.records" />
      );

      return <Empty description={message} />;
    };

    return (
      <div className={className}>
        <Title.LabelForm value={<IntlMessages id="chart.evolution.events.title" />} />
        <Spin spinning={loading}>{events.length > 0 ? renderChart() : renderEmptyState()}</Spin>
      </div>
    );
  },
);

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const time = payload.find(record => record.dataKey === 'timeAsSeconds').value;
    const infoFromY = payload.find(record => record.dataKey === 'user');

    return (
      <div className={styles.mainCustomTooltip}>
        <KeyValueWidget
          oneLine
          label={<IntlMessages id="reports.userPositionHistory.chart.tooltip.eventName" />}
          value={infoFromY.payload.eventName}
        />
        <KeyValueWidget
          oneLine
          label={<IntlMessages id="general.name" />}
          value={infoFromY.value}
        />
        <KeyValueWidget
          oneLine
          label={<IntlMessages id="common.time" />}
          value={moment(time).format('HH:mm:ss')}
        />
      </div>
    );
  }

  return null;
};

CustomTooltip.defaultProps = {
  active: false,
  payload: null,
};
CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.any,
};

EventEvolutions.defaultProps = {
  events: [],
  className: null,
  loading: false,
  rangeDate: [],
  hasUsers: false,
  onSetPos: null,
};

EventEvolutions.propTypes = {
  events: PropTypes.array,
  className: PropTypes.string,
  loading: PropTypes.bool,
  rangeDate: PropTypes.array,
  hasUsers: PropTypes.bool,
  onSetPos: PropTypes.func,
};
