export const decoder = window.atob;

export const decode = str => decoder(str.substr(5));

export default str => {
  try {
    return JSON.parse(decode(decode(decode(str))));
  } catch (e) {
    return undefined;
  }
};
