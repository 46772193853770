import { eventChannel } from 'redux-saga';
import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';
import { auth } from 'firebase/firebase';

import { AUTH_TOKEN_LISTENER_REGISTER, SIGNOUT_USER_SUCCESS } from 'constants/ActionTypes';
import {
  errorNotification,
  userAccessFetch,
  userAccessReset,
  userProfileFetch,
  userProfileReset,
  userSignOut,
} from 'appRedux/actions';

let authTokenChannel = null;

function closeEventChannel() {
  if (authTokenChannel) {
    authTokenChannel.close();
  }
  authTokenChannel = null;
}

function openEventChannel() {
  if (!authTokenChannel) {
    authTokenChannel = eventChannel(emit =>
      auth.onIdTokenChanged(data => {
        const credentialsError =
          window.location.pathname !== '/signin' && new Error('Error with stored credentials.');
        return emit(data || credentialsError);
      }),
    );
  }
  return authTokenChannel;
}

// Everytime we receive a state change:
function* listenerRegister() {
  const channel = yield call(openEventChannel);

  while (true) {
    // Take means the saga will block until action is dispatched
    try {
      const result = yield take(channel);
      if (result) {
        yield put(userProfileFetch());
        yield put(userAccessFetch());
      } else {
        yield put(userProfileReset());
        yield put(userAccessReset());
      }
    } catch (error) {
      yield put(userProfileReset());
      yield put(userAccessReset());
      yield put(errorNotification(error.toString()));
      yield put(userSignOut());
    }
  }
}

export function* listenerUnregister() {
  yield call(closeEventChannel);
}

function* filterListenerRegister() {
  yield takeEvery(AUTH_TOKEN_LISTENER_REGISTER, listenerRegister);
}

function* filterListenerUnregister() {
  yield takeEvery(SIGNOUT_USER_SUCCESS, listenerUnregister);
}

export default function* rootSaga() {
  yield all([fork(filterListenerRegister), fork(filterListenerUnregister)]);
}
