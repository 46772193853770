import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from 'firebase/firebase';

import { ALLOWED_ROLES, hasAnyAllowedRole } from 'packages/utils';

import {
  errorNotification,
  organizationFetch,
  organizationSelect,
  userAccessFetchError,
  userAccessFetchSuccess,
} from 'appRedux/actions';
import { USER_ACCESS_FETCH } from 'constants/ActionTypes';
import { actions } from 'packages/divisions';

const userAccessFetchRequest = async () =>
  auth.currentUser.getIdTokenResult().then(result => result);

let activeOrganizationId = null;

function* userAccessFetch() {
  try {
    const response = yield call(userAccessFetchRequest);
    yield put(userAccessFetchSuccess(response));

    // @todo load from local store the last organization selected in case of refresh
    const initialOrganizationId =
      localStorage.getItem('activeOrg') || Object.keys(response.claims.org || {})?.[0];

    const userOrgAccess = response.claims.org?.[initialOrganizationId];
    const canGetOrganization = hasAnyAllowedRole(ALLOWED_ROLES.ORGANIZATIONS.GET, userOrgAccess);

    if (initialOrganizationId && activeOrganizationId !== initialOrganizationId) {
      yield put(organizationSelect(initialOrganizationId));
      yield put(actions.assignedDivisionsFetch(initialOrganizationId));
      if (canGetOrganization) {
        yield put(organizationFetch(initialOrganizationId));
      }
      activeOrganizationId = initialOrganizationId;
    }
  } catch (error) {
    yield put(userAccessFetchError(error));
    yield put(errorNotification(error.toString()));
  }
}

export function* fetchUserAccess() {
  yield takeEvery(USER_ACCESS_FETCH, userAccessFetch);
}

export default function* rootSaga() {
  yield all([fork(fetchUserAccess)]);
}
