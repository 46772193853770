import React, { useEffect } from 'react';
import { Card, Divider, Avatar } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { UserOutlined } from '@ant-design/icons';
import { auth, db } from 'firebase/firebase';
import { doc } from 'firebase/firestore';
import { getStateList, actions as divisionsActions } from 'packages/divisions';
import { useFirestoreQuery, ACCESS_TYPES_INTL, ACCESS_TYPES, getFullUrl } from 'packages/utils';
import IntlMessages from 'util/IntlMessages';

import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import styles from './styles.module.less';

import { getRouteToUsersHome } from '../../constants';
import DivisionsPermissions from './components/DivisionsPermissions';
import OrganizationPermissions from './components/OrganizationPermissions';

const UserPermissions = () => {
  const history = useHistory();
  const { orgId, userId } = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { url } = useRouteMatch();

  const orgName = useSelector(({ user }) => user.profile.data?.organizations[orgId].name);
  const divisionsListState = useSelector(getStateList);
  const { loading: fetchingUser } = useSelector(({ users }) => users?.user?.save);

  const userRef = doc(db, 'organizations', orgId, 'users', userId);
  const { data: user, loading: userLoading } = useFirestoreQuery(userRef, [orgId, userId]);

  const loading = userLoading || divisionsListState.loading || fetchingUser;

  // User cannot change his own permissions
  const isHimself = userId === auth.currentUser.uid;
  if (isHimself) {
    history.replace('/');
  }

  useEffect(() => {
    if (divisionsListState.id !== orgId) {
      dispatch(divisionsActions.divisionsListFetch(orgId));
    }
  }, [dispatch, divisionsListState.id, orgId]);

  const divisionsPermissions = () => {
    switch (user.access) {
      case ACCESS_TYPES.ADMIN:
        return (
          <h2>
            <IntlMessages id="users.list.adminOnAllDivisions" />
          </h2>
        );
      case ACCESS_TYPES.OWNER:
        return (
          <h2>
            <IntlMessages id="users.list.ownerOnAllDivisions" />
          </h2>
        );
      default:
        return <DivisionsPermissions user={user} />;
    }
  };

  return (
    <BoxContainer>
      <BoxContainer content fixed shadow>
        <FilterContainer
          goBack={() => history.push(getFullUrl(getRouteToUsersHome(), url))}
          title={
            <Title.Header
              value={<IntlMessages id="users.permissions.title" values={{ name: orgName }} />}
            />
          }
        />
      </BoxContainer>
      <BoxContainer content loading={loading}>
        {!!user && (
          <Card className={styles.card}>
            <div className="m-bottom-4">
              <Avatar size={36} className="m-right-2" icon={<UserOutlined />} />
              <span className={styles.name}>{`${user?.firstName} ${user?.lastName}`}</span>
              <Divider type="vertical" className={styles.divider} />
              {intl.formatMessage({ id: ACCESS_TYPES_INTL[user.access]?.name })}
            </div>
            <OrganizationPermissions user={user} orgName={orgName} />
            <Divider />
            {divisionsPermissions(user)}
          </Card>
        )}
      </BoxContainer>
    </BoxContainer>
  );
};

export default UserPermissions;
