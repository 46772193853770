import React from 'react';
import classname from 'classnames';
import PropTypes from 'prop-types';
import { Tag, Tooltip } from 'antd';

import IntlMessages from 'util/IntlMessages';
import styles from './styles.module.less';
import EventTypeIcon from '../../../../../../components/EventTypeIcon';
import { LOC_TYPES } from '../../../../../locations/constants';
import { eventTypeSummaryPropTypes } from '../../../../../utils/proptypes/eventTypes';

const TriggerProgressItem = ({ aggregation, trigger }) => {
  const getStatus = percentageCompleted => {
    if (percentageCompleted < 100) {
      return (
        <div className={classname(styles.status, styles.inProgress)}>
          <div className={styles.progressBar} style={{ width: `${percentageCompleted}%` }} />
          <span>
            <IntlMessages id="dashboards.schedules.triggerProgress.inProgress" />
          </span>
        </div>
      );
    }
    return (
      <div className={classname(styles.status, styles.done)}>
        <span>
          <IntlMessages id="dashboards.schedules.triggerProgress.done" />
        </span>
      </div>
    );
  };

  const eventType = trigger?.eventType || null;

  const color = eventType?.color
    ? `rgba(${eventType.color.r}, ${eventType.color.g}, ${eventType.color.b}, ${eventType.color.a})`
    : '';

  return (
    <div className={styles.progressItem}>
      <div
        className={classname(
          'gx-flex-row gx-flex-1 gx-align-items-center gx-flex-nowrap',
          styles.overflowHide,
        )}
      >
        {eventType && (
          <>
            <Tooltip title={eventType?.name}>
              <div>
                {/* To the dev: idk why but without wraping it in a div, the tooltip wont work */}
                <EventTypeIcon color={eventType.color} icon={eventType.icon} className="gx-mr-3" />
              </div>
            </Tooltip>
            <Tag className={classname('gx-mr-3 gx-mb-0', styles.desktop)} color={color}>
              {eventType.name}
            </Tag>
          </>
        )}
        {trigger && <span className="gx-text-truncate">{trigger.name}</span>}
      </div>
      {getStatus(aggregation.triggerFinishedPercentage.value)}
      <div className={styles.executionsAmount}>
        {aggregation.finishedTriggerExecutions.value}/{aggregation.expectedTriggerExecutions.value}
      </div>
    </div>
  );
};

TriggerProgressItem.propTypes = {
  aggregation: PropTypes.shape({
    key: PropTypes.string.isRequired,
    finishedTriggerExecutions: PropTypes.shape({
      value: PropTypes.number.isRequired,
    }).isRequired,
    expectedTriggerExecutions: PropTypes.shape({
      value: PropTypes.number.isRequired,
    }).isRequired,
    triggerFinishedPercentage: PropTypes.shape({
      value: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  trigger: PropTypes.shape({
    avoidConsecutiveTriggering: PropTypes.bool.isRequired,
    cooldown: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    eventType: eventTypeSummaryPropTypes.isRequired,
    expectedExecutions: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.oneOf(Object.values(LOC_TYPES)).isRequired,

        token: PropTypes.string,
        distance: PropTypes.shape({
          outside: PropTypes.bool.isRequired,
          meters: PropTypes.number.isRequired,
        }),
        divId: PropTypes.string,
        position: PropTypes.shape({
          lat: PropTypes.number.isRequired,
          lng: PropTypes.number,
          lon: PropTypes.number,
        }),
      }),
    ),
  }).isRequired,
};

export default TriggerProgressItem;
