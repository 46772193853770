import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row, Tag, Typography } from 'antd';
import { ZoomInOutlined } from '@ant-design/icons';
import styles from './styles.module.less';
import { useToggle } from '../../packages/utils/hooks';
import IntlMessages from 'util/IntlMessages';
import ModalContent from '../../packages/dashboard/screens/ActiveSchedulesDashboard/components/ModalContent';
import { getCompletitionData } from '../../packages/utils/functions/triggers';
import { triggerPropType } from '../../packages/utils/proptypes/trigger';

const ExecutionCounter = ({ execution, triggers }) => {
  const {
    organization: { id: orgId },
    division: { id: divId },
    id: execId,
  } = execution;

  const [isModalOpen, setIsModalOpen] = useToggle(false);
  const completitionData = getCompletitionData(triggers);
  const isComplete = completitionData.finishedPercentage === 100;

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[8, 4]}>
          <Col span={24} className={styles.counterTitleContainer}>
            <Typography.Text strong className="gx-guarnic-label">
              <IntlMessages id="dashboards.schedules.progressItem.executions.label" />
            </Typography.Text>
          </Col>
          <Col span={6}>
            <Tag className={isComplete ? styles.executionTagCompleted : styles.executionTag}>
              <Typography.Text className={styles.counterText}>
                {completitionData.finishedExecutions}/{completitionData.expectedExecutions}
              </Typography.Text>
            </Tag>
          </Col>
          {!!completitionData?.finishedExecutions && (
            <Col span={8}>
              <ZoomInOutlined
                className={styles.counterSeeMore}
                onClick={() => setIsModalOpen(true)}
              />
            </Col>
          )}
        </Row>
      </Col>

      <Modal
        title={execution.schedule.name}
        open={isModalOpen}
        width={800}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button type="primary" onClick={() => setIsModalOpen(false)}>
            Ok
          </Button>,
        ]}
      >
        <ModalContent divId={divId} execId={execId} orgId={orgId} />
      </Modal>
    </Row>
  );
};

ExecutionCounter.propTypes = {
  execution: PropTypes.shape({
    createdAt: PropTypes.object.isRequired,
    division: PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    endAt: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    organization: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    schedule: PropTypes.shape({
      createdAt: PropTypes.object.isRequired,
      divId: PropTypes.string.isRequired,
      duration: PropTypes.number.isRequired,
      id: PropTypes.string.isRequired,

      members: PropTypes.shape({
        groups: PropTypes.arrayOf(
          PropTypes.shape({
            divId: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            avatarURL: PropTypes.string,
            users: PropTypes.arrayOf(
              PropTypes.shape({
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string.isRequired,
                photoURL: PropTypes.string,
                displayName: PropTypes.string.isRequired,
                id: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
              }),
            ),
          }),
        ).isRequired,
        users: PropTypes.arrayOf(
          PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            photoURL: PropTypes.string,
            displayName: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
          }),
        ),
      }).isRequired,
      name: PropTypes.string.isRequired,
      reminders: PropTypes.arrayOf(PropTypes.number).isRequired,
      triggers: PropTypes.arrayOf(triggerPropType),
      jobs: PropTypes.shape({
        start: PropTypes.shape({
          cron: PropTypes.string.isRequired,
          execution: PropTypes.shape({
            group: PropTypes.string.isRequired,
            state: PropTypes.string.isRequired,
            uuid: PropTypes.string.isRequired,
            status: PropTypes.string,
            lastExecution: PropTypes.string,
            nextExecution: PropTypes.string,
            description: PropTypes.string,
          }),
        }).isRequired,
        end: PropTypes.shape({
          cron: PropTypes.string.isRequired,
          execution: PropTypes.shape({
            group: PropTypes.string.isRequired,
            state: PropTypes.string.isRequired,
            uuid: PropTypes.string.isRequired,
            status: PropTypes.string,
            lastExecution: PropTypes.string,
            nextExecution: PropTypes.string,
            description: PropTypes.string,
          }),
        }).isRequired,
        reminders: PropTypes.arrayOf(
          PropTypes.shape({
            cron: PropTypes.string.isRequired,
            execution: PropTypes.shape({
              group: PropTypes.string.isRequired,
              state: PropTypes.string.isRequired,
              uuid: PropTypes.string.isRequired,
              status: PropTypes.string,
              lastExecution: PropTypes.string,
              nextExecution: PropTypes.string,
              description: PropTypes.string,
            }),
          }),
        ),
      }),
    }),
    startAt: PropTypes.object.isRequired,
    triggersIds: PropTypes.arrayOf(PropTypes.string),
    updatedAt: PropTypes.object.isRequired,
  }).isRequired,
  triggers: PropTypes.arrayOf(triggerPropType.isRequired),
};

export default ExecutionCounter;
