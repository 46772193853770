import { union } from 'lodash';

import { ALLOWED_ROLES } from 'packages/utils';

import { PATHS } from './constants';
import EventTypesList from './screens/EventTypesList';
import EventType from './screens/EventType';

export default [
  {
    component: EventTypesList,
    path: PATHS.BASE_URL,
    exact: true,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.EVENT_TYPES.LIST,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.EVENT_TYPES.LIST,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: EventType,
    path: PATHS.CREATE_EVENT_TYPE,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.EVENT_TYPES.CREATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.EVENT_TYPES.CREATE,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: EventType,
    path: PATHS.EDIT_EVENT_TYPE,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.EVENT_TYPES.UPDATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.EVENT_TYPES.UPDATE,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
];
