import { ChildrenSchema } from 'packages/utils/schemas';
import PropTypes from 'prop-types';

export const buttonPropType = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.string, ChildrenSchema]),
  type: PropTypes.string,
  danger: PropTypes.bool,
  action: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  hidden: PropTypes.bool,
  htmlType: PropTypes.string,
  shape: PropTypes.string,
  className: PropTypes.string,
});

export const actionButton = PropTypes.shape({
  ...buttonPropType,
  iconButton: PropTypes.string,
  allowedRole: PropTypes.any,
  divId: PropTypes.string,
});
