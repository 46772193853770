import { useEffect, useRef } from 'react';

export const useResetFormOnCloseModal = ({ form, isVisible }) => {
  const prevVisibleRef = useRef();
  useEffect(() => {
    prevVisibleRef.current = isVisible;
  }, [isVisible]);
  const prevVisible = prevVisibleRef.current;
  useEffect(() => {
    if (!isVisible && prevVisible) {
      form.resetFields();
    }
  }, [form, prevVisible, isVisible]);
};
