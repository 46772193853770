import React, { useCallback, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { Card, Empty, Spin, Grid } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { collection, doc, serverTimestamp, writeBatch } from 'firebase/firestore';

import { useFirestoreQuery, ALLOWED_ROLES, getFullUrl } from 'packages/utils';
import { db } from 'firebase/firebase';
import IntlMessages from 'util/IntlMessages';
import { errorNotification } from 'appRedux/actions';

import { useHistory, useRouteMatch } from 'react-router-dom';
import Title from 'components/BoxContainer/components/Title';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import ChartCard from './components/ChartCard';
import EventsForm from './components/EventsForm';
import { PATHS } from '../../constants';
import ChartFilters from './components/ChartFilters';
import {
  GROUP_BY_DEFAULT,
  ONE_WEEK_BACK,
  RELATIVE_DATE_PER_DEFAULT,
  TODAY_DATE,
} from './components/ChartFilters/constants';
import { set, get } from '../../../utils/storage';
import { METRICS_FILTER, makeSubtitle } from './constants';

const ResponsiveGridLayout = WidthProvider(Responsive);
const { useBreakpoint } = Grid;

const EventsDashboard = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const screens = useBreakpoint();
  const isMobile = screens.xs;

  const orgId = useSelector(state => state.organizations.organization.id);
  const dashboardId = 'events';
  const metricsFilterLastTime = get(METRICS_FILTER);
  const initFilters = metricsFilterLastTime || {
    relative: RELATIVE_DATE_PER_DEFAULT,
    dateRange: [ONE_WEEK_BACK, TODAY_DATE],
    groupBy: GROUP_BY_DEFAULT,
  };

  const [creatingChart, setCreatingChart] = useState(false);
  const [layoutChanges, setLayoutChanges] = useState({});
  const [savingLayoutChanges, setSavingLayoutChanges] = useState(false);
  const [refreshChart, setRefreshChart] = useState(false);
  const [filters, setFilters] = useState(initFilters);
  const hasChanges = !!Object.values(layoutChanges)?.length;

  const addChartForm = () => {
    setCreatingChart(true);
  };

  const cancelNewChart = () => {
    setCreatingChart(false);
  };

  const { data: charts = [], loading: chartsLoading } = useFirestoreQuery(
    collection(db, 'organizations', orgId, 'dashboards', dashboardId, 'charts'),
    [orgId],
  );

  const checkLayoutChange = currentLayout => {
    // From array to key-value
    const originalLayoutByChart = Object.assign({}, ...charts.map(x => ({ [x.id]: x.layout })));
    const newChanges = {};

    currentLayout?.forEach(({ i: id, x, y, w, h }) => {
      const { x: originalX, y: originalY, w: originalW, h: originalH } = originalLayoutByChart[id];

      const layoutChanged =
        originalX !== x || originalY !== y || originalW !== w || originalH !== h;

      if (layoutChanged) {
        newChanges[id] = { x, y, w, h, id };
      }
    });

    setLayoutChanges(newChanges);
  };

  const saveLayout = async () => {
    const changesList = Object.values(layoutChanges);
    if (!hasChanges) {
      return;
    }

    const batch = writeBatch(db);

    changesList.forEach(({ id, x, y, w, h }) => {
      batch.update(doc(db, 'organizations', orgId, 'dashboards', dashboardId, 'charts', id), {
        layout: { x, y, w, h },
        updateAt: serverTimestamp(),
      });
    });

    setSavingLayoutChanges(true);
    try {
      await batch.commit();
    } catch (e) {
      dispatch(errorNotification(e.message || e));
    }
    setSavingLayoutChanges(false);
    setLayoutChanges({});
  };

  const onRefreshCharts = useCallback(
    refresh => {
      if (refresh) setRefreshChart(!refreshChart);
    },
    [refreshChart],
  );

  const handleEditChart = chart => {
    history.push(getFullUrl(PATHS.EDIT_CHART, url), {
      chart,
    });
  };

  const handleFilters = e => {
    const { value, name } = e;
    setFilters(prev => {
      const newFilters = {
        ...prev,
        [name]: value,
      };
      // Save into localStorage
      set(METRICS_FILTER, newFilters);
      return newFilters;
    });
  };
  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer
          description={
            <IntlMessages
              id={makeSubtitle({
                isRelative: filters.relative.isRelative,
                dateType: filters.relative.dateType,
              })}
              values={{
                amount: filters.relative.relativeAmount,
                groupBy: intl.formatMessage({ id: `dateTypes.${filters.groupBy}` }, { amount: 1 }),
              }}
            />
          }
          showHide
          title={<Title value={<IntlMessages id="dashboards.metrics" />} />}
          content={
            <ChartFilters
              handleFilters={handleFilters}
              onRefreshCharts={onRefreshCharts}
              filters={filters}
            />
          }
          actionButtons={[
            {
              label: intl.formatMessage({ id: 'dashboards.chart.addChart' }),
              action: addChartForm,
              type: 'primary',
              hidden: !isMobile && creatingChart,
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.CHARTS.CREATE,
            },
            {
              label: intl.formatMessage({ id: 'dashboards.saveLayout' }),
              action: saveLayout,
              type: 'secondary',
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.CHARTS.UPDATE,
              hidden: !hasChanges,
              loading: savingLayoutChanges,
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer content>
        {creatingChart && (
          <Card className="gx-card" title={intl.formatMessage({ id: 'dashboards.chart.newChart' })}>
            <EventsForm hideForm={cancelNewChart} />
          </Card>
        )}

        <ResponsiveGridLayout
          isDraggable={!isMobile}
          className="layout"
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          onLayoutChange={checkLayoutChange}
          draggableCancel=".cancelDrag"
        >
          {charts.map(chart => (
            /* Dev: DO NOT move the div with data-grid inside the maped component,
          it throws an error on running time about references.
        */
            <div
              key={chart.id}
              data-grid={{ ...chart.layout, minW: 2, minH: 2, maxH: 7 }}
              className="gx-d-flex"
            >
              <ChartCard
                filters={filters}
                refresh={refreshChart}
                chart={chart}
                onEdit={handleEditChart}
              />
            </div>
          ))}
        </ResponsiveGridLayout>
        {chartsLoading && <Spin />}
        {!charts.length && (
          <Empty description={<IntlMessages id="components.empty.description" />} />
        )}
      </BoxContainer>
    </BoxContainer>
  );
};

export default EventsDashboard;
