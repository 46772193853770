export const EVENT_TYPE_SAVE_FETCH = 'EVENT_TYPE_SAVE_FETCH ';
export const EVENT_TYPE_SAVE_FETCH_ERROR = 'EVENT_TYPE_SAVE_FETCH_ERROR';
export const EVENT_TYPE_SAVE_FETCH_SUCCESS = 'EVENT_TYPE_SAVE_FETCH_SUCCESS';

export const eventTypeSaveFetch = (organizationId, divisionId, eventTypeId, data) => ({
  type: EVENT_TYPE_SAVE_FETCH,
  payload: { organizationId, divisionId, eventTypeId, data },
});

export const eventTypeSaveFetchSuccess = eventType => ({
  type: EVENT_TYPE_SAVE_FETCH_SUCCESS,
  payload: eventType,
});

export const eventTypeSaveFetchError = error => ({
  type: EVENT_TYPE_SAVE_FETCH_ERROR,
  payload: error,
});
