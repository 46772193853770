import { SWITCH_TIME_ZONE } from 'constants/ActionTypes';

import { dates, storage } from 'packages/utils';

export function switchTimeZone(tz) {
  // Do nothing if you get an empty string
  if (!tz) {
    return () => null;
  }
  storage.set('timeZone', tz);
  dates.setDefaultTimeZone(tz);
  return dispatch => {
    dispatch({
      type: SWITCH_TIME_ZONE,
      payload: tz,
    });
  };
}
