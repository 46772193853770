import React from 'react';
import { createRoot } from 'react-dom/client';
import * as OfflinePluginRuntime from '@lcdp/offline-plugin/runtime';
import * as Sentry from '@sentry/browser';
import * as unRegisterListenerUser from 'appRedux/sagas/user/profile';
import * as unRegisterListenerAuth from 'appRedux/sagas/user/token';

import NextApp from './NextApp';
import registerServiceWorker from './registerServiceWorker';
import { firebaseApp } from './firebase/firebase';

// Environment
const environment = firebaseApp?.options?.projectId;
// Wrap the rendering in a function:
const container = document.getElementById('root');
const root = createRoot(container);

const render = Component => {
  root.render(<Component />);
};
// Do this once
registerServiceWorker();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    unRegisterListenerUser.listenerUnregister()();
    unRegisterListenerAuth.listenerUnregister()();

    render(NextApp);
  });
} else {
  // Init Sentry
  Sentry.init({
    environment,
    dsn: 'https://accb4b320f9247ed92ff755fa4f44fc3@o436503.ingest.sentry.io/5755449',
    release: `${SYSTEM.version}`,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
  });
  const loadTime = Date.now();

  OfflinePluginRuntime.install({
    onUpdateReady() {
      OfflinePluginRuntime.applyUpdate();
    },
    onUpdated() {
      const autoUpdate = Date.now() - loadTime <= 7000;
      if (autoUpdate) {
        window.location.reload();
        // eslint-disable-next-line no-alert
      } else if (window.confirm('There is a new version of the app. ¿Do you want to reload?')) {
        window.location.reload();
      }
    },
  });
}
