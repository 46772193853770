import React from 'react';
import EventTypeIcon from 'components/EventTypeIcon';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './index.module.less';

const PreviewEventType = React.memo(({ color, icon, eventName }) => (
  <div className={classNames('gx-flex-row gx-p-1', styles.previewEventType)}>
    <div className="gx-flex-column gx-justify-content-center  gx-m-1 gx-w-10">
      <EventTypeIcon color={color} icon={icon} />
    </div>
    <div
      className={classNames(
        'gx-flex-column gx-justify-content-center gx-m-1 gx-w-50 gx-pt-3',
        styles.previewEventTypeName,
      )}
    >
      <p>{eventName}</p>
    </div>
  </div>
));

PreviewEventType.defaultProps = {
  eventName: '',
};
PreviewEventType.propTypes = {
  color: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  eventName: PropTypes.string,
};

export default PreviewEventType;
