import {
  USER_PROFILE_FETCH,
  USER_PROFILE_FETCH_ERROR,
  USER_PROFILE_FETCH_SUCCESS,
  USER_PROFILE_RESET,
  USER_PROFILE_SAVE_FETCH,
  USER_PROFILE_SAVE_FETCH_ERROR,
  USER_PROFILE_SAVE_FETCH_SUCCESS,
  USER_PROFILE_VALUES_RESET,
  USER_PROFILE_VALUES_SET,
} from 'constants/ActionTypes';

const getInitialState = () => ({
  data: null,
  error: null,
  loaded: false,
  save: {
    data: null,
    error: null,
    loaded: false,
    loading: false,
  },
  values: {},
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case USER_PROFILE_FETCH: {
      // We should add `loading: true` but, since this is not a real fetch,
      // that will only trigger refreshes and side effects
      return state;
    }
    case USER_PROFILE_FETCH_ERROR: {
      return {
        ...state,
        data: null,
        error: action.payload,
        loaded: true,
      };
    }
    case USER_PROFILE_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: null,
        loaded: true,
      };
    }

    case USER_PROFILE_RESET: {
      return getInitialState();
    }

    case USER_PROFILE_SAVE_FETCH: {
      return {
        ...state,
        save: {
          data: null,
          error: null,
          loaded: false,
          loading: true,
        },
      };
    }
    case USER_PROFILE_SAVE_FETCH_ERROR: {
      return {
        ...state,
        save: {
          data: null,
          error: action.payload,
          loaded: true,
          loading: false,
        },
      };
    }
    case USER_PROFILE_SAVE_FETCH_SUCCESS: {
      return {
        ...state,
        save: {
          data: action.payload,
          loaded: true,
          loading: false,
        },
      };
    }

    case USER_PROFILE_VALUES_RESET: {
      return { ...state, values: {} };
    }

    case USER_PROFILE_VALUES_SET: {
      return { ...state, values: { ...state.values, ...action.payload } };
    }
    default:
      return state;
  }
};
