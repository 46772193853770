import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

import styles from './styles.module.less';

const EventTypeIcon = ({ color, icon, className }) => {
  return (
    <div className={classname(styles.iconWrap, className)}>
      <div
        className={classname('gx-timeline-badge', styles.icon)}
        style={{
          backgroundColor: color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : '',
        }}
      >
        {!!icon && <span className="material-icons-outlined">{icon}</span>}
      </div>
    </div>
  );
};

EventTypeIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.shape({
    a: PropTypes.number.isRequired,
    b: PropTypes.number.isRequired,
    g: PropTypes.number.isRequired,
    r: PropTypes.number.isRequired,
  }),
  className: PropTypes.string,
};

export default React.memo(EventTypeIcon);
