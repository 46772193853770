import { useEffect, useRef } from 'react';

// This hook is similar to useMemo, but instead of passing an array of dependencies
// we pass a custom compare function that receives the previous and new value.
// The compare function can then compare nested properties, call object methods,
// or anything else to determine equality.
// If the compare function returns true then the hook returns the old object reference.

const useMemoCompare = (nextValue, compareFunction) => {
  // Ref for storing previous value
  const prevRef = useRef();

  // Pass previous and next value to compareFunction function
  // to determine whether to consider them equal.
  const isEqual = compareFunction(prevRef.current, nextValue);

  // If not equal update prevRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compareFunction keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      prevRef.current = nextValue;
    }
  });

  // Finally, if equal then return the previous value
  return isEqual ? prevRef.current : nextValue;
};

export default useMemoCompare;
