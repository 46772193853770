import React, { useRef, useState } from 'react';
import IntlMessages from 'util/IntlMessages';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { Col, Form, notification, Tag, Tooltip, Typography, Upload } from 'antd';
import { useSelector } from 'react-redux';
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
// eslint-disable-next-line import/no-extraneous-dependencies
import { utils } from 'xlsx';
import Title from 'components/BoxContainer/components/Title';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import { getFullUrl, parserFile } from '../../../utils/functions';
import { ALLOWED_FILE_TYPES, HEADERS_NAME, PATHS, uploadFile } from '../../constants';
import DropDownDivisions from '../../../../components/DropDownDivision';
import { ALLOWED_ROLES, getDivsWithAccess } from '../../../utils/access';

const { Item } = Form;
const { Dragger } = Upload;

const UploadLocationFiles = () => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { url } = useRouteMatch();
  const history = useHistory();
  const [division, setDivision] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(null);

  const { orgId } = useParams();
  const uploadFormRef = useRef();

  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);

  const ERROR_MESSAGES = {
    headersName: intl.formatMessage({ id: 'general.upload.columnError' }),
    fileType: intl.formatMessage({ id: 'general.upload.allowedFileType' }),
    latlng: intl.formatMessage({ id: 'locations.upload.formats.gps.info' }),
  };

  const allowedDivisions = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.CREATE,
    userOrgAccess,
    userOrgDivisions,
  );

  const divisionOptions = [
    ...[{ id: '', name: intl.formatMessage({ id: 'location.division.option.import' }) }],
    ...allowedDivisions,
  ];

  const validationFile = async file => {
    const errorTypes = {
      headersName: false,
      fileType: false,
      latlng: false,
    };

    // Check First File type
    errorTypes.fileType = !ALLOWED_FILE_TYPES.includes(file?.type);
    if (errorTypes.fileType) return errorTypes;

    const data = await parserFile(file);
    let headers = data[0];
    headers = headers.map(item => item);
    // Get File Type (QR, NFC, GPS)
    let locationFileType = null;

    if (headers.includes('NFC')) locationFileType = 'NFC';
    else if (headers.includes('CODE')) locationFileType = 'QR';
    else if (headers.includes('LAT')) locationFileType = 'GPS';

    if (locationFileType === 'GPS') {
      const onlyLngAndLat = data.reduce((acc, val, idx) => {
        const aux = [];
        if (idx > 0) {
          const lat = val[1];
          const lng = val[2];
          aux.push(lat, lng);
        }
        return [...acc, ...aux];
      }, []);

      const patron = /^-?\d+(\.\d+)?$/;
      const hasSomeString = onlyLngAndLat.some(element => typeof element === 'string');
      const hasGoodFormat = onlyLngAndLat.some(element => patron.test(element));

      if (hasSomeString || !hasGoodFormat) {
        errorTypes.latlng = true;
      }
    }

    if (!locationFileType) {
      errorTypes.headersName = true;
      return errorTypes;
    }

    const columnsByFile = HEADERS_NAME[locationFileType];

    // Check headers
    errorTypes.headersName = JSON.stringify(headers) !== JSON.stringify(columnsByFile);

    return errorTypes;
  };

  const handleGoBack = () => history.push(getFullUrl(PATHS.BASE_URL, url));

  const handleLoad = async () => {
    const uploadForm = uploadFormRef.current;

    /* validateFields
     * Check division selected , Must be required
     */
    await uploadForm.validateFields();

    if (!fileUploaded) {
      return notification.warning({
        message: intl.formatMessage({ id: 'locations.upload.dragger.isRequired' }),
        placement: 'topRight',
      });
    }
    const divId = uploadForm.getFieldValue('divId');
    const file = fileUploaded;
    const document = file.file.originFileObj;

    const fileBody = {
      file: document,
      orgId,
      divId,
    };

    /** validateFile
     * Method to validate into file (columns, file type, etc.)
     */
    const errors = await validationFile(document);
    const errorsArray = Object.values(errors);
    const hasErrors = errorsArray.some(item => item === true);

    if (hasErrors) {
      return Object.keys(errors).forEach(item => {
        const errorItem = errors[item];
        if (errorItem) {
          notification.error({
            message: ERROR_MESSAGES[item],
            placement: 'topRight',
          });
        }
      });
    }

    // Active spinner
    setIsLoading(true);
    return uploadFile(fileBody)
      .then(() => {
        setIsLoading(false);
        uploadForm.resetFields();
        notification.success({
          message: intl.formatMessage({ id: 'locations.upload.successful' }),
          placement: 'topRight',
        });
      })
      .catch(() => {
        setIsLoading(false);
        notification.error({
          message: intl.formatMessage({ id: 'locations.upload.error' }),
          placement: 'topRight',
        });
      });
  };

  const downloadTemplate = e => {
    const fileType = e.target.innerText;
    // Create a new workbook
    const workbook = utils.book_new();
    const wsData = [HEADERS_NAME[fileType]];

    const ws = utils.aoa_to_sheet(wsData);
    // Add the worksheet to the workbook
    utils.book_append_sheet(workbook, ws, 'Sheet1');

    // Convert the workbook to CSV format
    const csvData = utils.sheet_to_csv(ws);

    // Create a Blob object with the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });
    // Create a URL for the Blob
    const urlObj = URL.createObjectURL(blob);
    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a');
    a.href = urlObj;
    a.download = `${intl.formatMessage({ id: 'locations.upload.filename' })}_${fileType}.csv`;

    // Programmatically click the anchor to trigger the download
    a.click();
  };

  return (
    <BoxContainer>
      <BoxContainer content>
        <FilterContainer
          goBack={handleGoBack}
          title={<Title value={<IntlMessages id="location.uploadFile.title" />} />}
          actionButtons={[
            {
              label: <IntlMessages id="general.upload" />,
              type: 'primary',
              action: handleLoad,
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.CREATE,
              htmlType: 'submit',
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer content loading={isLoading}>
        <Form ref={uploadFormRef} form={form} layout="vertical">
          <Item name="divId" label={<IntlMessages id="locations.upload.division" />}>
            <DropDownDivisions
              placeholder={<IntlMessages id="location.division.option.import" />}
              options={divisionOptions}
              onChange={setDivision}
              value={division}
              defaultValue={intl.formatMessage({ id: 'location.division.option.import' })}
            />
          </Item>
          <Dragger onChange={setFileUploaded} maxCount={1} multiple={false}>
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">
              <IntlMessages id="locations.upload.dragger.message" />
            </p>
          </Dragger>
          <Col className="gx-m-2">
            <Typography.Text className="gx-mr-2">
              <IntlMessages id="locations.upload.download.template" />
            </Typography.Text>
            <Tag onClick={e => downloadTemplate(e)} className="gx-pointer" color="processing">
              QR
            </Tag>
            <Tag onClick={e => downloadTemplate(e)} className="gx-pointer" color="processing">
              NFC
            </Tag>
            <Tag onClick={e => downloadTemplate(e)} className="gx-pointer" color="processing">
              GPS
            </Tag>
            <Tooltip
              placement="right"
              title={<IntlMessages id="locations.upload.formats.gps.info" />}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Col>
        </Form>
      </BoxContainer>
    </BoxContainer>
  );
};

export default UploadLocationFiles;
