import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Select, Row, Col } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { ClearOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { LOC_TYPES } from '../../constants';

const LocationFilter = ({
  handleFilter,
  handleSearch,
  locationType,
  locationName,
  handleClear,
}) => {
  const intl = useIntl();

  let locationTypeOptions = Object.values(LOC_TYPES).map(item => ({ label: item, value: item }));
  locationTypeOptions = locationTypeOptions.concat({
    label: intl.formatMessage({ id: 'location.type.search' }),
    value: '',
  });

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Select
          value={locationType}
          onChange={value => {
            handleFilter('locationType', value);
          }}
          showSearch
          style={{ width: '100%' }}
          options={locationTypeOptions}
          className="gx-m-2"
        />
      </Col>
      <Col span={24}>
        <Input
          className="gx-m-2"
          value={locationName}
          onChange={e => {
            handleFilter('locationName', e.target.value);
          }}
          onPressEnter={() => handleSearch()}
          placeholder={intl.formatMessage({ id: 'location.placeholder.name' })}
          style={{ width: '100%' }}
        />
      </Col>
      <Col>
        <Button
          danger
          icon={<ClearOutlined className="gx-mr-2" />}
          className="gx-m-2"
          onClick={handleClear}
        >
          <IntlMessages id="general.clear" />
        </Button>
      </Col>
    </Row>
  );
};

LocationFilter.defaultProps = {
  locationType: '',
  locationName: '',
};
LocationFilter.propTypes = {
  handleFilter: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  locationType: PropTypes.string,
  locationName: PropTypes.string,
};

export default LocationFilter;
