import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SwitchAction from './SwitchAction';

import styles from './styles.module.less';
import { triggerPropType } from '../../../utils/proptypes/trigger';
import useGetSubscriberByNotification from '../../../utils/hooks/useGetSubscriberByNotification';
import { deleteDuplicated } from '../../../utils/functions';

const EventTypes = React.memo(
  ({ notificationBody, user, trigger = {}, onTotalEventTypes, loading }) => {
    // NOTE:
    /* useGetSubscriberByNotification @Params
     * user: userSelected
     * body: request body to each toggle
     * onTotalEventTypes: function to call to action
     * dependencies: in this case we are going to pass loading by the batch created (all events switch)
     */
    const { hasSubscriber, handleActionReport, fetching } = useGetSubscriberByNotification({
      user,
      body: {
        ...notificationBody,
        entityId: trigger?.id,
      },
      onTotalEventTypes,
      dependencies: [loading],
    });

    useEffect(() => {
      if (hasSubscriber) {
        onTotalEventTypes(prev => deleteDuplicated([...prev, trigger.id]));
      }
    }, [hasSubscriber, onTotalEventTypes, trigger.id]);

    return (
      <SwitchAction
        className={styles.elementActionCustom}
        label={trigger?.name}
        checked={hasSubscriber}
        onChange={() => handleActionReport()}
        loading={fetching}
      />
    );
  },
);

EventTypes.propTypes = {
  notificationBody: PropTypes.shape({
    orgId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    divisionId: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.any.isRequired,
  trigger: triggerPropType.isRequired,
  onTotalEventTypes: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default EventTypes;
