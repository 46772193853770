import { useEffect, useCallback, useRef, useReducer } from 'react';

import { query, startAfter, limit, getDocs } from 'firebase/firestore';

import getDocData from '../../firebase/getDocData';

import {
  queryPaginationFirstBatch,
  queryPaginationFirstBatchError,
  queryPaginationNextBatch,
  queryPaginationNextBatchError,
  queryPaginationBatchSuccess,
  queryPaginationReset,
} from './actions';
import { getInitialState, reducer } from './reducer';

const DEFAULT_PAGE_SIZE = 10;

// This hook helps to fetch data in your Firestore database without having to worry
// about state management and pagination. Instead of calling Firestore's getDocs(query(...))
// you simply pass a query to useFirestoreQueryPagination() and you get back everything you need,
// including loaded, loading, data, and error.
// use it like so:
// const { data, error, loading, loaded, next } = useFirestoreQueryPagination(queryRef, dependencies, pageSize);
// where next is a callback action that you can trigger when you need the next page

// ### YOU MIGHT NEED AN INDEX TO USE THIS ON PRODUCTION ! ###
const useFirestoreQueryPagination = (queryRef, pageSize = DEFAULT_PAGE_SIZE) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const stateRef = useRef();
  const lastQueryRef = useRef();
  stateRef.current = state;

  const onNextBatchError = useCallback(
    error => dispatch(queryPaginationNextBatchError(error)),
    [dispatch],
  );
  const onNextBatchSuccess = useCallback(
    (lastDocument, data) => dispatch(queryPaginationBatchSuccess(lastDocument, data)),
    [dispatch],
  );

  const getNextBatch = useCallback(async () => {
    // If there is no lastDocument ref, dont fetch the next batch
    if (!stateRef.current.lastDocument) {
      return;
    }
    if (!stateRef.current.next) {
      dispatch(queryPaginationReset());
    } else if (!stateRef.current.loading) {
      try {
        dispatch(queryPaginationNextBatch());
        const nextBatch = query(
          lastQueryRef.current,
          startAfter(stateRef.current.lastDocument),
          limit(pageSize),
        );
        const data = await getDocs(nextBatch);
        const lastDoc = data.docs[data.size - 1];
        const docs = {};
        data.docs.forEach(i => {
          const doc = getDocData(i);
          docs[doc.id] = doc;
        });

        onNextBatchSuccess(lastDoc, docs);
      } catch (error) {
        onNextBatchError(error);
      }
    }
  }, [dispatch, onNextBatchError, onNextBatchSuccess, pageSize]);

  const onFirstBatchError = useCallback(
    error => dispatch(queryPaginationFirstBatchError(error)),
    [dispatch],
  );
  const onFirstBatchSuccess = useCallback(
    (lastDocument, data) => dispatch(queryPaginationBatchSuccess(lastDocument, data, getNextBatch)),
    [dispatch, getNextBatch],
  );

  const getFirstBatch = useCallback(async () => {
    dispatch(queryPaginationFirstBatch());
    try {
      lastQueryRef.current = query(queryRef, limit(pageSize));
      const data = await getDocs(lastQueryRef.current);
      const lastDoc = data.docs[data.size - 1];
      const docs = {};
      data.docs.forEach(i => {
        const doc = getDocData(i);
        docs[doc.id] = doc;
      });
      onFirstBatchSuccess(lastDoc, docs);
    } catch (error) {
      onFirstBatchError(error);
    }
  }, [dispatch, onFirstBatchError, onFirstBatchSuccess, pageSize, queryRef]);

  useEffect(() => {
    // If the dependencies changes and we already had data loaded, then reset
    dispatch(queryPaginationReset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryRef.path]);

  useEffect(() => {
    if (!queryRef) {
      return;
    }
    if (!stateRef.current.loaded && !stateRef.current.loading) {
      getFirstBatch();
    }
  }, [dispatch, getFirstBatch, queryRef]);

  return { ...stateRef.current, data: Object.values(stateRef.current.data) };
};

export default useFirestoreQueryPagination;
