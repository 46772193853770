export const ACTIONS = {
  UTI_FQB_FETCH: 'UTI_FQB_FETCH',
  UTI_FQB_FETCH_ERROR: 'UTI_FQB_FETCH_ERROR',
  UTI_FQB_FETCH_BATCH_SUCCESS: 'UTI_FQB_FETCH_BATCH_SUCCESS',
  UTI_FQB_RESET: 'UTI_FQB_RESET',
};

export const queryFetch = batchesAmount => ({
  payload: { batchesAmount },
  type: ACTIONS.UTI_FQB_FETCH,
});

export const queryFetchError = error => ({
  payload: error,
  type: ACTIONS.UTI_FQB_FETCH_ERROR,
});

export const queryFetchBatchSuccess = (batchId, lastDocument, data, next) => ({
  payload: { batchId, lastDocument, data, next },
  type: ACTIONS.UTI_FQB_FETCH_BATCH_SUCCESS,
});

export const queryReset = () => ({
  type: ACTIONS.UTI_FQB_RESET,
});
