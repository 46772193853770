import moment from 'moment';

const DATE_TYPES = {
  hours: 'hours',
  days: 'days',
  months: 'months',
};
export const RELATIVE_OPTIONS = [
  {
    label: '6h',
    value: 6,
    dateType: DATE_TYPES.hours,
  },
  {
    label: '12h',
    value: 12,
    dateType: DATE_TYPES.hours,
  },
  {
    label: '24h',
    value: 24,
    dateType: DATE_TYPES.hours,
  },
  {
    label: '7d',
    value: 7,
    dateType: DATE_TYPES.days,
  },
  {
    label: '1m',
    value: 1,
    dateType: DATE_TYPES.months,
  },
  {
    label: '3m',
    value: 3,
    dateType: DATE_TYPES.months,
  },
];

export const RELATIVE_DATE_PER_DEFAULT = {
  isRelative: true,
  dateType: 'days',
  relativeAmount: 6,
};

export const GROUP_BY_OPTIONS = ['hour', 'day', 'week', 'month', 'year'];

export const GROUP_BY_DEFAULT = 'day';

export const TODAY_DATE = moment();
export const ONE_WEEK_BACK = moment().subtract(1, 'weeks');
export const RELATIVE_ABSOLUTE = [
  {
    label: 'relative',
    value: true,
  },
  {
    label: 'absolute',
    value: false,
  },
];
