import React, { useCallback } from 'react';
import { Button, Modal, Divider, Avatar } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import IntlMessages from 'util/IntlMessages';
import { ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons';
import { ALLOWED_ROLES } from 'packages/utils';
import ComponentBlocker from 'components/ComponentBlocker';

import { getRouteToEditGroup } from '../../constants';
import { groupDelete } from '../../redux/actions';

import styles from './styles.module.less';

const { confirm } = Modal;

const Group = ({ group: { divId, users, id, name, avatarURL } }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const division = useSelector(
    ({ user }) => user.profile.data.organizations[orgId].divisions[divId]?.name,
  );

  const handleEdit = () => history.push(getRouteToEditGroup(divId, id));

  const showDeleteConfirm = useCallback(() => {
    confirm({
      cancelText: intl.formatMessage({ id: 'button.no' }),
      content: intl.formatMessage({ id: 'groups.list.deleteModal.content' }),
      icon: <ExclamationCircleOutlined />,
      okText: intl.formatMessage({ id: 'button.yes' }),
      okType: 'danger',
      onOk() {
        dispatch(groupDelete(orgId, divId, id));
      },
      title: intl.formatMessage({ id: 'groups.list.deleteModal.title' }, { group: name }),
    });
  }, [dispatch, divId, id, name, intl, orgId]);

  return (
    <div className={classnames('gx-d-flex m-bottom-3', styles.mainContainer)}>
      <Avatar size={40} src={avatarURL} icon={!avatarURL && <UserOutlined />} className="gx-mr-2" />
      <div className="gx-flex-1">
        <div className="gx-d-flex gx-align-items-center">
          <span className={styles.boldText}>{name}</span>
          <Divider className={styles.divider} type="vertical" orientation="center" />
          <span className={classnames('gx-flex-1', styles.division)}>{division}</span>
        </div>
        <span className={styles.membersAmount}>
          <IntlMessages id="groups.group.membersAmount" values={{ amount: users.length }} />
        </span>
      </div>
      <div className="gx-flex-row gx-justify-content-end">
        <ComponentBlocker
          divId={divId}
          allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.GROUPS.UPDATE}
        >
          <Button className="gx-mb-0" onClick={handleEdit} type="text">
            <IntlMessages id="button.edit" />
          </Button>
        </ComponentBlocker>
        <ComponentBlocker
          divId={divId}
          allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.GROUPS.DELETE}
        >
          <Button className="gx-mb-0" danger onClick={showDeleteConfirm} type="text">
            <IntlMessages id="button.delete" />
          </Button>
        </ComponentBlocker>
      </div>
    </div>
  );
};

Group.propTypes = {
  group: PropTypes.shape({
    users: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    divId: PropTypes.string.isRequired,
    avatarURL: PropTypes.string,
  }).isRequired,
};

export default Group;
