import React from 'react';
import classnames from 'classnames';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import IntlMessages from 'util/IntlMessages';
import CustomInput from 'components/CustomInput';

import styles from '../../styles.module.less';

const StepThree = ({ onFinish, onPrevious }) => {
  const intl = useIntl();

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
  });

  const isOrganizationReady = useSelector(({ user }) => {
    const organizationId = Object.keys(user.profile.data?.organizations || {})?.[0];
    return !!organizationId && !!user.access.data?.claims?.org?.[organizationId];
  });

  const isDivisionLoadingOrSave = useSelector(
    ({ divisions: { division: { save } = {} } }) => save?.loading || (save?.loaded && !save?.error),
  );

  const onEnter = f => e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault();
      f();
    }
  };

  return (
    <>
      <h2 className={classnames('m-bottom-3', styles.titleSmall)}>
        <IntlMessages id="onboarding.stepThree.title" />
      </h2>
      <span className={classnames('m-bottom-8', styles.infoText)}>
        <IntlMessages id="onboarding.stepThree.description" />
      </span>
      <form onSubmit={handleSubmit(onFinish)} className={styles.formContainer}>
        <CustomInput
          autoFocus
          onKeyDown={onEnter(() => setFocus('description'))}
          label={intl.formatMessage({ id: 'onboarding.stepThree.divisionName' })}
          textClassName={styles.whiteText}
          errors={errors}
          register={register('name', {
            required: intl.formatMessage({ id: 'form.required.msg' }),
          })}
        />
        <CustomInput
          className="m-top-5"
          label={intl.formatMessage({ id: 'onboarding.stepThree.divisionDescription' })}
          textClassName={styles.whiteText}
          errors={errors}
          register={register('description')}
        />

        <div className={classnames('m-top-5', styles.buttonsPanel)}>
          <Button type="text" className={styles.text} onClick={onPrevious}>
            <ArrowLeftOutlined className="m-right-1" />
            <IntlMessages id="onboarding.wizard.previous" />
          </Button>
          {!isOrganizationReady ? (
            <span className={styles.infoText}>
              <Spin />
              <IntlMessages id="onboarding.stepThree.waitForAccess" />
            </span>
          ) : (
            <Button
              type={isValid ? 'primary' : 'default'}
              shape="circle"
              htmlType="submit"
              disabled={!isValid || !isOrganizationReady || isDivisionLoadingOrSave}
            >
              <ArrowRightOutlined />
            </Button>
          )}
        </div>
      </form>
    </>
  );
};

export default StepThree;
