import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { union } from 'lodash';

import * as ui from 'packages/ui';
import IntlMessages from 'util/IntlMessages';
import { getRouteToUsersHome } from 'packages/users';
import { ALLOWED_ROLES } from 'packages/utils';
import { PATHS as EVENT_TYPES_PATHS } from 'packages/eventTypes';
import { PATHS as GROUPS_PATHS } from 'packages/groups';
import { PATHS as EVENTS_PATHS } from 'packages/events';
import { PATHS as DASHBOARD_PATHS } from 'packages/dashboard';
import { PATHS as LOCATIONS_PATHS } from 'packages/locations';
import { PATHS as SCHEDULES_PATHS } from 'packages/schedules';
import { PATHS as TRIGGERS_PATHS } from 'packages/triggers';
import { PATHS as USER_POSITION_HISTORY_PATHS } from 'packages/reports';
import { PATHS as CONFIG_PATHS } from 'routes/config/constants';
import { PATHS as WORK_TEMPLATES_PATHS } from 'packages/workTemplates';
import { PATHS as WORK_ORDERS_PATHS } from 'packages/workOrders';

import { switchLanguage } from 'appRedux/actions';

import SidebarLogo from './SidebarLogo';
import OrganizationSelector from './components/OrganizationSelector';
import languageData from '../Topbar/languageData';

import styles from './styles.module.less';
import { useRouteMatch } from 'react-router-dom';
import useRemoteConfig from 'packages/utils/hooks/useRemoteConfig';

const { MenuOption } = ui;

const SidebarContent = React.memo(() => {
  let { url } = useRouteMatch();
  const dispatch = useDispatch();

  const { pathname } = useSelector(({ common }) => common);
  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);

  const { value: historyViewAvailableRC } = useRemoteConfig('position_history_view');

  const historyViewAvailable = useMemo(() => {
    if (!historyViewAvailableRC && Object.values(historyViewAvailableRC).length === 0) return false;

    if (
      typeof historyViewAvailableRC === 'object' &&
      Object.values(historyViewAvailableRC).length === 0
    ) {
      return true;
    }

    return (historyViewAvailableRC && historyViewAvailableRC[orgId || '']) || false;
  }, [historyViewAvailableRC, orgId]);

  return (
    <div className="gx-flex-column gx-h-100">
      <SidebarLogo />
      <div className={styles.content}>
        <OrganizationSelector />
        <MenuOption selected={pathname === url} to={url}>
          <span className="material-symbols-outlined gx-mr-3">home</span>
          <IntlMessages id="general.home" />
        </MenuOption>
        <MenuOption
          allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.EVENTS_SUMMARY.LIST}
          selected={pathname.startsWith(EVENTS_PATHS.BASE_URL)}
          to={`${url}/${EVENTS_PATHS.BASE_URL}`}
        >
          <i className="material-symbols-outlined gx-mr-3">timeline</i>
          <IntlMessages id="sidebar.configuration.timeline" />
        </MenuOption>

        <MenuOption
          allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.WORK_ORDER.LIST}
          selected={pathname.startsWith(WORK_ORDERS_PATHS.BASE_URL)}
          to={`${url}/${WORK_ORDERS_PATHS.BASE_URL}`}
        >
          <span className="material-symbols-outlined gx-mr-3">desktop_mac</span>
          <IntlMessages id="sidebar.menu.active.work.orders" />
        </MenuOption>

        <MenuOption
          selected={pathname.startsWith(DASHBOARD_PATHS.SCHEDULES)}
          to={`${url}/${DASHBOARD_PATHS.SCHEDULES}`}
        >
          <span className="material-symbols-outlined gx-mr-3">add_chart</span>
          <IntlMessages id="sidebar.dashboards.schedules" />
        </MenuOption>

        <MenuOption
          selected={pathname.startsWith(DASHBOARD_PATHS.ACTIVE_SCHEDULES)}
          to={`${url}/${DASHBOARD_PATHS.ACTIVE_SCHEDULES}`}
        >
          <span className="material-symbols-outlined gx-mr-3">share</span>
          <IntlMessages id="sidebar.dashboards.actives" />
        </MenuOption>

        <MenuOption
          selected={pathname.startsWith(DASHBOARD_PATHS.EVENTS)}
          to={`${url}/${DASHBOARD_PATHS.EVENTS}`}
        >
          <span className="material-icons">show_chart</span>
          <IntlMessages id="sidebar.dashboards.metrics" />
        </MenuOption>

        {historyViewAvailable && (
          <MenuOption
            selected={pathname.startsWith(USER_POSITION_HISTORY_PATHS.USER_POSITIONS_HISTORY)}
            to={`${url}/${USER_POSITION_HISTORY_PATHS.USER_POSITIONS_HISTORY}`}
          >
            <span className="material-icons">history</span>
            <IntlMessages id="sidebar.positionHistory.entry" />
          </MenuOption>
        )}

        <hr />
        <MenuOption
          allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.SCHEDULES.LIST}
          selected={pathname.startsWith(SCHEDULES_PATHS.BASE_URL)}
          to={`${url}/${SCHEDULES_PATHS.BASE_URL}`}
        >
          <i className="material-symbols-outlined gx-mr-3">calendar_today</i>
          <IntlMessages id="sidebar.menu.schedules" />
        </MenuOption>
        <MenuOption
          allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.WORK_TEMPLATES.LIST}
          selected={pathname.startsWith(WORK_TEMPLATES_PATHS.BASE_URL)}
          to={`${url}/${WORK_TEMPLATES_PATHS.BASE_URL}`}
        >
          <span className="material-icons-outlined gx-mr-3">task_alt</span>
          <IntlMessages id="sidebar.menu.work.templates" />
        </MenuOption>
        <MenuOption
          allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.TRIGGERS.LIST}
          selected={pathname.startsWith(TRIGGERS_PATHS.BASE_URL)}
          to={`${url}/${TRIGGERS_PATHS.BASE_URL}`}
        >
          <i className="material-icons">flash_on</i>
          <IntlMessages id="sidebar.menu.triggers" />
        </MenuOption>
        <MenuOption
          allowedRoles={union(
            ALLOWED_ROLES.ORGANIZATIONS.EVENT_TYPES.LIST,
            ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.EVENT_TYPES.LIST,
          )}
          selected={pathname.startsWith(EVENT_TYPES_PATHS.BASE_URL)}
          to={`${url}/${EVENT_TYPES_PATHS.BASE_URL}`}
        >
          <span className="material-icons-outlined gx-mr-3">assignment</span>
          <IntlMessages id="sidebar.menu.forms" />
        </MenuOption>
        <MenuOption
          allowedRoles={union(
            ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.LIST,
            ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.LIST,
          )}
          selected={pathname.startsWith(LOCATIONS_PATHS.BASE_URL)}
          to={`${url}/${LOCATIONS_PATHS.BASE_URL}`}
        >
          <i className="material-icons-outlined gx-mr-3">location_on</i>
          <IntlMessages id="sidebar.configuration.locations" />
        </MenuOption>

        <hr />
        <MenuOption
          allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.UPDATE}
          selected={pathname.startsWith(`/${CONFIG_PATHS.ORGANIZATION}`)}
          to={`${url}/${CONFIG_PATHS.ORGANIZATION}`}
        >
          <i className="material-icons">business</i>
          <IntlMessages id="sidebar.configuration.organization" />
        </MenuOption>
        <MenuOption
          allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.GET}
          selected={pathname.startsWith(`/${CONFIG_PATHS.DIVISIONS}`)}
          to={`${url}/${CONFIG_PATHS.DIVISIONS}`}
        >
          <span className="material-icons-outlined gx-mr-3">map</span>
          <IntlMessages id="sidebar.configuration.divisions" />
        </MenuOption>
        <MenuOption
          allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.GROUPS.LIST}
          selected={pathname.startsWith(GROUPS_PATHS.BASE_URL)}
          to={`${url}/${GROUPS_PATHS.BASE_URL}`}
        >
          <span className="material-icons-outlined gx-mr-3">groups</span>
          <IntlMessages id="sidebar.configuration.groups" />
        </MenuOption>
        <MenuOption
          allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.USERS.LIST}
          selected={pathname === getRouteToUsersHome()}
          to={`${url}/${getRouteToUsersHome()}`}
        >
          <span className="material-icons-outlined gx-mr-3">group</span>
          <IntlMessages id="sidebar.configuration.users" />
        </MenuOption>
        <div className={styles.devInfo}>
          <small>Version: {SYSTEM.version}</small>
        </div>
        {process.env.NODE_ENV === 'development' && (
          <>
            <hr />
            <MenuOption
              className={styles.compactOption}
              selected={pathname.startsWith(EVENTS_PATHS.BASE_URL)}
              to={`${url}/${EVENTS_PATHS.TEMP_CREATE}`}
            >
              Create Events
            </MenuOption>
            <hr />
            <MenuOption
              className={styles.compactOption}
              as="a"
              onClick={() =>
                document.body.classList.contains('dark-mode')
                  ? document.body.classList.remove('dark-mode')
                  : document.body.classList.add('dark-mode')
              }
            >
              Dark Mode
            </MenuOption>
            <MenuOption
              className={styles.compactOption}
              as="a"
              onClick={() => dispatch(switchLanguage(languageData.find(l => l.locale === 'es')))}
            >
              Es
            </MenuOption>
            <MenuOption
              className={styles.compactOption}
              as="a"
              onClick={() => dispatch(switchLanguage(languageData.find(l => l.locale === 'en')))}
            >
              En
            </MenuOption>
            <hr />
            <MenuOption
              className={styles.compactOption}
              as="a"
              target="_blank"
              href="https://wieldy.g-axon.work/"
            >
              <i className="material-icons">link</i>
              Wieldy
            </MenuOption>
            <MenuOption
              className={styles.compactOption}
              as="a"
              target="_blank"
              href="https://fonts.google.com/icons"
            >
              <i className="material-icons">link</i>
              Material icons
            </MenuOption>
            <hr />
            <div>
              {Object.keys(ui).map(name => (
                <MenuOption
                  className={styles.compactOption}
                  key={name}
                  selected={pathname.endsWith(name)}
                  to={`/ui/component/${name}`}
                >
                  <i className="material-icons">call_to_action</i>
                  {name}
                </MenuOption>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default SidebarContent;
