import PropTypes from 'prop-types';
import { ACCESS_TYPES } from 'packages/utils/access';

export const userProptype = PropTypes.shape({
  displayName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  divisions: PropTypes.objectOf(
    PropTypes.shape({
      access: PropTypes.oneOf(Object.values(ACCESS_TYPES)).isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      descriptionn: PropTypes.string,
      avatarURL: PropTypes.string,
    }),
  ).isRequired,
  access: PropTypes.oneOf(Object.values(ACCESS_TYPES)).isRequired,

  photoURL: PropTypes.string,
  createdAt: PropTypes.any,
  updatedAt: PropTypes.any,
});

export const usersFromMembers = PropTypes.shape({
  displayName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  photoURL: PropTypes.string,
});

export const groupsFromMembers = PropTypes.shape({
  createdAt: PropTypes.any,
  divId: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  uid: PropTypes.string,
  updatedAt: PropTypes.any,
  users: PropTypes.arrayOf(usersFromMembers),
});
