export const EVENT_DEFAULT = '1';

export const EVENT_OPTIONS = [
  {
    key: '1',
    label: 'Cronogramas',
    children: null,
    name: 'schedules',
  },
];

export const TYPES_PATH = {
  schedule_end: 'SCHEDULE_END',
  event_created: 'EVENT_CREATED',
};

export const SUBSCRIPTIONS_PAYLOAD = ['EMAIL', 'PUSH'];
