import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';

const UsersGroupsWidget = React.memo(({ users, groups }) => {
  const withoutUsers = users === null;
  const withoutGroups = groups === null;

  return (
    <div className="gx-flex-row">
      {!withoutGroups && (
        <div className="gx-flex-row">
          <span className="material-icons-outlined">group</span>
          <div className="gx-m-1">
            {
              <IntlMessages
                id="component.usersGroupsWidget.groups.label"
                values={{ amount: groups }}
              />
            }
          </div>
        </div>
      )}
      {!withoutUsers && (
        <div className="gx-flex-row">
          <span className="material-icons-outlined">person</span>
          <div className="gx-m-1">
            {
              <IntlMessages
                id="component.usersGroupsWidget.users.label"
                values={{ amount: users }}
              />
            }
          </div>
        </div>
      )}
    </div>
  );
});

UsersGroupsWidget.defaultProps = {
  users: null,
  groups: null,
};

UsersGroupsWidget.propTypes = {
  users: PropTypes.number,
  groups: PropTypes.number,
};

export { UsersGroupsWidget };
