import React from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

import { QuestionCircleFilled } from '@ant-design/icons';

import styles from './styles.module.less';

const TooltipLabel = ({ label, tooltip }) => (
  <div className={styles.mainContainer}>
    <span className="gx-guarnic-label">{label}</span>
    <Tooltip title={tooltip} placement="left">
      <QuestionCircleFilled />
    </Tooltip>
  </div>
);

TooltipLabel.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default TooltipLabel;
