import React from 'react';
import { Button } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedTime } from 'react-intl';

import IntlMessages from 'util/IntlMessages';

import styles from './styles.module.less';

const Invitation = ({ email, expiresAt, id, sentAt, ...props }) => {
  const now = Date.now();
  const isExpired = !!expiresAt && expiresAt < now;
  const expiredDate = expiresAt ? new Date(expiresAt) : null;
  const dateSent = sentAt ? new Date(sentAt) : null;

  const getStatus = () => {
    if (isExpired) {
      return (
        <IntlMessages
          id="users.invitation.invite.expired"
          values={{
            at: (
              <>
                <FormattedDate value={expiredDate} year="numeric" month="long" day="2-digit" />{' '}
                <FormattedTime
                  value={expiredDate}
                  hour="numeric"
                  minute="numeric"
                  second="numeric"
                />
              </>
            ),
          }}
        />
      );
    }
    if (dateSent) {
      return (
        <IntlMessages
          id="users.invitation.invite.sent"
          values={{
            at: (
              <>
                <FormattedDate value={dateSent} year="numeric" month="long" day="2-digit" />{' '}
                <FormattedTime value={dateSent} hour="numeric" minute="numeric" second="numeric" />
              </>
            ),
          }}
        />
      );
    }

    return <IntlMessages id="users.invitation.invite.pending" />;
  };

  const onResend = ({ currentTarget: { value } }) => props.onResend(value);

  const onRevoke = ({ currentTarget: { value } }) => props.onRevoke(value);

  return (
    <div className={classnames('ant-card', styles.invitation)}>
      <div>{email}</div>
      <div>
        <i className={styles.status}>{getStatus()}</i>
        <Button type="link" className={styles.button} value={id} onClick={onRevoke}>
          <IntlMessages id="users.invitation.invite.revoke" />
        </Button>
        {(!!sentAt || isExpired) && (
          <Button type="primary" className={styles.button} value={id} onClick={onResend}>
            <IntlMessages id="users.invitation.invite.resend" />
          </Button>
        )}
      </div>
    </div>
  );
};

Invitation.propTypes = {
  email: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onResend: PropTypes.func.isRequired,
  onRevoke: PropTypes.func.isRequired,

  expiresAt: PropTypes.number,
  sentAt: PropTypes.number,
};

Invitation.defaultProps = {
  expiresAt: null,
  sentAt: null,
};

export default React.memo(Invitation);
