import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import { ChildrenSchema } from 'packages/utils';

import styles from './index.module.less';

const SiteContent = ({ breadcrumb, children, className, title, wrapped, actions }) => (
  <div className={classnames(styles.wrapper, 'gx-w-100', className)}>
    {!!breadcrumb && (
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {breadcrumb.length > 0 ? (
            <Link to="/">
              <IntlMessages id="general.home" />
            </Link>
          ) : (
            <IntlMessages id="general.home" />
          )}
        </Breadcrumb.Item>
        {breadcrumb.map(bc => (
          <Breadcrumb.Item key={`${bc.path}-${bc.name}`}>
            {bc.path ? <Link to={bc.path}>{bc.name}</Link> : bc.name}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    )}
    <div className={styles.header}>
      {!!title && <h1 className={styles.title}>{title}</h1>}
      {actions}
    </div>
    <div className={classnames(styles.content, { [styles.invisible]: !wrapped })}>{children}</div>
  </div>
);

SiteContent.propTypes = {
  children: ChildrenSchema.isRequired,

  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      name: ChildrenSchema.isRequired,
      path: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
  title: ChildrenSchema,
  wrapped: PropTypes.bool,
  actions: ChildrenSchema,
};

SiteContent.defaultProps = {
  breadcrumb: null,
  className: '',
  title: '',
  wrapped: true,
  actions: [],
};

export default React.memo(SiteContent);
