import React from 'react';
import PropTypes from 'prop-types';
import { Space, Typography } from 'antd';
import styles from './styles.module.less';
import classNames from 'classnames';

const LabelValue = ({ name, value, vertical, required, className }) => {
  const verticalProps = vertical && {
    direction: 'vertical',
    size: 4,
  };
  const title = vertical ? (
    name
  ) : (
    <>
      {name}
      {': '}
    </>
  );

  return (
    <Space {...verticalProps} style={{ width: '100%' }} className={className}>
      <Typography.Text className={classNames(required && styles.requiredLabel, styles.label)}>
        {title}
      </Typography.Text>
      {value && <Typography.Text className={styles.value}>{value}</Typography.Text>}
    </Space>
  );
};

LabelValue.defaultProps = {
  vertical: false,
  required: false,
  className: '',
};

LabelValue.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.number]).isRequired,
  vertical: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
};

export default LabelValue;
