import moment from 'moment-timezone';

import { get } from '../storage';

const DEFAULT_TIMEZONE = get('timeZone', moment.tz.guess(true));

export const getDefaultTimeZone = () => DEFAULT_TIMEZONE;

export const setDefaultTimeZone = tz => moment.tz.setDefault(tz);

export const convertToDate = (seconds, nanoseconds) => {
  // Convert nanoseconds to milliseconds
  const milliseconds = nanoseconds / 1e6;

  // Create a new Date object with the seconds and milliseconds
  const date = new Date(seconds * 1000 + milliseconds);

  return date;
};

setDefaultTimeZone(getDefaultTimeZone());
