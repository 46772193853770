import { useMap } from 'react-leaflet';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

//NOTE: component was created to viewport bounds
const Radious = ({ onChangeMap }) => {
  const map = useMap();

  const handleMap = e => {
    const currentTarget = e.target;
    const currentPos = map.getCenter();
    const zoom = currentTarget._zoom;

    const bounds = map?.getBounds();
    const southWest = bounds?.getSouthWest();
    const northEast = bounds?.getNorthEast();

    const distance = (southWest && northEast && southWest.distanceTo(northEast)) || 1000;

    if (onChangeMap) onChangeMap([currentPos?.lat, currentPos?.lng], distance, zoom);
  };

  useEffect(() => {
    map.on('zoomend', handleMap);
    map.on('moveend', handleMap);
  }, []);

  return null;
};

Radious.defaultProps = {
  onChangeMap: null,
};

Radious.propTypes = {
  onChangeMap: PropTypes.func,
};

export default Radious;
