import React, { useEffect, useState } from 'react';
import { Form, Select, Button, Spin, Drawer } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { db } from 'firebase/firebase';
import { collection } from 'firebase/firestore';
import { useFirestoreQuery } from 'packages/utils';
import IntlMessages from 'util/IntlMessages';

import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import styles from './styles.module.less';

import SelectedUsers from '../SelectedUsers';
import SelectedGroups from '../SelectedGroups';
import { FORMS, MEMBER_TYPES } from '../../constants';
import { useResetFormOnCloseModal } from '../../util';
import Member from '../Member';

const { Item } = Form;
const { Option } = Select;

const MembersForm = ({ isVisible, onCancel, divId, defaultMembers, onlyRead }) => {
  const [form] = Form.useForm();
  const [selectedGroups, setSelectedGroups] = useState(defaultMembers.groups);
  const [selectedUsers, setSelectedUsers] = useState(defaultMembers.users);

  useEffect(() => {
    if (isVisible) {
      setSelectedGroups(defaultMembers.groups);
      setSelectedUsers(defaultMembers.users);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const [seeAllGroups, setSeeAllGroups] = useState(false);
  const [seeAllUsers, setSeeAllUsers] = useState(false);

  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const divUsersRef = collection(db, 'organizations', orgId, 'divisions', divId, 'users');
  const { data: divUsers = [], loading: usersLoading } = useFirestoreQuery(divUsersRef, [
    orgId,
    divId,
  ]);

  const groupsRef = collection(db, 'organizations', orgId, 'divisions', divId, 'groups');
  const { data: groups = [], loading: groupsLoading } = useFirestoreQuery(groupsRef, [
    orgId,
    divId,
  ]);

  useResetFormOnCloseModal({
    form,
    isVisible,
  });

  const onOk = () => {
    form.setFieldsValue({
      groups: selectedGroups,
      users: selectedUsers,
    });
    form.submit();
  };

  const onChange = (id, { type }) => {
    if (type === MEMBER_TYPES.GROUP) {
      const newGroup = groups.find(group => group.id === id);
      setSelectedGroups([...selectedGroups, newGroup]);
    } else {
      const newUser = divUsers.find(user => user.id === id);
      setSelectedUsers([...selectedUsers, newUser]);
    }
  };

  const handleRemoveUser = userId => {
    setSelectedUsers(selectedUsers.filter(user => user.id !== userId));
  };
  const handleRemoveGroup = groupId => {
    setSelectedGroups(selectedGroups.filter(group => group.id !== groupId));
  };

  return (
    <Drawer className={styles.mainContainer} open={isVisible} closable={false} footer={null}>
      <BoxContainer>
        <BoxContainer content fixed>
          <FilterContainer
            goBack={onCancel}
            title={
              <Title.Header
                className="gx-pt-1 gx-pb-1"
                value={<IntlMessages id="schedule.form.members.modal.title" />}
              />
            }
            actionButtons={[
              {
                label: <IntlMessages id="form.save" />,
                action: onOk,
                type: 'primary',
              },
            ]}
          />
        </BoxContainer>
        <BoxContainer content>
          {!onlyRead && (
            <Form
              className={styles.customMembersForm}
              form={form}
              name={FORMS.MEMBERS}
              layout="vertical"
            >
              {usersLoading || groupsLoading ? (
                <Spin size="large" />
              ) : (
                <Item
                  label={
                    <Title.LabelForm
                      value={<IntlMessages id="schedule.form.members.modal.addMmebers.label" />}
                    />
                  }
                  name="members"
                >
                  <Select
                    autoClearSearchValue
                    onSelect={onChange}
                    optionFilterProp="label"
                    optionLabelProp="label"
                    showSearch
                  >
                    {divUsers.map(user => (
                      <Option
                        disabled={!!selectedUsers.find(({ id }) => id === user.id)}
                        key={user.id}
                        label={`${user?.firstName} ${user?.lastName}`}
                        type={MEMBER_TYPES.USER}
                        value={user.id}
                      >
                        <Member
                          className="gx-mb-0"
                          comment={user.email}
                          imageURL={user.photoURL}
                          name={`${user?.firstName} ${user?.lastName}`}
                        />
                      </Option>
                    ))}
                    {groups?.map(group => (
                      <Option
                        disabled={selectedGroups.find(({ id }) => id === group.id)}
                        key={group.id}
                        label={group.name}
                        type={MEMBER_TYPES.GROUP}
                        value={group.id}
                      >
                        <Member imageURL={group.avatarURL} name={group.name} className="gx-mb-0" />
                      </Option>
                    ))}
                  </Select>
                </Item>
              )}
            </Form>
          )}

          {/* Users Assigned */}
          <SelectedUsers
            users={seeAllUsers ? selectedUsers : selectedUsers.slice(0, 2)}
            onRemoveUser={onlyRead ? null : handleRemoveUser}
          />
          {selectedUsers.length > 2 && !seeAllUsers && (
            <Button type="link" onClick={() => setSeeAllUsers(true)}>
              <IntlMessages
                id="schedule.form.members.modal.users.seeMore"
                values={{ amount: selectedUsers.slice(2).length }}
              />
            </Button>
          )}
          {/* Groups Assigned */}
          <SelectedGroups
            className="gx-guarnic-pt-3"
            groups={seeAllGroups ? selectedGroups : selectedGroups.slice(0, 2)}
            onRemoveGroup={onlyRead ? null : handleRemoveGroup}
          />
          {selectedGroups.length > 2 && !seeAllGroups && (
            <Button type="link" onClick={() => setSeeAllGroups(true)}>
              <IntlMessages
                id="schedule.form.members.modal.groups.seeMore"
                values={{ amount: selectedGroups.slice(2).length }}
              />
            </Button>
          )}
        </BoxContainer>
      </BoxContainer>
    </Drawer>
  );
};

MembersForm.propTypes = {
  divId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onlyRead: PropTypes.bool,

  defaultMembers: PropTypes.shape({
    users: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        photoURL: PropTypes.string,
      }),
    ),
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        avatarURL: PropTypes.string,
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }),
};

MembersForm.defaultProps = {
  defaultMembers: {
    users: [],
    groups: [],
  },
  onlyRead: false,
};

export default MembersForm;
