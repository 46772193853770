import React from 'react';

import { Divider } from 'antd';
import PropTypes from 'prop-types';

import IntlMessages from 'util/IntlMessages';
import DynamicForm from 'components/Form/DynamicForm';
import Title from 'components/BoxContainer/components/Title';
import TriggersList from 'components/TriggersList';
import WorkOrderMainInfo from 'components/WorkOrders/WorkOrderInfo/WorkOrderMainInfo';

export default function GeneralInformation({ data }) {
  const { templateName, forms = [], triggers = [] } = data;

  return (
    <div className="gx-flex-column">
      <Title.SubTitle className="gx-guarnic-mb-3" value={templateName} />
      <WorkOrderMainInfo data={data} />
      <Divider />
      <div className="gx-guarnic-mb-3">
        <DynamicForm.Display forms={forms} />
      </div>
      <Title.LabelForm
        className="gx-guarnic-mb-1"
        value={<IntlMessages id="work.order.details.general.tasks.associated" />}
      />
      <TriggersList.Simple initLimitTosee={5} includeLocations data={triggers} wrapperData />
    </div>
  );
}

GeneralInformation.propTypes = {
  data: PropTypes.shape({
    workOrderName: PropTypes.string,
    templateName: PropTypes.string,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    duration: PropTypes.number,
    forms: PropTypes.arrayOf(PropTypes.object),
    triggers: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};
